import React, { useMemo, useState, useCallback } from "react";
import { db } from "./firebase";
import { collection, query, getDocs, where } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFileExport,
  faSearch,
  faFilter,
  faSync,
  faColumns,
  faUserFriends,
  faSort,
  faSortUp,
  faSortDown,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import * as XLSX from "xlsx";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import Select from "react-select";
import { motion, AnimatePresence } from "framer-motion";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Tooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";

const formatCurrency = (amount) => {
  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
  }).format(amount);
};

const Compendium = () => {
  const [filters, setFilters] = useState({
    status: ["Active"],
    searchTerm: "",
    selectedFields: [
      "firstName",
      "lastName",
      "companyName",
      "workLocation",
      "workAddress",
      "position",
      "payMethod",
      "salary",
      "email",
      "phone",
      "status",
    ],
  });
  const [showFilters, setShowFilters] = useState(false);

  const fetchEmployees = async () => {
    const usersRef = collection(db, "users");
    const locationsRef = collection(db, "locations");

    // First, get all locations to create a lookup map
    const locationsSnapshot = await getDocs(locationsRef);
    const locationMap = {};
    locationsSnapshot.docs.forEach((doc) => {
      const data = doc.data();
      locationMap[data.name] = data.address;
    });

    // Then proceed with the regular user query
    const q = query(
      usersRef,
      where(
        "status",
        "in",
        filters.status.length > 0
          ? filters.status
          : ["Active", "inactive", "terminated"]
      )
    );

    const querySnapshot = await getDocs(q);
    const employeeData = querySnapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        firstName: data.firstName || "Unknown",
        lastName: data.lastName || "Unknown",
        companyName: data.companyName || "",
        workLocation: data.workLocation || "Unknown",
        workAddress: locationMap[data.workLocation] || "Unknown",
        payMethod: data.payMethod || "Unknown",
        salary: parseFloat(data.salary) || 0,
        rate: parseFloat(data.rate) || 0,
        weeklySalary: parseFloat(data.salary)
          ? parseFloat(data.salary) / 52
          : 0,
        account: data.account || "",
        bank: data.bank || "",
        routing: data.routing || "",
        position: data.position || "",
        email: data.email || "",
        phone: data.phone || "",
        address: data.address || "",
        city: data.city || "",
        state: data.state || "",
        zip: data.zip || "",
        hiringDate: data.hiringDate || "",
        uid: data.uid || "",
        status: data.status || "",
        role: data.role || "",
        ein: data.ein || "",
        itin: data.itin || "",
        socialSecurity: data.socialSecurity || "",
        notes: data.notes || "",
        careerEvolution: data.careerEvolution || "",
        specialAbilities: data.specialAbilities || "",
        birthDate: data.birthDate || "",
      };
    });

    return employeeData.sort((a, b) => {
      const aName = a.companyName || `${a.firstName} ${a.lastName}`;
      const bName = b.companyName || `${b.firstName} ${b.lastName}`;
      return aName.localeCompare(bName);
    });
  };

  const {
    data: employees,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["employees", filters.status],
    queryFn: fetchEmployees,
  });

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();

    // Create header row using selected fields' labels
    const wsHeader = [
      filters.selectedFields.map(
        (field) =>
          availableFields.find((f) => f.value === field)?.label || field
      ),
    ];

    // Use tableData instead of employees to respect the search filter
    const wsData = tableData.map((employee) =>
      filters.selectedFields.map((field) => {
        const value = employee[field];

        // Format specific fields
        if (field === "salary") {
          return formatCurrency(value);
        } else if (field === "weeklySalary") {
          return formatCurrency(value);
        } else if (field === "hiringDate" || field === "birthDate") {
          return value ? new Date(value).toLocaleDateString() : "";
        } else {
          return value?.toString() || "";
        }
      })
    );

    const ws = XLSX.utils.aoa_to_sheet(wsHeader.concat(wsData));

    // Auto-size columns
    const colWidths = wsHeader[0].map((header, i) => {
      const maxLength = Math.max(
        header.length,
        ...wsData.map((row) => (row[i]?.toString() || "").length)
      );
      return { wch: maxLength + 2 };
    });
    ws["!cols"] = colWidths;

    XLSX.utils.book_append_sheet(wb, ws, "Employee Data");

    const filename = `employee_data_${
      new Date().toISOString().split("T")[0]
    }.xlsx`;
    XLSX.writeFile(wb, filename);
  };

  const [sorting, setSorting] = useState([
    {
      id: "firstName",
      desc: false,
    },
  ]);

  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });

  const availableFields = useMemo(
    () => [
      { value: "address", label: "Address" },
      { value: "account", label: "Account" },
      { value: "bank", label: "Bank" },
      { value: "birthDate", label: "Birth Date" },
      { value: "careerEvolution", label: "Career Evolution" },
      { value: "city", label: "City" },
      { value: "companyName", label: "Company Name" },
      { value: "ein", label: "EIN" },
      { value: "email", label: "Email" },
      { value: "firstName", label: "First Name" },
      { value: "hiringDate", label: "Hiring Date" },
      { value: "itin", label: "ITIN" },
      { value: "lastName", label: "Last Name" },
      { value: "notes", label: "Notes" },
      { value: "payMethod", label: "Pay Method" },
      { value: "phone", label: "Phone" },
      { value: "position", label: "Position" },
      { value: "rate", label: "Rate" },
      { value: "role", label: "Role" },
      { value: "routing", label: "Routing" },
      { value: "salary", label: "Salary" },
      { value: "socialSecurity", label: "Social Security" },
      { value: "specialAbilities", label: "Special Abilities" },
      { value: "state", label: "State" },
      { value: "status", label: "Status" },
      { value: "uid", label: "User ID" },
      { value: "workLocation", label: "Work Location" },
      { value: "workAddress", label: "Work Address" },
      { value: "zip", label: "ZIP" },
    ],
    []
  );

  const handleStatusChange = useCallback((selectedStatuses) => {
    setFilters((prev) => ({ ...prev, status: selectedStatuses }));
  }, []);

  const handleSearchChange = useCallback((e) => {
    setFilters((prev) => ({ ...prev, searchTerm: e.target.value }));
  }, []);

  const handleFieldsChange = useCallback((selected) => {
    if (!selected || selected.length === 0) {
      return; // Prevent empty selection
    }

    setFilters((prev) => ({
      ...prev,
      selectedFields: selected.map((option) => option.value),
    }));
  }, []);

  const columns = useMemo(() => {
    const defaultColumn = {
      header: "Name",
      accessorKey: "firstName",
      cell: (info) => info.getValue()?.toString() || "",
    };

    if (!filters.selectedFields.length) {
      return [defaultColumn];
    }

    return filters.selectedFields.map((field) => ({
      header: availableFields.find((f) => f.value === field)?.label || field,
      accessorKey: field,
      cell: (info) => {
        const value = info.getValue();
        if (field === "salary") {
          return formatCurrency(value);
        }
        return value?.toString() || "";
      },
    }));
  }, [filters.selectedFields, availableFields]);

  const tableData = useMemo(() => {
    if (!employees) return [];
    return employees.filter((employee) => {
      if (!filters.searchTerm) return true;
      return filters.selectedFields.some((field) => {
        const value = employee[field];
        return value
          ?.toString()
          .toLowerCase()
          .includes(filters.searchTerm.toLowerCase());
      });
    });
  }, [employees, filters.searchTerm, filters.selectedFields]);

  const table = useReactTable({
    data: tableData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
      },
    },
  };

  const itemVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.4 },
    },
  };

  if (error) {
    return (
      <div className="bg-gray-50 min-h-screen text-gray-800 p-8 flex items-center justify-center">
        <div className="bg-red-100 p-6 rounded-lg border border-red-300 max-w-md">
          <h3 className="text-xl font-bold mb-2 text-red-800">
            Error Loading Data
          </h3>
          <p className="text-gray-700">{error.message}</p>
          <button
            onClick={() => window.location.reload()}
            className="mt-4 bg-red-500 hover:bg-red-600 text-white py-2 px-4 rounded-md transition-all duration-300"
          >
            <FontAwesomeIcon icon={faSync} className="mr-2" /> Try Again
          </button>
        </div>
      </div>
    );
  }

  // Define light mode styles directly
  const baseClasses = "bg-gray-50 text-gray-800";
  const cardClasses = "bg-white border-gray-200";
  const tableHeaderClasses = "bg-gray-100 text-gray-600";
  const tableBodyClasses = "bg-white divide-gray-200";
  const tableRowHoverClasses = "hover:bg-gray-50";

  return (
    <div
      className={`container mx-auto px-4 sm:px-8 ${baseClasses} transition-colors duration-200 rounded-xl`}
      style={{ width: "calc(100vw - 320px)" }}
    >
      <motion.div
        className="flex flex-col h-full max-w-full"
        initial="hidden"
        animate="visible"
        variants={containerVariants}
      >
        <div className="flex justify-between items-center mb-6">
          <motion.h1
            className="text-2xl font-bold flex items-center"
            variants={itemVariants}
          >
            <FontAwesomeIcon
              icon={faUserFriends}
              className="mr-3 text-blue-500"
            />
            User Management
          </motion.h1>

          <div className="flex space-x-3">
            <motion.button
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => setShowFilters(!showFilters)}
              className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-2 px-4 rounded-xl flex items-center transition-colors duration-200"
              data-tooltip-id="filter-tooltip"
              data-tooltip-content="Toggle Filters"
            >
              <FontAwesomeIcon icon={faFilter} className="mr-2" /> Filters
            </motion.button>

            <motion.button
              variants={itemVariants}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={exportToExcel}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded-xl flex items-center transition-colors duration-200"
              data-tooltip-id="export-tooltip"
              data-tooltip-content="Export to Excel"
            >
              <FontAwesomeIcon icon={faFileExport} className="mr-2" /> Export
            </motion.button>
          </div>

          <Tooltip id="filter-tooltip" />
          <Tooltip id="export-tooltip" />
        </div>

        {/* Search Bar - Always visible */}
        <motion.div
          className={`${cardClasses} p-4 rounded-xl shadow border mb-4`}
          variants={itemVariants}
        >
          <div className="relative">
            <input
              type="text"
              value={filters.searchTerm}
              onChange={handleSearchChange}
              placeholder="Search employees..."
              className="w-full p-3 pl-10 bg-white border-gray-300 text-gray-800 placeholder-gray-500 border rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
            />
            <FontAwesomeIcon
              icon={faSearch}
              className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"
            />
            {filters.searchTerm && (
              <button
                onClick={() =>
                  setFilters((prev) => ({ ...prev, searchTerm: "" }))
                }
                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
              >
                <FontAwesomeIcon icon={faTimes} />
              </button>
            )}
          </div>
        </motion.div>

        {/* Filters Section - Toggleable */}
        <AnimatePresence>
          {showFilters && (
            <motion.div
              className={`${cardClasses} my-4 p-6 rounded-xl shadow-lg border`}
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div>
                  <label className="block text-sm font-semibold text-gray-600 mb-2">
                    Employee Status
                  </label>
                  <Select
                    isMulti
                    value={filters.status.map((status) => ({
                      value: status,
                      label: status,
                    }))}
                    onChange={(selected) =>
                      handleStatusChange(
                        selected ? selected.map((option) => option.value) : []
                      )
                    }
                    options={[
                      { value: "Active", label: "Active" },
                      { value: "inactive", label: "Inactive" },
                      { value: "terminated", label: "Terminated" },
                    ]}
                    className="react-select-light"
                    classNamePrefix="select"
                    placeholder="Select status..."
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#3B82F6",
                        primary25: "#DBEAFE",
                      },
                      borderRadius: 12,
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderRadius: "0.75rem",
                      }),
                      menu: (base) => ({
                        ...base,
                        borderRadius: "0.75rem",
                        overflow: "hidden",
                      }),
                    }}
                  />
                </div>

                <div>
                  <label className="block text-sm font-semibold text-gray-600 mb-2">
                    <FontAwesomeIcon icon={faColumns} className="mr-2" />
                    Visible Columns
                  </label>
                  <Select
                    isMulti
                    value={filters.selectedFields.map((field) =>
                      availableFields.find((f) => f.value === field)
                    )}
                    onChange={handleFieldsChange}
                    options={availableFields}
                    className="react-select-light"
                    classNamePrefix="select"
                    placeholder="Select columns..."
                    isOptionDisabled={() => filters.selectedFields.length <= 1}
                    theme={(theme) => ({
                      ...theme,
                      colors: {
                        ...theme.colors,
                        primary: "#3B82F6",
                        primary25: "#DBEAFE",
                      },
                      borderRadius: 12,
                    })}
                    styles={{
                      control: (base) => ({
                        ...base,
                        borderRadius: "0.75rem",
                      }),
                      menu: (base) => ({
                        ...base,
                        borderRadius: "0.75rem",
                        overflow: "hidden",
                      }),
                    }}
                  />
                </div>
              </div>
            </motion.div>
          )}
        </AnimatePresence>

        {/* Data Table */}
        {isLoading ? (
          <motion.div className="w-full" variants={itemVariants}>
            <div className={`${cardClasses} rounded-xl shadow border p-4 mb-4`}>
              <div className="flex items-center mb-4">
                <Skeleton
                  width={150}
                  height={24}
                  baseColor="#E5E7EB"
                  highlightColor="#F3F4F6"
                  borderRadius={12}
                />
                <div className="ml-auto flex space-x-2">
                  <Skeleton
                    width={80}
                    height={36}
                    baseColor="#E5E7EB"
                    highlightColor="#F3F4F6"
                    borderRadius={12}
                  />
                  <Skeleton
                    width={80}
                    height={36}
                    baseColor="#E5E7EB"
                    highlightColor="#F3F4F6"
                    borderRadius={12}
                  />
                </div>
              </div>
              {[...Array(5)].map((_, idx) => (
                <div
                  key={idx}
                  className="flex space-x-4 mb-4 overflow-x-hidden"
                >
                  {[...Array(filters.selectedFields.length)].map(
                    (_, cellIdx) => (
                      <Skeleton
                        key={cellIdx}
                        width={150}
                        height={20}
                        baseColor="#E5E7EB"
                        highlightColor="#F3F4F6"
                        borderRadius={8}
                      />
                    )
                  )}
                </div>
              ))}
            </div>
          </motion.div>
        ) : employees && employees.length === 0 ? (
          <motion.div
            className={`${cardClasses} p-8 rounded-xl shadow border text-center`}
            variants={itemVariants}
          >
            <div className="text-5xl mb-4 opacity-30">
              <FontAwesomeIcon icon={faUserFriends} />
            </div>
            <p className="text-gray-600 text-lg">
              No employees found matching your criteria.
            </p>
            <button
              onClick={() =>
                setFilters({
                  status: ["Active", "inactive", "terminated"],
                  searchTerm: "",
                  selectedFields: filters.selectedFields,
                })
              }
              className="mt-4 bg-blue-500 hover:bg-blue-600 text-white py-2 px-4 rounded-xl transition-colors duration-200"
            >
              Reset Filters
            </button>
          </motion.div>
        ) : (
          <motion.div variants={itemVariants} className="flex flex-col">
            <div
              className={`w-full overflow-hidden ${cardClasses} rounded-xl shadow border`}
              style={{ maxWidth: "calc(100vw - 352px)" }}
            >
              <div className="overflow-x-auto rounded-t-xl">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className={`${tableHeaderClasses} rounded-t-xl`}>
                    {table.getHeaderGroups().map((headerGroup) => (
                      <tr key={headerGroup.id}>
                        {headerGroup.headers.map((header, index) => (
                          <th
                            key={header.id}
                            onClick={header.column.getToggleSortingHandler()}
                            className={`px-5 py-3 text-left text-xs font-semibold uppercase tracking-wider cursor-pointer whitespace-nowrap transition-colors duration-200 ${
                              index === 0 ? "rounded-tl-xl" : ""
                            } ${
                              index === headerGroup.headers.length - 1
                                ? "rounded-tr-xl"
                                : ""
                            }`}
                          >
                            <div className="flex items-center space-x-1">
                              <span>
                                {flexRender(
                                  header.column.columnDef.header,
                                  header.getContext()
                                )}
                              </span>
                              <span>
                                {{
                                  asc: (
                                    <FontAwesomeIcon
                                      icon={faSortUp}
                                      className="ml-1 text-blue-500"
                                    />
                                  ),
                                  desc: (
                                    <FontAwesomeIcon
                                      icon={faSortDown}
                                      className="ml-1 text-blue-500"
                                    />
                                  ),
                                }[header.column.getIsSorted()] ?? (
                                  <FontAwesomeIcon
                                    icon={faSort}
                                    className="ml-1 opacity-30"
                                  />
                                )}
                              </span>
                            </div>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>
                  <tbody className={tableBodyClasses}>
                    {table.getRowModel().rows.map((row) => (
                      <tr
                        key={row.id}
                        className={`${tableRowHoverClasses} transition-colors`}
                      >
                        {row.getVisibleCells().map((cell) => (
                          <td
                            key={cell.id}
                            className="px-5 py-4 text-sm whitespace-nowrap border-b border-gray-200"
                          >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>

              {/* Pagination */}
              <div className="px-5 py-3 flex items-center justify-between border-t border-gray-200 rounded-b-xl">
                <div className="flex-1 flex justify-between sm:hidden">
                  <button
                    onClick={() => table.previousPage()}
                    disabled={!table.getCanPreviousPage()}
                    className={`relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl 
                      ${
                        !table.getCanPreviousPage()
                          ? "bg-gray-100 text-gray-400 cursor-default"
                          : "bg-white text-gray-700 hover:bg-gray-50"
                      } 
                      border border-gray-300`}
                  >
                    Previous
                  </button>
                  <button
                    onClick={() => table.nextPage()}
                    disabled={!table.getCanNextPage()}
                    className={`ml-3 relative inline-flex items-center px-4 py-2 text-sm font-medium rounded-xl 
                      ${
                        !table.getCanNextPage()
                          ? "bg-gray-100 text-gray-400 cursor-default"
                          : "bg-white text-gray-700 hover:bg-gray-50"
                      } 
                      border border-gray-300`}
                  >
                    Next
                  </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                  <div>
                    <p className="text-sm text-gray-700">
                      Showing{" "}
                      <span className="font-medium">
                        {table.getState().pagination.pageIndex *
                          table.getState().pagination.pageSize +
                          1}
                      </span>{" "}
                      to{" "}
                      <span className="font-medium">
                        {Math.min(
                          (table.getState().pagination.pageIndex + 1) *
                            table.getState().pagination.pageSize,
                          tableData.length
                        )}
                      </span>{" "}
                      of <span className="font-medium">{tableData.length}</span>{" "}
                      results
                    </p>
                  </div>
                  <div>
                    <nav
                      className="relative z-0 inline-flex rounded-xl shadow-sm overflow-hidden"
                      aria-label="Pagination"
                    >
                      <button
                        onClick={() => table.setPageIndex(0)}
                        disabled={!table.getCanPreviousPage()}
                        className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium
                          ${
                            !table.getCanPreviousPage()
                              ? "bg-gray-100 text-gray-400 cursor-default"
                              : "bg-white text-gray-700 hover:bg-gray-50"
                          } 
                          border-gray-300`}
                      >
                        <span className="sr-only">First page</span>
                        <span aria-hidden="true">&laquo;</span>
                      </button>
                      <button
                        onClick={() => table.previousPage()}
                        disabled={!table.getCanPreviousPage()}
                        className={`relative inline-flex items-center px-3 py-2 border text-sm font-medium
                          ${
                            !table.getCanPreviousPage()
                              ? "bg-gray-100 text-gray-400 cursor-default"
                              : "bg-white text-gray-700 hover:bg-gray-50"
                          } 
                          border-gray-300`}
                      >
                        <span className="sr-only">Previous</span>
                        <span aria-hidden="true">&lt;</span>
                      </button>

                      {/* Page numbers */}
                      {Array.from(
                        { length: Math.min(table.getPageCount(), 5) },
                        (_, i) => {
                          const pageIndex =
                            i +
                            Math.max(
                              0,
                              Math.min(
                                table.getPageCount() - 5,
                                table.getState().pagination.pageIndex - 2
                              )
                            );
                          return (
                            pageIndex < table.getPageCount() && (
                              <button
                                key={pageIndex}
                                onClick={() => table.setPageIndex(pageIndex)}
                                className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium
                                  ${
                                    pageIndex ===
                                    table.getState().pagination.pageIndex
                                      ? "bg-blue-500 text-white"
                                      : "bg-white text-gray-700 hover:bg-gray-50"
                                  } 
                                  border-gray-300`}
                              >
                                {pageIndex + 1}
                              </button>
                            )
                          );
                        }
                      )}

                      <button
                        onClick={() => table.nextPage()}
                        disabled={!table.getCanNextPage()}
                        className={`relative inline-flex items-center px-3 py-2 border text-sm font-medium
                          ${
                            !table.getCanNextPage()
                              ? "bg-gray-100 text-gray-400 cursor-default"
                              : "bg-white text-gray-700 hover:bg-gray-50"
                          } 
                          border-gray-300`}
                      >
                        <span className="sr-only">Next</span>
                        <span aria-hidden="true">&gt;</span>
                      </button>
                      <button
                        onClick={() =>
                          table.setPageIndex(table.getPageCount() - 1)
                        }
                        disabled={!table.getCanNextPage()}
                        className={`relative inline-flex items-center px-2 py-2 border text-sm font-medium
                          ${
                            !table.getCanNextPage()
                              ? "bg-gray-100 text-gray-400 cursor-default"
                              : "bg-white text-gray-700 hover:bg-gray-50"
                          } 
                          border-gray-300`}
                      >
                        <span className="sr-only">Last page</span>
                        <span aria-hidden="true">&raquo;</span>
                      </button>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </motion.div>
        )}
      </motion.div>
    </div>
  );
};

export default Compendium;
