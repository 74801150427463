import React, { useState, useEffect, useCallback } from "react";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import ReportingUsers from "./ReportingUsers";
import { db } from "./firebase";
import {
  getFirestore,
  collection,
  getDocs,
  doc,
  updateDoc,
  query,
  where,
} from "firebase/firestore";
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
  deleteObject,
} from "firebase/storage";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPenToSquare,
  faFloppyDisk,
  faCheck,
  faExclamationTriangle,
  faCalendarAlt,
  faEyeSlash,
  faEye,
  faUpload,
  faTrash,
  faPhone,
  faMapMarkerAlt,
  faUser,
  faEnvelope,
  faIdCard,
  faFileAlt,
  faMoneyBillAlt,
  faUserTie,
  faLock,
  faSpinner,
  faTimes,
  faIdCardAlt,
  faFileInvoice,
} from "@fortawesome/free-solid-svg-icons";
import { getAuth } from "firebase/auth";
import { format } from "date-fns";

const EmployeeModal = ({
  selectedEmployee,
  isEditing,
  handleCloseModal,
  handleSave,
  handleInputChange,
  setIsEditing,
  isLoading,
  alert,
  updateSelectedEmployee,
  userRole,
}) => {
  const positions = [
    "Supervisor - Hertz",
    "Supervisor - Sixt",
    "Supervisor - Avis",
    "Supervisor - TCR",
    "Supervisor - Dollar/Thrifty",
    "VSA - Sixt",
    "Fleet Driver - Sixt",
    "Driver - Sixt",
    "Greeter - Sixt",
    "Shuttle Driver - Sixt",
    "Flex Rep - Sixt",
    "Detail - Hertz",
    "Detail - Sixt",
    "Janitorial - Hertz",
    "Janitorial - Sixt",
    "Driver - Hertz",
    "Counter - Hertz",
    "Lot Att - Avis",
    "Lot Att - Sixt",
    "IR - Hertz",
    "ADM - Hertz",
    "Greeter - Hertz",
    "VSA - Hertz",
    "Overnight VSA - Hertz",
    "Overnight Driver - Hertz",
    "Exit Gate - Hertz",
    "Transporters - Hertz",
    "Triage - Hertz",
    "CSR - Hertz",
    "Quality Control Inspector - Hertz",
    "Driver Back - Hertz",
    "Bus Driver - Hertz",
    "Flex Rep - Hertz",
    "Technician - Hertz",
    "Vehicle Mechanic - Amazon",
    "Geotab - Hertz",
    "VSA - Avis",
    "Driver - Avis",
    "Counter/IR - Avis",
    "Exit Gate - Avis",
    "Room Parts Assistant - TCR",
    "Front Desk - Office",
    "Administrative Assistant - Office",
    "General Services - Shop",
    "Detailer - Shop",
    "Electrician - Shop",
    "Supervisor - Drivo",
    "Rental Agent - Drivo",
    "Inspection Agent - Drivo",
    "VSA - Drivo",
    "Shuttle Driver - Drivo",
  ].map((pos) => ({ value: pos, label: pos }));
  const roleOptions = [
    { value: "employee", label: "employee" },
    { value: "mobile", label: "mobile" },
    { value: "supervisor", label: "supervisor" },
    { value: "director", label: "director" },
    { value: "recruiter", label: "recruiter" },
    { value: "HR", label: "HR" },
    { value: "consultant", label: "consultant" },
    { value: "admin", label: "admin" },
    { value: "manager", label: "manager" },
    { value: "owner", label: "owner" },
  ];
  const [isSSNVisible, setSSNVisible] = useState(false);
  const [isITINVisible, setITINVisible] = useState(false);
  const [isDriversLicenseVisible, setDriversLicenseVisible] = useState(false);
  const [isSalaryVisible, setSalaryVisible] = useState(false);
  const [locations, setLocations] = useState([]);
  const [activeSupervisors, setActiveSupervisors] = useState([]);
  const [idUploadProgress, setIdUploadProgress] = useState(0);
  const [documentUploadProgress, setDocumentUploadProgress] = useState(0);
  const [displayedSupervisorNames, setDisplayedSupervisorNames] = useState("");
  const [emailInput, setEmailInput] = useState(selectedEmployee.email || "");
  const [emailLoading, setEmailLoading] = useState(false);
  const [emailUpdateStatus, setEmailUpdateStatus] = useState(null);
  const [documentDeleteProgress, setDocumentDeleteProgress] = useState(0);
  const [isEINVisible, setEINVisible] = useState(false);
  const [employeeId, setEmployeeId] = useState(null);
  const [activeTab, setActiveTab] = useState("details");

  // State for HR documents
  const [hrDocuments, setHrDocuments] = useState({});

  // Fetch HR documents on component mount
  useEffect(() => {
    const fetchHrDocuments = async () => {
      try {
        const db = getFirestore();
        const hrDocsCollection = collection(db, "hrDocuments");
        const hrDocsSnapshot = await getDocs(hrDocsCollection);

        const docsMap = {};
        hrDocsSnapshot.forEach((doc) => {
          docsMap[doc.id] = doc.data();
        });

        setHrDocuments(docsMap);
      } catch (error) {
        console.error("Error fetching HR documents:", error);
      }
    };

    fetchHrDocuments();
  }, []);

  useEffect(() => {
    if (selectedEmployee) {
      setEmployeeId(selectedEmployee.uid);
    }
  }, [selectedEmployee]);

  const customStyles = {
    option: (provided, state) => {
      const optionColor =
        statusOptions.find((opt) => opt.value === state.data.value)
          ?.colorClass || "bg-gray-500";
      return {
        ...provided,
        backgroundColor: state.isSelected
          ? "lightgray"
          : state.isFocused
          ? optionColor
          : null,
        color: state.isSelected ? "black" : state.isFocused ? "blue" : "black",
      };
    },
    singleValue: (provided, state) => {
      return {
        ...provided,
        color: "black",
      };
    },
  };

  const fetchActiveSupervisors = useCallback(async () => {
    try {
      const querySnapshot = await getDocs(
        query(
          collection(db, "users"),
          where("status", "==", "Active"),
          where("role", "in", ["supervisor", "manager"])
        )
      );
      const fetchedSupervisors = querySnapshot.docs.map((doc) => {
        const data = doc.data();
        return {
          value: doc.id,
          label: `${data.firstName} ${
            data.middleName ? data.middleName + " " : ""
          }${data.lastName} (${data.role})`,
        };
      });

      // Sort the supervisors and managers alphabetically by label
      const sortedSupervisors = fetchedSupervisors.sort((a, b) =>
        a.label.localeCompare(b.label)
      );

      setActiveSupervisors(sortedSupervisors);
    } catch (error) {
      console.error("Error fetching active supervisors and managers:", error);
    }
  }, []);

  useEffect(() => {
    fetchActiveSupervisors();
  }, [fetchActiveSupervisors]);

  const toggleEINVisibility = () => {
    setEINVisible(!isEINVisible);
  };

  const toggleSalaryVisibility = () => {
    setSalaryVisible(!isSalaryVisible);
  };

  const displayReportsToNames = useCallback(
    (reportsToUIDs) => {
      // Check if reportsToUIDs is invalid
      if (!reportsToUIDs) {
        return "N/A";
      }

      // If reportsToUIDs is a string, return it directly
      if (typeof reportsToUIDs === "string") {
        return reportsToUIDs;
      }

      // Check if reportsToUIDs is an array and process it
      if (Array.isArray(reportsToUIDs)) {
        return reportsToUIDs
          .map((uid) => {
            const supervisor = activeSupervisors.find(
              (sup) => sup.value === uid
            );
            return supervisor ? supervisor.label : "Unknown";
          })
          .join(", ");
      }

      // Log a warning if reportsToUIDs is of an unexpected type
      console.warn(
        "Expected reportsToUIDs to be an array or a string, but got:",
        reportsToUIDs
      );
      return "Unknown";
    },
    [activeSupervisors]
  );

  useEffect(() => {
    if (selectedEmployee && selectedEmployee.reportsTo) {
      const names = displayReportsToNames(selectedEmployee.reportsTo);
      setDisplayedSupervisorNames(names);
    }
  }, [selectedEmployee, activeSupervisors, displayReportsToNames]);

  useEffect(() => {
    setEmailInput(selectedEmployee.email || "");
  }, [selectedEmployee]);

  const renderRateSection = () => {
    // Allow recruiters to see rates only for inactive employees
    const canSeeRate =
      userRole === "manager" ||
      userRole === "owner" ||
      userRole === "hr" ||
      (userRole === "recruiter" &&
        selectedEmployee.status?.toLowerCase() === "inactive");

    if (
      isEditing &&
      (userRole === "owner" ||
        userRole === "hr" ||
        (userRole === "recruiter" &&
          selectedEmployee.status?.toLowerCase() === "inactive"))
    ) {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Rate
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="number"
              step="0.01"
              value={selectedEmployee.rate || ""}
              onChange={(e) => handleInputChange("rate", e.target.value)}
              onBlur={(e) =>
                handleInputChange("rate", parseFloat(e.target.value).toFixed(2))
              }
              placeholder="0.00"
              className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10"
            />
          </div>
        </div>
      );
    } else if (canSeeRate) {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Rate
          </label>
          <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
            $
            {selectedEmployee.rate
              ? parseFloat(selectedEmployee.rate).toFixed(2)
              : "N/A"}
          </div>
        </div>
      );
    }
    return null; // Don't render anything if user doesn't have permission
  };

  const renderSalarySection = () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;
    const canEditSalary = currentUser?.uid === "aX5iMnYE26hdEi5MN5eqSa6rOrP2";

    if (isEditing && canEditSalary) {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Salary
            <span className="text-xs text-gray-500 ml-1">(Sensitive)</span>
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type={isSalaryVisible ? "text" : "password"}
              value={selectedEmployee.salary || ""}
              onChange={(e) => handleInputChange("salary", e.target.value)}
              onBlur={(e) => {
                const value = e.target.value.trim();
                handleInputChange(
                  "salary",
                  value ? parseFloat(value).toFixed(2) : ""
                );
              }}
              placeholder="0.00"
              className="block w-full rounded-md border-gray-300 pl-7 pr-12 focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10"
            />
            <div className="absolute inset-y-0 right-0 flex items-center">
              <button
                type="button"
                onClick={toggleSalaryVisibility}
                className="focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2 h-full rounded-r-md border-0 bg-transparent py-0 px-3 text-gray-500 hover:text-gray-700"
              >
                <FontAwesomeIcon
                  icon={isSalaryVisible ? faEyeSlash : faEye}
                  className="h-4 w-4"
                />
              </button>
            </div>
          </div>
        </div>
      );
    } else if (userRole === "owner") {
      // Format the salary for display
      const formattedSalary =
        selectedEmployee.salary &&
        selectedEmployee.salary !== "" &&
        !isNaN(selectedEmployee.salary)
          ? `$${parseFloat(selectedEmployee.salary).toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`
          : "Not Set";

      // Mask the salary
      const maskedSalary =
        selectedEmployee.salary &&
        selectedEmployee.salary !== "" &&
        !isNaN(selectedEmployee.salary)
          ? "$******.**"
          : "Not Set";

      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Salary
            <span className="text-xs text-gray-500 ml-1">(Sensitive)</span>
          </label>
          <div
            className="bg-gray-50 rounded-md py-2 px-3 text-gray-700 relative group"
            onMouseEnter={toggleSalaryVisibility}
            onMouseLeave={toggleSalaryVisibility}
          >
            {isSalaryVisible ? formattedSalary : maskedSalary}
          </div>
        </div>
      );
    }
    return null;
  };

  const renderApprovedBySection = () => {
    return (
      <div className="form-group mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Approved By
        </label>
        {isEditing && userRole === "owner" ? (
          <input
            type="text"
            name="approvedBy"
            value={selectedEmployee.approvedBy || ""}
            onChange={handleInputChange}
            className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Name of approving person"
          />
        ) : (
          <div className="py-2 px-1 flex items-center">
            {selectedEmployee.approvedBy ? (
              <>
                <span className="bg-green-100 text-green-800 text-xs font-medium px-2.5 py-0.5 rounded-full mr-2">
                  <FontAwesomeIcon icon={faCheck} className="mr-1" />
                  Approved
                </span>
                <span className="text-gray-700">
                  {selectedEmployee.approvedBy}
                </span>
              </>
            ) : (
              <span className="bg-amber-100 text-amber-800 text-xs font-medium px-2.5 py-0.5 rounded-full">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-1"
                />
                Not approved
              </span>
            )}
          </div>
        )}
      </div>
    );
  };

  // Helper function to validate if a date is valid
  const isValidDate = (date) => {
    return date instanceof Date && !isNaN(date.getTime());
  };

  const renderDocumentAgreements = () => {
    // Check if the nonCompeteSignedDate exists and is valid
    const hasSignedNonCompete = selectedEmployee.nonCompeteSignedDate != null;
    let nonCompeteSignedDate = null;

    try {
      if (hasSignedNonCompete) {
        if (typeof selectedEmployee.nonCompeteSignedDate === "string") {
          // Handle stringified Firestore timestamp
          try {
            const parsedTimestamp = JSON.parse(
              selectedEmployee.nonCompeteSignedDate
            );
            if (parsedTimestamp && parsedTimestamp.seconds) {
              nonCompeteSignedDate = new Date(parsedTimestamp.seconds * 1000);
            }
          } catch (parseError) {
            nonCompeteSignedDate = new Date(
              selectedEmployee.nonCompeteSignedDate
            );
          }
        } else if (
          typeof selectedEmployee.nonCompeteSignedDate.toDate === "function"
        ) {
          nonCompeteSignedDate = selectedEmployee.nonCompeteSignedDate.toDate();
        } else if (selectedEmployee.nonCompeteSignedDate instanceof Date) {
          nonCompeteSignedDate = selectedEmployee.nonCompeteSignedDate;
        }

        // Validate the date
        if (!isValidDate(nonCompeteSignedDate)) {
          console.warn(
            "Invalid nonCompeteSignedDate:",
            selectedEmployee.nonCompeteSignedDate
          );
          nonCompeteSignedDate = null;
        }
      }
    } catch (error) {
      console.error("Error processing nonCompeteSignedDate:", error);
      nonCompeteSignedDate = null;
    }

    // Filter and validate document agreements before processing
    const validDocumentAgreements = selectedEmployee.documentAgreements
      ? Object.entries(selectedEmployee.documentAgreements).filter(
          ([docId, timestamp]) => {
            // Skip if docId doesn't exist in hrDocuments
            if (!hrDocuments[docId]) return false;

            // Skip if timestamp is a single character or obviously invalid
            if (typeof timestamp === "string" && timestamp.length <= 1)
              return false;

            // Skip if timestamp is not a valid format
            if (
              !timestamp ||
              (typeof timestamp !== "object" &&
                typeof timestamp !== "string" &&
                !(timestamp instanceof Date))
            ) {
              return false;
            }

            return true;
          }
        )
      : [];

    return (
      <div className="form-group mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Document Agreements
        </label>
        <div className="border border-gray-200 rounded-lg bg-gray-50 p-4">
          {/* Non-Compete Agreement */}
          <div className="mb-4">
            <h4 className="text-sm font-medium text-gray-700 mb-2">
              Non-Compete Agreement
            </h4>
            {isEditing && (userRole === "owner" || userRole === "hr") ? (
              <div className="flex items-center bg-white p-3 rounded border border-gray-200">
                <input
                  type="checkbox"
                  name="nonCompeteSignedDate"
                  checked={hasSignedNonCompete}
                  onChange={(e) => {
                    const updatedEmployee = {
                      ...selectedEmployee,
                      nonCompeteSignedDate: e.target.checked
                        ? new Date()
                        : null,
                    };
                    updateSelectedEmployee(updatedEmployee);
                  }}
                  className="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                />
                <label
                  htmlFor="nonCompeteSignedDate"
                  className="ml-2 block text-sm text-gray-700"
                >
                  Employee has signed non-compete
                </label>
              </div>
            ) : (
              <div className="py-2 px-3 bg-white rounded border border-gray-200">
                {hasSignedNonCompete ? (
                  <div className="grid grid-cols-2 items-center">
                    <div className="font-medium text-gray-700">
                      Non-Compete Agreement
                    </div>
                    <div className="text-green-600 flex items-center justify-end">
                      <FontAwesomeIcon icon={faCheck} className="mr-2" />
                      <span>
                        Signed on{" "}
                        {nonCompeteSignedDate &&
                        isValidDate(nonCompeteSignedDate)
                          ? format(
                              nonCompeteSignedDate,
                              "MMM d, yyyy 'at' h:mm a"
                            )
                          : "Unknown date"}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="grid grid-cols-2 items-center">
                    <div className="font-medium text-gray-700">
                      Non-Compete Agreement
                    </div>
                    <div className="text-amber-600 flex items-center justify-end">
                      <FontAwesomeIcon
                        icon={faExclamationTriangle}
                        className="mr-2"
                      />
                      <span>Not signed</span>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>

          {/* Other Document Agreements */}
          {validDocumentAgreements.length > 0 ? (
            <div>
              <h4 className="text-sm font-medium text-gray-700 mb-2">
                Other Signed Documents
              </h4>
              <div className="space-y-2">
                {validDocumentAgreements.map(([docId, timestamp]) => {
                  // Convert timestamp to readable date
                  let signedDate = null;

                  try {
                    if (timestamp && typeof timestamp.toDate === "function") {
                      signedDate = timestamp.toDate();
                    } else if (
                      typeof timestamp === "string" &&
                      timestamp.length > 1
                    ) {
                      signedDate = new Date(timestamp);
                    } else if (timestamp instanceof Date) {
                      signedDate = timestamp;
                    }

                    // Validate the date
                    if (!isValidDate(signedDate)) {
                      return null;
                    }
                  } catch (error) {
                    return null;
                  }

                  const documentTitle = hrDocuments[docId].title;

                  return (
                    <div
                      key={docId}
                      className="grid grid-cols-2 items-center py-2 px-3 bg-white rounded border border-gray-200"
                    >
                      <div>
                        <div className="font-medium text-gray-700">
                          {documentTitle}
                        </div>
                      </div>
                      <div className="text-sm text-green-600 flex items-center justify-end">
                        <FontAwesomeIcon icon={faCheck} className="mr-1" />
                        <span>
                          Signed on{" "}
                          {format(signedDate, "MMM d, yyyy 'at' h:mm a")}
                        </span>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          ) : (
            !hasSignedNonCompete && (
              <div className="py-3 px-4 bg-amber-50 text-amber-700 rounded-lg border border-amber-200 mt-3">
                <div className="flex items-center">
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="mr-2"
                  />
                  <span>No other signed documents on file</span>
                </div>
              </div>
            )
          )}
        </div>
      </div>
    );
  };

  // Modify the renderAdditionalInformation function to remove the duplicate heading
  const renderAdditionalInformation = () => {
    return (
      <div>
        {renderBirthDateSection()}
        {renderOptionalCompanyName()}
        <div className="mt-4">
          <div className="form-group">{renderApprovedBySection()}</div>
        </div>
        <div className="mt-4">
          <div className="form-group">{renderDocumentAgreements()}</div>
        </div>
      </div>
    );
  };

  const renderTerminationDateSection = () => {
    if (userRole !== "supervisor") {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Termination Date
          </label>
          {isEditing && (userRole === "owner" || userRole === "hr") ? (
            <div className="flex items-center relative">
              <ReactDatePicker
                selected={
                  selectedEmployee.terminationDate &&
                  isValidDate(
                    new Date(selectedEmployee.terminationDate + "T00:00:00")
                  )
                    ? new Date(selectedEmployee.terminationDate + "T00:00:00")
                    : null
                }
                onChange={(date) => {
                  const formattedDate = date
                    ? new Date(date.setHours(0, 0, 0, 0))
                        .toISOString()
                        .split("T")[0]
                    : null;
                  handleInputChange("terminationDate", formattedDate);
                }}
                dateFormat="yyyy-MM-dd"
                placeholderText="Select a date"
                className="block w-full rounded-md border-gray-300 focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10 pr-10"
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  className="text-gray-400"
                />
              </div>
            </div>
          ) : (
            (userRole === "manager" ||
              userRole === "hr" ||
              userRole === "owner" ||
              userRole === "admin") && (
              <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
                {selectedEmployee.terminationDate || "N/A"}
              </div>
            )
          )}
        </div>
      );
    }
    return null;
  };

  const renderStatusSection = () => {
    if (isEditing && (userRole === "owner" || userRole === "hr")) {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <Select
            value={statusOptions.find(
              (option) =>
                option.value.toLowerCase() ===
                selectedEmployee.status.toLowerCase()
            )}
            onChange={(selectedOption) =>
              handleInputChange("status", selectedOption.value)
            }
            options={statusOptions}
            formatOptionLabel={(option) => (
              <div className="flex items-center">
                <span>{option.label}</span>
                <span
                  className={`ml-2 w-3 h-3 rounded-full ${option.colorClass}`}
                ></span>
              </div>
            )}
            styles={{
              ...customStyles,
              control: (provided) => ({
                ...provided,
                borderRadius: "0.375rem",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#6366F1",
                },
              }),
              menu: (provided) => ({
                ...provided,
                borderRadius: "0.375rem",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }),
            }}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        </div>
      );
    } else {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Status
          </label>
          <div className="flex items-center bg-gray-50 rounded-md py-2 px-3 text-gray-700">
            {selectedEmployee.status
              ? selectedEmployee.status.charAt(0).toUpperCase() +
                selectedEmployee.status.slice(1)
              : "N/A"}
            <span
              className={`ml-2 inline-block w-3 h-3 rounded-full ${
                statusOptions.find(
                  (option) =>
                    option.value.toLowerCase() ===
                    selectedEmployee.status.toLowerCase()
                )?.colorClass || "bg-gray-500"
              }`}
            ></span>
          </div>
        </div>
      );
    }
  };

  const renderPayMethod = () => {
    if (userRole !== "supervisor") {
      return (
        <div className="form-group">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            Pay Method
          </label>
          {isEditing && (userRole === "owner" || userRole === "hr") ? (
            <Select
              value={payMethods.find(
                (option) => option.value === selectedEmployee.payMethod
              )}
              onChange={(selectedOption) =>
                handleInputChange("payMethod", selectedOption.value)
              }
              options={payMethods}
              styles={{
                control: (provided) => ({
                  ...provided,
                  borderRadius: "0.375rem",
                  boxShadow: "none",
                  "&:hover": {
                    borderColor: "#6366F1",
                  },
                }),
                menu: (provided) => ({
                  ...provided,
                  borderRadius: "0.375rem",
                  boxShadow:
                    "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                }),
              }}
              className="react-select-container"
              classNamePrefix="react-select"
              autoComplete="off"
            />
          ) : (
            <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
              {selectedEmployee.payMethod || "N/A"}
            </div>
          )}
        </div>
      );
    }
    return null;
  };

  const renderRoleSection = () => {
    return (
      <div className="form-group">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Role
        </label>
        {isEditing && (userRole === "owner" || userRole === "hr") ? (
          <Select
            value={roleOptions.find(
              (option) => option.value === selectedEmployee.role
            )}
            onChange={(selectedOption) =>
              handleInputChange("role", selectedOption.value)
            }
            options={roleOptions}
            styles={{
              control: (provided) => ({
                ...provided,
                borderRadius: "0.375rem",
                boxShadow: "none",
                "&:hover": {
                  borderColor: "#6366F1",
                },
              }),
              menu: (provided) => ({
                ...provided,
                borderRadius: "0.375rem",
                boxShadow:
                  "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
              }),
            }}
            className="react-select-container"
            classNamePrefix="react-select"
          />
        ) : (
          <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
            {selectedEmployee.role || "N/A"}
          </div>
        )}
      </div>
    );
  };

  const handleDocumentFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file || file.type !== "application/pdf") {
      console.error("File type is not PDF");
      window.alert("Only PDF files are allowed for tax documents.");
      return;
    }

    setDocumentUploadProgress(0);

    const storage = getStorage();
    const fileRef = ref(
      storage,
      `tax-documents/${selectedEmployee.uid}/${file.name}`
    );

    try {
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setDocumentUploadProgress(progress);
        },
        (error) => {
          console.error("Error during upload:", error);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          const employeeDocRef = doc(db, "users", selectedEmployee.uid);
          await updateDoc(employeeDocRef, { documentUrl: url });

          // Use the passed function to update selectedEmployee state
          updateSelectedEmployee({ documentUrl: url });
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
    }
  };

  const handleIdFileChange = async (e) => {
    const file = e.target.files[0];

    if (!file.type.startsWith("image/")) {
      console.error("File type is not an image");
      window.alert("Only image files are allowed for the driver's license.");
      return;
    }

    setIdUploadProgress(0);

    const storage = getStorage();
    const fileRef = ref(
      storage,
      `drivers-license/${selectedEmployee.uid}/${file.name}`
    );

    try {
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress =
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          setIdUploadProgress(progress);
        },
        (error) => {
          console.error("Error during upload:", error);
        },
        async () => {
          const url = await getDownloadURL(uploadTask.snapshot.ref);

          const employeeDocRef = doc(db, "users", selectedEmployee.uid);
          await updateDoc(employeeDocRef, { driversLicenseFileUrl: url });

          // Use updateSelectedEmployee here
          updateSelectedEmployee({ driversLicenseFileUrl: url });
          setIdUploadProgress(100);
        }
      );
    } catch (error) {
      console.error("Error setting up upload:", error);
    }
  };

  const fetchLocations = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "locations"));
      const fetchedLocations = querySnapshot.docs.map((doc) => {
        const locationData = doc.data();
        return {
          value: doc.id,
          label: locationData.name,
          reportsTo: locationData.reportsTo, // Add this line
        };
      });
      setLocations(fetchedLocations);
    } catch (error) {
      console.error("Error fetching locations:", error);
      // Handle errors, e.g., set an error state or message
    }
  };

  useEffect(() => {
    fetchLocations();
  }, []);

  const handleLocationChange = async (selectedOption) => {
    handleInputChange("workLocation", selectedOption.label);

    try {
      const location = locations.find(
        (loc) => loc.value === selectedOption.value
      );
      if (location && location.reportsTo) {
        const allSupervisors = await fetchActiveSupervisors();
        const filteredSupervisors = allSupervisors.filter((sup) =>
          location.reportsTo.includes(sup.value)
        );
        setActiveSupervisors(filteredSupervisors);
      } else {
        setActiveSupervisors([]);
      }
    } catch (error) {
      console.error("Error fetching supervisors based on location:", error);
    }
  };

  const payMethods = [
    { value: "W2", label: "W2" },
    { value: "1099", label: "1099" },
    { value: "Direct Deposit", label: "Direct Deposit" },
    { value: "EIN", label: "EIN" },
  ];

  const toggleSSNVisibility = () => {
    setSSNVisible(!isSSNVisible);
  };

  const toggleITINVisibility = () => {
    setITINVisible(!isITINVisible);
  };

  const toggleDriversLicenseVisibility = () => {
    setDriversLicenseVisible(!isDriversLicenseVisible);
  };

  function formatPhoneNumber(phone) {
    if (!phone) return "";
    const cleaned = ("" + phone).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return phone;
  }

  function capitalizeName(name) {
    if (!name) return name;

    const lowercasedName = name.toLowerCase();
    const exceptions = ["de", "da", "das", "do", "dos"];

    return lowercasedName
      .split(" ")
      .map((word) =>
        exceptions.includes(word)
          ? word
          : word.charAt(0).toUpperCase() + word.slice(1)
      )
      .join(" ");
  }

  const handleEmailChange = async () => {
    if (!emailInput) {
      setEmailUpdateStatus({
        success: false,
        message: "Email cannot be empty",
      });
      return;
    }

    setEmailLoading(true);
    setEmailUpdateStatus(null);

    const uid = selectedEmployee.uid;

    try {
      const response = await fetch(
        `https://us-central1-uniquepunchcard-ab19f.cloudfunctions.net/updateUserEmailDirectly?uid=${uid}&newEmail=${encodeURIComponent(
          emailInput
        )}`
      );

      if (!response.ok) {
        throw new Error(`Failed to update email: ${response.statusText}`);
      }

      const result = await response.json();
      if (result.success) {
        handleInputChange("email", emailInput);
        setEmailUpdateStatus({
          success: true,
          message: "Email updated successfully",
        });
      } else {
        throw new Error(result.message || "Unknown error occurred");
      }
    } catch (error) {
      console.error("Error updating email:", error);
      setEmailUpdateStatus({ success: false, message: error.message });
    } finally {
      setEmailLoading(false);
    }
  };

  const statusOptions = [
    { value: "Active", label: "Active", colorClass: "status-active" },
    { value: "inactive", label: "Inactive", colorClass: "status-inactive" },
    {
      value: "terminated",
      label: "Terminated",
      colorClass: "status-terminated",
    },
  ];

  const handleDeleteIdFile = async () => {
    if (
      window.confirm(
        "Are you sure you want to delete this driver's license document?"
      )
    ) {
      const storage = getStorage();

      // Extract the correct file path from the URL
      const url = new URL(selectedEmployee.driversLicenseFileUrl);
      const decodedPath = decodeURIComponent(url.pathname);
      const fullPathSegments = decodedPath.split("/");
      const fileName = fullPathSegments[fullPathSegments.length - 1];
      const correctPath = `drivers-license/${selectedEmployee.uid}/${fileName}`;

      // Construct the correct file path
      const fileRef = ref(storage, correctPath);

      try {
        // Delete the file from storage
        await deleteObject(fileRef);

        // Update Firestore document
        const employeeDocRef = doc(db, "users", selectedEmployee.uid);
        await updateDoc(employeeDocRef, { driversLicenseFileUrl: null });

        // Update local state
        updateSelectedEmployee({ driversLicenseFileUrl: null });
      } catch (error) {
        console.error("Error deleting driver's license document:", error);
      }
    }
  };

  const renderBirthDateSection = () => {
    return (
      <div className="form-group mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Birth Date
        </label>
        <div className="relative">
          {isEditing &&
          (userRole === "owner" ||
            userRole === "hr" ||
            userRole === "recruiter") ? (
            <ReactDatePicker
              selected={
                selectedEmployee.birthDate &&
                isValidDate(new Date(selectedEmployee.birthDate))
                  ? new Date(selectedEmployee.birthDate)
                  : null
              }
              onChange={(date) => {
                if (date) {
                  // Format date using local components to avoid timezone shifts
                  const year = date.getFullYear();
                  const month = date.getMonth() + 1; // Month is 0-indexed
                  const day = date.getDate();
                  // Pad month and day with leading zeros if needed
                  const formattedDate = `${year}-${String(month).padStart(
                    2,
                    "0"
                  )}-${String(day).padStart(2, "0")}`;
                  handleInputChange("birthDate", formattedDate);
                } else {
                  // Handle case when date is cleared
                  handleInputChange("birthDate", null);
                }
              }}
              className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
              dateFormat="MM/dd/yyyy"
              placeholderText="Select birth date"
              autoComplete="off"
            />
          ) : (
            <div className="py-2 px-1 text-gray-700">
              {selectedEmployee.birthDate &&
              isValidDate(new Date(selectedEmployee.birthDate + "T00:00:00")) // Ensure local parsing for validation
                ? format(
                    new Date(selectedEmployee.birthDate + "T00:00:00"),
                    "MM/dd/yyyy"
                  ) // Use date-fns format and ensure local parsing
                : "Not specified"}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderHiringDateSection = () => {
    return (
      <div className="mb-2">
        <label className="mr-2 block">Hiring Date:</label>
        {isEditing ? (
          <div className="flex items-center">
            <ReactDatePicker
              selected={
                selectedEmployee.hiringDate &&
                isValidDate(new Date(selectedEmployee.hiringDate + "T00:00:00"))
                  ? new Date(selectedEmployee.hiringDate + "T00:00:00")
                  : null
              }
              onChange={(date) => {
                if (date) {
                  // Format date using local components to avoid timezone shifts
                  const year = date.getFullYear();
                  const month = date.getMonth() + 1; // Month is 0-indexed
                  const day = date.getDate();
                  // Pad month and day with leading zeros if needed
                  const formattedDate = `${year}-${String(month).padStart(
                    2,
                    "0"
                  )}-${String(day).padStart(2, "0")}`;
                  handleInputChange("hiringDate", formattedDate);
                } else {
                  handleInputChange("hiringDate", null);
                }
              }}
              dateFormat="MM/dd/yyyy" // Consistent format
              placeholderText="Select a date"
              className="mr-2"
            />
            <FontAwesomeIcon icon={faCalendarAlt} />
          </div>
        ) : (
          <span>{selectedEmployee.hiringDate || "N/A"}</span>
        )}
      </div>
    );
  };

  const renderOptionalCompanyName = () => {
    return (
      <div className="form-group mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Company Name (if applicable)
        </label>
        {isEditing &&
        (userRole === "owner" ||
          userRole === "hr" ||
          userRole === "recruiter") ? (
          <input
            type="text"
            name="companyName"
            value={selectedEmployee.companyName || ""}
            onChange={(e) => handleInputChange("companyName", e.target.value)}
            className="block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
            placeholder="Enter company name if contractor"
          />
        ) : (
          <div className="py-2 px-1 text-gray-700">
            {selectedEmployee.companyName || "Not applicable"}
          </div>
        )}
      </div>
    );
  };

  const renderAddressSection = () => {
    return (
      <div className="form-group mb-4">
        <label className="block text-sm font-medium text-gray-700 mb-1">
          Address Information
        </label>

        {isEditing ? (
          <div className="space-y-3">
            <div className="relative">
              <label className="text-xs text-gray-500 mb-1 block">
                Street Address
              </label>
              <input
                type="text"
                name="address"
                value={selectedEmployee.address || ""}
                onChange={(e) => handleInputChange("address", e.target.value)}
                placeholder="Street Address"
                className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
              />
            </div>

            <div className="grid grid-cols-3 gap-3">
              <div className="relative col-span-1">
                <label className="text-xs text-gray-500 mb-1 block">City</label>
                <input
                  type="text"
                  name="city"
                  value={selectedEmployee.city || ""}
                  onChange={(e) => handleInputChange("city", e.target.value)}
                  placeholder="City"
                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                />
              </div>

              <div className="relative col-span-1">
                <label className="text-xs text-gray-500 mb-1 block">
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={selectedEmployee.state || ""}
                  onChange={(e) => handleInputChange("state", e.target.value)}
                  placeholder="State"
                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                />
              </div>

              <div className="relative col-span-1">
                <label className="text-xs text-gray-500 mb-1 block">
                  ZIP Code
                </label>
                <input
                  type="text"
                  name="zip"
                  value={selectedEmployee.zip || ""}
                  onChange={(e) => handleInputChange("zip", e.target.value)}
                  placeholder="ZIP Code"
                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
            {selectedEmployee.address ||
            selectedEmployee.city ||
            selectedEmployee.state ||
            selectedEmployee.zip ? (
              <>
                <p className="text-gray-700 mb-1">
                  {selectedEmployee.address || ""}
                </p>
                <p className="text-gray-700">
                  {[
                    selectedEmployee.city,
                    selectedEmployee.state,
                    selectedEmployee.zip,
                  ]
                    .filter(Boolean)
                    .join(", ")}
                </p>
              </>
            ) : (
              <p className="text-gray-500">No address information provided</p>
            )}
          </div>
        )}
      </div>
    );
  };

  const handleDeleteDocumentFile = async () => {
    if (window.confirm("Are you sure you want to delete this tax document?")) {
      const storage = getStorage();
      let decodedPath, correctPath;

      try {
        // Extract the correct file path from the URL
        const url = new URL(selectedEmployee.documentUrl);
        decodedPath = decodeURIComponent(url.pathname);

        // Extract only the relevant path after '/o/'
        const pathIndex = decodedPath.indexOf("/o/") + 3;
        correctPath = decodedPath.substring(pathIndex);

        // Construct the correct file path
        const fileRef = ref(storage, correctPath);

        // Indicate the deletion process has started
        setDocumentDeleteProgress(10); // Arbitrary start value

        // Delete the file from storage
        await deleteObject(fileRef);

        // Update Firestore document
        const employeeDocRef = doc(db, "users", selectedEmployee.uid);
        await updateDoc(employeeDocRef, { documentUrl: null });

        // Update local state
        updateSelectedEmployee({ documentUrl: null });

        // Indicate the deletion process has finished
        setDocumentDeleteProgress(100); // Arbitrary completion value

        // Reset progress after a short delay
        setTimeout(() => setDocumentDeleteProgress(0), 1000);
      } catch (error) {
        console.error("Error deleting tax document:", error);
        setDocumentDeleteProgress(0);
      }
    }
  };

  const canSeeDocuments = () => {
    return (
      userRole === "manager" ||
      userRole === "owner" ||
      userRole === "hr" ||
      (userRole === "recruiter" &&
        selectedEmployee.status?.toLowerCase() === "inactive")
    );
  };

  // Improved renderers for sensitive information fields
  const renderSensitiveField = (
    label,
    value,
    isVisible,
    toggleVisibility,
    placeholder,
    fieldName,
    formatter = null
  ) => {
    const formattedValue = formatter ? formatter(value) : value;
    const maskedValue = value
      ? fieldName === "ein"
        ? `${value.substr(0, 2)}-*****${value.substr(-3)}`
        : `***-**-${value.substr(-4)}`
      : "N/A";

    if (isEditing) {
      return (
        <div className="form-group mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
            <span className="text-xs text-gray-500 ml-1">(Sensitive)</span>
          </label>
          <div className="relative mt-1 rounded-md shadow-sm">
            <input
              type={isVisible ? "text" : "password"}
              value={value || ""}
              onChange={(e) => {
                const value = e.target.value;
                const formattedValue = value.replace(/[^0-9-]/g, "");
                handleInputChange(fieldName, formattedValue);
              }}
              placeholder={placeholder}
              className="block w-full rounded-md border-gray-300 pr-10 focus:border-blue-500 focus:ring-blue-500 sm:text-sm h-10"
              autoComplete="off"
            />
            <button
              type="button"
              onClick={toggleVisibility}
              className="absolute inset-y-0 right-0 flex items-center pr-3"
            >
              <FontAwesomeIcon
                icon={isVisible ? faEyeSlash : faEye}
                className="h-5 w-5 text-gray-400 hover:text-gray-600 transition-colors duration-200"
                aria-hidden="true"
              />
            </button>
          </div>
          <p className="mt-1 text-xs text-amber-600 flex items-center">
            <FontAwesomeIcon icon={faExclamationTriangle} className="mr-1" />
            This information is sensitive and should be protected
          </p>
        </div>
      );
    } else {
      return (
        <div className="form-group mb-4">
          <label className="block text-sm font-medium text-gray-700 mb-1">
            {label}
            <span className="text-xs text-gray-500 ml-1">(Masked)</span>
          </label>
          <div className="flex items-center justify-between bg-gray-50 rounded-md py-2 px-3 border border-gray-200">
            <span className="text-gray-700">{maskedValue}</span>
            {value && userRole === "owner" && (
              <button
                onClick={toggleVisibility}
                className="ml-2 text-xs px-2 py-1 bg-gray-200 text-gray-700 rounded hover:bg-gray-300 transition-colors duration-200 flex items-center"
              >
                <FontAwesomeIcon
                  icon={isVisible ? faEyeSlash : faEye}
                  className="mr-1"
                />
                {isVisible ? "Hide" : "View"}
              </button>
            )}
          </div>
          {isVisible && value && (
            <div className="mt-2 p-2 bg-amber-50 border border-amber-200 rounded-md">
              <p className="text-amber-800 text-sm flex items-center">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="mr-1"
                />
                Full {label}: {formattedValue || value}
              </p>
            </div>
          )}
        </div>
      );
    }
  };

  const renderSensitiveInformation = () => {
    if (userRole !== "manager") {
      return (
        <div className="bg-gray-50 rounded-lg p-4 border border-gray-200 mb-4">
          <h3 className="text-base font-medium text-gray-800 mb-3 flex items-center">
            <FontAwesomeIcon icon={faLock} className="w-5 h-5 mr-2" />
            Sensitive Information
          </h3>

          {/* Render SSN */}
          {renderSensitiveField(
            "Social Security Number",
            selectedEmployee.socialSecurity,
            isSSNVisible,
            toggleSSNVisibility,
            "XXX-XX-XXXX",
            "socialSecurity",
            (val) =>
              val ? `${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(5)}` : ""
          )}

          {/* Render ITIN */}
          {renderSensitiveField(
            "ITIN",
            selectedEmployee.itin,
            isITINVisible,
            toggleITINVisibility,
            "XXX-XX-XXXX",
            "itin",
            (val) =>
              val ? `${val.slice(0, 3)}-${val.slice(3, 5)}-${val.slice(5)}` : ""
          )}

          {/* Render EIN */}
          {renderSensitiveField(
            "EIN",
            selectedEmployee.ein,
            isEINVisible,
            toggleEINVisibility,
            "XX-XXXXXXX",
            "ein",
            (val) => (val ? `${val.slice(0, 2)}-${val.slice(2)}` : "")
          )}

          {/* Render Driver's License */}
          {renderSensitiveField(
            "Driver's License",
            selectedEmployee.driversLicense,
            isDriversLicenseVisible,
            toggleDriversLicenseVisibility,
            "Enter Driver's License",
            "driversLicense"
          )}
        </div>
      );
    }
    return null;
  };

  return (
    <div className="fixed inset-0 bg-black/40 z-50 flex justify-center items-center overflow-hidden backdrop-blur-sm p-4">
      <div className="bg-white rounded-xl shadow-2xl w-11/12 max-w-7xl max-h-[90vh] overflow-hidden flex flex-col">
        {/* Header section with title, buttons, and alerts */}
        <div className="p-5 border-b border-gray-200 flex items-center justify-between sticky top-0 bg-white z-10">
          <h2 className="text-2xl font-bold text-gray-800">
            {isEditing ? "Edit Employee" : "Employee Details"}
          </h2>

          <div className="flex items-center space-x-2">
            {/* Alert indicators */}
            {alert.type === "success" && (
              <div className="text-green-500 bg-green-50 p-2 rounded-full">
                <FontAwesomeIcon icon={faCheck} className="text-lg" />
              </div>
            )}
            {alert.type === "error" && (
              <div className="text-red-500 bg-red-50 p-2 rounded-full">
                <FontAwesomeIcon
                  icon={faExclamationTriangle}
                  className="text-lg"
                />
              </div>
            )}

            {/* Edit/Save button */}
            {userRole !== "supervisor" && (
              <button
                onClick={async () => {
                  if (isEditing) {
                    try {
                      await handleSave();
                      // Don't toggle editing state here - it will be set to false in handleSave
                    } catch (error) {
                      console.error("Error in handleSave:", error);
                      // Keep editing mode on if there's an error
                    }
                  } else {
                    // Only toggle editing state when entering edit mode
                    setIsEditing(true);
                  }
                }}
                className={`px-4 py-2 rounded-lg transition duration-300 ease-in-out flex items-center ${
                  isEditing
                    ? "bg-emerald-500 hover:bg-emerald-600 text-white"
                    : "bg-blue-500 hover:bg-blue-600 text-white"
                }`}
                disabled={isLoading}
              >
                {isLoading ? (
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                    />
                    Saving...
                  </span>
                ) : (
                  <span className="flex items-center">
                    <FontAwesomeIcon
                      icon={isEditing ? faFloppyDisk : faPenToSquare}
                      className="mr-2"
                    />
                    {isEditing ? "Save Changes" : "Edit"}
                  </span>
                )}
              </button>
            )}

            {/* Close button */}
            <button
              onClick={handleCloseModal}
              className="bg-gray-100 text-gray-600 hover:bg-gray-200 rounded-lg p-2 transition duration-300 ease-in-out w-10 h-10 flex items-center justify-center"
              aria-label="Close modal"
            >
              <FontAwesomeIcon icon={faTimes} className="h-6 w-6" />
            </button>
          </div>
        </div>

        {/* Tab Navigation - redesigned with better visual feedback */}
        <div className="border-b border-gray-200 bg-gray-50 px-5 pt-3">
          <div className="flex space-x-1 -mb-px">
            <button
              onClick={() => setActiveTab("details")}
              className={`${
                activeTab === "details"
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700 hover:border-b hover:border-gray-300"
              } px-5 py-2.5 font-medium text-sm transition-all duration-200`}
            >
              Employee Details
            </button>
            <button
              onClick={() => setActiveTab("reportingUsers")}
              className={`${
                activeTab === "reportingUsers"
                  ? "border-b-2 border-blue-500 text-blue-600"
                  : "text-gray-500 hover:text-gray-700 hover:border-b hover:border-gray-300"
              } px-5 py-2.5 font-medium text-sm transition-all duration-200`}
            >
              Reporting Users
            </button>
          </div>
        </div>

        {/* Content area */}
        <div className="flex-grow overflow-auto p-5">
          {/* Tab Content */}
          <div>
            {activeTab === "details" && (
              <div>
                {selectedEmployee && (
                  <div className="flex flex-col md:flex-row gap-6">
                    <div className="md:w-1/3 bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                      {/* Left Column - Employee Profile */}
                      <div className="flex flex-col items-center">
                        {/* Document and ID Uploads */}
                        <div className="w-full mb-6">
                          <h3 className="text-gray-600 uppercase text-xs font-semibold tracking-wider mb-3 flex items-center">
                            <FontAwesomeIcon
                              icon={faIdCard}
                              className="w-4 h-4 mr-1"
                            />
                            Identification Documents
                          </h3>

                          {/* Driver's License section */}
                          <div className="bg-gray-50 rounded-lg p-4 mb-4 border border-gray-200">
                            <h4 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
                              <FontAwesomeIcon
                                icon={faIdCard}
                                className="w-4 h-4 mr-1 text-gray-500"
                              />
                              Driver's License
                            </h4>

                            {canSeeDocuments() ? (
                              selectedEmployee.driversLicenseFileUrl ? (
                                <div className="flex flex-col items-center">
                                  <div className="relative rounded-lg overflow-hidden border border-gray-300 w-full mb-2 group">
                                    <a
                                      href={
                                        selectedEmployee.driversLicenseFileUrl
                                      }
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="block relative"
                                    >
                                      <img
                                        src={
                                          selectedEmployee.driversLicenseFileUrl
                                        }
                                        alt="Driver's License"
                                        className="rounded-lg mx-auto transition-all duration-300 group-hover:opacity-90 max-h-48 object-contain w-full"
                                      />
                                      <div className="absolute inset-0 bg-black bg-opacity-0 group-hover:bg-opacity-20 flex items-center justify-center transition-all duration-300">
                                        <span className="text-white opacity-0 group-hover:opacity-100 font-medium px-3 py-1 bg-black bg-opacity-60 rounded-lg">
                                          View Full Size
                                        </span>
                                      </div>
                                    </a>
                                  </div>

                                  {isEditing &&
                                    (userRole === "owner" ||
                                      userRole === "hr" ||
                                      userRole === "recruiter") && (
                                      <button
                                        onClick={handleDeleteIdFile}
                                        className="mt-2 text-red-600 hover:text-red-800 font-medium text-sm flex items-center px-3 py-1 rounded-lg hover:bg-red-50 transition-colors duration-200"
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="mr-1"
                                        />
                                        Delete Driver's License
                                      </button>
                                    )}
                                </div>
                              ) : (
                                <div className="text-center py-6 border border-dashed border-gray-300 rounded-lg bg-gray-50">
                                  <FontAwesomeIcon
                                    icon={faIdCardAlt}
                                    className="w-12 h-12 mx-auto text-gray-400 mb-4"
                                  />
                                  <p className="text-gray-500">
                                    No Driver's License on file
                                  </p>
                                  {isEditing && (
                                    <>
                                      <button
                                        onClick={() =>
                                          document
                                            .getElementById("idFileInput")
                                            .click()
                                        }
                                        className="mt-3 inline-flex items-center px-4 py-2 border border-blue-500 text-blue-500 bg-white rounded-lg hover:bg-blue-50 transition-colors duration-200"
                                      >
                                        <FontAwesomeIcon
                                          icon={faUpload}
                                          className="mr-2"
                                        />
                                        Upload Driver's License
                                      </button>
                                      <input
                                        type="file"
                                        id="idFileInput"
                                        onChange={handleIdFileChange}
                                        accept="image/*"
                                        className="hidden"
                                      />
                                    </>
                                  )}
                                </div>
                              )
                            ) : selectedEmployee.driversLicenseFileUrl ? (
                              <div className="flex items-center justify-center py-3 bg-green-50 text-green-700 rounded-lg border border-green-200">
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="mr-2"
                                />
                                Driver's License has been uploaded
                              </div>
                            ) : (
                              <div className="flex items-center justify-center py-3 bg-amber-50 text-amber-700 rounded-lg border border-amber-200">
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  className="mr-2"
                                />
                                No Driver's License on file
                              </div>
                            )}

                            {/* Upload progress indicator */}
                            {idUploadProgress > 0 && (
                              <div className="w-full mt-3">
                                <div className="flex justify-between text-xs font-medium mb-1">
                                  <span className="text-blue-600">
                                    Uploading document...
                                  </span>
                                  <span className="text-gray-600">
                                    {idUploadProgress.toFixed(0)}%
                                  </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-1.5">
                                  <div
                                    className="bg-blue-600 h-1.5 rounded-full transition-all duration-200"
                                    style={{ width: `${idUploadProgress}%` }}
                                  ></div>
                                </div>
                              </div>
                            )}
                          </div>

                          {/* Tax Document Upload/Display */}
                          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                            <h4 className="text-sm font-medium text-gray-700 mb-3 flex items-center">
                              <FontAwesomeIcon
                                icon={faFileInvoice}
                                className="w-4 h-4 mr-1 text-gray-500"
                              />
                              Tax Documentation
                            </h4>

                            {canSeeDocuments() ? (
                              selectedEmployee.documentUrl ? (
                                <div className="flex flex-col items-center border border-gray-200 rounded-lg p-4 bg-white">
                                  <div className="flex items-center justify-center mb-3">
                                    <FontAwesomeIcon
                                      icon={faFileAlt}
                                      className="w-12 h-12 text-blue-500"
                                    />
                                  </div>
                                  <a
                                    href={selectedEmployee.documentUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="px-4 py-2 bg-blue-500 font-medium text-white rounded-lg hover:bg-blue-600 transition-colors duration-200 flex items-center shadow-sm"
                                  >
                                    <FontAwesomeIcon
                                      icon={faFileAlt}
                                      className="w-4 h-4 mr-2"
                                    />
                                    View Document
                                  </a>

                                  {isEditing &&
                                    (userRole === "owner" ||
                                      userRole === "hr" ||
                                      userRole === "recruiter") && (
                                      <button
                                        onClick={handleDeleteDocumentFile}
                                        className="mt-3 text-red-600 hover:text-red-800 font-medium text-sm flex items-center px-3 py-1 rounded-lg hover:bg-red-50 transition-colors duration-200"
                                      >
                                        <FontAwesomeIcon
                                          icon={faTrash}
                                          className="mr-1"
                                        />
                                        Delete Tax Document
                                      </button>
                                    )}

                                  {/* Delete progress indicator */}
                                  {documentDeleteProgress > 0 && (
                                    <div className="w-full mt-3">
                                      <div className="flex justify-between text-xs font-medium mb-1">
                                        <span className="text-red-600">
                                          Deleting document...
                                        </span>
                                        <span className="text-gray-600">
                                          {documentDeleteProgress.toFixed(0)}%
                                        </span>
                                      </div>
                                      <div className="w-full bg-gray-200 rounded-full h-1.5">
                                        <div
                                          className="bg-red-600 h-1.5 rounded-full transition-all duration-200"
                                          style={{
                                            width: `${documentDeleteProgress}%`,
                                          }}
                                        ></div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              ) : (
                                <div className="text-center py-6 border border-dashed border-gray-300 rounded-lg bg-gray-50">
                                  <FontAwesomeIcon
                                    icon={faFileInvoice}
                                    className="w-12 h-12 mx-auto text-gray-400 mb-4"
                                  />
                                  <p className="text-gray-500">
                                    No Tax Document on file
                                  </p>
                                  {isEditing && (
                                    <>
                                      <button
                                        onClick={() =>
                                          document
                                            .getElementById("taxDocumentInput")
                                            .click()
                                        }
                                        className="mt-3 inline-flex items-center px-4 py-2 border border-blue-500 text-blue-500 bg-white rounded-lg hover:bg-blue-50 transition-colors duration-200"
                                      >
                                        <FontAwesomeIcon
                                          icon={faUpload}
                                          className="mr-2"
                                        />
                                        Upload Tax Document
                                      </button>
                                      <input
                                        type="file"
                                        id="taxDocumentInput"
                                        onChange={handleDocumentFileChange}
                                        accept=".pdf"
                                        className="hidden"
                                      />
                                    </>
                                  )}
                                </div>
                              )
                            ) : selectedEmployee.documentUrl ? (
                              <div className="flex items-center justify-center py-3 bg-green-50 text-green-700 rounded-lg border border-green-200">
                                <FontAwesomeIcon
                                  icon={faCheck}
                                  className="mr-2"
                                />
                                Tax document has been uploaded
                              </div>
                            ) : (
                              <div className="flex items-center justify-center py-3 bg-amber-50 text-amber-700 rounded-lg border border-amber-200">
                                <FontAwesomeIcon
                                  icon={faExclamationTriangle}
                                  className="mr-2"
                                />
                                No Tax Document on file
                              </div>
                            )}

                            {/* Upload progress indicator */}
                            {documentUploadProgress > 0 && (
                              <div className="w-full mt-3">
                                <div className="flex justify-between text-xs font-medium mb-1">
                                  <span className="text-blue-600">
                                    Uploading document...
                                  </span>
                                  <span className="text-gray-600">
                                    {documentUploadProgress.toFixed(0)}%
                                  </span>
                                </div>
                                <div className="w-full bg-gray-200 rounded-full h-1.5">
                                  <div
                                    className="bg-blue-600 h-1.5 rounded-full transition-all duration-200"
                                    style={{
                                      width: `${documentUploadProgress}%`,
                                    }}
                                  ></div>
                                </div>
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Employee Name Section */}
                        <div className="w-full mb-6">
                          <h3 className="text-gray-600 uppercase text-xs font-semibold tracking-wider mb-3 flex items-center">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="w-4 h-4 mr-1"
                            />
                            Personal Information
                          </h3>
                          {isEditing ? (
                            <div className="space-y-3">
                              <div className="relative">
                                <label className="text-xs text-gray-500 mb-1 block">
                                  First Name
                                </label>
                                <input
                                  type="text"
                                  value={capitalizeName(
                                    selectedEmployee.firstName
                                  )}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "firstName",
                                      e.target.value
                                    )
                                  }
                                  placeholder="First Name"
                                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                />
                              </div>
                              <div className="relative">
                                <label className="text-xs text-gray-500 mb-1 block">
                                  Middle Name
                                </label>
                                <input
                                  type="text"
                                  value={capitalizeName(
                                    selectedEmployee.middleName
                                  )}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "middleName",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Middle Name (optional)"
                                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                />
                              </div>
                              <div className="relative">
                                <label className="text-xs text-gray-500 mb-1 block">
                                  Last Name
                                </label>
                                <input
                                  type="text"
                                  value={capitalizeName(
                                    selectedEmployee.lastName
                                  )}
                                  onChange={(e) =>
                                    handleInputChange(
                                      "lastName",
                                      e.target.value
                                    )
                                  }
                                  placeholder="Last Name"
                                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                />
                              </div>
                            </div>
                          ) : (
                            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
                              <h2 className="text-xl font-bold text-gray-800">{`${capitalizeName(
                                selectedEmployee.firstName
                              )} ${capitalizeName(
                                selectedEmployee.middleName
                              )} ${capitalizeName(
                                selectedEmployee.lastName
                              )}`}</h2>
                              <div className="text-sm text-gray-500 mt-1 flex items-center">
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="w-4 h-4 mr-1"
                                />
                                {selectedEmployee.email || "No email provided"}
                              </div>
                              <div className="mt-2">
                                <span className="bg-blue-100 text-blue-800 text-xs font-semibold px-2.5 py-0.5 rounded-full">
                                  {selectedEmployee.role}
                                </span>
                              </div>
                            </div>
                          )}
                        </div>

                        {/* Phone Number */}
                        <div className="w-full mb-6">
                          <h3 className="text-gray-600 uppercase text-xs font-semibold tracking-wider mb-3 flex items-center">
                            <FontAwesomeIcon
                              icon={faPhone}
                              className="w-4 h-4 mr-1"
                            />
                            Contact
                          </h3>
                          {isEditing ? (
                            <div className="relative">
                              <label className="text-xs text-gray-500 mb-1 block">
                                Phone Number
                              </label>
                              <input
                                type="text"
                                value={
                                  formatPhoneNumber(selectedEmployee.phone) ||
                                  ""
                                }
                                onChange={(e) =>
                                  handleInputChange(
                                    "phone",
                                    e.target.value.replace(/\D/g, "")
                                  )
                                }
                                placeholder="(XXX) XXX-XXXX"
                                className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                autoComplete="off"
                              />
                            </div>
                          ) : (
                            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200 flex items-center">
                              <FontAwesomeIcon
                                icon={faPhone}
                                className="w-5 h-5 text-gray-400 mr-2"
                              />
                              <span className="text-gray-700">
                                {selectedEmployee.phone
                                  ? formatPhoneNumber(selectedEmployee.phone)
                                  : "N/A"}
                              </span>
                            </div>
                          )}
                        </div>

                        {/* Address Section */}
                        <div className="w-full mb-6">
                          <h3 className="text-gray-600 uppercase text-xs font-semibold tracking-wider mb-3 flex items-center">
                            <FontAwesomeIcon
                              icon={faMapMarkerAlt}
                              className="w-4 h-4 mr-1"
                            />
                            Address
                          </h3>
                          {renderAddressSection()}
                        </div>

                        {/* Compensation & Payment Information */}
                        <div className="w-full mb-6">
                          <h3 className="text-gray-600 uppercase text-xs font-semibold tracking-wider mb-3 flex items-center">
                            <FontAwesomeIcon
                              icon={faMoneyBillAlt}
                              className="w-4 h-4 mr-1 text-green-500"
                            />
                            Banking Information
                          </h3>

                          <div className="bg-gray-50 rounded-lg p-4 border border-gray-200">
                            {userRole !== "supervisor" &&
                              userRole !== "manager" && (
                                <div className="space-y-3">
                                  {/* Bank Name */}
                                  {isEditing ? (
                                    <div className="form-group">
                                      <label className="block text-xs text-gray-500 mb-1">
                                        Bank Name
                                      </label>
                                      <input
                                        type="text"
                                        value={selectedEmployee.bank || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            "bank",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Bank name"
                                        className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                        autoComplete="off"
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex items-center mb-2">
                                      <span className="text-sm font-medium text-gray-500 w-24">
                                        Bank:
                                      </span>
                                      <span className="text-sm text-gray-700">
                                        {selectedEmployee.bank || "N/A"}
                                      </span>
                                    </div>
                                  )}

                                  {/* Account */}
                                  {isEditing ? (
                                    <div className="form-group">
                                      <label className="block text-xs text-gray-500 mb-1">
                                        Account Number
                                      </label>
                                      <input
                                        type="text"
                                        value={selectedEmployee.account || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            "account",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Account number"
                                        className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                        autoComplete="off"
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex items-center mb-2">
                                      <span className="text-sm font-medium text-gray-500 w-24">
                                        Account:
                                      </span>
                                      <span className="text-sm text-gray-700">
                                        {selectedEmployee.account || "N/A"}
                                      </span>
                                    </div>
                                  )}

                                  {/* Routing */}
                                  {isEditing ? (
                                    <div className="form-group">
                                      <label className="block text-xs text-gray-500 mb-1">
                                        Routing Number
                                      </label>
                                      <input
                                        type="text"
                                        value={selectedEmployee.routing || ""}
                                        onChange={(e) =>
                                          handleInputChange(
                                            "routing",
                                            e.target.value
                                          )
                                        }
                                        placeholder="Routing number"
                                        className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                        autoComplete="off"
                                      />
                                    </div>
                                  ) : (
                                    <div className="flex items-center">
                                      <span className="text-sm font-medium text-gray-500 w-24">
                                        Routing:
                                      </span>
                                      <span className="text-sm text-gray-700">
                                        {selectedEmployee.routing || "N/A"}
                                      </span>
                                    </div>
                                  )}
                                </div>
                              )}
                            {(userRole === "supervisor" ||
                              userRole === "manager") && (
                              <div className="py-3 text-center text-gray-500">
                                Banking information is restricted
                              </div>
                            )}
                          </div>
                        </div>

                        {/* Email Update Section for admins */}
                        {isEditing &&
                          (userRole === "owner" ||
                            userRole === "hr" ||
                            userRole === "recruiter") && (
                            <div className="w-full mb-6">
                              <h3 className="text-gray-600 uppercase text-xs font-semibold tracking-wider mb-3 flex items-center">
                                <FontAwesomeIcon
                                  icon={faEnvelope}
                                  className="w-4 h-4 mr-1"
                                />
                                Update Email
                              </h3>
                              <div className="space-y-2">
                                <input
                                  type="email"
                                  value={emailInput}
                                  onChange={(e) =>
                                    setEmailInput(
                                      e.target.value.toLowerCase().trim()
                                    )
                                  }
                                  placeholder="Enter new email"
                                  className="border border-gray-300 rounded-lg h-10 w-full p-2 focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                                />
                                <button
                                  onClick={handleEmailChange}
                                  className="w-full bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded-lg transition-colors duration-200 flex items-center justify-center"
                                  disabled={emailLoading}
                                >
                                  {emailLoading ? (
                                    <span className="flex items-center">
                                      <FontAwesomeIcon
                                        icon={faSpinner}
                                        className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                      />
                                      Updating...
                                    </span>
                                  ) : (
                                    "Update Email"
                                  )}
                                </button>
                                {emailUpdateStatus && (
                                  <p
                                    className={`text-sm mt-1 ${
                                      emailUpdateStatus.success
                                        ? "text-green-600"
                                        : "text-red-600"
                                    }`}
                                  >
                                    {emailUpdateStatus.message}
                                  </p>
                                )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>

                    <div className="md:w-2/3">
                      {/* Right Column - Employment & Financial Details */}
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {/* Employment Information */}
                        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                          <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                            <FontAwesomeIcon
                              icon={faUserTie}
                              className="w-5 h-5 mr-2 text-blue-500"
                            />
                            Employment Information
                          </h3>

                          <div className="space-y-4">
                            <div className="grid grid-cols-1 gap-4">
                              {renderStatusSection()}
                              {renderRoleSection()}
                              {renderHiringDateSection()}
                              {renderTerminationDateSection()}
                              {renderPayMethod()}
                              {renderRateSection()}
                              {renderSalarySection()}
                            </div>

                            {/* Work Location */}
                            <div className="form-group">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Work Location
                              </label>
                              {isEditing ? (
                                <Select
                                  value={locations.find(
                                    (location) =>
                                      location.label ===
                                      selectedEmployee.workLocation
                                  )}
                                  onChange={handleLocationChange}
                                  options={locations}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      borderRadius: "0.375rem",
                                      boxShadow: "none",
                                      "&:hover": {
                                        borderColor: "#6366F1",
                                      },
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      borderRadius: "0.375rem",
                                      boxShadow:
                                        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                                    }),
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                />
                              ) : (
                                <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
                                  {selectedEmployee.workLocation || "N/A"}
                                </div>
                              )}
                            </div>

                            {/* Reports To */}
                            <div className="form-group">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Reports To
                              </label>
                              {isEditing ? (
                                <Select
                                  value={activeSupervisors.filter(
                                    (supervisor) =>
                                      selectedEmployee.reportsTo?.includes(
                                        supervisor.value
                                      )
                                  )}
                                  onChange={(selectedOptions) =>
                                    handleInputChange(
                                      "reportsTo",
                                      selectedOptions.map(
                                        (option) => option.value
                                      )
                                    )
                                  }
                                  options={activeSupervisors}
                                  isMulti
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      borderRadius: "0.375rem",
                                      boxShadow: "none",
                                      "&:hover": {
                                        borderColor: "#6366F1",
                                      },
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      borderRadius: "0.375rem",
                                      boxShadow:
                                        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                                    }),
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                />
                              ) : (
                                <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
                                  {selectedEmployee.reportsTo
                                    ? displayedSupervisorNames
                                    : "N/A"}
                                </div>
                              )}
                            </div>

                            {/* Position */}
                            <div className="form-group">
                              <label className="block text-sm font-medium text-gray-700 mb-1">
                                Position
                              </label>
                              {isEditing ? (
                                <Select
                                  isMulti
                                  value={
                                    Array.isArray(selectedEmployee.position)
                                      ? selectedEmployee.position.map(
                                          (pos) => ({
                                            value: pos,
                                            label: pos,
                                          })
                                        )
                                      : selectedEmployee.position
                                      ? [
                                          {
                                            value: selectedEmployee.position,
                                            label: selectedEmployee.position,
                                          },
                                        ]
                                      : []
                                  }
                                  onChange={(selectedOptions) =>
                                    handleInputChange(
                                      "position",
                                      selectedOptions
                                        .map((option) => option.value)
                                        .join(", ")
                                    )
                                  }
                                  options={positions}
                                  styles={{
                                    control: (provided) => ({
                                      ...provided,
                                      borderRadius: "0.375rem",
                                      boxShadow: "none",
                                      "&:hover": {
                                        borderColor: "#6366F1",
                                      },
                                    }),
                                    menu: (provided) => ({
                                      ...provided,
                                      borderRadius: "0.375rem",
                                      boxShadow:
                                        "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)",
                                    }),
                                  }}
                                  className="react-select-container"
                                  classNamePrefix="react-select"
                                />
                              ) : (
                                <div className="bg-gray-50 rounded-md py-2 px-3 text-gray-700">
                                  {Array.isArray(selectedEmployee.position)
                                    ? selectedEmployee.position.join(", ")
                                    : selectedEmployee.position || "N/A"}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>

                        {/* Additional Employee Information */}
                        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                          <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
                            <FontAwesomeIcon
                              icon={faUser}
                              className="w-5 h-5 mr-2 text-indigo-500"
                            />
                            Additional Information
                          </h3>

                          {renderAdditionalInformation()}
                        </div>

                        {/* Sensitive Information (only for authorized roles) */}
                        {userRole !== "manager" && (
                          <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 md:col-span-2">
                            {renderSensitiveInformation()}
                          </div>
                        )}
                      </div>

                      {/* Notes and Additional information in a full-width row at the bottom */}
                      <div className="mt-6 grid grid-cols-1 md:grid-cols-3 gap-6">
                        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                          <h3 className="text-gray-800 font-medium mb-3">
                            Career Evolution
                          </h3>
                          {isEditing ? (
                            <textarea
                              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                              rows="4"
                              placeholder="Provide a detailed account of the employee's career progression, including promotions, role changes, and significant achievements..."
                              value={selectedEmployee.careerEvolution || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  "careerEvolution",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 text-gray-700">
                              {selectedEmployee.careerEvolution ||
                                "No information provided"}
                            </div>
                          )}
                        </div>

                        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                          <h3 className="text-gray-800 font-medium mb-3">
                            Special Abilities
                          </h3>
                          {isEditing ? (
                            <textarea
                              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                              rows="4"
                              placeholder="Detail any special skills, talents, or certifications that set this employee apart from others..."
                              value={selectedEmployee.specialAbilities || ""}
                              onChange={(e) =>
                                handleInputChange(
                                  "specialAbilities",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 text-gray-700">
                              {selectedEmployee.specialAbilities ||
                                "No information provided"}
                            </div>
                          )}
                        </div>

                        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                          <h3 className="text-gray-800 font-medium mb-3">
                            Notes
                          </h3>
                          {isEditing ? (
                            <textarea
                              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                              rows="4"
                              placeholder="Enter any additional notes or observations about the employee..."
                              value={selectedEmployee.notes || ""}
                              onChange={(e) =>
                                handleInputChange("notes", e.target.value)
                              }
                            />
                          ) : (
                            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 text-gray-700">
                              {selectedEmployee.notes ||
                                "No information provided"}
                            </div>
                          )}
                        </div>

                        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                          <h3 className="text-gray-800 font-medium mb-3">
                            Termination Description
                          </h3>
                          {isEditing ? (
                            <textarea
                              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent outline-none transition-all duration-200"
                              rows="4"
                              placeholder="Enter detailed information about the termination, including reasons and circumstances..."
                              value={
                                selectedEmployee.terminationDescription || ""
                              }
                              onChange={(e) =>
                                handleInputChange(
                                  "terminationDescription",
                                  e.target.value
                                )
                              }
                            />
                          ) : (
                            <div className="bg-gray-50 p-3 rounded-lg border border-gray-200 text-gray-700">
                              {selectedEmployee.terminationDescription ||
                                "No information provided"}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            )}

            {activeTab === "reportingUsers" && (
              <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-6">
                <h3 className="text-lg font-semibold text-gray-800 mb-4">
                  Reporting Users
                </h3>
                {employeeId ? (
                  <ReportingUsers employeeId={employeeId} />
                ) : (
                  <div className="text-center py-8 text-gray-500">
                    <FontAwesomeIcon
                      icon={faUser}
                      className="w-12 h-12 mx-auto text-gray-400 mb-4"
                    />
                    <p className="text-xl font-medium">No employee selected</p>
                    <p className="mt-2">
                      Please select an employee to view their reporting users.
                    </p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeModal;
