import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import {
  doc,
  updateDoc,
  Timestamp,
  onSnapshot,
  collection,
  getDocs,
  addDoc,
  deleteDoc,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faClock,
  faFileAlt,
  faTimes,
  faUser,
  faBriefcase,
  faLocationDot,
  faLanguage,
  faCalendarDay,
  faPencilAlt,
  faCheck,
  faTimes as faTimesIcon,
  faInfoCircle,
  faCopy,
  faTrash,
  faDollarSign,
} from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";

// InfoItem component for displaying labeled information
function InfoItem({ icon, label, value }) {
  return (
    <div className="mb-3 flex items-center">
      <FontAwesomeIcon icon={icon} className="text-gray-500 mr-2 w-5" />
      <span className="font-medium text-gray-600">{label}:</span>
      <span className="text-gray-800 ml-2">{value}</span>
    </div>
  );
}

// Badge component for displaying status/type items
function Badge({ text, colorClass }) {
  return (
    <span
      className={`px-3 py-1 rounded-full text-xs font-medium ${colorClass}`}
    >
      {text}
    </span>
  );
}

function PositionDetails({ position, onClose, userName, userRole }) {
  const [status, setStatus] = useState(position?.status || "open");
  const [isEditing, setIsEditing] = useState(false);
  const [locations, setLocations] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});
  const [showDuplicateSuccess, setShowDuplicateSuccess] = useState(false);
  const [showDuplicateConfirm, setShowDuplicateConfirm] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    notes: "",
    location: "",
    isTemporary: false,
    scheduleType: "full-time",
    shiftPreference: {
      am: false,
      pm: false,
      overnight: false,
    },
    requiresEnglish: false,
    englishLevel: "basic",
    hourlyRate: "",
    showHourlyRate: true,
  });

  // Initialize form data when position data is available
  useEffect(() => {
    if (position) {
      setFormData({
        title: position.title || "",
        notes: position.notes || "",
        location: position.location || "",
        isTemporary: position.isTemporary || false,
        scheduleType: position.scheduleType || "full-time",
        shiftPreference: position.shiftPreference || {
          am: false,
          pm: false,
          overnight: false,
        },
        requiresEnglish: position.requiresEnglish || false,
        englishLevel: position.englishLevel || "basic",
        hourlyRate: position.hourlyRate ? position.hourlyRate.toString() : "",
        showHourlyRate: position.showHourlyRate ?? true,
      });
    }
  }, [position]);

  // Fetch locations and position titles
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch locations
        const locationsCollection = collection(db, "locations");
        const locationsSnapshot = await getDocs(locationsCollection);
        const locationsList = locationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort locations alphabetically by name
        const sortedLocations = locationsList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setLocations(sortedLocations);

        // Fetch existing position titles
        const positionsCollection = collection(db, "positions");
        const positionsSnapshot = await getDocs(positionsCollection);

        // Extract unique titles and create options
        const titles = new Set();
        positionsSnapshot.docs.forEach((doc) => {
          const position = doc.data();
          if (position.title) {
            titles.add(position.title);
          }
        });

        const formattedTitles = Array.from(titles)
          .sort()
          .map((title) => ({
            value: title,
            label: title,
          }));

        setTitleOptions(formattedTitles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Toggle edit mode function
  const toggleEditMode = () => {
    if (isEditing) {
      // Exiting edit mode, reset form data to current position data
      setFormData({
        title: position.title || "",
        notes: position.notes || "",
        location: position.location || "",
        isTemporary: position.isTemporary || false,
        scheduleType: position.scheduleType || "full-time",
        shiftPreference: position.shiftPreference || {
          am: false,
          pm: false,
          overnight: false,
        },
        requiresEnglish: position.requiresEnglish || false,
        englishLevel: position.englishLevel || "basic",
        hourlyRate: position.hourlyRate ? position.hourlyRate.toString() : "",
        showHourlyRate: position.showHourlyRate ?? true,
      });
      setErrors({});
    }
    setIsEditing(!isEditing);
  };

  useEffect(() => {
    // Set up real-time listener for position updates
    const unsubscribe = onSnapshot(
      doc(db, "positions", position.id),
      (docSnapshot) => {
        const updatedData = docSnapshot.data();
        // Update the position object with the latest data
        if (updatedData) {
          // Update status
          setStatus(updatedData.status || "open");

          // Instead of mutating the position object directly, we update the form state
          // and let the parent component handle position updates through props
          setFormData({
            title: updatedData.title || "",
            notes: updatedData.notes || "",
            location: updatedData.location || "",
            isTemporary: updatedData.isTemporary || false,
            scheduleType: updatedData.scheduleType || "full-time",
            shiftPreference: updatedData.shiftPreference || {
              am: false,
              pm: false,
              overnight: false,
            },
            requiresEnglish: updatedData.requiresEnglish || false,
            englishLevel: updatedData.requiresEnglish
              ? updatedData.englishLevel
              : "basic",
            hourlyRate: updatedData.hourlyRate
              ? updatedData.hourlyRate.toString()
              : "",
            showHourlyRate: updatedData.showHourlyRate ?? true,
          });
        }
      }
    );

    // Clean up the listener when the component unmounts
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.id]);

  // Roles allowed to approve positions
  const approverRoles = ["owner", "hr", "recruiter"];
  const canApprove = approverRoles.includes(userRole);

  // Roles allowed to manage (edit, delete, duplicate) positions
  const managerRoles = ["owner", "hr", "recruiter"]; // Or adjust as needed
  const canManage = managerRoles.includes(userRole);

  // Function to handle position approval
  const handleApprove = async () => {
    if (!canApprove) {
      console.error("User does not have permission to approve.");
      return;
    }
    setIsSubmitting(true);
    try {
      const positionRef = doc(db, "positions", position.id);
      await updateDoc(positionRef, {
        status: "open",
        approved: true,
        approvedBy: userName,
        approvedAt: Timestamp.now(),
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });
      // Optionally close the modal or show a success message
      // setStatus('open'); // Update local state immediately if needed
      console.log("Position approved successfully.");
    } catch (error) {
      console.error("Error approving position:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const formatDateTime = (timestamp) => {
    if (!timestamp || !timestamp.seconds) {
      return { date: "N/A", time: "N/A" };
    }
    const date = new Date(timestamp.seconds * 1000);
    return {
      date: date.toLocaleDateString(),
      time: date.toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      }),
    };
  };

  // If position time of submission is missing, use default value
  const { date, time } = formatDateTime(position?.timeOfSubmission);

  const handleFieldUpdate = async (field, value) => {
    try {
      const positionRef = doc(db, "positions", position.id);
      await updateDoc(positionRef, {
        [field]: value,
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });
    } catch (error) {
      console.error(`Error updating ${field}:`, error);
    }
  };

  const handleSave = async () => {
    // Get the title value (accounting for whether it's an object or string)
    const titleValue =
      typeof formData.title === "object" && formData.title
        ? formData.title.value
        : formData.title;

    // Form validation
    const newErrors = {};
    if (!titleValue || titleValue.trim() === "") {
      newErrors.title = "Position title is required";
    }

    // Validate that at least one shift preference is selected
    if (
      !formData.shiftPreference.am &&
      !formData.shiftPreference.pm &&
      !formData.shiftPreference.overnight
    ) {
      newErrors.shiftPreference =
        "At least one shift preference must be selected";
    }

    // Validate hourly rate (if provided) is a valid number
    if (formData.hourlyRate && isNaN(parseFloat(formData.hourlyRate))) {
      newErrors.hourlyRate = "Hourly rate must be a valid number";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const updatedData = {
        title: titleValue,
        notes: formData.notes,
        location: formData.location,
        isTemporary: formData.isTemporary,
        scheduleType: formData.scheduleType,
        shiftPreference: formData.shiftPreference,
        requiresEnglish: formData.requiresEnglish,
        englishLevel: formData.requiresEnglish ? formData.englishLevel : null,
        hourlyRate: formData.hourlyRate
          ? parseFloat(formData.hourlyRate)
          : null,
        showHourlyRate: formData.showHourlyRate,
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      };

      const positionRef = doc(db, "positions", position.id);
      await updateDoc(positionRef, updatedData);

      // Exit edit mode
      setIsEditing(false);
    } catch (error) {
      console.error("Error updating position:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleClosePosition = async () => {
    try {
      const positionRef = doc(db, "positions", position.id);
      await updateDoc(positionRef, {
        status: "closed",
        lastUpdatedBy: userName,
        lastUpdatedAt: Timestamp.now(),
      });
    } catch (error) {
      console.error("Error closing position:", error);
    }
  };

  // Get English level display
  const getEnglishLevelInfo = () => {
    if (!position || !position.requiresEnglish) {
      return {
        text: "Not Required",
        colorClass: "bg-gray-100 text-gray-800",
      };
    }

    const levelColors = {
      basic: "bg-green-100 text-green-800",
      intermediate: "bg-blue-100 text-blue-800",
      advanced: "bg-purple-100 text-purple-800",
      fluent: "bg-indigo-100 text-indigo-800",
    };

    return {
      text: position.englishLevel
        ? position.englishLevel.charAt(0).toUpperCase() +
          position.englishLevel.slice(1)
        : "Required",
      colorClass:
        levelColors[position.englishLevel] || "bg-red-100 text-red-800",
    };
  };

  const englishLevel = getEnglishLevelInfo();

  // Helper function to check if the current user created the position
  const isPositionCreator = () => {
    if (!position || !position.userUid) return false;

    const currentUserUid =
      auth.currentUser?.uid || localStorage.getItem("userUid");
    return position.userUid === currentUserUid;
  };

  // Determine if user can perform management actions
  const showManagementActions = canManage || isPositionCreator();

  // Function to show delete confirmation
  const confirmDelete = () => {
    setShowDeleteConfirm(true);
  };

  // Function to delete a position
  const handleDelete = async () => {
    setShowDeleteConfirm(false);
    setIsSubmitting(true);

    try {
      if (!position || !position.id) {
        console.error("No position ID found");
        setIsSubmitting(false);
        onClose();
        return;
      }

      // Delete the position document
      const positionRef = doc(db, "positions", position.id);
      await deleteDoc(positionRef);

      // Close the modal immediately after deletion
      onClose();
    } catch (error) {
      console.error("Error deleting position:", error);
      setIsSubmitting(false);
    }
  };

  // Function to show duplicate confirmation
  const confirmDuplicate = () => {
    setShowDuplicateConfirm(true);
  };

  // Function to duplicate a position
  const handleDuplicate = async () => {
    setShowDuplicateConfirm(false);
    setIsSubmitting(true);
    try {
      // Generate a title that indicates it's a duplicate
      const titleValue = position.title || "Untitled Position";

      const positionsCollection = collection(db, "positions");

      // Get positions count for generating ID
      const positionsSnapshot = await getDocs(positionsCollection);
      const nextIndex = positionsSnapshot.size;

      // Generate a new position ID
      const generatePositionId = (index) => {
        return `POS-${String(index + 1).padStart(4, "0")}`;
      };

      // Create a new position with the same data as the current one
      await addDoc(positionsCollection, {
        title: titleValue,
        notes: position.notes || "",
        location: position.location || "",
        isTemporary: position.isTemporary || false,
        scheduleType: position.scheduleType || "full-time",
        shiftPreference: position.shiftPreference || {
          am: false,
          pm: false,
          overnight: false,
        },
        requiresEnglish: position.requiresEnglish || false,
        englishLevel: position.requiresEnglish ? position.englishLevel : null,
        hourlyRate: position.hourlyRate || null,
        showHourlyRate: position.showHourlyRate ?? true,
        positionId: generatePositionId(nextIndex),
        status: "open", // Always set as open
        createdBy: userName,
        createdAt: Timestamp.now(),
        timeOfSubmission: Timestamp.now(),
        userUid: auth.currentUser?.uid || localStorage.getItem("userUid"),
      });

      // Show success message briefly
      setShowDuplicateSuccess(true);

      // Close the modal after a short delay to show the success message
      setTimeout(() => {
        setShowDuplicateSuccess(false);
        onClose(); // Close the modal to return to the positions list which will be updated via onSnapshot
      }, 1500);
    } catch (error) {
      console.error("Error duplicating position:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div
      className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
      onClick={onClose}
    >
      {/* Delete Confirmation Dialog */}
      {showDeleteConfirm && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Delete Position
            </h3>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete "
              {position.title || "Untitled Position"}"? This action cannot be
              undone.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                onClick={() => setShowDeleteConfirm(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-red-600 text-white rounded-md hover:bg-red-700 transition-colors"
                onClick={handleDelete}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Deleting..." : "Delete"}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Duplicate Confirmation Dialog */}
      {showDuplicateConfirm && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="bg-white rounded-lg p-6 max-w-md w-full shadow-xl">
            <h3 className="text-lg font-medium text-gray-900 mb-4">
              Duplicate Position
            </h3>
            <p className="text-gray-600 mb-6">
              This will create a copy of "
              {position.title || "Untitled Position"}" with all its details. The
              new position will be set to "Open" status.
            </p>
            <div className="flex justify-end space-x-4">
              <button
                className="px-4 py-2 bg-gray-200 text-gray-800 rounded-md hover:bg-gray-300 transition-colors"
                onClick={() => setShowDuplicateConfirm(false)}
              >
                Cancel
              </button>
              <button
                className="px-4 py-2 bg-purple-600 text-white rounded-md hover:bg-purple-700 transition-colors"
                onClick={handleDuplicate}
                disabled={isSubmitting}
              >
                {isSubmitting ? "Creating..." : "Duplicate"}
              </button>
            </div>
          </div>
        </div>
      )}

      <div
        className="relative top-20 mx-auto p-6 border w-11/12 md:w-3/4 lg:w-2/3 shadow-lg rounded-lg bg-white mb-20"
        onClick={(e) => e.stopPropagation()}
      >
        {/* Header */}
        <div className="flex justify-between items-center mb-6 border-b pb-4">
          <div>
            <h2 className="text-2xl font-bold text-gray-900 flex items-center">
              <FontAwesomeIcon
                icon={faBriefcase}
                className="text-blue-500 mr-3"
              />
              {isEditing
                ? "Edit Position"
                : formData.title || position.title || "Untitled Position"}
              {!isEditing && position.positionId && (
                <span className="ml-3 text-sm font-mono bg-gray-100 px-2 py-1 rounded text-gray-600">
                  {position.positionId}
                </span>
              )}
            </h2>
            {!isEditing && (
              <div className="flex items-center mt-2">
                <Badge
                  text={
                    formData.isTemporary || position.isTemporary
                      ? "Temporary"
                      : "Permanent"
                  }
                  colorClass={
                    formData.isTemporary || position.isTemporary
                      ? "bg-yellow-100 text-yellow-800"
                      : "bg-green-100 text-green-800"
                  }
                />
                <span className="mx-2">•</span>
                <Badge
                  text={
                    formData.scheduleType ||
                    position.scheduleType ||
                    "Full-time"
                  }
                  colorClass={
                    (formData.scheduleType || position.scheduleType) ===
                    "part-time"
                      ? "bg-orange-100 text-orange-800"
                      : "bg-blue-100 text-blue-800"
                  }
                />
                <span className="mx-2">•</span>
                <Badge
                  text={status.charAt(0).toUpperCase() + status.slice(1)}
                  colorClass={
                    status === "open"
                      ? "bg-emerald-100 text-emerald-800"
                      : "bg-gray-100 text-gray-800"
                  }
                />
              </div>
            )}
          </div>
          <div className="flex items-center">
            {/* Delete Button */}
            {showManagementActions && !isEditing && (
              <button
                onClick={confirmDelete}
                disabled={isSubmitting}
                className="text-red-500 hover:text-red-700 transition-colors mr-4"
                title="Delete position"
              >
                <FontAwesomeIcon icon={faTrash} className="text-xl" />
              </button>
            )}
            {/* Duplicate Button */}
            {showManagementActions && !isEditing && (
              <button
                onClick={confirmDuplicate}
                disabled={isSubmitting}
                className="text-purple-500 hover:text-purple-700 transition-colors mr-4"
                title="Duplicate position"
              >
                <FontAwesomeIcon icon={faCopy} className="text-xl" />
              </button>
            )}
            {showManagementActions && !isEditing && (
              <button
                onClick={toggleEditMode}
                className="text-blue-500 hover:text-blue-700 transition-colors mr-4"
                title="Edit position"
              >
                <FontAwesomeIcon icon={faPencilAlt} className="text-xl" />
              </button>
            )}
            {isEditing ? (
              <>
                <button
                  onClick={handleSave}
                  disabled={isSubmitting}
                  className="text-green-500 hover:text-green-700 transition-colors mr-4"
                  title="Save changes"
                >
                  <FontAwesomeIcon icon={faCheck} className="text-xl" />
                </button>
                <button
                  onClick={toggleEditMode}
                  className="text-red-500 hover:text-red-700 transition-colors mr-4"
                  title="Cancel"
                >
                  <FontAwesomeIcon icon={faTimesIcon} className="text-xl" />
                </button>
              </>
            ) : (
              <>
                {/* Approval Button - START */}
                {status === "pending_approval" && canApprove && (
                  <button
                    onClick={handleApprove}
                    disabled={isSubmitting}
                    className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition-colors shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
                  >
                    <FontAwesomeIcon icon={faCheck} className="mr-2" />
                    {isSubmitting ? "Approving..." : "Approve Position"}
                  </button>
                )}
                {/* Approval Button - END */}

                <button
                  onClick={onClose}
                  className="text-gray-500 hover:text-gray-700 transition-colors"
                >
                  <FontAwesomeIcon icon={faTimes} className="text-xl" />
                </button>
              </>
            )}
          </div>
        </div>

        {/* Success message for duplication */}
        {showDuplicateSuccess && (
          <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded relative mb-6 flex items-center">
            <FontAwesomeIcon icon={faCheck} className="mr-2" />
            <span>Position duplicated successfully!</span>
          </div>
        )}

        {/* Content */}
        <div className="space-y-6">
          {isEditing ? (
            /* Edit Mode */
            <EditModeContent
              formData={formData}
              setFormData={setFormData}
              locations={locations}
              titleOptions={titleOptions}
              errors={errors}
              setErrors={setErrors}
            />
          ) : (
            /* View Mode */
            <ViewModeContent
              position={position}
              formData={formData}
              date={date}
              time={time}
              englishLevel={englishLevel}
              status={status}
              setStatus={setStatus}
              handleFieldUpdate={handleFieldUpdate}
              handleClosePosition={handleClosePosition}
              isPositionCreator={isPositionCreator}
              userName={userName}
            />
          )}
        </div>
      </div>
    </div>
  );
}

// View mode component to display position details
function ViewModeContent({
  position,
  formData,
  date,
  time,
  englishLevel,
  status,
  setStatus,
  handleFieldUpdate,
  handleClosePosition,
  isPositionCreator,
  userName,
}) {
  // Get the most current values, preferring formData when available
  const notes = formData.notes || position.notes || "";
  const shiftPreference = formData.shiftPreference ||
    position.shiftPreference || { am: false, pm: false, overnight: false };
  const hourlyRate = formData.hourlyRate || position.hourlyRate;

  // Function to format hourly rate
  const formatCurrency = (amount) => {
    if (!amount) return "Not specified";
    return `$${parseFloat(amount).toFixed(2)}`;
  };

  return (
    <>
      {/* Main Content Section */}
      <div className="bg-gray-50 p-5 rounded-lg shadow-sm border border-gray-200">
        {/* Two-column layout for details and notes */}
        <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
          {/* Left Column - Position Details */}
          <div className="md:col-span-1">
            <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faLocationDot}
                className="mr-2 text-blue-500"
              />
              Position Details
            </h3>

            <InfoItem
              icon={faLocationDot}
              label="Location"
              value={position.locationName || "Unknown Location"}
            />
            <InfoItem
              icon={faCalendarDay}
              label="Posted"
              value={`${date} at ${time}`}
            />
            <InfoItem
              icon={faUser}
              label="Posted By"
              value={position.createdBy}
            />
            <InfoItem
              icon={faDollarSign}
              label="Hourly Rate"
              value={formatCurrency(hourlyRate)}
            />

            <div className="mt-4">
              <span className="font-medium text-gray-600 flex items-center mb-2">
                <FontAwesomeIcon
                  icon={faLanguage}
                  className="text-gray-500 mr-2 w-5"
                />
                English:
              </span>
              <Badge
                text={englishLevel.text}
                colorClass={englishLevel.colorClass}
              />
            </div>

            <div className="mt-4">
              <span className="font-medium text-gray-600 flex items-center mb-2">
                <FontAwesomeIcon
                  icon={faClock}
                  className="text-gray-500 mr-2 w-5"
                />
                Shift Preference:
              </span>
              {shiftPreference && typeof shiftPreference === "object" ? (
                <div className="flex gap-1 flex-wrap">
                  {shiftPreference.am && (
                    <Badge
                      text="AM"
                      colorClass="bg-indigo-100 text-indigo-800"
                    />
                  )}
                  {shiftPreference.pm && (
                    <Badge
                      text="PM"
                      colorClass="bg-purple-100 text-purple-800"
                    />
                  )}
                  {shiftPreference.overnight && (
                    <Badge
                      text="Overnight"
                      colorClass="bg-teal-100 text-teal-800"
                    />
                  )}
                  {!shiftPreference.am &&
                    !shiftPreference.pm &&
                    !shiftPreference.overnight && (
                      <Badge
                        text="Flexible"
                        colorClass="bg-gray-100 text-gray-800"
                      />
                    )}
                </div>
              ) : (
                <Badge
                  text={
                    shiftPreference
                      ? shiftPreference.toString().toUpperCase()
                      : "Flexible"
                  }
                  colorClass={
                    shiftPreference === "am"
                      ? "bg-indigo-100 text-indigo-800"
                      : shiftPreference === "pm"
                      ? "bg-purple-100 text-purple-800"
                      : "bg-teal-100 text-teal-800"
                  }
                />
              )}
            </div>
          </div>

          {/* Right Column - Notes */}
          <div className="md:col-span-2">
            <h3 className="text-lg font-semibold text-gray-800 mb-4 flex items-center">
              <FontAwesomeIcon
                icon={faFileAlt}
                className="mr-2 text-blue-500"
              />
              Notes
            </h3>
            <div className="bg-white p-4 rounded-md border border-gray-200 min-h-[150px]">
              <p className="text-gray-700 whitespace-pre-wrap leading-relaxed">
                {notes
                  ? notes
                  : position.description ||
                    position.duties ||
                    position.requirements
                  ? [
                      position.description &&
                        `Description:\n${position.description}`,
                      position.duties && `\n\nDuties:\n${position.duties}`,
                      position.requirements &&
                        `\n\nRequirements:\n${position.requirements}`,
                    ]
                      .filter(Boolean)
                      .join("")
                  : "No additional information provided"}
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

// Edit mode component to edit position details
function EditModeContent({
  formData,
  setFormData,
  locations,
  titleOptions,
  errors,
  setErrors,
}) {
  return (
    <div className="space-y-6">
      {/* Basic Information Section */}
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
        <h3 className="text-lg font-semibold text-gray-800 mb-4">
          Basic Information
        </h3>

        <div className="mb-5">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Position Title *
          </label>
          <CreatableSelect
            isClearable
            placeholder="Enter or select a position title..."
            options={titleOptions}
            value={
              typeof formData.title === "string" && formData.title
                ? { value: formData.title, label: formData.title }
                : formData.title
            }
            className={`shadow-sm rounded-md w-full text-gray-700 ${
              errors.title ? "border-red-500" : ""
            }`}
            classNamePrefix="select"
            onChange={(selectedOption) => {
              setFormData((prevState) => ({
                ...prevState,
                title: selectedOption,
              }));
              // Clear error when user makes a selection
              if (errors.title) {
                setErrors((prev) => ({ ...prev, title: undefined }));
              }
            }}
            onCreateOption={(inputValue) => {
              const newOption = { value: inputValue, label: inputValue };
              setFormData((prevState) => ({
                ...prevState,
                title: newOption,
              }));
              // Clear error when user creates an option
              if (errors.title) {
                setErrors((prev) => ({ ...prev, title: undefined }));
              }
            }}
            styles={{
              control: (provided, state) => ({
                ...provided,
                padding: "2px",
                borderColor: errors.title
                  ? "#EF4444"
                  : state.isFocused
                  ? "#3B82F6"
                  : "#D1D5DB",
                boxShadow: errors.title
                  ? "0 0 0 1px #EF4444"
                  : state.isFocused
                  ? "0 0 0 1px #3B82F6"
                  : provided.boxShadow,
                "&:hover": {
                  borderColor: errors.title ? "#EF4444" : "#9CA3AF",
                },
              }),
              option: (provided, state) => ({
                ...provided,
                backgroundColor: state.isSelected
                  ? "#2563EB"
                  : state.isFocused
                  ? "#DBEAFE"
                  : "white",
                color: state.isSelected ? "white" : "#374151",
              }),
            }}
          />
          {errors.title && (
            <p className="mt-1 text-sm text-red-600">{errors.title}</p>
          )}
        </div>

        <div className="mb-0">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Location *
          </label>
          <select
            value={formData.location}
            onChange={(e) =>
              setFormData({ ...formData, location: e.target.value })
            }
            className="shadow-sm border border-gray-300 rounded-md w-full py-2.5 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            required
          >
            {locations.length === 0 ? (
              <option value="">Loading locations...</option>
            ) : (
              locations.map((location) => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))
            )}
          </select>
        </div>
      </div>

      {/* Position Details Section */}
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faBriefcase} className="text-blue-600 mr-2" />
          <h3 className="text-lg font-semibold text-gray-800">
            Position Details
          </h3>
        </div>

        {/* Hourly Rate */}
        <div className="mb-5">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Hourly Rate ($)
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <span className="text-gray-500 sm:text-sm">$</span>
            </div>
            <input
              type="text"
              value={formData.hourlyRate}
              onChange={(e) => {
                // Only allow numbers and decimal point
                const value = e.target.value.replace(/[^0-9.]/g, "");
                setFormData({ ...formData, hourlyRate: value });

                // Clear error when user types
                if (errors.hourlyRate) {
                  setErrors((prev) => ({ ...prev, hourlyRate: undefined }));
                }
              }}
              placeholder="0.00"
              className={`shadow-sm border ${
                errors.hourlyRate ? "border-red-500" : "border-gray-300"
              } rounded-md w-full py-2.5 pl-8 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors`}
            />
          </div>
          {errors.hourlyRate && (
            <p className="mt-1 text-sm text-red-600">{errors.hourlyRate}</p>
          )}
          <div className="mt-2">
            <label className="flex items-center text-sm text-gray-700">
              <input
                type="checkbox"
                checked={formData.showHourlyRate}
                onChange={(e) =>
                  setFormData({ ...formData, showHourlyRate: e.target.checked })
                }
                className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
              />
              <span className="ml-2">Show hourly rate on Careers page</span>
            </label>
          </div>
        </div>

        {/* Position Type */}
        <div className="mb-5">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Position Type
          </label>
          <div className="mt-1 grid grid-cols-2 gap-4">
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                !formData.isTemporary
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="radio"
                className="form-radio text-blue-600 hidden"
                name="positionType"
                checked={!formData.isTemporary}
                onChange={() =>
                  setFormData({ ...formData, isTemporary: false })
                }
              />
              <span className="font-medium">Permanent</span>
            </label>
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.isTemporary
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="radio"
                className="form-radio text-blue-600 hidden"
                name="positionType"
                checked={formData.isTemporary}
                onChange={() => setFormData({ ...formData, isTemporary: true })}
              />
              <span className="font-medium">Temporary</span>
            </label>
          </div>
        </div>

        {/* Schedule Type */}
        <div className="mb-5">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Schedule Type
          </label>
          <div className="mt-1 grid grid-cols-2 gap-4">
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.scheduleType === "full-time"
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="radio"
                className="form-radio text-blue-600 hidden"
                name="scheduleType"
                checked={formData.scheduleType === "full-time"}
                onChange={() =>
                  setFormData({ ...formData, scheduleType: "full-time" })
                }
              />
              <span className="font-medium">Full-time</span>
            </label>
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.scheduleType === "part-time"
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="radio"
                className="form-radio text-blue-600 hidden"
                name="scheduleType"
                checked={formData.scheduleType === "part-time"}
                onChange={() =>
                  setFormData({ ...formData, scheduleType: "part-time" })
                }
              />
              <span className="font-medium">Part-time</span>
            </label>
          </div>
        </div>

        {/* Shift Preference */}
        <div className="mb-0">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Shift Preference
          </label>
          <div className="mt-1 grid grid-cols-3 gap-2">
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.shiftPreference.am
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="checkbox"
                className="form-checkbox text-blue-600 hidden"
                name="shiftPreferenceAM"
                checked={formData.shiftPreference.am}
                onChange={() => {
                  const newShiftPreference = {
                    ...formData.shiftPreference,
                    am: !formData.shiftPreference.am,
                    overnight: false, // Uncheck overnight if AM is selected
                  };
                  setFormData({
                    ...formData,
                    shiftPreference: newShiftPreference,
                  });

                  // Clear shift preference error if at least one is selected
                  if (
                    errors.shiftPreference &&
                    (newShiftPreference.am || newShiftPreference.pm)
                  ) {
                    setErrors((prev) => ({
                      ...prev,
                      shiftPreference: undefined,
                    }));
                  }
                }}
              />
              <span className="font-medium">AM</span>
            </label>
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.shiftPreference.pm
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="checkbox"
                className="form-checkbox text-blue-600 hidden"
                name="shiftPreferencePM"
                checked={formData.shiftPreference.pm}
                onChange={() => {
                  const newShiftPreference = {
                    ...formData.shiftPreference,
                    pm: !formData.shiftPreference.pm,
                    overnight: false, // Uncheck overnight if PM is selected
                  };
                  setFormData({
                    ...formData,
                    shiftPreference: newShiftPreference,
                  });

                  // Clear shift preference error if at least one is selected
                  if (
                    errors.shiftPreference &&
                    (newShiftPreference.am || newShiftPreference.pm)
                  ) {
                    setErrors((prev) => ({
                      ...prev,
                      shiftPreference: undefined,
                    }));
                  }
                }}
              />
              <span className="font-medium">PM</span>
            </label>
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.shiftPreference.overnight
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="checkbox"
                className="form-checkbox text-blue-600 hidden"
                name="shiftPreferenceOvernight"
                checked={formData.shiftPreference.overnight}
                onChange={() => {
                  const newShiftPreference = {
                    am: false, // Uncheck AM if overnight is selected
                    pm: false, // Uncheck PM if overnight is selected
                    overnight: !formData.shiftPreference.overnight,
                  };
                  setFormData({
                    ...formData,
                    shiftPreference: newShiftPreference,
                  });

                  // Clear shift preference error if overnight is selected
                  if (errors.shiftPreference && newShiftPreference.overnight) {
                    setErrors((prev) => ({
                      ...prev,
                      shiftPreference: undefined,
                    }));
                  }
                }}
              />
              <span className="font-medium">Overnight</span>
            </label>
          </div>
          {errors.shiftPreference && (
            <p className="mt-1 text-sm text-red-600">
              {errors.shiftPreference}
            </p>
          )}
        </div>
      </div>

      {/* Language Requirements Section */}
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faLanguage} className="text-blue-600 mr-2" />
          <h3 className="text-lg font-semibold text-gray-800">
            Language Requirements
          </h3>
        </div>

        {/* English Requirement */}
        <div className="mb-5">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            English Requirement
          </label>
          <div className="mt-1 grid grid-cols-2 gap-4">
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                !formData.requiresEnglish
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="radio"
                className="form-radio text-blue-600 hidden"
                name="requiresEnglish"
                checked={!formData.requiresEnglish}
                onChange={() =>
                  setFormData({ ...formData, requiresEnglish: false })
                }
              />
              <span className="font-medium">Not Required</span>
            </label>
            <label
              className={`flex items-center justify-center p-3 rounded-md border ${
                formData.requiresEnglish
                  ? "bg-blue-50 border-blue-300 text-blue-700"
                  : "bg-white border-gray-300 text-gray-700"
              } cursor-pointer transition-colors`}
            >
              <input
                type="radio"
                className="form-radio text-blue-600 hidden"
                name="requiresEnglish"
                checked={formData.requiresEnglish}
                onChange={() =>
                  setFormData({ ...formData, requiresEnglish: true })
                }
              />
              <span className="font-medium">Required</span>
            </label>
          </div>
        </div>

        {/* English Level - only visible if English is required */}
        {formData.requiresEnglish && (
          <div className="mb-0 mt-5">
            <label className="block text-gray-700 text-sm font-bold mb-2">
              English Level Required
            </label>
            <div className="grid grid-cols-4 gap-2">
              {["basic", "intermediate", "advanced", "fluent"].map((level) => (
                <label
                  key={level}
                  className={`flex items-center justify-center p-2 rounded-md border text-center ${
                    formData.englishLevel === level
                      ? "bg-blue-50 border-blue-300 text-blue-700"
                      : "bg-white border-gray-300 text-gray-700"
                  } cursor-pointer transition-colors`}
                >
                  <input
                    type="radio"
                    className="hidden"
                    name="englishLevel"
                    checked={formData.englishLevel === level}
                    onChange={() =>
                      setFormData({ ...formData, englishLevel: level })
                    }
                  />
                  <span className="font-medium capitalize">{level}</span>
                </label>
              ))}
            </div>
          </div>
        )}
      </div>

      {/* Job Details Section */}
      <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
        <div className="flex items-center mb-4">
          <FontAwesomeIcon icon={faInfoCircle} className="text-blue-600 mr-2" />
          <h3 className="text-lg font-semibold text-gray-800">Job Details</h3>
        </div>

        <div className="mb-0">
          <label className="block text-gray-700 text-sm font-bold mb-2">
            Notes *
          </label>
          <textarea
            value={formData.notes}
            onChange={(e) =>
              setFormData({ ...formData, notes: e.target.value })
            }
            className="shadow-sm border border-gray-300 rounded-md w-full py-2.5 px-4 text-gray-700 h-48 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
            placeholder="Please describe the position details, duties, and requirements..."
            required
          />
        </div>
      </div>
    </div>
  );
}

export default PositionDetails;
