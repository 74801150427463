import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { getAuth, confirmPasswordReset, sendPasswordResetEmail, checkActionCode } from "firebase/auth";
import { motion } from "framer-motion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getFriendlyAuthErrorMessage } from "./utils/authErrorMessages";

const PasswordReset = () => {
  const [searchParams] = useSearchParams();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [passwordRequirements, setPasswordRequirements] = useState({
    length: false,
    uppercase: false,
    lowercase: false,
    number: false,
    special: false,
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);

  const oobCode = searchParams.get("oobCode");
  const mode = searchParams.get("mode");

  useEffect(() => {
    // Validate the action code
    if (mode !== "resetPassword" || !oobCode) {
      setError("Invalid or missing reset code.");
    } else {
      // Validate the code
      const verifyCode = async () => {
        try {
          // Check if the code is valid using Firebase's API
          const auth = getAuth();
          await checkActionCode(auth, oobCode);
        } catch (error) {
          console.error("Verification error:", error);
          setError(getFriendlyAuthErrorMessage(error.code || "unknown"));
        }
      };
      verifyCode();
    }
  }, [oobCode, mode]);

  const checkPasswordStrength = (password) => {
    const minLength = 8;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumbers = /\d/.test(password);
    const hasNonalphas = /\W/.test(password);

    setPasswordRequirements({
      length: password.length >= minLength,
      uppercase: hasUpperCase,
      lowercase: hasLowerCase,
      number: hasNumbers,
      special: hasNonalphas,
    });

    if (password.length < minLength) {
      setPasswordStrength("Too short");
    } else if (hasUpperCase && hasLowerCase && hasNumbers && hasNonalphas) {
      setPasswordStrength("Strong");
    } else if ((hasUpperCase || hasLowerCase) && (hasNumbers || hasNonalphas)) {
      setPasswordStrength("Medium");
    } else {
      setPasswordStrength("Weak");
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value.replace(/\s/g, '');
    setNewPassword(password);
    checkPasswordStrength(password);
  };

  const handleConfirmPasswordChange = (e) => {
    const confirmPwd = e.target.value.replace(/\s/g, '');
    setConfirmPassword(confirmPwd);
    setPasswordsMatch(newPassword === confirmPwd);
  };

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }
    setIsLoading(true);
    try {
      const auth = getAuth();
      await confirmPasswordReset(auth, oobCode, newPassword);
      setMessage("Password has been successfully reset.");
      setError("");
    } catch (error) {
      console.error("Password reset error:", error);
      setError(getFriendlyAuthErrorMessage(error.code || "unknown"));
      setMessage("");
    }
    setIsLoading(false);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const handleRequestNewLink = async (e) => {
    e.preventDefault();
    if (!email) {
      setError("Please enter your email address.");
      return;
    }
    setIsLoading(true);
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setMessage("A new password reset link has been sent to your email. Please check your inbox and use the link within 1 hour.");
      setError("");
      setShowEmailInput(false);
    } catch (error) {
      console.error("Error sending reset email:", error);
      setError(getFriendlyAuthErrorMessage(error.code || "unknown"));
    }
    setIsLoading(false);
  };

  return (
    <main className="flex min-h-screen flex-col items-center justify-center bg-gray-900 text-white overflow-hidden">
      <div className="w-full max-w-md px-4 py-4 sm:px-6 md:px-8">
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
          className="relative flex flex-col items-center justify-center mb-4"
        >
          <div
            className="relative z-10 text-white text-4xl sm:text-5xl md:text-6xl audiowide mb-1"
            style={{
              textShadow:
                "2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            UNIQUE
          </div>
          <div
            className="relative z-10 text-white text-4xl sm:text-5xl md:text-6xl audiowide"
            style={{
              textShadow:
                "2px 2px 4px rgba(0, 0, 0, 0.8), 0 0 10px rgba(0, 0, 0, 0.5)",
            }}
          >
            HUB
          </div>
          {/* Brighter blue glow effect */}
          <div
            style={{
              position: "absolute",
              top: "-50%",
              left: "-50%",
              right: "-50%",
              bottom: "-50%",
              background:
                "radial-gradient(circle, rgba(59, 130, 246, 0.3) 0%, rgba(59, 130, 246, 0.2) 40%, transparent 70%)",
              filter: "blur(40px)",
              zIndex: 5,
            }}
          ></div>
          {/* Enhanced shine layer */}
          <div
            style={{
              position: "absolute",
              top: "-25%",
              left: "-25%",
              right: "-25%",
              bottom: "-25%",
              background:
                "conic-gradient(from 180deg at 50% 50%, #38bdf8 0deg, #3b82f6 180deg, transparent 360deg)",
              opacity: 0.2,
              filter: "blur(60px)",
              zIndex: 6,
            }}
          ></div>
        </motion.div>
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5, delay: 0.2 }}
          className="flex flex-col items-center"
        >
          <div className="bg-gray-800 shadow-md rounded px-6 pt-4 pb-6 mb-2 w-full">
            <h2 className="text-xl font-bold text-white uppercase tracking-wider audiowide cursor-pointer text-center mb-2">
              Reset Your Password
            </h2>
            
            {error && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-red-900 text-red-100 border-l-4 border-red-500 p-2 mb-3 rounded-r w-full text-sm"
                role="alert"
              >
                <p className="font-bold">Error</p>
                <p>{error}</p>
                {(error.includes("expired") || error.includes("already been used")) && (
                  <button
                    onClick={() => setShowEmailInput(true)}
                    className="mt-2 text-white bg-blue-600 hover:bg-blue-700 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-xs px-3 py-1.5 text-center"
                  >
                    Request New Reset Link
                  </button>
                )}
              </motion.div>
            )}
            
            {message && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                className="bg-green-900 text-green-100 border-l-4 border-green-500 p-2 mb-3 rounded-r w-full text-sm"
                role="alert"
              >
                <p className="font-bold">Success</p>
                <p>{message}</p>
              </motion.div>
            )}
            
            {showEmailInput && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                className="bg-gray-700 p-3 mb-3 rounded"
              >
                <form onSubmit={handleRequestNewLink} className="space-y-3">
                  <div className="relative">
                    <input
                      type="email"
                      id="reset-email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-600 text-white leading-tight focus:outline-none focus:shadow-outline text-sm"
                      required
                    />
                    <label
                      className={`absolute left-3 transition-all duration-300 ${
                        email ? 'text-xs -top-2 bg-gray-700 px-1' : 'text-sm top-2'
                      } text-gray-400 pointer-events-none`}
                      htmlFor="reset-email"
                    >
                      Your Email Address
                    </label>
                  </div>
                  <div className="flex justify-between">
                    <button
                      type="submit"
                      disabled={isLoading}
                      className="bg-blue-600 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm transition-colors duration-200 disabled:opacity-50"
                    >
                      {isLoading ? "Sending..." : "Send New Link"}
                    </button>
                    <button
                      type="button"
                      onClick={() => setShowEmailInput(false)}
                      className="bg-gray-600 hover:bg-gray-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline text-sm transition-colors duration-200"
                    >
                      Cancel
                    </button>
                  </div>
                </form>
              </motion.div>
            )}
            
            {!message && (
              <form className="space-y-4" onSubmit={handlePasswordReset}>
                <div className="relative">
                  <input
                    id="newPassword"
                    name="newPassword"
                    type={showPassword ? "text" : "password"}
                    required
                    value={newPassword}
                    onChange={handlePasswordChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline text-sm pr-10"
                  />
                  <label
                    className={`absolute left-3 transition-all duration-300 ${
                      newPassword ? 'text-xs -top-2 bg-gray-800 px-1' : 'text-sm top-2'
                    } text-gray-400 pointer-events-none`}
                    htmlFor="newPassword"
                  >
                    New Password
                  </label>
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={togglePasswordVisibility}
                  >
                    <FontAwesomeIcon 
                      icon={showPassword ? faEyeSlash : faEye} 
                      className="text-gray-400 hover:text-white transition-colors duration-200"
                    />
                  </button>
                </div>
                <p className={`mt-1 text-xs ${
                  passwordStrength === "Strong" ? "text-green-400" :
                  passwordStrength === "Medium" ? "text-yellow-400" :
                  "text-red-400"
                }`}>
                  Password strength: {passwordStrength}
                </p>
                
                <div className="relative">
                  <input
                    id="confirmPassword"
                    name="confirmPassword"
                    type={showConfirmPassword ? "text" : "password"}
                    required
                    value={confirmPassword}
                    onChange={handleConfirmPasswordChange}
                    className="shadow appearance-none border rounded w-full py-2 px-3 bg-gray-700 text-white leading-tight focus:outline-none focus:shadow-outline text-sm pr-10"
                  />
                  <label
                    className={`absolute left-3 transition-all duration-300 ${
                      confirmPassword ? 'text-xs -top-2 bg-gray-800 px-1' : 'text-sm top-2'
                    } text-gray-400 pointer-events-none`}
                    htmlFor="confirmPassword"
                  >
                    Confirm New Password
                  </label>
                  <button
                    type="button"
                    className="absolute inset-y-0 right-0 pr-3 flex items-center text-sm leading-5"
                    onClick={toggleConfirmPasswordVisibility}
                  >
                    <FontAwesomeIcon 
                      icon={showConfirmPassword ? faEyeSlash : faEye} 
                      className="text-gray-400 hover:text-white transition-colors duration-200"
                    />
                  </button>
                </div>
                {!passwordsMatch && confirmPassword && (
                  <p className="mt-1 text-xs text-red-400">Passwords do not match</p>
                )}

                <div className="text-xs text-gray-400">
                  <p className="font-semibold mb-1">Password Requirements:</p>
                  <div className="grid grid-cols-1 gap-2">
                    <div className={passwordRequirements.length ? "text-green-400 flex items-center" : "text-red-400 flex items-center"}>
                      {passwordRequirements.length ? "✓" : "✗"} Minimum 8 Characters
                    </div>
                    <div className={passwordRequirements.uppercase ? "text-green-400 flex items-center" : "text-red-400 flex items-center"}>
                      {passwordRequirements.uppercase ? "✓" : "✗"} At Least One Uppercase Letter
                    </div>
                    <div className={passwordRequirements.lowercase ? "text-green-400 flex items-center" : "text-red-400 flex items-center"}>
                      {passwordRequirements.lowercase ? "✓" : "✗"} At Least One Lowercase Letter
                    </div>
                    <div className={passwordRequirements.number ? "text-green-400 flex items-center" : "text-red-400 flex items-center"}>
                      {passwordRequirements.number ? "✓" : "✗"} At Least One Number
                    </div>
                    <div className={passwordRequirements.special ? "text-green-400 flex items-center" : "text-red-400 flex items-center"}>
                      {passwordRequirements.special ? "✓" : "✗"} At Least One Special Character (e.g., !, @, #, $, %)
                    </div>
                  </div>
                </div>

                <button
                  type="submit"
                  disabled={isLoading || passwordStrength !== "Strong" || !passwordsMatch}
                  className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline transition duration-300 disabled:opacity-50 disabled:cursor-not-allowed uppercase tracking-wider text-sm"
                >
                  {isLoading ? (
                    <>
                      <svg className="animate-spin -ml-1 mr-3 h-4 w-4 text-white inline" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      Resetting...
                    </>
                  ) : "Reset Password"}
                </button>
              </form>
            )}
          </div>
        </motion.div>
      </div>
    </main>
  );
};

export default PasswordReset;
