import React, { useState, useMemo, useCallback } from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { db, auth } from "./firebase";
import {
  collection,
  addDoc,
  Timestamp,
  deleteDoc,
  doc,
  updateDoc,
  query,
  getDocs,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faPlus,
  faSave,
  faExclamationCircle,
  faEye,
  faTrash,
  faChevronUp,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { onSnapshot } from "firebase/firestore";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  createColumnHelper,
} from "@tanstack/react-table";
import { STATES } from "./constants";
import Skeleton from "react-loading-skeleton";

function Alert({ message, type = "error", onClose, action, showCancel }) {
  const colors = {
    error: "red",
    success: "green",
    warning: "yellow",
  };

  const color = colors[type] || colors.error;

  return (
    <div className="fixed top-4 right-4 z-50 animate-fade-in">
      <div
        className={`bg-white rounded-xl shadow-lg p-4 max-w-md border-l-4 border-${color}-500 flex flex-col space-y-3`}
      >
        <div className="flex items-start space-x-4">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            className={`text-${color}-500 text-xl mt-0.5`}
          />
          <div className="flex-1">
            <p className="text-gray-800 font-medium">{message}</p>
          </div>
          <button
            onClick={onClose}
            className="text-gray-400 hover:text-gray-600 transition-colors"
          >
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>

        {showCancel && (
          <div className="flex justify-end space-x-2 pt-2">
            <button
              onClick={onClose}
              className="px-4 py-2 text-sm font-medium text-gray-600 hover:text-gray-800 
                         transition-colors rounded-lg border border-gray-200 hover:border-gray-300"
            >
              Cancel
            </button>
            <button
              onClick={() => {
                action?.();
                onClose();
              }}
              className="px-4 py-2 text-sm font-medium text-white rounded-lg
                         bg-red-500 hover:bg-red-600 transition-colors"
            >
              Confirm
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

function useLocations() {
  return useQuery({
    queryKey: ["locations"],
    queryFn: async () => {
      const q = query(collection(db, "locations"));
      const snapshot = await getDocs(q);
      const locations = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Sort locations by name
      return locations.sort((a, b) => a.name.localeCompare(b.name));
    },
  });
}

function CreateDocumentModal({ isOpen, onClose, userName }) {
  const [title, setTitle] = useState("");
  const [content, setContent] = useState("");
  const [accessibleTo, setAccessibleTo] = useState([]);
  const [selectedStates, setSelectedStates] = useState([]);
  const [selectedLocations, setSelectedLocations] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState("");
  const [alert, setAlert] = useState(null);
  const queryClient = useQueryClient();

  const statesWithAll = [
    { name: "All States", abbreviation: "ALL" },
    ...STATES,
  ];

  const accessTypes = [
    { id: "ALL", label: "All Types" },
    { id: "w2", label: "W2" },
    { id: "1099", label: "1099" },
    { id: "ein", label: "EIN" },
  ];

  const { data: locations = [] } = useLocations();

  const branches = [
    { id: "all", name: "All Branches" },
    { id: "avis", name: "Avis" },
    { id: "dtg", name: "DTG" },
    { id: "hertz", name: "Hertz" },
    { id: "sixt", name: "Sixt" },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      setAlert("Please enter a document title");
      return;
    }
    if (!content.trim()) {
      setAlert("Please enter document content");
      return;
    }
    if (accessibleTo.length === 0) {
      setAlert("Please select at least one access level");
      return;
    }

    // Updated validation for locations OR states/branch
    if (selectedLocations.length === 0) {
      // If no locations selected, require states and branch
      if (selectedStates.length === 0) {
        setAlert("Please select at least one state or specific locations");
        return;
      }
      if (selectedBranch === "") {
        setAlert("Please select a branch or specific locations");
        return;
      }
    }
    // No need to validate states/branch if locations are selected

    try {
      const userUid = auth.currentUser?.uid || localStorage.getItem("userUid");

      const accessConfigs = [];

      // Handle ALL cases
      if (accessibleTo.includes("ALL")) {
        if (selectedStates.includes("ALL")) {
          // Access for all types and all states
          accessConfigs.push({ type: "ALL", state: "ALL" });
        } else {
          // Access for all types but specific states
          selectedStates.forEach((state) => {
            accessConfigs.push({ type: "ALL", state });
          });
        }
      } else {
        // Handle specific types
        accessibleTo.forEach((type) => {
          if (selectedStates.includes("ALL")) {
            accessConfigs.push({ type, state: "ALL" });
          } else {
            selectedStates.forEach((state) => {
              accessConfigs.push({ type, state });
            });
          }
        });
      }

      await addDoc(collection(db, "hrDocuments"), {
        title,
        content,
        accessibleTo: accessConfigs,
        locations: selectedLocations,
        // Only include branch if no specific locations are selected
        ...(selectedLocations.length === 0 && {
          branch: selectedBranch === "all" ? "all" : selectedBranch,
        }),
        createdBy: {
          uid: userUid,
          name: userName,
        },
        createdAt: Timestamp.now(),
        lastUpdatedBy: {
          uid: userUid,
          name: userName,
        },
        lastUpdatedAt: Timestamp.now(),
        userUid,
      });

      onClose();
      queryClient.invalidateQueries(["hrDocuments"]).catch(console.error);
    } catch (error) {
      console.error("Error creating document:", error);
      setAlert("Error creating document");
    }
  };

  const handleTypeSelection = (typeId) => {
    if (typeId === "ALL") {
      setAccessibleTo(["ALL"]);
    } else {
      const newTypes = accessibleTo.includes(typeId)
        ? accessibleTo.filter((t) => t !== typeId)
        : [...accessibleTo.filter((t) => t !== "ALL"), typeId];

      // If all individual types are selected, switch to "ALL"
      const individualTypes = ["w2", "1099", "ein"];
      if (individualTypes.every((type) => newTypes.includes(type))) {
        setAccessibleTo(["ALL"]);
      } else {
        setAccessibleTo(newTypes);
      }
    }
  };

  const handleStateSelection = (stateAbbr) => {
    if (stateAbbr === "ALL") {
      setSelectedStates(["ALL"]);
    } else {
      const newStates = selectedStates.includes(stateAbbr)
        ? selectedStates.filter((s) => s !== stateAbbr)
        : [...selectedStates.filter((s) => s !== "ALL"), stateAbbr];

      // If all states are selected, switch to "ALL"
      if (newStates.length === STATES.length) {
        setSelectedStates(["ALL"]);
      } else {
        setSelectedStates(newStates);
      }
    }
  };

  const handleLocationSelection = (locationId) => {
    const newLocations = selectedLocations.includes(locationId)
      ? selectedLocations.filter((id) => id !== locationId)
      : [...selectedLocations, locationId];

    setSelectedLocations(newLocations);

    // If any locations are selected, reset states and branch
    if (newLocations.length > 0) {
      setSelectedStates([]);
      setSelectedBranch("");
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {alert && <Alert message={alert} onClose={() => setAlert(null)} />}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full z-50 flex items-start justify-center py-10"
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <div className="relative mx-auto p-8 border-0 w-11/12 md:w-3/4 lg:w-2/3 shadow-2xl rounded-2xl bg-white max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-8 bg-white pt-4">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
              Create New Document
            </h2>
            <button
              onClick={onClose}
              className="rounded-full p-2 hover:bg-gray-100 transition-colors duration-200"
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="text-xl text-gray-500"
              />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6 pb-4">
            <div className="group">
              <input
                type="text"
                placeholder="Document Title"
                className="w-full p-4 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 outline-none text-lg"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                Accessible To:
              </label>
              <div className="flex flex-wrap gap-4">
                {accessTypes.map(({ id, label }) => (
                  <label key={id} className="relative">
                    <input
                      type="checkbox"
                      checked={accessibleTo.includes(id)}
                      onChange={() => handleTypeSelection(id)}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center px-6 py-3 rounded-lg cursor-pointer
                                border-2 border-gray-200 
                                peer-checked:border-blue-500 peer-checked:bg-blue-50
                                hover:border-blue-200 transition-all duration-200"
                    >
                      <span
                        className={`text-lg font-medium 
                                   ${
                                     accessibleTo.includes(id)
                                       ? "text-blue-600"
                                       : "text-gray-600"
                                   }`}
                      >
                        {label}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div
              className={`bg-gray-50 rounded-xl p-6 ${
                selectedLocations.length > 0
                  ? "opacity-50 pointer-events-none"
                  : ""
              }`}
            >
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                States:
                {selectedLocations.length > 0 && (
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    (Disabled when specific locations are selected)
                  </span>
                )}
              </label>
              <div className="flex flex-wrap gap-4">
                {statesWithAll.map(({ name, abbreviation }) => (
                  <label key={abbreviation} className="relative">
                    <input
                      type="checkbox"
                      checked={selectedStates.includes(abbreviation)}
                      onChange={() => handleStateSelection(abbreviation)}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center px-6 py-3 rounded-lg cursor-pointer
                                border-2 border-gray-200 
                                peer-checked:border-blue-500 peer-checked:bg-blue-50
                                hover:border-blue-200 transition-all duration-200"
                    >
                      <span
                        className={`text-lg font-medium 
                                   ${
                                     selectedStates.includes(abbreviation)
                                       ? "text-blue-600"
                                       : "text-gray-600"
                                   }`}
                      >
                        {name}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div
              className={`bg-gray-50 rounded-xl p-6 ${
                selectedLocations.length > 0
                  ? "opacity-50 pointer-events-none"
                  : ""
              }`}
            >
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                Branch:
                {selectedLocations.length > 0 && (
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    (Disabled when specific locations are selected)
                  </span>
                )}
              </label>
              <div className="flex flex-wrap gap-4">
                {branches.map(({ id, name }) => (
                  <label key={id} className="relative">
                    <input
                      type="radio"
                      checked={selectedBranch === id}
                      onChange={() => {
                        // If all individual branches are selected, switch to 'all'
                        if (id === "all") {
                          setSelectedBranch("all");
                        } else {
                          setSelectedBranch(id);
                        }
                      }}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center px-6 py-3 rounded-lg cursor-pointer
                                  border-2 border-gray-200 
                                  peer-checked:border-blue-500 peer-checked:bg-blue-50
                                  hover:border-blue-200 transition-all duration-200"
                    >
                      <span
                        className={`text-lg font-medium ${
                          selectedBranch === id
                            ? "text-blue-600"
                            : "text-gray-600"
                        }`}
                      >
                        {name}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                Locations:
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {locations.map((location) => (
                  <label key={location.id} className="relative">
                    <input
                      type="checkbox"
                      checked={selectedLocations.includes(location.id)}
                      onChange={() => handleLocationSelection(location.id)}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center p-4 rounded-lg cursor-pointer
                                  border-2 border-gray-200 
                                  peer-checked:border-blue-500 peer-checked:bg-blue-50
                                  hover:border-blue-200 transition-all duration-200"
                    >
                      <div>
                        <div
                          className={`font-medium ${
                            selectedLocations.includes(location.id)
                              ? "text-blue-600"
                              : "text-gray-600"
                          }`}
                        >
                          {location.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {location.address}
                        </div>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="border-2 border-gray-200 rounded-xl overflow-hidden">
              <div className="min-h-[300px] max-h-[500px] overflow-y-auto">
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ color: [] }, { background: [] }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  className="h-auto"
                />
              </div>
            </div>

            <div className="flex justify-end gap-4 pt-8 bg-white mt-8">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-3 rounded-xl bg-white border-2 border-gray-200 
                         hover:border-gray-300 transition-all duration-200
                         text-gray-600 font-semibold"
              >
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Cancel
              </button>
              <button
                type="submit"
                className="!bg-blue-500 !text-white px-8 py-3 rounded-xl font-semibold
                         hover:!bg-blue-600 
                         shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]
                         flex items-center"
              >
                <FontAwesomeIcon icon={faPlus} className="!text-white mr-2" />
                <span className="!text-white">Create Document</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

function EditDocumentModal({ isOpen, onClose, document, userName }) {
  const [title, setTitle] = useState(document.title);
  const [content, setContent] = useState(document.content);
  const [selectedTypes, setSelectedTypes] = useState(() => {
    if (!document.accessibleTo || !Array.isArray(document.accessibleTo) || document.accessibleTo.length === 0) {
      return ["ALL"]; // Default to ALL if accessibleTo is missing or empty
    }
    
    const types = new Set();
    document.accessibleTo.forEach((access) => {
      if (typeof access === "string") {
        types.add(access);
      } else if (access && access.type) {
        types.add(access.type);
      }
    });
    
    // If no types were found, default to ALL
    return types.size > 0 ? Array.from(types) : ["ALL"];
  });
  const [selectedStates, setSelectedStates] = useState(() => {
    if (!document.accessibleTo || !Array.isArray(document.accessibleTo) || document.accessibleTo.length === 0) {
      return ["ALL"];
    }
    if (typeof document.accessibleTo[0] === "string") return ["ALL"];

    const states = new Set();
    document.accessibleTo.forEach((access) => {
      if (access && access.state) states.add(access.state);
    });
    return states.size > 0 ? Array.from(states) : ["ALL"];
  });
  const [selectedLocations, setSelectedLocations] = useState(
    document.locations || []
  );
  const [selectedBranch, setSelectedBranch] = useState(document.branch || "");
  const [alert, setAlert] = useState(null);
  const queryClient = useQueryClient();

  const statesWithAll = [
    { name: "All States", abbreviation: "ALL" },
    ...STATES,
  ];

  const accessTypes = [
    { id: "ALL", label: "All Types" },
    { id: "w2", label: "W2" },
    { id: "1099", label: "1099" },
    { id: "ein", label: "EIN" },
  ];

  const { data: locations = [] } = useLocations();

  const branches = [
    { id: "all", name: "All Branches" },
    { id: "avis", name: "Avis" },
    { id: "dtg", name: "DTG" },
    { id: "hertz", name: "Hertz" },
    { id: "sixt", name: "Sixt" },
  ].sort((a, b) => {
    // Keep "All Branches" at the top
    if (a.id === "") return -1;
    if (b.id === "") return 1;
    // Sort the rest alphabetically
    return a.name.localeCompare(b.name);
  });

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!title.trim()) {
      setAlert("Please enter a document title");
      return;
    }
    if (!content.trim()) {
      setAlert("Please enter document content");
      return;
    }
    if (selectedTypes.length === 0) {
      setAlert("Please select at least one access level");
      return;
    }

    // Updated validation for locations OR states/branch
    if (selectedLocations.length === 0) {
      // If no locations selected, require states and branch
      if (selectedStates.length === 0) {
        setAlert("Please select at least one state or specific locations");
        return;
      }
      if (selectedBranch === "") {
        setAlert("Please select a branch or specific locations");
        return;
      }
    }
    // No need to validate states/branch if locations are selected

    try {
      const accessConfigs = [];

      // Handle ALL cases
      if (selectedTypes.includes("ALL")) {
        if (selectedStates.includes("ALL")) {
          // Access for all types and all states
          accessConfigs.push({ type: "ALL", state: "ALL", branch: selectedBranch || "all" });
        } else {
          // Access for all types but specific states
          selectedStates.forEach((state) => {
            accessConfigs.push({ type: "ALL", state, branch: selectedBranch || "all" });
          });
        }
      } else {
        // Handle specific types
        selectedTypes.forEach((type) => {
          if (selectedStates.includes("ALL")) {
            accessConfigs.push({ type, state: "ALL", branch: selectedBranch || "all" });
          } else {
            selectedStates.forEach((state) => {
              accessConfigs.push({ type, state, branch: selectedBranch || "all" });
            });
          }
        });
      }

      // Ensure we have at least one item in accessConfigs
      if (accessConfigs.length === 0) {
        accessConfigs.push({ type: selectedTypes[0] || "ALL", state: selectedStates[0] || "ALL", branch: selectedBranch || "all" });
      }

      console.log("Updating document with accessConfigs:", accessConfigs);

      await updateDoc(doc(db, "hrDocuments", document.id), {
        title,
        content,
        accessibleTo: accessConfigs,
        locations: selectedLocations,
        // Only include branch if no specific locations are selected
        ...(selectedLocations.length === 0 && { branch: selectedBranch || "all" }),
        lastUpdatedBy: {
          uid: auth.currentUser.uid,
          name: userName,
        },
        lastUpdatedAt: Timestamp.now(),
      });

      onClose();
      queryClient.invalidateQueries(["hrDocuments"]).catch(console.error);
    } catch (error) {
      console.error("Error updating document:", error);
      setAlert("Error updating document");
    }
  };

  const handleTypeSelection = (typeId) => {
    if (typeId === "ALL") {
      setSelectedTypes(["ALL"]);
    } else {
      // If ALL is currently selected and user selects a specific type,
      // replace ALL with just that specific type
      if (selectedTypes.includes("ALL")) {
        setSelectedTypes([typeId]);
      } else {
        const newTypes = selectedTypes.includes(typeId)
          ? selectedTypes.filter((t) => t !== typeId)
          : [...selectedTypes.filter((t) => t !== "ALL"), typeId];

        // Only convert to ALL if all types are selected AND the user hasn't explicitly
        // chosen to work with individual types
        const individualTypes = ["w2", "1099", "ein"];
        const allTypesSelected = individualTypes.every((type) => 
          newTypes.includes(type));
          
        if (allTypesSelected && newTypes.length === individualTypes.length) {
          setSelectedTypes(["ALL"]);
        } else {
          setSelectedTypes(newTypes.length ? newTypes : ["ALL"]);
        }
      }
    }
  };

  const handleStateSelection = (stateAbbr) => {
    if (stateAbbr === "ALL") {
      setSelectedStates(["ALL"]);
    } else {
      const newStates = selectedStates.includes(stateAbbr)
        ? selectedStates.filter((s) => s !== stateAbbr)
        : [...selectedStates.filter((s) => s !== "ALL"), stateAbbr];

      // If all states are selected, switch to "ALL"
      if (newStates.length === STATES.length) {
        setSelectedStates(["ALL"]);
      } else {
        setSelectedStates(newStates);
      }
    }
  };

  const handleLocationSelection = (locationId) => {
    const newLocations = selectedLocations.includes(locationId)
      ? selectedLocations.filter((id) => id !== locationId)
      : [...selectedLocations, locationId];

    setSelectedLocations(newLocations);

    // If any locations are selected, reset states and branch
    if (newLocations.length > 0) {
      setSelectedStates([]);
      setSelectedBranch("");
    }
  };

  if (!isOpen) return null;

  return (
    <>
      {alert && <Alert message={alert} onClose={() => setAlert(null)} />}
      <div
        className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm overflow-y-auto h-full w-full z-50 flex items-start justify-center py-10"
        onClick={(e) => e.target === e.currentTarget && onClose()}
      >
        <div className="relative mx-auto p-8 border-0 w-11/12 md:w-3/4 lg:w-2/3 shadow-2xl rounded-2xl bg-white max-h-[90vh] overflow-y-auto">
          <div className="flex justify-between items-center mb-8 bg-white pt-4">
            <h2 className="text-3xl font-bold bg-gradient-to-r from-blue-600 to-blue-400 bg-clip-text text-transparent">
              Edit Document
            </h2>
            <button
              onClick={onClose}
              className="rounded-full p-2 hover:bg-gray-100 transition-colors duration-200"
            >
              <FontAwesomeIcon
                icon={faTimes}
                className="text-xl text-gray-500"
              />
            </button>
          </div>

          <form onSubmit={handleSubmit} className="space-y-6 pb-4">
            <div className="group">
              <input
                type="text"
                placeholder="Document Title"
                className="w-full p-4 border-2 border-gray-200 rounded-xl focus:border-blue-500 focus:ring-2 focus:ring-blue-200 transition-all duration-200 outline-none text-lg"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              />
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                Accessible To:
              </label>
              <div className="flex flex-wrap gap-4">
                {accessTypes.map(({ id, label }) => (
                  <label key={id} className="relative">
                    <input
                      type="checkbox"
                      checked={selectedTypes.includes(id)}
                      onChange={() => handleTypeSelection(id)}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center px-6 py-3 rounded-lg cursor-pointer
                                border-2 border-gray-200 
                                peer-checked:border-blue-500 peer-checked:bg-blue-50
                                hover:border-blue-200 transition-all duration-200"
                    >
                      <span
                        className={`text-lg font-medium 
                                   ${
                                     selectedTypes.includes(id)
                                       ? "text-blue-600"
                                       : "text-gray-600"
                                   }`}
                      >
                        {label}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div
              className={`bg-gray-50 rounded-xl p-6 ${
                selectedLocations.length > 0
                  ? "opacity-50 pointer-events-none"
                  : ""
              }`}
            >
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                States:
                {selectedLocations.length > 0 && (
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    (Disabled when specific locations are selected)
                  </span>
                )}
              </label>
              <div className="flex flex-wrap gap-4">
                {statesWithAll.map(({ name, abbreviation }) => (
                  <label key={abbreviation} className="relative">
                    <input
                      type="checkbox"
                      checked={selectedStates.includes(abbreviation)}
                      onChange={() => handleStateSelection(abbreviation)}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center px-6 py-3 rounded-lg cursor-pointer
                                border-2 border-gray-200 
                                peer-checked:border-blue-500 peer-checked:bg-blue-50
                                hover:border-blue-200 transition-all duration-200"
                    >
                      <span
                        className={`text-lg font-medium 
                                   ${
                                     selectedStates.includes(abbreviation)
                                       ? "text-blue-600"
                                       : "text-gray-600"
                                   }`}
                      >
                        {name}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div
              className={`bg-gray-50 rounded-xl p-6 ${
                selectedLocations.length > 0
                  ? "opacity-50 pointer-events-none"
                  : ""
              }`}
            >
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                Branch:
                {selectedLocations.length > 0 && (
                  <span className="text-sm font-normal text-gray-500 ml-2">
                    (Disabled when specific locations are selected)
                  </span>
                )}
              </label>
              <div className="flex flex-wrap gap-4">
                {branches.map(({ id, name }) => (
                  <label key={id} className="relative">
                    <input
                      type="radio"
                      checked={selectedBranch === id}
                      onChange={() => {
                        // If all individual branches are selected, switch to 'all'
                        if (id === "all") {
                          setSelectedBranch("all");
                        } else {
                          setSelectedBranch(id);
                        }
                      }}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center px-6 py-3 rounded-lg cursor-pointer
                                  border-2 border-gray-200 
                                  peer-checked:border-blue-500 peer-checked:bg-blue-50
                                  hover:border-blue-200 transition-all duration-200"
                    >
                      <span
                        className={`text-lg font-medium ${
                          selectedBranch === id
                            ? "text-blue-600"
                            : "text-gray-600"
                        }`}
                      >
                        {name}
                      </span>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="bg-gray-50 rounded-xl p-6">
              <label className="block mb-3 text-lg font-semibold text-gray-700">
                Locations:
              </label>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                {locations.map((location) => (
                  <label key={location.id} className="relative">
                    <input
                      type="checkbox"
                      checked={selectedLocations.includes(location.id)}
                      onChange={() => handleLocationSelection(location.id)}
                      className="sr-only peer"
                    />
                    <div
                      className="flex items-center p-4 rounded-lg cursor-pointer
                                  border-2 border-gray-200 
                                  peer-checked:border-blue-500 peer-checked:bg-blue-50
                                  hover:border-blue-200 transition-all duration-200"
                    >
                      <div>
                        <div
                          className={`font-medium ${
                            selectedLocations.includes(location.id)
                              ? "text-blue-600"
                              : "text-gray-600"
                          }`}
                        >
                          {location.name}
                        </div>
                        <div className="text-sm text-gray-500">
                          {location.address}
                        </div>
                      </div>
                    </div>
                  </label>
                ))}
              </div>
            </div>

            <div className="border-2 border-gray-200 rounded-xl overflow-hidden">
              <div className="min-h-[300px] max-h-[500px] overflow-y-auto">
                <ReactQuill
                  value={content}
                  onChange={setContent}
                  theme="snow"
                  modules={{
                    toolbar: [
                      [{ header: [1, 2, 3, false] }],
                      ["bold", "italic", "underline", "strike"],
                      [{ list: "ordered" }, { list: "bullet" }],
                      [{ color: [] }, { background: [] }],
                      ["link"],
                      ["clean"],
                    ],
                  }}
                  className="h-auto"
                />
              </div>
            </div>

            <div className="flex justify-end gap-4 pt-8 bg-white mt-8">
              <button
                type="button"
                onClick={onClose}
                className="px-8 py-3 rounded-xl bg-white border-2 border-gray-200 
                         hover:border-gray-300 transition-all duration-200
                         text-gray-600 font-semibold"
              >
                <FontAwesomeIcon icon={faTimes} className="mr-2" />
                Cancel
              </button>
              <button
                type="submit"
                className="!bg-blue-500 !text-white px-8 py-3 rounded-xl font-semibold
                         hover:!bg-blue-600 
                         shadow-[0_4px_6px_-1px_rgba(0,0,0,0.1)]
                         flex items-center"
              >
                <FontAwesomeIcon icon={faSave} className="!text-white mr-2" />
                <span className="!text-white">Update Document</span>
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

function useDocuments() {
  return useQuery({
    queryKey: ["hrDocuments"],
    queryFn: () => {
      return new Promise((resolve, reject) => {
        // Remove filter-based query, always get all documents
        const q = collection(db, "hrDocuments");

        const unsubscribe = onSnapshot(
          q,
          (snapshot) => {
            const docs = snapshot.docs.map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }));
            resolve(docs);
          },
          (error) => {
            reject(error);
          }
        );

        return () => unsubscribe();
      });
    },
    refetchOnWindowFocus: false,
    staleTime: 0,
    cacheTime: 0,
  });
}

function DocumentSummary({ documents, filter, onFilterChange, stateFilter, onStateFilterChange, branchFilter, onBranchFilterChange, locationFilter, onLocationFilterChange, locations }) {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);
  
  const summary = useMemo(() => {
    return documents.reduce(
      (acc, doc) => {
        // Count total documents
        acc.total++;

        // Initialize counters if they don't exist
        acc.w2 = acc.w2 || 0;
        acc["1099"] = acc["1099"] || 0;
        acc.ein = acc.ein || 0;

        // Count documents by type
        doc.accessibleTo.forEach(({ type }) => {
          if (type === "ALL") {
            // If type is ALL, increment all type counters
            acc.w2++;
            acc["1099"]++;
            acc.ein++;
          } else {
            // Otherwise increment specific type counter
            acc[type]++;
          }
        });

        return acc;
      },
      { total: 0 }
    );
  }, [documents]);

  // Helper function to determine button styles
  const getButtonStyles = (type) => {
    const baseStyles =
      "rounded-lg p-6 shadow-lg transform hover:scale-105 transition-transform duration-200 cursor-pointer";
    const isActive = filter === type;

    if (type === "all") {
      return `${baseStyles} ${
        isActive ? "bg-gray-600" : "bg-gray-700"
      } hover:bg-gray-600`;
    }

    const typeStyles = {
      w2: isActive ? "bg-blue-700" : "bg-blue-600",
      1099: isActive ? "bg-purple-700" : "bg-purple-600",
      ein: isActive ? "bg-green-700" : "bg-green-600",
    };

    return `${baseStyles} ${typeStyles[type] || "bg-gray-700"}`;
  };

  const branches = [
    { id: "", name: "All Branches" },
    { id: "avis", name: "Avis" },
    { id: "dtg", name: "DTG" },
    { id: "hertz", name: "Hertz" },
    { id: "sixt", name: "Sixt" }
  ].sort((a, b) => {
    // Keep "All Branches" at the top
    if (a.id === "") return -1;
    if (b.id === "") return 1;
    // Sort the rest alphabetically
    return a.name.localeCompare(b.name);
  });

  return (
    <div className="space-y-4">
      {/* Existing summary cards */}
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
        <div
          onClick={() => onFilterChange("all")}
          className={getButtonStyles("all")}
        >
          <div className="text-gray-300 font-medium mb-2">All Documents</div>
          <div className="text-3xl font-bold text-white">{summary.total}</div>
        </div>

        <div
          onClick={() => onFilterChange("w2")}
          className={getButtonStyles("w2")}
        >
          <div className="text-blue-100 font-medium mb-2">W2 Documents</div>
          <div className="text-3xl font-bold text-white">{summary.w2}</div>
        </div>

        <div
          onClick={() => onFilterChange("1099")}
          className={getButtonStyles("1099")}
        >
          <div className="text-purple-100 font-medium mb-2">1099 Documents</div>
          <div className="text-3xl font-bold text-white">{summary["1099"]}</div>
        </div>

        <div
          onClick={() => onFilterChange("ein")}
          className={getButtonStyles("ein")}
        >
          <div className="text-green-100 font-medium mb-2">EIN Documents</div>
          <div className="text-3xl font-bold text-white">{summary.ein}</div>
        </div>
      </div>

      {/* Advanced Filters Section */}
      <div className="flex items-center justify-end">
        <button
          onClick={() => setShowAdvancedFilters(!showAdvancedFilters)}
          className="text-gray-300 hover:text-white flex items-center gap-2 text-sm"
        >
          <span>{showAdvancedFilters ? 'Hide' : 'Show'} Advanced Filters</span>
          <FontAwesomeIcon
            icon={showAdvancedFilters ? faChevronUp : faChevronDown}
            className="w-4 h-4"
          />
        </button>
      </div>

      {/* Collapsible Advanced Filters */}
      {showAdvancedFilters && (
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 p-4 bg-gray-700 rounded-lg animate-fade-in">
          {/* State Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              State
            </label>
            <select
              value={stateFilter}
              onChange={(e) => onStateFilterChange(e.target.value)}
              className="w-full bg-gray-600 text-white rounded-lg border border-gray-500 p-2 text-sm"
            >
              <option value="">All States</option>
              {STATES.map(state => (
                <option key={state.abbreviation} value={state.abbreviation}>
                  {state.name}
                </option>
              ))}
            </select>
          </div>

          {/* Branch Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Branch
            </label>
            <select
              value={branchFilter}
              onChange={(e) => onBranchFilterChange(e.target.value)}
              className="w-full bg-gray-600 text-white rounded-lg border border-gray-500 p-2 text-sm"
            >
              {branches.map(branch => (
                <option key={branch.id} value={branch.id}>
                  {branch.name}
                </option>
              ))}
            </select>
          </div>

          {/* Location Filter */}
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Location
            </label>
            <select
              value={locationFilter}
              onChange={(e) => onLocationFilterChange(e.target.value)}
              className="w-full bg-gray-600 text-white rounded-lg border border-gray-500 p-2 text-sm"
            >
              <option value="">All Locations</option>
              {locations.map(location => (
                <option key={location.id} value={location.id}>
                  {location.name}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
    </div>
  );
}

function DocumentSummarySkeleton() {
  return (
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
      {[...Array(4)].map((_, i) => (
        <div key={i} className="rounded-lg p-6 shadow-lg bg-gray-700">
          <Skeleton
            height={20}
            width={120}
            className="mb-2"
            baseColor="#374151"
            highlightColor="#4B5563"
          />
          <Skeleton
            height={36}
            width={80}
            baseColor="#374151"
            highlightColor="#4B5563"
          />
        </div>
      ))}
    </div>
  );
}

function TableSkeleton() {
  return (
    <div className="bg-white rounded-lg shadow-xl overflow-hidden border border-gray-200">
      <table className="min-w-full divide-y divide-gray-200">
        <thead>
          <tr>
            {[...Array(5)].map((_, i) => (
              <th key={i} className="px-6 py-4 bg-gray-50">
                <Skeleton height={20} width={100} />
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {[...Array(5)].map((_, rowIndex) => (
            <tr key={rowIndex}>
              <td className="px-6 py-4">
                <Skeleton height={24} width={200} />
              </td>
              <td className="px-6 py-4">
                <div className="flex flex-wrap gap-1">
                  <Skeleton height={24} width={80} className="rounded-full" />
                  <Skeleton height={24} width={80} className="rounded-full" />
                </div>
              </td>
              <td className="px-6 py-4">
                <Skeleton height={20} width={100} />
              </td>
              <td className="px-6 py-4">
                <Skeleton height={20} width={120} />
              </td>
              <td className="px-6 py-4">
                <div className="flex justify-end space-x-3">
                  <Skeleton height={36} width={80} className="rounded-lg" />
                  <Skeleton height={36} width={80} className="rounded-lg" />
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function Documents({ userName }) {
  const [filter, setFilter] = useState("all");
  const [searchQuery, setSearchQuery] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [alert, setAlert] = useState(null);
  const queryClient = useQueryClient();
  const [stateFilter, setStateFilter] = useState("");
  const [branchFilter, setBranchFilter] = useState("");
  const [locationFilter, setLocationFilter] = useState("");

  const { data: allDocuments = [], isLoading, isError } = useDocuments();
  const { data: locations = [] } = useLocations();

  // Update the documents filtering
  const documents = useMemo(() => {
    let filtered = allDocuments;

    // Apply type filter
    if (filter !== "all") {
      filtered = filtered.filter((doc) =>
        doc.accessibleTo.some(
          (access) => access.type === "ALL" || access.type === filter
        )
      );
    }

    // Apply state filter
    if (stateFilter) {
      filtered = filtered.filter((doc) =>
        doc.accessibleTo.some(
          (access) => access.state === "ALL" || access.state === stateFilter
        )
      );
    }

    // Apply branch filter
    if (branchFilter) {
      filtered = filtered.filter(
        (doc) => doc.branch === "all" || doc.branch === branchFilter
      );
    }

    // Apply location filter
    if (locationFilter) {
      filtered = filtered.filter((doc) =>
        doc.locations?.includes(locationFilter)
      );
    }

    // Apply search filter if there's a search query
    if (searchQuery.trim()) {
      const query = searchQuery.toLowerCase().trim();
      filtered = filtered.filter((doc) => {
        // Search in title
        if (doc.title.toLowerCase().includes(query)) return true;
        // Search in content
        if (doc.content.toLowerCase().includes(query)) return true;
        // Search in created by name
        if (doc.createdBy?.name.toLowerCase().includes(query)) return true;
        // Search in last updated by name
        if (doc.lastUpdatedBy?.name.toLowerCase().includes(query)) return true;
        // Search in access levels (both type and state)
        if (doc.accessibleTo.some(
          (access) =>
            access.type.toLowerCase().includes(query) ||
            access.state.toLowerCase().includes(query)
        )) return true;
        
        return false;
      });
    }

    return filtered;
  }, [allDocuments, filter, searchQuery, stateFilter, branchFilter, locationFilter]);

  const handleDelete = useCallback(
    async (docId) => {
      try {
        await deleteDoc(doc(db, "hrDocuments", docId));
        await queryClient.invalidateQueries(["hrDocuments"]);
        setAlert({ message: "Document deleted successfully", type: "success" });
      } catch (error) {
        console.error("Error deleting document:", error);
        setAlert({ message: "Error deleting document", type: "error" });
      }
    },
    [queryClient]
  );

  const confirmDelete = useCallback(
    (docId) => {
      setAlert({
        message:
          "Are you sure you want to delete this document? This action cannot be undone.",
        type: "warning",
        action: () => handleDelete(docId),
        showCancel: true,
      });
    },
    [handleDelete]
  );

  const columnHelper = createColumnHelper();

  const columns = useMemo(
    () => [
      columnHelper.accessor("title", {
        header: "Title",
        cell: (info) => (
          <div className="font-medium text-gray-900 truncate max-w-[200px]">
            {info.getValue()}
          </div>
        ),
      }),
      columnHelper.accessor("accessibleTo", {
        header: "Access",
        cell: (info) => (
          <div className="flex flex-wrap gap-1 max-w-[150px]">
            {info.getValue().length === 0 || info.getValue().some(access => access.type === "ALL") ? (
              <span
                className="px-2 py-0.5 rounded-full text-xs font-semibold bg-gray-100 text-gray-800"
              >
                ALL TYPES
              </span>
            ) : (
              info.getValue().map(({ type, state }) => (
                <span
                  key={`${type}-${state}`}
                  className={`px-2 py-0.5 rounded-full text-xs font-semibold ${
                    type === "w2"
                      ? "bg-blue-100 text-blue-800"
                      : type === "1099"
                      ? "bg-purple-100 text-purple-800"
                      : "bg-green-100 text-green-800"
                  }`}
                >
                  {`${type.toUpperCase()}`}
                </span>
              ))
            )}
          </div>
        ),
      }),
      columnHelper.accessor("branch", {
        header: "Branch",
        cell: (info) => {
          const branch = info.getValue();
          const row = info.row.original;
          const hasLocations = row.locations && row.locations.length > 0;
          
          // If branch is undefined/null/NaN but has locations
          if ((!branch || isNaN(branch)) && hasLocations) {
            return <div className="text-sm font-medium w-[100px] truncate text-gray-600">Location Specific</div>;
          }
          
          return (
            <div className="text-sm font-medium w-[100px] truncate">
              {branch === "all"
                ? "All"
                : branch?.charAt(0).toUpperCase() + branch?.slice(1) || "All"}
            </div>
          );
        },
      }),
      columnHelper.accessor("locations", {
        header: "Locations",
        cell: (info) => {
          const locationIds = info.getValue() || [];
          const locationNames = locations
            ?.filter((loc) => locationIds.includes(loc.id))
            .map((loc) => loc.name);

          if (locationNames.length === 0)
            return <div className="text-sm text-gray-600">All</div>;

          // Show first location + count of remaining
          const displayName = locationNames[0];
          const remaining = locationNames.length - 1;

          return (
            <div className="text-sm text-gray-600 group relative w-[120px]">
              <div className="truncate">
                {displayName}
                {remaining > 0 && (
                  <span className="ml-1 text-blue-600">+{remaining}</span>
                )}
              </div>

              {/* Tooltip */}
              {remaining > 0 && (
                <div className="absolute hidden group-hover:block z-50 bg-gray-900 text-white p-2 rounded-lg shadow-lg min-w-[200px] max-w-[300px] mt-1 left-0">
                  <div className="text-sm">{locationNames.join(", ")}</div>
                </div>
              )}
            </div>
          );
        },
      }),
      columnHelper.display({
        id: "actions",
        header: "",
        cell: (info) => (
          <div className="flex justify-end space-x-2">
            <button
              onClick={() => setSelectedDocument(info.row.original)}
              className="p-1.5 text-sm font-medium rounded-lg
                     bg-blue-500 text-white
                     hover:bg-blue-600 transition-colors"
            >
              <FontAwesomeIcon icon={faEye} />
            </button>
            <button
              onClick={() => confirmDelete(info.row.original.id)}
              className="p-1.5 text-sm font-medium rounded-lg
                     bg-red-500 text-white
                     hover:bg-red-600 transition-colors"
            >
              <FontAwesomeIcon icon={faTrash} />
            </button>
          </div>
        ),
      }),
    ],
    [locations, columnHelper, confirmDelete, setSelectedDocument]
  );

  const table = useReactTable({
    data: documents,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
  });

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="flex justify-between items-center">
            <Skeleton
              height={24}
              width={200}
              baseColor="#374151"
              highlightColor="#4B5563"
            />
          </div>

          <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
            <div className="flex flex-col space-y-6">
              <DocumentSummarySkeleton />

              <div className="flex flex-col gap-2">
                <div className="flex gap-4">
                  <Skeleton
                    height={48}
                    className="flex-1"
                    baseColor="#374151"
                    highlightColor="#4B5563"
                  />
                  <Skeleton
                    height={48}
                    width={150}
                    baseColor="#374151"
                    highlightColor="#4B5563"
                  />
                </div>
              </div>
            </div>
          </div>

          <TableSkeleton />
        </div>
      </div>
    );
  }

  if (isError) {
    return <div className="text-red-500">Error loading documents</div>;
  }

  return (
    <div className="container mx-auto px-4 sm:px-8">
      {alert && (
        <Alert
          message={alert.message}
          type={alert.type}
          onClose={() => setAlert(null)}
          action={alert.action}
          showCancel={alert.showCancel}
        />
      )}
      <div className="py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            HR Documents
          </h1>
        </div>

        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-col space-y-6">
            <DocumentSummary
              documents={documents}
              filter={filter}
              onFilterChange={setFilter}
              stateFilter={stateFilter}
              onStateFilterChange={setStateFilter}
              branchFilter={branchFilter}
              onBranchFilterChange={setBranchFilter}
              locationFilter={locationFilter}
              onLocationFilterChange={setLocationFilter}
              locations={locations}
            />

            <div className="flex flex-col gap-2">
              <div className="flex gap-4">
                <input
                  type="text"
                  placeholder="Search documents..."
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e.target.value)}
                  className="flex-1 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                />
                <button
                  onClick={() => setIsCreateModalOpen(true)}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-bold px-6 rounded-lg flex items-center transition-colors duration-200 whitespace-nowrap"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-2" />
                  Create Document
                </button>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-xl overflow-hidden border border-gray-200">
          <table className="min-w-full divide-y divide-gray-200">
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <th
                      key={header.id}
                      className="px-6 py-4 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100 transition-colors duration-200"
                      onClick={header.column.getToggleSortingHandler()}
                    >
                      <div className="flex items-center space-x-1">
                        <span>
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                        </span>
                        {header.column.getIsSorted() && (
                          <span className="text-gray-400">
                            {header.column.getIsSorted() === "desc"
                              ? " ↓"
                              : " ↑"}
                          </span>
                        )}
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="bg-white divide-y divide-gray-200">
              {table.getRowModel().rows.map((row) => (
                <tr
                  key={row.id}
                  className="hover:bg-gray-50 transition-colors duration-150"
                >
                  {row.getVisibleCells().map((cell) => (
                    <td key={cell.id} className="px-6 py-4 whitespace-nowrap">
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext()
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>

      {selectedDocument && (
        <EditDocumentModal
          isOpen={!!selectedDocument}
          onClose={() => setSelectedDocument(null)}
          document={selectedDocument}
          userName={userName}
        />
      )}

      {isCreateModalOpen && (
        <CreateDocumentModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          userName={userName}
        />
      )}
    </div>
  );
}

export default Documents;
