import React, { useState } from "react";
import { Link } from "react-router-dom";
import { motion, AnimatePresence, useScroll, useSpring } from "framer-motion";
import { collection, query, where, getDocs } from "firebase/firestore";
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRightToBracket, faShare } from "@fortawesome/free-solid-svg-icons";
import { STATES } from "./constants";

// Animation variants
const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      when: "beforeChildren",
      staggerChildren: 0.1,
      duration: 0.3,
    },
  },
};

const itemVariants = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
    transition: { type: "spring", stiffness: 100 },
  },
};

const logoVariants = {
  initial: { opacity: 0, scale: 0.8, rotate: -5 },
  animate: {
    opacity: 1,
    scale: 1,
    rotate: 0,
    transition: {
      duration: 0.6,
      ease: "easeOut",
    },
  },
  hover: {
    scale: 1.05,
    rotate: 2,
    transition: { duration: 0.2 },
  },
};

function Careers() {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchFocused, setSearchFocused] = useState(false);
  const { scrollYProgress } = useScroll();
  const scaleX = useSpring(scrollYProgress, { stiffness: 100, damping: 30 });

  // Function to fetch locations
  const fetchLocations = async () => {
    const locationsSnapshot = await getDocs(collection(db, "locations"));
    const locMap = {};

    locationsSnapshot.forEach((doc) => {
      locMap[doc.id] = {
        name: doc.data().name,
        state: doc.data().state,
      };
    });

    return locMap;
  };

  // Function to fetch positions
  const fetchPositions = async () => {
    const positionsQuery = query(
      collection(db, "positions"),
      where("status", "==", "open")
    );

    const querySnapshot = await getDocs(positionsQuery);
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
  };

  // Using React Query for locations
  const locationsQuery = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocations,
  });

  // Using React Query for positions
  const positionsQuery = useQuery({
    queryKey: ["positions"],
    queryFn: fetchPositions,
  });

  // Loading and error states derived from queries
  const loading = locationsQuery.isLoading || positionsQuery.isLoading;
  const error = locationsQuery.error || positionsQuery.error;

  const locationMap = locationsQuery.data || {};
  const positions = positionsQuery.data || [];

  // Function to get location name from the map
  const getLocationName = (locationId) => {
    if (!locationId) return "Various Locations";
    return locationMap[locationId]?.name || locationId; // Fallback to ID if name not found
  };

  // Function to get location state from the map
  const getLocationState = (locationId) => {
    if (!locationId) return "";
    return locationMap[locationId]?.state || "";
  };

  const filteredPositions = positions.filter((position) => {
    if (searchTerm === "") return true;

    const searchTermLower = searchTerm.toLowerCase();

    // Check if search term matches or is contained in a state name or abbreviation
    const matchingStates = STATES.filter(
      (state) =>
        state.name.toLowerCase().includes(searchTermLower) ||
        state.abbreviation.toLowerCase().includes(searchTermLower)
    );

    const locationState = getLocationState(position.location);
    const stateMatches = matchingStates.some(
      (state) => locationState === state.abbreviation
    );

    return (
      position.title?.toLowerCase().includes(searchTermLower) ||
      position.notes?.toLowerCase().includes(searchTermLower) ||
      getLocationName(position.location)
        .toLowerCase()
        .includes(searchTermLower) ||
      position.scheduleType?.toLowerCase().includes(searchTermLower) ||
      stateMatches
    );
  });

  // Function to handle sharing
  const handleShare = async (position) => {
    const shareData = {
      title: position.title,
      text: `${position.title} at Unique\n${
        position.notes || "Join our growing team in this exciting role."
      }\nLocation: ${getLocationName(position.location)}\nSchedule: ${
        position.scheduleType || "Full-time"
      }`,
      url: `${window.location.origin}/apply?position=${position.id}`,
    };

    try {
      if (navigator.share) {
        await navigator.share(shareData);
      } else {
        // Fallback for browsers that don't support Web Share API
        const shareUrl = encodeURIComponent(shareData.url);
        const shareText = encodeURIComponent(shareData.text);
        window.open(
          `https://twitter.com/intent/tweet?url=${shareUrl}&text=${shareText}`,
          "_blank"
        );
      }
    } catch (error) {
      console.log("Error sharing:", error);
    }
  };

  return (
    <div className="min-h-screen bg-gray-900 relative">
      <motion.div
        className="fixed top-0 left-0 right-0 h-1 bg-blue-500 z-50 origin-left"
        style={{ scaleX }}
      />

      <header className="py-6 bg-gray-800 sticky top-0 z-20">
        <div className="container mx-auto px-4 flex justify-between items-center">
          <motion.div
            variants={logoVariants}
            initial="initial"
            animate="animate"
            whileHover="hover"
            className="text-3xl audiowide text-white cursor-pointer"
          >
            UNIQUE
          </motion.div>
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.2 }}
          >
            <Link
              to="/login"
              className="p-2 bg-blue-600 hover:bg-blue-700 text-white font-bold rounded transition duration-150 ease-in-out flex items-center"
            >
              <FontAwesomeIcon icon={faRightToBracket} className="mr-2" />
              <span>Sign In</span>
            </Link>
          </motion.div>
        </div>
      </header>

      <main className="container mx-auto px-4 py-12">
        <motion.div
          initial={{ opacity: 0, y: 30 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{
            duration: 0.7,
            ease: "easeOut",
          }}
          className="text-center mb-12"
        >
          <h1 className="text-4xl md:text-5xl font-bold text-white mb-4 relative">
            <motion.span
              initial={{ opacity: 0, x: -20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.4, duration: 0.6 }}
              className="inline-block"
            >
              Career
            </motion.span>
            <motion.span
              initial={{ opacity: 0, x: 20 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: 0.7, duration: 0.6 }}
              className="inline-block text-blue-400"
            >
              Opportunities
            </motion.span>
          </h1>
          <motion.p
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 1, duration: 0.5 }}
            className="text-xl text-gray-300 max-w-3xl mx-auto"
          >
            Join our team and be part of something unique. We're always looking
            for talented individuals.
          </motion.p>
        </motion.div>

        {!loading && !error && (
          <motion.div
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.9, duration: 0.4 }}
            className="mb-8 mx-auto max-w-2xl"
          >
            <div className="relative">
              <motion.input
                type="text"
                placeholder="Search positions..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                onFocus={() => setSearchFocused(true)}
                onBlur={() => setSearchFocused(false)}
                animate={{
                  boxShadow: searchFocused
                    ? "0 0 0 3px rgba(59, 130, 246, 0.5)"
                    : "0 0 0 0 rgba(59, 130, 246, 0)",
                }}
                className="w-full bg-gray-800 text-white border border-gray-700 rounded-lg py-4 px-6 pl-12 focus:outline-none text-lg transition-all"
              />
              <motion.div
                className="absolute inset-y-0 left-0 pl-4 flex items-center pointer-events-none"
                animate={{ scale: searchFocused ? 1.1 : 1 }}
                transition={{ type: "spring", stiffness: 200 }}
              >
                <svg
                  className="h-6 w-6 text-gray-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
                  />
                </svg>
              </motion.div>
            </div>
          </motion.div>
        )}

        <AnimatePresence mode="wait">
          {loading ? (
            <motion.div
              key="loading"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center text-white"
            >
              <motion.div
                animate={{
                  scale: [1, 1.02, 1],
                  opacity: [0.7, 1, 0.7],
                }}
                transition={{
                  repeat: Infinity,
                  duration: 2,
                }}
                className="bg-gray-800 rounded-lg p-6 h-40 max-w-md mx-auto"
              ></motion.div>
              <p className="mt-4">Loading positions...</p>
            </motion.div>
          ) : error ? (
            <motion.div
              key="error"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center text-red-400 p-6"
            >
              <p>
                {error?.message ||
                  "Failed to load job positions. Please try again later."}
              </p>
            </motion.div>
          ) : filteredPositions.length === 0 ? (
            <motion.div
              key="empty"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              className="text-center text-white p-6"
            >
              {positions.length === 0 ? (
                <p>No open positions at this time. Please check back later.</p>
              ) : (
                <p>No positions match your search. Try different keywords.</p>
              )}
            </motion.div>
          ) : (
            <motion.div
              key="positions"
              variants={containerVariants}
              initial="hidden"
              animate="visible"
              className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6"
            >
              {filteredPositions.map((position, index) => (
                <motion.div
                  key={position.id}
                  variants={itemVariants}
                  whileHover={{
                    scale: 1.03,
                    boxShadow: "0 10px 25px -5px rgba(0, 0, 0, 0.3)",
                    y: -5,
                  }}
                  transition={{ type: "spring", stiffness: 300 }}
                  className="bg-gray-800 rounded-lg p-6 shadow-lg flex flex-col h-full"
                >
                  <div className="flex-grow">
                    <motion.h2
                      className="text-xl font-semibold text-white mb-3"
                      layout
                    >
                      {position.title}
                    </motion.h2>
                    <motion.p className="text-gray-300 mb-4" layout>
                      {position.notes ||
                        "Join our growing team in this exciting role."}
                    </motion.p>
                    <motion.div className="text-gray-400 mb-4 text-sm" layout>
                      <span className="inline-block mr-4">
                        <motion.span
                          whileHover={{ scale: 1.2 }}
                          className="mr-1"
                        >
                          📍
                        </motion.span>
                        {getLocationName(position.location)}
                      </span>
                      <span>
                        <motion.span
                          whileHover={{ scale: 1.2 }}
                          className="mr-1"
                        >
                          ⏱️
                        </motion.span>
                        {position.scheduleType || "Full-time"}
                      </span>
                    </motion.div>
                    <AnimatePresence>
                      {position.requiresEnglish && (
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="text-yellow-400 text-sm mb-4"
                        >
                          <motion.span
                            whileHover={{ rotate: 10 }}
                            className="mr-1"
                          >
                            🗣️
                          </motion.span>
                          English Required
                        </motion.p>
                      )}
                      {position.hourlyRate && position.showHourlyRate && (
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="text-green-400 text-sm mb-4"
                        >
                          <motion.span
                            whileHover={{ scale: 1.2 }}
                            className="mr-1"
                          >
                            💰
                          </motion.span>
                          ${position.hourlyRate}
                          /hour
                        </motion.p>
                      )}
                      {position.isTemporary && (
                        <motion.p
                          initial={{ opacity: 0, y: 10 }}
                          animate={{ opacity: 1, y: 0 }}
                          exit={{ opacity: 0, y: -10 }}
                          className="text-blue-400 text-sm mb-4"
                        >
                          <motion.span
                            whileHover={{ rotate: 360 }}
                            transition={{ duration: 1 }}
                            className="mr-1"
                          >
                            ⏳
                          </motion.span>
                          Temporary Position
                        </motion.p>
                      )}
                    </AnimatePresence>
                    <div className="mb-4">
                      <p className="text-gray-400 text-sm">Shift preference:</p>
                      <motion.div className="flex space-x-2 mt-1" layout>
                        {position.shiftPreference?.am && (
                          <motion.span
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            whileHover={{ scale: 1.1 }}
                            className="px-2 py-1 bg-blue-900 text-blue-300 rounded text-xs"
                          >
                            Morning
                          </motion.span>
                        )}
                        {position.shiftPreference?.pm && (
                          <motion.span
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            whileHover={{ scale: 1.1 }}
                            className="px-2 py-1 bg-orange-900 text-orange-300 rounded text-xs"
                          >
                            Afternoon
                          </motion.span>
                        )}
                        {position.shiftPreference?.overnight && (
                          <motion.span
                            initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            whileHover={{ scale: 1.1 }}
                            className="px-2 py-1 bg-indigo-900 text-indigo-300 rounded text-xs"
                          >
                            Overnight
                          </motion.span>
                        )}
                      </motion.div>
                    </div>
                  </div>
                  <div className="flex gap-2">
                    <motion.div
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                      className="flex-1"
                    >
                      <Link
                        to={`/apply?position=${position.id}`}
                        className="block w-full py-3 px-4 bg-blue-600 hover:bg-blue-700 text-white text-center rounded transition-colors mt-auto overflow-hidden relative"
                      >
                        <motion.span
                          className="relative z-1"
                          whileHover={{
                            scale: 1.05,
                            transition: { duration: 0.2 },
                          }}
                        >
                          Apply Now
                        </motion.span>
                      </Link>
                    </motion.div>
                    <motion.button
                      onClick={() => handleShare(position)}
                      whileHover={{ scale: 1.03 }}
                      whileTap={{ scale: 0.97 }}
                      className="py-3 px-4 bg-gray-700 hover:bg-gray-600 text-white rounded transition-colors mt-auto flex items-center justify-center"
                      title="Share position"
                    >
                      <FontAwesomeIcon icon={faShare} className="text-lg" />
                    </motion.button>
                  </div>
                </motion.div>
              ))}
            </motion.div>
          )}
        </AnimatePresence>
      </main>

      <motion.footer
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 1.2 }}
        className="bg-gray-800 py-8 mt-auto"
      >
        <div className="container mx-auto px-4 text-center text-gray-400">
          <p>© {new Date().getFullYear()} Unique. All rights reserved.</p>
        </div>
      </motion.footer>
    </div>
  );
}

export default Careers;
