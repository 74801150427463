import React, { useState, useEffect } from "react";
import { auth, db } from "../firebase";
import { collection, getDocs, addDoc, Timestamp } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faInfoCircle,
  faBriefcase,
  faLanguage,
} from "@fortawesome/free-solid-svg-icons";
import CreatableSelect from "react-select/creatable";

// Helper function to generate position IDs
const generatePositionId = (index) => {
  return `POS-${String(index + 1).padStart(4, "0")}`;
};

function CreatePositionModal({ isOpen, onClose, userName }) {
  const [formData, setFormData] = useState({
    title: "",
    notes: "",
    location: "",
    isTemporary: false,
    scheduleType: "full-time",
    shiftPreference: {
      am: false,
      pm: false,
      overnight: false,
    },
    requiresEnglish: false,
    englishLevel: "basic",
    hourlyRate: "",
    showHourlyRate: true,
  });
  const [titleOptions, setTitleOptions] = useState([]);
  const [locations, setLocations] = useState([]);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errors, setErrors] = useState({});

  // Fetch locations and position titles from Firestore
  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch locations
        const locationsCollection = collection(db, "locations");
        const locationsSnapshot = await getDocs(locationsCollection);
        const locationsList = locationsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        // Sort locations alphabetically by name
        const sortedLocations = locationsList.sort((a, b) =>
          a.name.localeCompare(b.name)
        );

        setLocations(sortedLocations);

        // Set default location if available
        if (sortedLocations.length > 0 && !formData.location) {
          setFormData((prev) => ({
            ...prev,
            location: sortedLocations[0].id,
          }));
        }

        // Fetch existing position titles
        const positionsCollection = collection(db, "positions");
        const positionsSnapshot = await getDocs(positionsCollection);

        // Extract unique titles and create options
        const titles = new Set();
        positionsSnapshot.docs.forEach((doc) => {
          const position = doc.data();
          if (position.title) {
            titles.add(position.title);
          }
        });

        const formattedTitles = Array.from(titles)
          .sort()
          .map((title) => ({
            value: title,
            label: title,
          }));

        setTitleOptions(formattedTitles);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    if (isOpen) {
      fetchData();
    }
  }, [isOpen, formData.location]);

  // Cleanup function
  const cleanupAndClose = () => {
    // Reset form data to initial state
    setFormData({
      title: "",
      notes: "",
      location: "",
      isTemporary: false,
      scheduleType: "full-time",
      shiftPreference: {
        am: false,
        pm: false,
        overnight: false,
      },
      requiresEnglish: false,
      englishLevel: "basic",
      hourlyRate: "",
      showHourlyRate: true,
    });
    setShowConfirmDialog(false);
    setErrors({});
    onClose();
  };

  // Handle modal close with confirmation
  const handleCloseModal = () => {
    if (formData.notes) {
      setShowConfirmDialog(true);
    } else {
      cleanupAndClose();
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Get the title value (accounting for whether it's an object or string)
    const titleValue =
      typeof formData.title === "object" && formData.title
        ? formData.title.value
        : formData.title;

    // Form validation
    const newErrors = {};
    if (!titleValue || titleValue.trim() === "") {
      newErrors.title = "Position title is required";
    }

    // Validate that at least one shift preference is selected
    if (
      !formData.shiftPreference.am &&
      !formData.shiftPreference.pm &&
      !formData.shiftPreference.overnight
    ) {
      newErrors.shiftPreference =
        "At least one shift preference must be selected";
    }

    // Validate hourly rate (if provided) is a valid number
    if (formData.hourlyRate && isNaN(parseFloat(formData.hourlyRate))) {
      newErrors.hourlyRate = "Hourly rate must be a valid number";
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setIsSubmitting(true);

    try {
      const positionsCollection = collection(db, "positions");
      const positionsSnapshot = await getDocs(positionsCollection);
      const nextIndex = positionsSnapshot.size;

      await addDoc(positionsCollection, {
        title: titleValue,
        notes: formData.notes,
        location: formData.location,
        isTemporary: formData.isTemporary,
        scheduleType: formData.scheduleType,
        shiftPreference: formData.shiftPreference,
        requiresEnglish: formData.requiresEnglish,
        englishLevel: formData.requiresEnglish ? formData.englishLevel : null,
        hourlyRate: formData.hourlyRate
          ? parseFloat(formData.hourlyRate)
          : null,
        showHourlyRate: formData.showHourlyRate,
        approved: false,
        positionId: generatePositionId(nextIndex),
        status: "pending_approval",
        createdBy: userName,
        createdAt: Timestamp.now(),
        timeOfSubmission: Timestamp.now(),
        userUid: auth.currentUser?.uid || localStorage.getItem("userUid"),
      });

      cleanupAndClose();
    } catch (error) {
      console.error("Error creating position:", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full">
      {showConfirmDialog ? (
        <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
          <h3 className="text-lg font-medium text-gray-900">
            Discard changes?
          </h3>
          <p className="mt-2 text-sm text-gray-500">
            All your changes will be lost. This action cannot be undone.
          </p>
          <div className="mt-4 flex justify-end space-x-3">
            <button
              onClick={() => setShowConfirmDialog(false)}
              className="px-4 py-2 bg-gray-100 hover:bg-gray-200 text-gray-800 rounded-md"
            >
              Cancel
            </button>
            <button
              onClick={cleanupAndClose}
              className="px-4 py-2 bg-red-600 hover:bg-red-700 text-white rounded-md"
            >
              Discard
            </button>
          </div>
        </div>
      ) : (
        <div className="relative top-20 mx-auto p-8 border max-w-2xl w-11/12 shadow-lg rounded-lg bg-white mb-10">
          <div className="flex justify-between items-center mb-6 border-b pb-4">
            <h2 className="text-2xl font-bold text-gray-800">
              Create Open Position
            </h2>
            <button
              onClick={handleCloseModal}
              className="text-gray-500 hover:text-gray-700 transition-colors"
              aria-label="Close modal"
            >
              <FontAwesomeIcon icon={faTimes} className="text-xl" />
            </button>
          </div>
          <form onSubmit={handleSubmit} className="space-y-6" noValidate>
            {/* Basic Information Section */}
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">
                Basic Information
              </h3>

              <div className="mb-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Position Title *
                </label>
                <CreatableSelect
                  isClearable
                  placeholder="Enter or select a position title..."
                  options={titleOptions}
                  value={
                    typeof formData.title === "string" && formData.title
                      ? { value: formData.title, label: formData.title }
                      : formData.title
                  }
                  className={`shadow-sm rounded-md w-full text-gray-700 ${
                    errors.title ? "border-red-500" : ""
                  }`}
                  classNamePrefix="select"
                  onChange={(selectedOption) => {
                    setFormData((prevState) => ({
                      ...prevState,
                      title: selectedOption,
                    }));
                    // Clear error when user makes a selection
                    if (errors.title) {
                      setErrors((prev) => ({ ...prev, title: undefined }));
                    }
                  }}
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };
                    setTitleOptions((prev) => [...prev, newOption]);
                    setFormData((prevState) => ({
                      ...prevState,
                      title: newOption,
                    }));
                    // Clear error when user creates an option
                    if (errors.title) {
                      setErrors((prev) => ({ ...prev, title: undefined }));
                    }
                  }}
                  styles={{
                    control: (provided, state) => ({
                      ...provided,
                      padding: "2px",
                      borderColor: errors.title
                        ? "#EF4444"
                        : state.isFocused
                        ? "#3B82F6"
                        : "#D1D5DB",
                      boxShadow: errors.title
                        ? "0 0 0 1px #EF4444"
                        : state.isFocused
                        ? "0 0 0 1px #3B82F6"
                        : provided.boxShadow,
                      "&:hover": {
                        borderColor: errors.title ? "#EF4444" : "#9CA3AF",
                      },
                    }),
                    option: (provided, state) => ({
                      ...provided,
                      backgroundColor: state.isSelected
                        ? "#2563EB"
                        : state.isFocused
                        ? "#DBEAFE"
                        : "white",
                      color: state.isSelected ? "white" : "#374151",
                    }),
                  }}
                />
                {errors.title && (
                  <p className="mt-1 text-sm text-red-600">{errors.title}</p>
                )}
              </div>

              <div className="mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Location *
                </label>
                <select
                  value={formData.location}
                  onChange={(e) =>
                    setFormData({ ...formData, location: e.target.value })
                  }
                  className="shadow-sm border border-gray-300 rounded-md w-full py-2.5 px-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  required
                >
                  {locations.length === 0 ? (
                    <option value="">Loading locations...</option>
                  ) : (
                    locations.map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))
                  )}
                </select>
              </div>
            </div>

            {/* Position Details Section */}
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center mb-4">
                <FontAwesomeIcon
                  icon={faBriefcase}
                  className="text-blue-600 mr-2"
                />
                <h3 className="text-lg font-semibold text-gray-800">
                  Position Details
                </h3>
              </div>

              {/* Hourly Rate */}
              <div className="mb-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Hourly Rate ($)
                </label>
                <div className="relative">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">$</span>
                  </div>
                  <input
                    type="text"
                    value={formData.hourlyRate}
                    onChange={(e) => {
                      // Only allow numbers and decimal point
                      const value = e.target.value.replace(/[^0-9.]/g, "");
                      setFormData({ ...formData, hourlyRate: value });

                      // Clear error when user types
                      if (errors.hourlyRate) {
                        setErrors((prev) => ({
                          ...prev,
                          hourlyRate: undefined,
                        }));
                      }
                    }}
                    placeholder="0.00"
                    className={`shadow-sm border ${
                      errors.hourlyRate ? "border-red-500" : "border-gray-300"
                    } rounded-md w-full py-2.5 pl-8 pr-4 text-gray-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors`}
                  />
                </div>
                {errors.hourlyRate && (
                  <p className="mt-1 text-sm text-red-600">
                    {errors.hourlyRate}
                  </p>
                )}
                <div className="mt-2">
                  <label className="flex items-center text-sm text-gray-700">
                    <input
                      type="checkbox"
                      checked={formData.showHourlyRate}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          showHourlyRate: e.target.checked,
                        })
                      }
                      className="form-checkbox h-4 w-4 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                    />
                    <span className="ml-2">
                      Show hourly rate on Careers page
                    </span>
                  </label>
                </div>
              </div>

              {/* Position Type */}
              <div className="mb-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Position Type
                </label>
                <div className="mt-1 grid grid-cols-2 gap-4">
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      !formData.isTemporary
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="radio"
                      className="form-radio text-blue-600 hidden"
                      name="positionType"
                      checked={!formData.isTemporary}
                      onChange={() =>
                        setFormData({ ...formData, isTemporary: false })
                      }
                    />
                    <span className="font-medium">Permanent</span>
                  </label>
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.isTemporary
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="radio"
                      className="form-radio text-blue-600 hidden"
                      name="positionType"
                      checked={formData.isTemporary}
                      onChange={() =>
                        setFormData({ ...formData, isTemporary: true })
                      }
                    />
                    <span className="font-medium">Temporary</span>
                  </label>
                </div>
              </div>

              {/* Schedule Type */}
              <div className="mb-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Schedule Type
                </label>
                <div className="mt-1 grid grid-cols-2 gap-4">
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.scheduleType === "full-time"
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="radio"
                      className="form-radio text-blue-600 hidden"
                      name="scheduleType"
                      checked={formData.scheduleType === "full-time"}
                      onChange={() =>
                        setFormData({ ...formData, scheduleType: "full-time" })
                      }
                    />
                    <span className="font-medium">Full-time</span>
                  </label>
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.scheduleType === "part-time"
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="radio"
                      className="form-radio text-blue-600 hidden"
                      name="scheduleType"
                      checked={formData.scheduleType === "part-time"}
                      onChange={() =>
                        setFormData({ ...formData, scheduleType: "part-time" })
                      }
                    />
                    <span className="font-medium">Part-time</span>
                  </label>
                </div>
              </div>

              {/* Shift Preference */}
              <div className="mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Shift Preference
                </label>
                <div className="mt-1 grid grid-cols-3 gap-2">
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.shiftPreference.am
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-600 hidden"
                      name="shiftPreferenceAM"
                      checked={formData.shiftPreference.am}
                      onChange={() => {
                        const newShiftPreference = {
                          ...formData.shiftPreference,
                          am: !formData.shiftPreference.am,
                          overnight: false, // Uncheck overnight if AM is selected
                        };
                        setFormData({
                          ...formData,
                          shiftPreference: newShiftPreference,
                        });
                      }}
                    />
                    <span className="font-medium">AM</span>
                  </label>
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.shiftPreference.pm
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-600 hidden"
                      name="shiftPreferencePM"
                      checked={formData.shiftPreference.pm}
                      onChange={() => {
                        const newShiftPreference = {
                          ...formData.shiftPreference,
                          pm: !formData.shiftPreference.pm,
                          overnight: false, // Uncheck overnight if PM is selected
                        };
                        setFormData({
                          ...formData,
                          shiftPreference: newShiftPreference,
                        });
                      }}
                    />
                    <span className="font-medium">PM</span>
                  </label>
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.shiftPreference.overnight
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="checkbox"
                      className="form-checkbox text-blue-600 hidden"
                      name="shiftPreferenceOvernight"
                      checked={formData.shiftPreference.overnight}
                      onChange={() => {
                        const newShiftPreference = {
                          am: false, // Uncheck AM if overnight is selected
                          pm: false, // Uncheck PM if overnight is selected
                          overnight: !formData.shiftPreference.overnight,
                        };
                        setFormData({
                          ...formData,
                          shiftPreference: newShiftPreference,
                        });
                      }}
                    />
                    <span className="font-medium">Overnight</span>
                  </label>
                </div>
              </div>
            </div>

            {/* Language Requirements Section */}
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center mb-4">
                <FontAwesomeIcon
                  icon={faLanguage}
                  className="text-blue-600 mr-2"
                />
                <h3 className="text-lg font-semibold text-gray-800">
                  Language Requirements
                </h3>
              </div>

              {/* English Requirement */}
              <div className="mb-5">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  English Requirement
                </label>
                <div className="mt-1 grid grid-cols-2 gap-4">
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      !formData.requiresEnglish
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="radio"
                      className="form-radio text-blue-600 hidden"
                      name="requiresEnglish"
                      checked={!formData.requiresEnglish}
                      onChange={() =>
                        setFormData({ ...formData, requiresEnglish: false })
                      }
                    />
                    <span className="font-medium">Not Required</span>
                  </label>
                  <label
                    className={`flex items-center justify-center p-3 rounded-md border ${
                      formData.requiresEnglish
                        ? "bg-blue-50 border-blue-300 text-blue-700"
                        : "bg-white border-gray-300 text-gray-700"
                    } cursor-pointer transition-colors`}
                  >
                    <input
                      type="radio"
                      className="form-radio text-blue-600 hidden"
                      name="requiresEnglish"
                      checked={formData.requiresEnglish}
                      onChange={() =>
                        setFormData({ ...formData, requiresEnglish: true })
                      }
                    />
                    <span className="font-medium">Required</span>
                  </label>
                </div>
              </div>

              {/* English Level - only visible if English is required */}
              {formData.requiresEnglish && (
                <div className="mb-0 mt-5">
                  <label className="block text-gray-700 text-sm font-bold mb-2">
                    English Level Required
                  </label>
                  <div className="grid grid-cols-4 gap-2">
                    {["basic", "intermediate", "advanced", "fluent"].map(
                      (level) => (
                        <label
                          key={level}
                          className={`flex items-center justify-center p-2 rounded-md border text-center ${
                            formData.englishLevel === level
                              ? "bg-blue-50 border-blue-300 text-blue-700"
                              : "bg-white border-gray-300 text-gray-700"
                          } cursor-pointer transition-colors`}
                        >
                          <input
                            type="radio"
                            className="hidden"
                            name="englishLevel"
                            checked={formData.englishLevel === level}
                            onChange={() =>
                              setFormData({ ...formData, englishLevel: level })
                            }
                          />
                          <span className="font-medium capitalize">
                            {level}
                          </span>
                        </label>
                      )
                    )}
                  </div>
                </div>
              )}
            </div>

            {/* Job Details Section */}
            <div className="bg-gray-50 p-4 rounded-lg border border-gray-200">
              <div className="flex items-center mb-4">
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className="text-blue-600 mr-2"
                />
                <h3 className="text-lg font-semibold text-gray-800">
                  Job Details
                </h3>
              </div>

              <div className="mb-0">
                <label className="block text-gray-700 text-sm font-bold mb-2">
                  Notes *
                </label>
                <textarea
                  value={formData.notes}
                  onChange={(e) =>
                    setFormData({ ...formData, notes: e.target.value })
                  }
                  className="shadow-sm border border-gray-300 rounded-md w-full py-2.5 px-4 text-gray-700 h-48 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors"
                  placeholder="Please describe the position details, duties, and requirements..."
                  required
                />
              </div>
            </div>

            <div className="flex justify-end space-x-4 pt-4">
              <button
                type="button"
                onClick={handleCloseModal}
                className="bg-gray-100 hover:bg-gray-200 text-gray-800 font-medium py-2.5 px-5 rounded-md transition-colors border border-gray-300"
              >
                Cancel
              </button>
              <button
                type="submit"
                disabled={isSubmitting}
                className="bg-green-500 hover:bg-green-600 text-white font-bold text-base py-3 px-8 rounded-md transition-colors disabled:bg-green-500 disabled:opacity-90 shadow-md border border-green-600 z-10"
                style={{
                  background: "#10b981",
                  color: "white",
                  boxShadow: "0 4px 6px rgba(5, 150, 105, 0.4)",
                }}
              >
                {isSubmitting ? "Creating..." : "Create Position"}
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  );
}

export default CreatePositionModal;
