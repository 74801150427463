import React, {
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from "react";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { auth, db } from "./firebase";
import CreatableSelect from "react-select/creatable";
import LocationsList from "./LocationsList";
import {
  collection,
  query,
  orderBy,
  getDocs,
  addDoc,
  updateDoc,
  doc,
  Timestamp,
  where,
  writeBatch,
} from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTimes } from "@fortawesome/free-solid-svg-icons";
import { GoogleMap, Marker, Circle } from "@react-google-maps/api";
import { GoogleMapsContext } from "./GoogleMapsProvider";
import { NumericFormat } from "react-number-format";
import { useUser } from "./UserContext";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Select from "react-select";

async function fetchLocationsData() {
  const q = query(collection(db, "locations"), orderBy("name"));
  const querySnapshot = await getDocs(q);
  return querySnapshot.docs.map((doc) => ({
    id: doc.id,
    ...doc.data(),
  }));
}

async function fetchRolesData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const rolesByHourSet = new Set();
  const rolesByUnitSet = new Set();
  locationsSnapshot.forEach((doc) => {
    const rolesMap = doc.data().roles;
    if (rolesMap) {
      Object.keys(rolesMap).forEach((role) => {
        if (rolesMap[role] === "notByHour") {
          rolesByUnitSet.add(role);
        } else {
          rolesByHourSet.add(role);
        }
      });
    }
  });
  const fetchedRolesByHour = Array.from(rolesByHourSet).map((role) => ({
    value: role,
    label: role,
  }));
  const fetchedRolesByUnit = Array.from(rolesByUnitSet).map((role) => ({
    value: role,
    label: role,
  }));
  return {
    byHour: fetchedRolesByHour,
    byUnit: fetchedRolesByUnit,
  };
}

async function fetchTasksData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const tasksByHourSet = new Set();
  const tasksByUnitSet = new Set();
  locationsSnapshot.forEach((doc) => {
    const tasksMap = doc.data().tasks;
    if (tasksMap) {
      Object.keys(tasksMap).forEach((task) => {
        if (tasksMap[task] === "notByHour") {
          tasksByUnitSet.add(task);
        } else {
          tasksByHourSet.add(task);
        }
      });
    }
  });
  const fetchedTasksByHour = Array.from(tasksByHourSet).map((task) => ({
    value: task,
    label: task,
  }));
  const fetchedTasksByUnit = Array.from(tasksByUnitSet).map((task) => ({
    value: task,
    label: task,
  }));
  return {
    byHour: fetchedTasksByHour,
    byUnit: fetchedTasksByUnit,
  };
}

async function fetchRegionsData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const regionsSet = new Set();

  locationsSnapshot.forEach((doc) => {
    const region = doc.data().region;
    if (region) {
      regionsSet.add(region);
    }
  });

  return Array.from(regionsSet).map((region) => ({
    value: region,
    label: region,
  }));
}

async function fetchManagersData() {
  const q = query(collection(db, "users"), where("status", "==", "Active"));

  const querySnapshot = await getDocs(q);
  return querySnapshot.docs
    .map((doc) => ({
      value: doc.id,
      label: `${doc.data().firstName} ${doc.data().lastName}`,
      userData: {
        firstName: doc.data().firstName,
        lastName: doc.data().lastName,
        uid: doc.id,
      },
    }))
    .sort((a, b) => a.label.localeCompare(b.label));
}

async function fetchServicesData() {
  const locationsSnapshot = await getDocs(collection(db, "locations"));
  const servicesByHourSet = new Set();
  const servicesByUnitSet = new Set();

  locationsSnapshot.forEach((doc) => {
    const services = doc.data().services;
    if (services) {
      Object.entries(services).forEach(([service, details]) => {
        if (details.type === "byUnit") {
          servicesByUnitSet.add(service);
        } else {
          servicesByHourSet.add(service);
        }
      });
    }
  });

  return {
    byHour: Array.from(servicesByHourSet).map((service) => ({
      value: service,
      label: service,
    })),
    byUnit: Array.from(servicesByUnitSet).map((service) => ({
      value: service,
      label: service,
    })),
  };
}

async function saveLocationData({ locationData, editingLocationId }) {
  const user = auth.currentUser;
  if (!user) {
    throw new Error("No authenticated user available");
  }

  const simplifiedTasks = {};
  Object.entries(locationData.tasks).forEach(([taskName, taskDetails]) => {
    simplifiedTasks[taskName] = {
      type: taskDetails.type,
      price: taskDetails.price,
    };
  });

  const dataToSave = {
    ...locationData,
    tasks: simplifiedTasks,
    lastModifiedBy: user.uid,
    lastModifiedAt: Timestamp.now(),
  };

  if (editingLocationId) {
    await updateDoc(doc(db, "locations", editingLocationId), dataToSave);
  } else {
    await addDoc(collection(db, "locations"), dataToSave);
  }
}

function Locations({ userRole: propUserRole }) {
  const { userRole: contextUserRole } = useUser();
  const effectiveUserRole = propUserRole || contextUserRole;

  const queryClient = useQueryClient();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const addressInputRef = useRef(null);
  const [locationForm, setLocationForm] = useState({
    name: "",
    address: "",
    supervisors: [],
    radius: 1000,
    latitude: null,
    longitude: null,
    rolesByHour: [],
    rolesByUnit: [],
    tasks: {},
    region: null,
    services: {},
    state: "",
  });
  const [editingLocationId, setEditingLocationId] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [map, setMap] = useState(null);
  const [isRegionModalOpen, setIsRegionModalOpen] = useState(false);
  const [editingRegion, setEditingRegion] = useState(null);
  const [regionForm, setRegionForm] = useState({
    name: "",
    manager: null,
    selectedLocations: [],
  });

  const {
    data: locations,
    isLoading: isLoadingLocations,
    isError,
    error,
  } = useQuery({
    queryKey: ["locations"],
    queryFn: fetchLocationsData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: roles } = useQuery({
    queryKey: ["roles"],
    queryFn: fetchRolesData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: tasks } = useQuery({
    queryKey: ["tasks"],
    queryFn: fetchTasksData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: regions } = useQuery({
    queryKey: ["regions"],
    queryFn: fetchRegionsData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: managers } = useQuery({
    queryKey: ["managers"],
    queryFn: fetchManagersData,
    staleTime: 5 * 60 * 1000,
  });

  const { data: services } = useQuery({
    queryKey: ["services"],
    queryFn: fetchServicesData,
    staleTime: 5 * 60 * 1000,
  });

  const saveLocationMutation = useMutation({
    mutationFn: saveLocationData,
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
      closeModal();
    },
    onError: (error) => {
      console.error("Error saving location: ", error);
    },
  });

  const saveRegionMutation = useMutation({
    mutationFn: async ({ regionName, newRegionName }) => {
      const q = query(
        collection(db, "locations"),
        where("region", "==", regionName)
      );
      const querySnapshot = await getDocs(q);

      const batch = writeBatch(db);
      querySnapshot.forEach((doc) => {
        batch.update(doc.ref, {
          region: newRegionName,
          regionManager: regionForm.manager?.label || "",
          regionManagerUid: regionForm.manager?.value || null,
        });
      });

      await batch.commit();
    },
    onSuccess: () => {
      queryClient.invalidateQueries(["locations"]);
      setIsRegionModalOpen(false);
    },
    onError: (error) => {
      console.error("Error updating region: ", error);
    },
  });

  const { isLoaded: isLoadedMaps, loadError } =
    React.useContext(GoogleMapsContext);

  // Use useMemo for filteredLocations
  const filteredLocations = useMemo(() => {
    return locations
      ? locations.filter((location) =>
          (
            location.name.toLowerCase() +
            " " +
            location.address.toLowerCase() +
            " " +
            (location.region || "").toLowerCase() +
            " " +
            (location.regionManager || "").toLowerCase()
          ).includes(searchTerm.toLowerCase())
        )
      : [];
  }, [locations, searchTerm]);

  const onMapLoad = useCallback((map) => {
    setMap(map);
  }, []);

  useEffect(() => {
    if (filteredLocations.length > 0 && map) {
      const bounds = new window.google.maps.LatLngBounds();
      let validLocations = 0;

      filteredLocations.forEach((location) => {
        if (
          typeof location.latitude === "number" &&
          typeof location.longitude === "number"
        ) {
          bounds.extend({ lat: location.latitude, lng: location.longitude });
          validLocations++;
        }
      });

      if (validLocations > 0) {
        map.fitBounds(bounds);
        if (validLocations === 1) {
          map.setZoom(13);
        }
      }
    }
  }, [filteredLocations, map]);

  // Process existing locations to ensure they have states
  useEffect(() => {
    if (locations && locations.length > 0) {
      const locationsToUpdate = [];

      locations.forEach((location) => {
        // If location doesn't have a state but has an address
        if (!location.state && location.address) {
          const extractedState = extractStateFromAddress(location.address);
          if (extractedState) {
            locationsToUpdate.push({
              id: location.id,
              state: extractedState,
            });
          }
        }
      });

      // Update locations in the database if needed
      if (locationsToUpdate.length > 0) {
        const updatePromises = locationsToUpdate.map((location) => {
          return updateDoc(doc(db, "locations", location.id), {
            state: location.state,
            lastModifiedBy: auth.currentUser?.uid || "system",
            lastModifiedAt: Timestamp.now(),
          });
        });

        Promise.all(updatePromises)
          .then(() => {
            console.log(
              `Updated state for ${locationsToUpdate.length} locations`
            );
            // Refresh the data
            queryClient.invalidateQueries("locations");
          })
          .catch((error) => {
            console.error("Error updating location states:", error);
          });
      }
    }
  }, [locations, queryClient]);

  useEffect(() => {
    if (isModalOpen && isLoadedMaps && addressInputRef.current) {
      const autocomplete = new window.google.maps.places.Autocomplete(
        addressInputRef.current,
        { types: ["geocode"] }
      );

      autocomplete.addListener("place_changed", () => {
        const place = autocomplete.getPlace();

        if (place.geometry) {
          const formattedAddress = place.formatted_address;
          const extractedState = extractStateFromAddress(formattedAddress);

          setLocationForm((prevState) => ({
            ...prevState,
            address: formattedAddress,
            latitude: place.geometry.location.lat(),
            longitude: place.geometry.location.lng(),
            state: extractedState,
          }));
        }
      });

      return () => {
        window.google.maps.event.clearInstanceListeners(autocomplete);
      };
    }
  }, [isLoadedMaps, isModalOpen]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setLocationForm((prevState) => ({
      ...prevState,
      [name]: name === "radius" ? Number(value) : value,
    }));
  };

  const handleAddLocation = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setLocationForm({
      name: "",
      address: "",
      supervisors: [],
      radius: 1000,
      latitude: null,
      longitude: null,
      rolesByHour: [],
      rolesByUnit: [],
      tasks: {},
      region: null,
      services: {},
      state: "",
    });
    setEditingLocationId(null);
    if (addressInputRef.current) {
      addressInputRef.current.value = "";
    }
  };

  const handleSaveLocation = () => {
    const rolesMap = locationForm.rolesByHour.reduce((acc, current) => {
      acc[current.value] = "";
      return acc;
    }, {});

    locationForm.rolesByUnit.forEach((current) => {
      rolesMap[current.value] = "notByHour";
    });

    const locationData = {
      ...locationForm,
      radius: Number(locationForm.radius),
      region: locationForm.region?.value || null,
      roles: rolesMap,
      lastModifiedBy: auth.currentUser.uid,
      lastModifiedAt: Timestamp.now(),
    };

    saveLocationMutation.mutate({ locationData, editingLocationId });
  };

  const handleEditLocation = (location) => {
    const rolesByHour = [];
    const rolesByUnit = [];
    Object.entries(location.roles || {}).forEach(([role, value]) => {
      if (value === "notByHour") {
        rolesByUnit.push({ value: role, label: role });
      } else {
        rolesByHour.push({ value: role, label: role });
      }
    });

    const tasksByHour = [];
    const tasksByUnit = [];
    const taskPrices = {};
    Object.entries(location.tasks || {}).forEach(([task, value]) => {
      const taskOption = { value: task, label: task };
      if (value.type === "byHour") {
        tasksByHour.push(taskOption);
      } else {
        tasksByUnit.push(taskOption);
      }
      taskPrices[task] = value.price;
    });

    setLocationForm({
      name: location.name || "",
      address: location.address || "",
      latitude: location.latitude || null,
      longitude: location.longitude || null,
      radius: location.radius || 1000,
      rolesByHour,
      rolesByUnit,
      tasks: location.tasks || {},
      region: location.region
        ? { value: location.region, label: location.region }
        : null,
      services: location.services || {},
      state: location.state || extractStateFromAddress(location.address || ""),
    });
    setEditingLocationId(location.id);
    setIsModalOpen(true);
  };

  const handleLocationClick = (location) => {
    setSearchTerm(location.name);
    if (map) {
      map.panTo({ lat: location.latitude, lng: location.longitude });
      map.setZoom(15);
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
    if (e.target.value === "" && map) {
      const bounds = new window.google.maps.LatLngBounds();
      filteredLocations.forEach((location) => {
        if (
          typeof location.latitude === "number" &&
          typeof location.longitude === "number"
        ) {
          bounds.extend({ lat: location.latitude, lng: location.longitude });
        }
      });
      map.fitBounds(bounds);
    }
  };

  const handleTaskChange = (taskName, field, value) => {
    setLocationForm((prevState) => ({
      ...prevState,
      tasks: {
        ...prevState.tasks,
        [taskName]: {
          ...prevState.tasks[taskName],
          [field]: value,
        },
      },
    }));
  };

  const handleDeleteTask = (taskName) => {
    setLocationForm((prevState) => {
      const updatedTasks = { ...prevState.tasks };
      delete updatedTasks[taskName];
      return { ...prevState, tasks: updatedTasks };
    });
  };

  const handleEditRegion = (region) => {
    setEditingRegion(region);
    const regionLocations = locations.filter((loc) => loc.region === region);
    const managerUid = regionLocations[0]?.regionManagerUid;

    setRegionForm({
      name: region,
      manager: managerUid
        ? managers?.find((m) => m.value === managerUid)
        : null,
      selectedLocations: [],
    });
    setIsRegionModalOpen(true);
  };

  const handleSaveRegion = async () => {
    await saveRegionMutation.mutate({
      regionName: editingRegion,
      newRegionName: regionForm.name,
    });

    if (regionForm.selectedLocations.length > 0) {
      const batch = writeBatch(db);
      regionForm.selectedLocations.forEach((locationId) => {
        const locationRef = doc(db, "locations", locationId);
        batch.update(locationRef, {
          region: regionForm.name,
          regionManager: regionForm.manager?.label || "",
          regionManagerUid: regionForm.manager?.value || null,
        });
      });
      await batch.commit();
      queryClient.invalidateQueries(["locations"]);
    }
  };

  const handleServiceChange = (serviceName, field, value) => {
    setLocationForm((prevState) => ({
      ...prevState,
      services: {
        ...prevState.services,
        [serviceName]: {
          ...prevState.services[serviceName],
          [field]: value,
        },
      },
    }));
  };

  const handleDeleteService = (serviceName) => {
    setLocationForm((prevState) => {
      const updatedServices = { ...prevState.services };
      delete updatedServices[serviceName];
      return { ...prevState, services: updatedServices };
    });
  };

  const mapContainerStyle = {
    width: "100%",
    height: "400px",
    marginBottom: "20px",
    borderRadius: "15px",
    overflow: "hidden",
  };

  const mapOptions = {
    mapTypeControl: false,
    streetViewControl: false,
  };

  // Function to extract state from address string
  const extractStateFromAddress = (address) => {
    if (!address) return "";

    // Common pattern for US addresses: City, STATE ZIP, USA
    const stateRegex = /,\s*(\w{2})\s+\d{5}/;
    const match = address.match(stateRegex);

    if (match && match[1]) {
      return match[1].toUpperCase(); // Return the state code in uppercase
    }

    return ""; // Return empty string if no state found
  };

  if (isLoadingLocations || !isLoadedMaps) {
    return (
      <div className="space-y-4">
        {/* Map skeleton */}
        <Skeleton height={400} borderRadius={15} />

        {/* Search bar skeleton */}
        <Skeleton height={40} />

        {/* Location list skeletons */}
        {[...Array(5)].map((_, index) => (
          <div key={index} className="mb-4 p-4 bg-white rounded-lg shadow">
            <div className="flex justify-between">
              <div className="space-y-2 w-full">
                <Skeleton width={200} />
                <Skeleton width="80%" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (isError) {
    return <div>Error loading locations: {error.message}</div>;
  }

  if (loadError) {
    return <div>Error loading Google Maps: {loadError.message}</div>;
  }

  return (
    <div className="relative">
      {isModalOpen && (
        <div
          className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50"
          id="my-modal"
        >
          <div className="relative top-20 mx-auto p-6 border shadow-lg rounded-lg bg-white w-11/12 max-w-2xl z-50">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-semibold text-gray-900">
                {editingLocationId ? "Edit Location" : "Add New Location"}
              </h3>
              <button
                onClick={closeModal}
                className="text-gray-400 hover:text-gray-600"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label
                  htmlFor="location-name"
                  className="block text-sm font-medium text-gray-700"
                >
                  Location Name
                </label>
                <input
                  type="text"
                  name="name"
                  value={locationForm.name}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="location-name"
                  placeholder="Enter location name"
                />
              </div>

              <div>
                <label
                  htmlFor="location-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Address
                </label>
                <input
                  type="text"
                  ref={addressInputRef}
                  name="address"
                  value={locationForm.address}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="location-address"
                  placeholder="Enter location address"
                />
              </div>

              <div>
                <label
                  htmlFor="location-state"
                  className="block text-sm font-medium text-gray-700"
                >
                  State
                </label>
                <input
                  type="text"
                  name="state"
                  value={locationForm.state}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="location-state"
                  placeholder="State (e.g., VA)"
                  maxLength="2"
                />
                <p className="text-xs text-gray-500 mt-1">
                  State is automatically extracted from the address, but can be
                  manually edited
                </p>
              </div>

              <div>
                <label
                  htmlFor="region"
                  className="block text-sm font-medium text-gray-700"
                >
                  Region
                </label>
                <CreatableSelect
                  isClearable
                  name="region"
                  options={regions}
                  value={locationForm.region}
                  className="mt-1"
                  classNamePrefix="select"
                  onChange={(selectedOption) =>
                    setLocationForm((prevState) => ({
                      ...prevState,
                      region: selectedOption,
                    }))
                  }
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };
                    queryClient.setQueryData(["regions"], (oldRegions) => [
                      ...(oldRegions || []),
                      newOption,
                    ]);
                    setLocationForm((prevState) => ({
                      ...prevState,
                      region: newOption,
                    }));
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="rolesByHour"
                  className="block text-sm font-medium text-gray-700"
                >
                  Positions By Hour
                </label>
                <CreatableSelect
                  isMulti
                  name="rolesByHour"
                  options={roles?.byHour}
                  value={locationForm.rolesByHour}
                  className="mt-1"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByHour: selectedOptions,
                    }))
                  }
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };
                    queryClient.setQueryData(["roles"], (oldRoles) => ({
                      ...oldRoles,
                      byHour: [...oldRoles.byHour, newOption],
                    }));
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByHour: [...prevState.rolesByHour, newOption],
                    }));
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="rolesByUnit"
                  className="block text-sm font-medium text-gray-700"
                >
                  Positions By Unit
                </label>
                <CreatableSelect
                  isMulti
                  name="rolesByUnit"
                  options={roles?.byUnit}
                  value={locationForm.rolesByUnit}
                  className="mt-1"
                  classNamePrefix="select"
                  onChange={(selectedOptions) =>
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByUnit: selectedOptions,
                    }))
                  }
                  onCreateOption={(inputValue) => {
                    const newOption = { value: inputValue, label: inputValue };
                    queryClient.setQueryData(["roles"], (oldRoles) => ({
                      ...oldRoles,
                      byUnit: [...oldRoles.byUnit, newOption],
                    }));
                    setLocationForm((prevState) => ({
                      ...prevState,
                      rolesByUnit: [...prevState.rolesByUnit, newOption],
                    }));
                  }}
                />
              </div>

              {effectiveUserRole === "owner" ||
              effectiveUserRole === "director" ? (
                <>
                  <div>
                    <label
                      htmlFor="tasks"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Cleaning Tasks
                    </label>
                    <div className="mt-1 space-y-2">
                      {Object.entries(locationForm.tasks).map(
                        ([taskName, taskDetails]) => (
                          <div
                            key={taskName}
                            className="flex items-center space-x-2"
                          >
                            <input
                              type="text"
                              value={taskName}
                              readOnly
                              className="flex-grow rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            />
                            <select
                              value={taskDetails.type}
                              onChange={(e) =>
                                handleTaskChange(
                                  taskName,
                                  "type",
                                  e.target.value
                                )
                              }
                              className="rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                            >
                              <option value="byHour">By Hour</option>
                              <option value="byUnit">By Unit</option>
                            </select>
                            <NumericFormat
                              value={taskDetails.price || ""}
                              onValueChange={(values) =>
                                handleTaskChange(
                                  taskName,
                                  "price",
                                  values.floatValue
                                )
                              }
                              thousandSeparator={true}
                              prefix={"$"}
                              decimalScale={2}
                              fixedDecimalScale={true}
                              allowNegative={false}
                              className="w-24 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                              placeholder="$ Price"
                            />
                            <button
                              onClick={() => handleDeleteTask(taskName)}
                              className="p-2 text-red-600 hover:text-red-800 focus:outline-none"
                            >
                              <FontAwesomeIcon icon={faTimes} />
                            </button>
                          </div>
                        )
                      )}
                    </div>
                    <CreatableSelect
                      name="tasks"
                      options={[...tasks?.byHour, ...tasks?.byUnit]}
                      className="mt-1"
                      classNamePrefix="select"
                      onChange={(newValue) => {
                        if (newValue) {
                          handleTaskChange(newValue.value, "type", "byHour");
                          handleTaskChange(newValue.value, "price", 0);
                        }
                      }}
                      onCreateOption={(inputValue) => {
                        handleTaskChange(inputValue, "type", "byHour");
                        handleTaskChange(inputValue, "price", 0);
                      }}
                    />
                  </div>
                </>
              ) : (
                <></>
              )}

              <div>
                <label
                  htmlFor="services"
                  className="block text-sm font-medium text-gray-700"
                >
                  Services
                </label>
                <div className="mt-1 space-y-2">
                  {Object.entries(locationForm.services).map(
                    ([serviceName, serviceDetails]) => (
                      <div
                        key={serviceName}
                        className="flex items-center space-x-2"
                      >
                        <div className="flex-grow">
                          <input
                            type="text"
                            value={serviceName}
                            readOnly
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        </div>
                        <select
                          value={serviceDetails.type || "byHour"}
                          onChange={(e) =>
                            handleServiceChange(
                              serviceName,
                              "type",
                              e.target.value
                            )
                          }
                          className="w-24 rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                        >
                          <option value="byHour">By Hour</option>
                          <option value="byUnit">By Unit</option>
                        </select>
                        <div className="w-32">
                          <label className="block text-xs font-medium text-gray-500">
                            Client Charge (Retail)
                          </label>
                          <NumericFormat
                            value={serviceDetails.retail_price || ""}
                            onValueChange={(values) =>
                              handleServiceChange(
                                serviceName,
                                "retail_price",
                                values.floatValue
                              )
                            }
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        </div>
                        <div className="w-32">
                          <label className="block text-xs font-medium text-gray-500">
                            Staff Pay (Provider)
                          </label>
                          <NumericFormat
                            value={serviceDetails.provider_rate || ""}
                            onValueChange={(values) =>
                              handleServiceChange(
                                serviceName,
                                "provider_rate",
                                values.floatValue
                              )
                            }
                            thousandSeparator={true}
                            prefix={"$"}
                            decimalScale={2}
                            fixedDecimalScale={true}
                            allowNegative={false}
                            className="w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                          />
                        </div>
                        <div className="flex items-end">
                          <button
                            onClick={() => handleDeleteService(serviceName)}
                            className="p-2 text-red-600 hover:text-red-800 focus:outline-none"
                          >
                            <FontAwesomeIcon icon={faTimes} />
                          </button>
                        </div>
                      </div>
                    )
                  )}
                </div>
                <CreatableSelect
                  name="services"
                  options={[
                    ...(services?.byHour || []),
                    ...(services?.byUnit || []),
                  ]}
                  className="mt-1"
                  classNamePrefix="select"
                  onChange={(newValue) => {
                    if (newValue) {
                      handleServiceChange(newValue.value, "type", "byHour");
                      handleServiceChange(newValue.value, "retail_price", 0);
                      handleServiceChange(newValue.value, "provider_rate", 0);
                    }
                  }}
                  onCreateOption={(inputValue) => {
                    handleServiceChange(inputValue, "type", "byHour");
                    handleServiceChange(inputValue, "retail_price", 0);
                    handleServiceChange(inputValue, "provider_rate", 0);
                  }}
                />
              </div>

              <div>
                <label
                  htmlFor="radius"
                  className="block text-sm font-medium text-gray-700"
                >
                  Radius (meters)
                </label>
                <input
                  type="number"
                  name="radius"
                  value={locationForm.radius}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                  id="radius"
                  placeholder="1000"
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                onClick={closeModal}
                className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50 transition-colors"
              >
                Cancel
              </button>
              <button
                onClick={handleSaveLocation}
                className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-colors"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      )}

      {isRegionModalOpen && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
          <div className="relative top-20 mx-auto p-6 border shadow-lg rounded-lg bg-white w-11/12 max-w-md">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-2xl font-semibold">Edit Region</h3>
              <button
                onClick={() => setIsRegionModalOpen(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <svg
                  className="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Region Name
                </label>
                <input
                  type="text"
                  value={regionForm.name}
                  onChange={(e) =>
                    setRegionForm((prev) => ({ ...prev, name: e.target.value }))
                  }
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Region Manager
                </label>
                <Select
                  value={regionForm.manager}
                  onChange={(selectedOption) =>
                    setRegionForm((prev) => ({
                      ...prev,
                      manager: selectedOption,
                    }))
                  }
                  options={managers}
                  className="mt-1"
                  classNamePrefix="select"
                  isClearable
                  isSearchable
                  placeholder="Select a manager..."
                  noOptionsMessage={() => "No active users found"}
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Add Locations to Region
                </label>
                <select
                  multiple
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
                  value={regionForm.selectedLocations}
                  onChange={(e) => {
                    const selectedOptions = Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    );
                    setRegionForm((prev) => ({
                      ...prev,
                      selectedLocations: selectedOptions,
                    }));
                  }}
                >
                  {locations
                    .filter((loc) => !loc.region)
                    .map((location) => (
                      <option key={location.id} value={location.id}>
                        {location.name}
                      </option>
                    ))}
                </select>
                <p className="mt-1 text-sm text-gray-500">
                  Hold Ctrl/Cmd to select multiple locations
                </p>
              </div>

              <div className="flex justify-end space-x-3 mt-6">
                <button
                  onClick={() => setIsRegionModalOpen(false)}
                  className="px-4 py-2 bg-gray-300 text-gray-800 rounded-md hover:bg-gray-400"
                >
                  Cancel
                </button>
                <button
                  onClick={handleSaveRegion}
                  className="px-4 py-2 bg-blue-600 text-white rounded-md hover:bg-blue-700"
                >
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-4">
        Locations
      </h1>

      <div
        style={{
          borderRadius: "15px",
          overflow: "hidden",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        }}
      >
        <GoogleMap
          mapContainerStyle={mapContainerStyle}
          onLoad={onMapLoad}
          options={mapOptions}
        >
          {filteredLocations.map((location) => (
            <React.Fragment key={location.id}>
              <Marker
                position={{ lat: location.latitude, lng: location.longitude }}
                onClick={() => handleLocationClick(location)}
              />
              <Circle
                center={{ lat: location.latitude, lng: location.longitude }}
                radius={location.radius}
                options={{
                  fillColor:
                    searchTerm === location.name ? "#4299e1" : "#3182ce",
                  fillOpacity: 0.35,
                  strokeColor: "#3182ce",
                  strokeOpacity: 0.8,
                  strokeWeight: 2,
                }}
              />
            </React.Fragment>
          ))}
        </GoogleMap>
      </div>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Search by name, address, region, or manager..."
          value={searchTerm}
          onChange={handleSearchChange}
          className="w-full px-3 py-2 placeholder-gray-300 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-indigo-100 focus:border-indigo-300"
        />
      </div>

      <LocationsList
        locations={filteredLocations}
        onEditLocation={handleEditLocation}
        onLocationClick={handleLocationClick}
        selectedLocation={searchTerm}
        userRole={effectiveUserRole}
        onEditRegion={handleEditRegion}
      />
      {effectiveUserRole !== "director" &&
        effectiveUserRole !== "consultant" && (
          <button
            onClick={handleAddLocation}
            className="fixed bottom-4 right-4 bg-blue-500 hover:bg-blue-700 text-white font-bold rounded-full shadow-lg flex items-center justify-center"
            style={{
              width: "56px",
              height: "56px",
              fontSize: "24px",
              borderRadius: "50%",
              boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)",
            }}
          >
            <FontAwesomeIcon icon={faPlus} />
          </button>
        )}
    </div>
  );
}

export default Locations;
