import React, { useState, useMemo, useEffect, useCallback } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  getPaginationRowModel,
  flexRender,
} from "@tanstack/react-table";
import { auth, db } from "./firebase";
import { collection, getDocs, onSnapshot } from "firebase/firestore";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faLongArrowAltLeft,
  faLongArrowAltRight,
} from "@fortawesome/free-solid-svg-icons";
import { useSearchParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CreatePositionModal from "./components/CreatePositionModal";
import PositionDetails from "./components/PositionDetails";

const fetchPositions = async () => {
  const positionsCollection = collection(db, "positions");
  const positionsSnapshot = await getDocs(positionsCollection);

  // Also fetch locations for mapping IDs to names
  const locationsCollection = collection(db, "locations");
  const locationsSnapshot = await getDocs(locationsCollection);

  // Create a mapping of location IDs to location names
  const locationMap = {};
  locationsSnapshot.docs.forEach((doc) => {
    locationMap[doc.id] = doc.data().name;
  });

  // Include location names in position data
  return positionsSnapshot.docs.map((doc) => {
    const data = doc.data();
    return {
      id: doc.id,
      ...data,
      locationName: locationMap[data.location] || "Unknown Location",
    };
  });
};

const subscribeToPositions = (queryClient) => {
  const positionsCollection = collection(db, "positions");

  return onSnapshot(positionsCollection, async (snapshot) => {
    // Get locations for mapping
    const locationsCollection = collection(db, "locations");
    const locationsSnapshot = await getDocs(locationsCollection);

    const locationMap = {};
    locationsSnapshot.docs.forEach((doc) => {
      locationMap[doc.id] = doc.data().name;
    });

    const positions = snapshot.docs.map((doc) => {
      const data = doc.data();
      return {
        id: doc.id,
        ...data,
        locationName: locationMap[data.location] || "Unknown Location",
      };
    });

    // Update the query cache
    queryClient.setQueryData(["positions"], positions);
  });
};

function PositionSummarySkeleton() {
  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg p-4 shadow-lg border border-gray-700">
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-baseline gap-2">
          <Skeleton
            height={28}
            width={40}
            baseColor="#1F2937"
            highlightColor="#374151"
          />
          <Skeleton
            height={16}
            width={100}
            baseColor="#1F2937"
            highlightColor="#374151"
          />
        </div>
        <div className="flex gap-2">
          <Skeleton
            height={32}
            width={60}
            baseColor="#1F2937"
            highlightColor="#374151"
          />
          <Skeleton
            height={32}
            width={60}
            baseColor="#1F2937"
            highlightColor="#374151"
          />
        </div>
      </div>

      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        {[...Array(4)].map((_, i) => (
          <div
            key={i}
            className="bg-gradient-to-br from-gray-800 to-gray-900 p-3 rounded-lg shadow-md border border-gray-700"
          >
            <Skeleton
              height={16}
              width={60}
              baseColor="#1F2937"
              highlightColor="#374151"
              className="mb-2"
            />
            <Skeleton
              height={24}
              width="100%"
              baseColor="#1F2937"
              highlightColor="#374151"
            />
          </div>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {[...Array(2)].map((_, i) => (
          <div
            key={i}
            className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 rounded-lg shadow-md border border-gray-700"
          >
            <Skeleton
              height={16}
              width={120}
              baseColor="#1F2937"
              highlightColor="#374151"
              className="mb-3"
            />
            <Skeleton
              height={20}
              width="100%"
              baseColor="#1F2937"
              highlightColor="#374151"
              className="mb-2"
            />
            <Skeleton
              height={20}
              width="80%"
              baseColor="#1F2937"
              highlightColor="#374151"
            />
          </div>
        ))}
      </div>
    </div>
  );
}

function PositionSummary({ positions, filterStatus, onFilterChange }) {
  const summary = useMemo(() => {
    if (!positions)
      return {
        total: 0,
        open: 0,
        fullTime: 0,
        partTime: 0,
        permanent: 0,
        temporary: 0,
        requiresEnglish: 0,
        topLocations: [],
      };

    // Get top locations
    const locationCounts = {};
    positions.forEach((position) => {
      if (position.locationName) {
        locationCounts[position.locationName] =
          (locationCounts[position.locationName] || 0) + 1;
      }
    });

    const topLocations = Object.entries(locationCounts)
      .sort((a, b) => b[1] - a[1])
      .slice(0, 3)
      .map(([name, count]) => ({ name, count }));

    return positions.reduce(
      (acc, position) => {
        // Increment total count
        acc.total++;

        // Count open positions
        if (position.status === "open") {
          acc.open++;
        }

        // Count by schedule type
        if (position.scheduleType === "full-time") {
          acc.fullTime++;
        } else if (position.scheduleType === "part-time") {
          acc.partTime++;
        }

        // Count by position type
        if (position.isTemporary) {
          acc.temporary++;
        } else {
          acc.permanent++;
        }

        // Count positions requiring English
        if (position.requiresEnglish) {
          acc.requiresEnglish++;
        }

        return acc;
      },
      {
        total: 0,
        open: 0,
        fullTime: 0,
        partTime: 0,
        permanent: 0,
        temporary: 0,
        requiresEnglish: 0,
        topLocations,
      }
    );
  }, [positions]);

  return (
    <div className="bg-gradient-to-br from-gray-900 to-gray-800 rounded-lg p-4 shadow-lg border border-gray-700">
      {/* Total positions and filter row */}
      <div className="flex justify-between items-center mb-6">
        <div className="flex items-baseline gap-2">
          <h2 className="text-white text-2xl font-bold">{summary.total}</h2>
          <span className="text-blue-300 text-sm">Total Positions</span>
        </div>

        <div className="flex gap-2">
          <button
            onClick={() => onFilterChange(null)}
            className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors ${
              filterStatus === null
                ? "bg-gradient-to-r from-blue-600 to-indigo-600 text-white shadow-md"
                : "bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white"
            }`}
          >
            All
          </button>
          <button
            onClick={() => onFilterChange("open")}
            className={`px-4 py-1.5 rounded-full text-sm font-medium transition-colors ${
              filterStatus === "open"
                ? "bg-gradient-to-r from-emerald-500 to-green-600 text-white shadow-md"
                : "bg-gray-700 text-gray-300 hover:bg-gray-600 hover:text-white"
            }`}
          >
            Open
          </button>
        </div>
      </div>

      {/* Stats row with improved colors */}
      <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-6">
        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-3 rounded-lg flex flex-col shadow-md border border-gray-700">
          <span className="text-blue-300 text-xs mb-1 font-medium">
            Full-time
          </span>
          <div className="flex items-center gap-2">
            <div className="text-xl font-bold text-white">
              {summary.fullTime}
            </div>
            <div className="text-xs text-blue-200">
              {summary.total
                ? Math.round((summary.fullTime / summary.total) * 100)
                : 0}
              %
            </div>
            <div className="flex-grow h-1.5 bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-blue-400 to-blue-600 rounded-full"
                style={{
                  width: `${
                    summary.total ? (summary.fullTime / summary.total) * 100 : 0
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-3 rounded-lg flex flex-col shadow-md border border-gray-700">
          <span className="text-yellow-300 text-xs mb-1 font-medium">
            Part-time
          </span>
          <div className="flex items-center gap-2">
            <div className="text-xl font-bold text-white">
              {summary.partTime}
            </div>
            <div className="text-xs text-yellow-200">
              {summary.total
                ? Math.round((summary.partTime / summary.total) * 100)
                : 0}
              %
            </div>
            <div className="flex-grow h-1.5 bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-yellow-400 to-amber-500 rounded-full"
                style={{
                  width: `${
                    summary.total ? (summary.partTime / summary.total) * 100 : 0
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-3 rounded-lg flex flex-col shadow-md border border-gray-700">
          <span className="text-emerald-300 text-xs mb-1 font-medium">
            Permanent
          </span>
          <div className="flex items-center gap-2">
            <div className="text-xl font-bold text-white">
              {summary.permanent}
            </div>
            <div className="text-xs text-emerald-200">
              {summary.total
                ? Math.round((summary.permanent / summary.total) * 100)
                : 0}
              %
            </div>
            <div className="flex-grow h-1.5 bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-emerald-400 to-emerald-600 rounded-full"
                style={{
                  width: `${
                    summary.total
                      ? (summary.permanent / summary.total) * 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-3 rounded-lg flex flex-col shadow-md border border-gray-700">
          <span className="text-orange-300 text-xs mb-1 font-medium">
            Temporary
          </span>
          <div className="flex items-center gap-2">
            <div className="text-xl font-bold text-white">
              {summary.temporary}
            </div>
            <div className="text-xs text-orange-200">
              {summary.total
                ? Math.round((summary.temporary / summary.total) * 100)
                : 0}
              %
            </div>
            <div className="flex-grow h-1.5 bg-gray-700 rounded-full overflow-hidden">
              <div
                className="h-full bg-gradient-to-r from-orange-400 to-orange-600 rounded-full"
                style={{
                  width: `${
                    summary.total
                      ? (summary.temporary / summary.total) * 100
                      : 0
                  }%`,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>

      {/* Bottom insights row with improved colors */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 rounded-lg shadow-md border border-gray-700">
          <h3 className="text-purple-300 text-sm mb-3 font-medium">
            English Requirements
          </h3>
          <div className="flex items-center justify-between">
            <div>
              <div className="text-2xl font-bold text-white">
                {summary.requiresEnglish}
              </div>
              <div className="text-xs text-purple-200">
                positions require English
              </div>
            </div>
            <div className="w-16 h-16 rounded-full flex items-center justify-center bg-gray-900 shadow-inner">
              <div className="w-14 h-14 rounded-full border-3 border-purple-500 flex items-center justify-center bg-gradient-to-br from-purple-900 to-purple-800">
                <span className="text-lg font-bold text-white">
                  {summary.total
                    ? Math.round(
                        (summary.requiresEnglish / summary.total) * 100
                      )
                    : 0}
                  %
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-gradient-to-br from-gray-800 to-gray-900 p-4 rounded-lg shadow-md border border-gray-700">
          <h3 className="text-indigo-300 text-sm mb-2 font-medium">
            Top Locations
          </h3>
          {summary.topLocations.length > 0 ? (
            <ul className="space-y-2">
              {summary.topLocations.map((loc) => (
                <li
                  key={loc.name}
                  className="flex justify-between items-center"
                >
                  <span className="text-white truncate max-w-[60%]">
                    {loc.name}
                  </span>
                  <span className="text-xs font-medium px-2 py-0.5 rounded-full bg-gradient-to-r from-indigo-700 to-indigo-900 text-indigo-100 shadow-sm">
                    {loc.count} {loc.count === 1 ? "position" : "positions"}
                  </span>
                </li>
              ))}
            </ul>
          ) : (
            <p className="text-indigo-200 text-sm">
              No location data available
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

function TableLoadingSkeleton() {
  return (
    <div className="min-w-full">
      <div className="mb-4">
        {[...Array(8)].map((_, i) => (
          <Skeleton key={i} height={40} className="mb-2" />
        ))}
      </div>
      {[...Array(5)].map((_, rowIndex) => (
        <div key={rowIndex} className="flex space-x-4 mb-4">
          {[...Array(8)].map((_, colIndex) => (
            <Skeleton
              key={colIndex}
              width={colIndex === 6 ? 200 : 100}
              height={30}
            />
          ))}
        </div>
      ))}
    </div>
  );
}

function Positions({ userRole }) {
  const [searchParams] = useSearchParams();
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [sorting, setSorting] = useState([
    {
      id: "status",
      desc: true,
    },
    {
      id: "Submission Date",
      desc: true,
    },
  ]);
  const [searchText, setSearchText] = useState("");
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 50,
  });
  const [statusFilter, setStatusFilter] = useState(null);

  const queryClient = useQueryClient();

  const {
    data: positions,
    isLoading,
    error,
  } = useQuery({
    queryKey: ["positions"],
    queryFn: () => {
      // Initial fetch
      return fetchPositions().then((initialData) => {
        // Set up real-time listener
        subscribeToPositions(queryClient);
        return initialData;
      });
    },
    // Disable automatic refetching since we're using real-time updates
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const [userName, setUserName] = useState(() => {
    const firstName = localStorage.getItem("userFirstName") || "";
    const lastName = localStorage.getItem("userLastName") || "";
    return `${firstName} ${lastName}`.trim();
  });

  useEffect(() => {
    const storedFirstName = localStorage.getItem("userFirstName");
    const storedLastName = localStorage.getItem("userLastName");
    if (storedFirstName || storedLastName) {
      setUserName(`${storedFirstName} ${storedLastName}`.trim());
    }
  }, []);

  useEffect(() => {
    const positionId = searchParams.get("id");
    if (positionId && positions) {
      const position = positions.find((pos) => pos.id === positionId);
      if (position) {
        setSelectedPosition(position);
      }
    }
  }, [searchParams, positions]);

  const handleViewDetails = useCallback((position) => {
    setSelectedPosition(position);
  }, []);

  const filteredPositions = useMemo(() => {
    if (!positions) return [];

    let filtered = positions.filter((position) => {
      // First apply status filter if it exists
      if (statusFilter && position.status !== statusFilter) {
        return false;
      }

      // Then apply search filter
      if (searchText === "") return true;

      const searchLower = searchText.toLowerCase();
      return (
        position.positionId?.toLowerCase().includes(searchLower) ||
        position.title?.toLowerCase().includes(searchLower) ||
        position.description?.toLowerCase().includes(searchLower) ||
        position.createdBy?.toLowerCase().includes(searchLower) ||
        position.type?.toLowerCase().includes(searchLower) ||
        position.status?.toLowerCase().includes(searchLower) ||
        position.priority?.toLowerCase().includes(searchLower) ||
        position.locationName?.toLowerCase().includes(searchLower)
      );
    });

    // Sort logic remains the same
    filtered.sort((a, b) => {
      if (a.status === "closed" && b.status !== "closed") return 1;
      if (a.status !== "closed" && b.status === "closed") return -1;

      const dateA = a.timeOfSubmission?.seconds || 0;
      const dateB = b.timeOfSubmission?.seconds || 0;
      return dateB - dateA;
    });

    return filtered;
  }, [positions, searchText, statusFilter]);

  const columns = useMemo(
    () => [
      {
        header: "Submission Date",
        accessorFn: (row) => {
          if (row.timeOfSubmission && row.timeOfSubmission.seconds) {
            return new Date(row.timeOfSubmission.seconds * 1000);
          }
          return null;
        },
        cell: ({ getValue }) => {
          const date = getValue();
          if (!date) return "N/A";

          const formattedDate = date.toLocaleDateString();
          const formattedTime = date.toLocaleTimeString([], {
            hour: "2-digit",
            minute: "2-digit",
          });

          return (
            <div className="flex flex-col">
              <span className="font-medium text-gray-700">{formattedDate}</span>
              <span className="text-xs text-gray-500">{formattedTime}</span>
            </div>
          );
        },
      },
      {
        header: "Position ID",
        accessorKey: "positionId",
        cell: ({ getValue }) => (
          <span className="font-mono text-gray-800 bg-gray-100 px-2 py-1 rounded">
            {getValue() || "No ID"}
          </span>
        ),
      },
      {
        header: "Title",
        accessorKey: "title",
        cell: ({ getValue }) => (
          <span className="font-medium text-gray-900">
            {getValue() || "Untitled Position"}
          </span>
        ),
      },
      {
        header: "Location",
        accessorKey: "locationName",
        cell: ({ getValue }) => (
          <span className="text-gray-700">{getValue() || "Not specified"}</span>
        ),
      },
      {
        header: "Status",
        accessorKey: "status",
        cell: ({ getValue }) => {
          const status = getValue();
          let bgColor, textColor, text;

          switch (status) {
            case "open":
              bgColor = "bg-green-100";
              textColor = "text-green-800";
              text = "Open";
              break;
            case "pending_approval":
              bgColor = "bg-yellow-100";
              textColor = "text-yellow-800";
              text = "Pending";
              break;
            case "closed":
              bgColor = "bg-gray-200";
              textColor = "text-gray-800";
              text = "Closed";
              break;
            default:
              bgColor = "bg-gray-100";
              textColor = "text-gray-600";
              text = status || "Unknown"; // Handle undefined or other statuses
          }

          return (
            <span
              className={`px-2.5 py-1 inline-flex text-xs leading-5 font-semibold rounded-full ${bgColor} ${textColor}`}
            >
              {text}
            </span>
          );
        },
      },
      {
        header: "Created By",
        accessorKey: "createdBy",
        cell: ({ getValue }) => (
          <div className="flex items-center">
            <div className="w-8 h-8 bg-blue-500 rounded-full flex items-center justify-center text-white font-semibold mr-2">
              {getValue()?.charAt(0)?.toUpperCase() || "?"}
            </div>
            <span className="font-medium text-gray-700">{getValue()}</span>
          </div>
        ),
      },
      {
        header: "Actions",
        cell: ({ row }) => (
          <button
            onClick={() => handleViewDetails(row.original)}
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-3 rounded transition-colors"
          >
            View Details
          </button>
        ),
      },
    ],
    [handleViewDetails]
  );

  const table = useReactTable({
    data: filteredPositions || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    state: {
      sorting,
      pagination,
    },
    onSortingChange: setSorting,
    onPaginationChange: setPagination,
  });

  const [isAdmin] = useState(() => {
    const currentUserUid =
      auth.currentUser?.uid || localStorage.getItem("userUid");
    console.log("Current User UID:", currentUserUid);
    return currentUserUid === "LmnRby2FDeaZmk7dhEZYBpHQoGA2";
  });

  if (isLoading) {
    return (
      <div className="container mx-auto px-4 sm:px-8">
        <div className="py-8">
          <div className="flex justify-between items-center">
            <Skeleton width={200} height={32} />
          </div>

          <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
            <div className="flex flex-col space-y-6">
              <PositionSummarySkeleton />

              <div className="flex flex-col gap-2">
                <Skeleton width={120} height={20} />
                <div className="flex gap-4">
                  <Skeleton height={48} className="flex-1" />
                  <Skeleton width={150} height={48} />
                </div>
              </div>
            </div>
          </div>

          <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
            <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
              <TableLoadingSkeleton />
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (error) {
    console.log("Showing error state:", error);
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );
  }

  return (
    <div className="container mx-auto px-2 sm:px-4">
      <div className="py-6">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            Career Opportunities
          </h1>
        </div>

        <div className="my-4 p-4 sm:p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-col space-y-4 sm:space-y-6">
            <PositionSummary
              positions={positions}
              filterStatus={statusFilter}
              onFilterChange={setStatusFilter}
            />

            <div className="flex flex-col gap-2">
              <div className="flex items-center justify-between">
                <label className="text-sm font-semibold text-gray-300">
                  Search Positions
                </label>
                <button
                  onClick={() => setIsCreateModalOpen(true)}
                  className="bg-green-500 hover:bg-green-600 text-white px-3 py-1 rounded text-sm flex items-center transition-colors duration-200"
                >
                  <FontAwesomeIcon icon={faPlus} className="mr-1" />
                  Create Position
                </button>
              </div>
              <input
                type="text"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
                placeholder="Search positions..."
                className="w-full p-2 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
              />
            </div>
          </div>
        </div>

        <div className="py-2 sm:py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <div className="overflow-x-auto">
              <table className="min-w-full leading-normal text-sm">
                <thead>
                  {table.getHeaderGroups().map((headerGroup) => (
                    <tr key={headerGroup.id}>
                      {headerGroup.headers.map((header) => (
                        <th
                          key={header.id}
                          onClick={header.column.getToggleSortingHandler()}
                          className="px-3 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                        >
                          {flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                          <span>
                            {{
                              asc: " 🔼",
                              desc: " 🔽",
                            }[header.column.getIsSorted()] ?? null}
                          </span>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody>
                  {table.getRowModel().rows.map((row) => (
                    <tr
                      key={row.id}
                      className={`${
                        row.original.status === "closed"
                          ? "opacity-75 bg-gray-100"
                          : "bg-white"
                      } hover:bg-gray-100 transition-colors duration-150`}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="px-3 py-3 border-b border-gray-200 text-sm"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="px-3 py-3 sm:px-5 sm:py-5 bg-gray-800 border-t border-gray-700 flex flex-col xs:flex-row items-center xs:justify-between">
          <div className="inline-flex rounded-full shadow-sm" role="group">
            <button
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              className={`inline-flex items-center px-3 py-2 text-sm font-medium text-blue-300 bg-blue-900 rounded-l-full hover:bg-blue-800 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
                !table.getCanPreviousPage()
                  ? "opacity-50 cursor-not-allowed"
                  : ""
              }`}
            >
              <FontAwesomeIcon icon={faLongArrowAltLeft} className="mr-2" />
              Previous
            </button>
            <button
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              className={`inline-flex items-center px-3 py-2 text-sm font-medium text-blue-300 bg-blue-900 rounded-r-full hover:bg-blue-800 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:outline-none transition-colors duration-200 ${
                !table.getCanNextPage() ? "opacity-50 cursor-not-allowed" : ""
              }`}
            >
              Next
              <FontAwesomeIcon icon={faLongArrowAltRight} className="ml-2" />
            </button>
          </div>
          <span className="text-sm text-gray-300 mt-2 xs:mt-0">
            Page{" "}
            <strong>
              {table.getState().pagination.pageIndex + 1} of{" "}
              {table.getPageCount()}
            </strong>{" "}
            | Total {filteredPositions.length} positions
          </span>
        </div>
      </div>
      {selectedPosition && (
        <PositionDetails
          position={selectedPosition}
          onClose={() => {
            // Ensure we clean up the selected position reference
            setSelectedPosition(null);
          }}
          userName={userName}
          isAdmin={isAdmin}
          userRole={userRole}
        />
      )}
      {isCreateModalOpen && (
        <CreatePositionModal
          isOpen={isCreateModalOpen}
          onClose={() => setIsCreateModalOpen(false)}
          userName={userName}
        />
      )}
    </div>
  );
}

export default Positions;
