import { faCheck, faListUl, faChartBar, faFileExcel, faFilePdf, faSync } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  eachDayOfInterval,
  endOfWeek,
  format,
  parseISO,
  startOfWeek,
  subWeeks,
} from "date-fns";
import { query, where, collection, getDocs } from "firebase/firestore";
import React, { memo, useEffect, useState, useRef } from "react";
import * as XLSX from "xlsx";
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { pdf } from '@react-pdf/renderer';
import TimesheetPDF from "./TimesheetPDF";

const Timesheets = memo(() => {
  const getLastWeekRange = () => {
    const lastWeekStart = format(
      startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
      "yyyy-MM-dd"
    );
    const lastWeekEnd = format(
      endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 0 }),
      "yyyy-MM-dd"
    );
    return { lastWeekStart, lastWeekEnd };
  };

  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("");
  const [startDateInput, setStartDateInput] = useState(getLastWeekRange().lastWeekStart);
  const [endDateInput, setEndDateInput] = useState(getLastWeekRange().lastWeekEnd);
  const [queryStartDate, setQueryStartDate] = useState(getLastWeekRange().lastWeekStart);
  const [queryEndDate, setQueryEndDate] = useState(getLastWeekRange().lastWeekEnd);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("");
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);
  const [showApprovedOnly, setShowApprovedOnly] = useState(true);
  const [showNegativeOnly, setShowNegativeOnly] = useState(false);
  const [showDetailedView, setShowDetailedView] = useState(false);
  const [dateRangeError, setDateRangeError] = useState(null);
  const [fetchCounter, setFetchCounter] = useState(0);

  const locationDropdownRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        locationDropdownRef.current &&
        !locationDropdownRef.current.contains(event.target)
      ) {
        setLocationDropdownOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const calculateHours = (roleChanges, endTimestamp, breakTimestamps) => {
    const roleTimes = roleChanges.map((change) => ({
      role: change.role,
      timestamp:
        change.timestamp instanceof Date
          ? change.timestamp
          : change.timestamp.toDate(),
    }));

    const end =
      endTimestamp instanceof Date
        ? endTimestamp
        : endTimestamp?.toDate() ?? new Date();

    roleTimes.push({ role: null, timestamp: end });

    const roleDurations = {};

    for (let i = 0; i < roleTimes.length - 1; i++) {
      const { role, timestamp } = roleTimes[i];
      const nextTimestamp = roleTimes[i + 1].timestamp;

      let shiftDuration = (nextTimestamp - timestamp) / 1000;

      if (breakTimestamps && breakTimestamps.length > 1) {
        for (let j = 0; j < breakTimestamps.length; j += 2) {
          const breakStart =
            breakTimestamps[j] instanceof Date
              ? breakTimestamps[j]
              : breakTimestamps[j]?.toDate
              ? breakTimestamps[j].toDate()
              : new Date(breakTimestamps[j]);
          const breakEnd =
            (breakTimestamps[j + 1] instanceof Date
              ? breakTimestamps[j + 1]
              : breakTimestamps[j + 1]?.toDate
              ? breakTimestamps[j + 1].toDate()
              : new Date(breakTimestamps[j + 1])) ?? new Date();

          if (breakStart >= timestamp && breakEnd <= nextTimestamp) {
            shiftDuration -= (breakEnd - breakStart) / 1000;
          }
        }
      }

      const shiftHours = shiftDuration / 3600;
      roleDurations[role] = (roleDurations[role] || 0) + shiftHours;
    }

    return roleDurations;
  };

  const fetchShifts = async ({ queryKey }) => {
    try {
      console.log('Starting fetchShifts with queryKey:', queryKey);
      const { startDate, endDate, showApprovedOnly } = queryKey[1];
      const start = new Date(`${startDate}T00:00:00`);
      const end = new Date(`${endDate}T23:59:59`);

      // Validate date range
      if (end < start) {
        throw new Error('End date must be after start date');
      }

      console.log('Fetching shifts between:', start, 'and', end);
      
      const shiftsRef = collection(db, "shifts");
      const queryConstraints = [];

      if (showApprovedOnly) {
        queryConstraints.push(where("approvedBy", "!=", null));
      }

      queryConstraints.push(where("startTimestamp", "<=", end));
      queryConstraints.push(where("endTimestamp", ">=", start));

      const shiftsQuery = query(shiftsRef, ...queryConstraints);
      console.log('Executing Firestore query...');
      const querySnapshot = await getDocs(shiftsQuery);
      console.log('Retrieved', querySnapshot.size, 'documents');

      const shiftsData = {};

      querySnapshot.forEach((doc) => {
        console.log('Processing document:', doc.id);
        const data = doc.data();
        const roleChanges = data.roleChanges || [];
        const shiftStart = data.startTimestamp.toDate();
        const shiftEnd = data.endTimestamp ? data.endTimestamp.toDate() : null;
        const breaks = data.breakTimestamps || [];

        const shiftWithinDateRange =
          (shiftStart >= start && shiftStart <= end) ||
          (shiftEnd >= start && shiftEnd <= end);

        if (shiftWithinDateRange) {
          const employeeKey = data.userId || "missing-userId";
          if (!shiftsData[employeeKey]) {
            shiftsData[employeeKey] = {
              userId: employeeKey,
              firstName: data.firstName || "Unknown",
              lastName: data.lastName || "Unknown",
              positions: new Set(),
              locations: new Set([data.locationName]),
              method: new Set([data.payMethod]),
              rates: new Set([parseFloat(data.rate) || 0]),
              dailyHours: {},
              shifts: {},
            };
          } else {
            shiftsData[employeeKey].locations.add(data.locationName);
            shiftsData[employeeKey].method.add(data.payMethod);
            shiftsData[employeeKey].rates.add(parseFloat(data.rate) || 0);
          }

          const roleDurations = calculateHours(roleChanges, shiftEnd, breaks);
          const dayKey = format(shiftStart, "yyyy-MM-dd");

          if (!shiftsData[employeeKey].dailyHours[dayKey]) {
            shiftsData[employeeKey].dailyHours[dayKey] = {};
          }

          Object.entries(roleDurations).forEach(([role, hours]) => {
            shiftsData[employeeKey].positions.add(role);
            if (!shiftsData[employeeKey].dailyHours[dayKey][role]) {
              shiftsData[employeeKey].dailyHours[dayKey][role] = {};
            }
            shiftsData[employeeKey].dailyHours[dayKey][role][data.locationName] = hours;
          });

          if (shiftEnd) {
            const dayKey = format(shiftStart, "yyyy-MM-dd");
            if (!shiftsData[employeeKey].shifts[dayKey]) {
              shiftsData[employeeKey].shifts[dayKey] = {
                startTimestamp: data.startTimestamp,
                endTimestamp: data.endTimestamp,
                breakTimestamps: data.breakTimestamps || [],
              };
            }
          }
        }
      });

      const aggregatedShiftsData = Object.values(shiftsData)
        .filter((employee) => {
          console.log('Employee data:', employee);
          return employee.positions.size > 0;
        })
        .map((employee) => ({
          ...employee,
          positions: Array.from(employee.positions).join(", "),
          locations: Array.from(employee.locations).join(", "),
          method: Array.from(employee.method),
          rates: Array.from(employee.rates),
        }));

      console.log('Processed data:', aggregatedShiftsData);
      return aggregatedShiftsData;
    } catch (error) {
      console.error("Detailed error in fetchShifts:", {
        error,
        message: error.message,
        code: error.code,
        stack: error.stack,
        name: error.name
      });
      throw error;
    }
  };

  const { data: employees = [], isLoading, error } = useQuery({
    queryKey: [
      "shifts",
      {
        startDate: queryStartDate,
        endDate: queryEndDate,
        showApprovedOnly,
        fetchCounter
      },
    ],
    queryFn: fetchShifts,
    enabled: !!queryStartDate && !!queryEndDate && fetchCounter > 0,
    retry: 1, // Limit retry attempts
    staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    onError: (error) => {
      console.error("React Query error:", error);
    }
  });

  useEffect(() => {
    const fetchLocationsAndPositions = async () => {
      try {
        // Use query dates for the locations/positions fetch
        const locationsSet = new Set();
        const positionsSet = new Set();

        const locationsRef = collection(db, "locations");
        const locationsSnapshot = await getDocs(locationsRef);

        locationsSnapshot.forEach((doc) => {
          const locationData = doc.data();
          if (locationData.name) {
            locationsSet.add(locationData.name);
          }
        });

        const setLocationsArray = Array.from(locationsSet).sort();
        setLocations(setLocationsArray);

        const positionsRef = collection(db, "positions");
        const positionsSnapshot = await getDocs(positionsRef);

        positionsSnapshot.forEach((doc) => {
          const positionData = doc.data();
          if (positionData.name) {
            positionsSet.add(positionData.name);
          }
        });

        const setPositionsArray = Array.from(positionsSet).sort();
        setPositions(setPositionsArray);
      } catch (error) {
        console.error("Error fetching locations and positions:", error);
      }
    };

    fetchLocationsAndPositions().catch(console.error);
  }, []); // Only fetch once on component mount, not when dates change

  const convertHoursToHMS = (hours) => {
    const totalSeconds = Math.floor(hours * 3600);
    const hoursPart = Math.floor(totalSeconds / 3600);
    const minutesPart = Math.floor((totalSeconds % 3600) / 60);
    const secondsPart = totalSeconds % 60;
    return `${hoursPart}:${minutesPart
      .toString()
      .padStart(2, "0")}:${secondsPart.toString().padStart(2, "0")}`;
  };

  const parseHMToDecimal = (hms) => {
    if (typeof hms === "string") {
      const [hours, minutes, seconds] = hms.split(":").map(Number);
      return hours + minutes / 60 + (seconds || 0) / 3600;
    }
    return 0;
  };

  const sumHM = (hoursArray) => {
    return hoursArray.reduce((acc, cur) => {
      if (cur === "Off" || cur === "0:00:00") return acc;
      return acc + parseHMToDecimal(cur);
    }, 0);
  };

  const groupEmployeesByLocationAndPosition = (employees) => {
    const grouped = {};
    employees.forEach((employee) => {
      employee.positions.split(", ").forEach((position) => {
        const employeeLocations = employee.locations.split(", ");
        employeeLocations.forEach((location) => {
          if (
            selectedLocation === "All Locations" ||
            selectedLocation === "" ||
            location === selectedLocation
          ) {
            const key = `${location} - ${position}`;

            if (!grouped[key]) {
              grouped[key] = {
                location: location,
                position: position,
                employees: [],
              };
            }

            const newEmployeeEntry = {
              ...employee,
              dailyHours: {},
              positions: position,
            };

            Object.entries(employee.dailyHours).forEach(([date, roles]) => {
              if (roles[position] && roles[position][location]) {
                newEmployeeEntry.dailyHours[date] = roles[position][location];
              }
            });

            if (Object.keys(newEmployeeEntry.dailyHours).length > 0) {
              grouped[key].employees.push(newEmployeeEntry);
            }
          }
        });
      });
    });
    
    return Object.values(grouped).filter(group => group.employees.length > 0);
  };

  const hasNegativeHours = (employee) => {
    return Object.values(employee.dailyHours).some(hours => 
      Object.values(hours).some(locationHours => 
        Object.values(locationHours).some(h => h < 0)
      )
    );
  };

  const formatPunchDetails = (date, employee) => {
    const dateKey = format(date, "yyyy-MM-dd");
    const shifts = employees.find(emp => emp.userId === employee.userId)?.shifts?.[dateKey];
    
    if (!shifts) return "Off";

    const formatTimestamp = (timestamp) => {
      if (!timestamp) return null;
      const date = timestamp instanceof Date 
        ? timestamp 
        : timestamp?.toDate?.() 
        ? timestamp.toDate() 
        : new Date(timestamp);
      return format(date, "h:mm a");
    };

    return (
      <div className="text-xs space-y-1">
        <div className="flex">
          <span>In: {formatTimestamp(shifts.startTimestamp)}</span>
        </div>
        {shifts.breakTimestamps?.length > 0 && (
          <div className="text-gray-500 flex flex-wrap">
            {chunks(shifts.breakTimestamps, 2).map((break_, idx) => (
              <div key={idx} className="flex">
                {shifts.breakTimestamps.length > 2 && <span>Break {idx + 1}: </span>}
                <span>{formatTimestamp(break_[0])} - {break_[1] ? formatTimestamp(break_[1]) : "ongoing"}</span>
              </div>
            ))}
          </div>
        )}
        {shifts.endTimestamp && (
          <div className="flex">
            <span>Out: {formatTimestamp(shifts.endTimestamp)}</span>
          </div>
        )}
      </div>
    );
  };

  const chunks = (arr, size) => {
    const result = [];
    for (let i = 0; i < arr.length; i += size) {
      result.push(arr.slice(i, i + size));
    }
    return result;
  };

  const renderTimesheets = () => {
    // Filter employees if needed
    const filteredEmployees = showNegativeOnly 
      ? employees.filter(hasNegativeHours) 
      : employees;

    // Group employees by location and position
    const groups = groupEmployeesByLocationAndPosition(filteredEmployees);

    const isAboveTenHours = (hours) => {
      return parseHMToDecimal(hours) > 10;
    };

    const isTotalAboveFortyHours = (hours) => {
      return parseHMToDecimal(hours) > 40;
    };

    const isNegativeHours = (hours) => {
      return parseHMToDecimal(hours) < 0;
    };

    return (
      <div className="p-8 bg-[#1F2937] min-h-screen text-white">
        <div className="mb-8">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-2">
            Timesheets
          </h1>
          <p className="text-gray-400">View and export the timesheets</p>
        </div>

        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 shadow-lg border border-white/20">
          <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
            <div className="min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Quick Select
              </label>
              <div className="flex flex-wrap gap-2">
                <button
                  onClick={() => handleQuickDateSelect('thisWeek')}
                  className="px-2 py-1 bg-white/10 hover:bg-white/20 rounded text-sm text-white transition-colors flex items-center"
                >
                  <span>This Week</span>
                  <FontAwesomeIcon icon={faSync} className="ml-1 w-3 h-3" />
                </button>
                <button
                  onClick={() => handleQuickDateSelect('lastWeek')}
                  className="px-2 py-1 bg-white/10 hover:bg-white/20 rounded text-sm text-white transition-colors flex items-center"
                >
                  <span>Last Week</span>
                  <FontAwesomeIcon icon={faSync} className="ml-1 w-3 h-3" />
                </button>
                <button
                  onClick={() => handleQuickDateSelect('twoWeeksAgo')}
                  className="px-2 py-1 bg-white/10 hover:bg-white/20 rounded text-sm text-white transition-colors flex items-center"
                >
                  <span>Two Weeks Ago</span>
                  <FontAwesomeIcon icon={faSync} className="ml-1 w-3 h-3" />
                </button>
                <button
                  onClick={() => handleQuickDateSelect('thisMonth')}
                  className="px-2 py-1 bg-white/10 hover:bg-white/20 rounded text-sm text-white transition-colors flex items-center"
                >
                  <span>This Month</span>
                  <FontAwesomeIcon icon={faSync} className="ml-1 w-3 h-3" />
                </button>
              </div>
            </div>
            
            <div className="min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Start Date
              </label>
              <input
                type="date"
                value={startDateInput}
                onChange={handleStartDateChange}
                className={`w-full px-4 py-2 rounded-lg bg-white/5 border ${dateRangeError ? 'border-red-500' : 'border-white/20'} text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all`}
              />
            </div>
            <div className="min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                End Date
              </label>
              <input
                type="date"
                value={endDateInput}
                onChange={handleEndDateChange}
                className={`w-full px-4 py-2 rounded-lg bg-white/5 border ${dateRangeError ? 'border-red-500' : 'border-white/20'} text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all`}
              />
            </div>
            
            <div className="min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Custom Range
              </label>
              <button
                onClick={handleFetchTimesheets}
                disabled={dateRangeError || isLoading}
                className={`w-full px-4 py-2 rounded-lg ${
                  dateRangeError || isLoading 
                    ? 'bg-gray-600 cursor-not-allowed' 
                    : 'bg-blue-600 hover:bg-blue-700'
                } text-white transition-colors flex items-center justify-center`}
                style={{ height: '38px' }} /* Match height with date inputs */
              >
                {isLoading ? (
                  <span className="inline-block w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin mr-2"></span>
                ) : (
                  <FontAwesomeIcon icon={faSync} className="mr-2" />
                )}
                {isLoading ? 'Loading...' : 'Load Data'}
              </button>
            </div>
          </div>
          
          {dateRangeError && (
            <div className="text-red-500 text-sm mb-4">
              {dateRangeError}
            </div>
          )}

          <div className="flex flex-wrap items-center gap-4">
            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Location
              </label>
              <div className="relative" ref={locationDropdownRef}>
                <button
                  className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={toggleLocationDropdown}
                >
                  <span>{selectedLocation || "Select Location"}</span>
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {locationDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedLocation("");
                        setLocationDropdownOpen(false);
                      }}
                    >
                      All Locations
                    </button>
                    {locations.map((location) => (
                      <button
                        key={location}
                        className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                        onClick={() => {
                          setSelectedLocation(location);
                          setLocationDropdownOpen(false);
                        }}
                      >
                        {location}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Position
              </label>
              <div className="relative">
                <button
                  className="w-full px-4 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={() => setPositionDropdownOpen(!positionDropdownOpen)}
                >
                  <span>{selectedPosition || "Select Position"}</span>
                  <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {positionDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedPosition("");
                        setPositionDropdownOpen(false);
                      }}
                    >
                      All Positions
                    </button>
                    {positions.map((position) => (
                      <button
                        key={position}
                        className="w-full px-4 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                        onClick={() => {
                          setSelectedPosition(position);
                          setPositionDropdownOpen(false);
                        }}
                      >
                        {position}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Filter
              </label>
              <button
                onClick={() => setShowApprovedOnly(!showApprovedOnly)}
                className={`w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 transition-all ${
                  showApprovedOnly
                    ? "bg-green-500/20 text-green-300 border border-green-500"
                    : "bg-white/5 text-gray-300 border border-white/20"
                }`}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  className={showApprovedOnly ? "opacity-100" : "opacity-50"}
                />
                Approved Only
              </button>
            </div>

            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Negative Hours
              </label>
              <button
                onClick={() => setShowNegativeOnly(!showNegativeOnly)}
                className={`w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 transition-all ${
                  showNegativeOnly
                    ? "bg-purple-500/20 text-purple-300 border border-purple-500"
                    : "bg-white/5 text-gray-300 border border-white/20"
                }`}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  className={showNegativeOnly ? "opacity-100" : "opacity-50"}
                />
                Show Negative Only
              </button>
            </div>

            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                View Mode
              </label>
              <button
                onClick={() => setShowDetailedView(!showDetailedView)}
                className={`w-full px-4 py-2 rounded-lg flex items-center justify-center gap-2 transition-all ${
                  showDetailedView
                    ? "bg-blue-500/20 text-blue-300 border border-blue-500"
                    : "bg-white/5 text-gray-300 border border-white/20"
                }`}
              >
                <FontAwesomeIcon
                  icon={showDetailedView ? faChartBar : faListUl}
                  className="opacity-100"
                />
                {showDetailedView ? "Show Total Hours" : "Show Punch Details"}
              </button>
            </div>

            <div className="flex-1 min-w-[200px]">
              <label className="block text-sm font-medium text-gray-200 mb-2">
                Export
              </label>
              <div className="flex gap-2">
                <button
                  onClick={exportToExcel}
                  className="flex-1 px-4 py-2 rounded-lg bg-green-600 hover:bg-green-700 text-white transition-colors flex items-center justify-center gap-2"
                >
                  <FontAwesomeIcon icon={faFileExcel} />
                  Excel
                </button>
                <PDFDownloadButton />
              </div>
            </div>
          </div>
        </div>

        {groups.map((group, index) => {
          const { location, position, employees: groupEmployees } = group;
          const dateRange = eachDayOfInterval({
            start: parseISO(queryStartDate),
            end: parseISO(queryEndDate),
          });

          const positionTotalHours = groupEmployees.reduce((total, emp) => {
            const dailyHoursArray = dateRange.map((date) => {
              const dateKey = format(date, "yyyy-MM-dd");
              return emp.dailyHours[dateKey]
                ? convertHoursToHMS(emp.dailyHours[dateKey])
                : "0:00:00";
            });
            return total + sumHM(dailyHoursArray);
          }, 0);

          return (
            <div key={`${location}-${position}-${index}`}>
              <h2 className="text-xl font-bold mb-4">
                {location} ({position})
              </h2>
              <table className="min-w-full bg-white rounded-lg mb-6">
                <thead className="bg-gray-200 text-gray-700">
                  <tr>
                    <th className="px-4 py-2">Employee</th>
                    {dateRange.map((date) => (
                      <th
                        key={format(date, "yyyy-MM-dd")}
                        className="px-4 py-2"
                      >
                        {format(date, "EEEE MMM d")}
                      </th>
                    ))}
                    <th className="px-4 py-2">Total</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {groupEmployees.map((employee, empIndex) => (
                    <tr key={employee.userId}>
                      <td
                        className={`border px-4 py-2 ${
                          empIndex === 0 ? "rounded-tl-lg" : ""
                        }`}
                      >
                        {employee.firstName} {employee.lastName}
                      </td>
                      {dateRange.map((date) => {
                        const dateKey = format(date, "yyyy-MM-dd");
                        const hours = employee.dailyHours[dateKey]
                          ? convertHoursToHMS(employee.dailyHours[dateKey])
                          : "Off";
                        return (
                          <td
                            key={dateKey}
                            className={`border px-4 py-2 ${
                              !showDetailedView && isAboveTenHours(hours)
                                ? "bg-amber-500 text-white font-bold animate-pulse"
                                : isNegativeHours(hours)
                                ? "bg-purple-500 text-white font-bold animate-pulse"
                                : ""
                            }`}
                          >
                            {showDetailedView 
                              ? formatPunchDetails(date, employee)
                              : hours}
                          </td>
                        );
                      })}
                      <td
                        className={`border px-4 py-2 ${
                          empIndex === groupEmployees.length - 1
                            ? "rounded-br-lg"
                            : ""
                        } ${
                          isTotalAboveFortyHours(
                            convertHoursToHMS(
                              sumHM(
                                dateRange.map((date) => {
                                  const dateKey = format(date, "yyyy-MM-dd");
                                  return employee.dailyHours[dateKey]
                                    ? convertHoursToHMS(
                                        employee.dailyHours[dateKey]
                                      )
                                    : "0:00:00";
                                })
                              )
                            )
                          )
                            ? "bg-rose-600 text-white font-bold animate-pulse"
                            : ""
                        }`}
                      >
                        {convertHoursToHMS(
                          sumHM(
                            dateRange.map((date) => {
                              const dateKey = format(date, "yyyy-MM-dd");
                              return employee.dailyHours[dateKey]
                                ? convertHoursToHMS(
                                    employee.dailyHours[dateKey]
                                  )
                                : "0:00:00";
                            })
                          )
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
                <tfoot className="bg-gray-100 text-gray-700">
                  <tr>
                    <th className="px-4 py-2 text-left">Total</th>
                    <th colSpan={dateRange.length + 1} className="px-4 py-2 text-left">
                      {convertHoursToHMS(positionTotalHours)}
                    </th>
                  </tr>
                </tfoot>
              </table>
            </div>
          );
        })}
      </div>
    );
  };

  const exportToExcel = () => {
    const wb = XLSX.utils.book_new();
    
    // First sheet - Total Hours
    const totalHoursData = [];
    const groupedPositions = groupEmployeesByLocationAndPosition(employees);
    
    // Only proceed if there are groups with data
    if (groupedPositions.length === 0) {
      console.log("No data to export");
      return;
    }

    const dateRange = eachDayOfInterval({
      start: parseISO(queryStartDate),
      end: parseISO(queryEndDate),
    });
    const dateRangeHeaders = dateRange.map((date) => format(date, "EEEE MMM d"));

    // Track all group totals for the summary sheet
    const allGroupTotals = [];

    groupedPositions.forEach((group) => {
      // Add group header
      totalHoursData.push([`${group.location} (${group.position})`]);
      totalHoursData.push(["Employee", ...dateRangeHeaders, "Total"]);

      // Add employee rows
      group.employees.forEach((employee) => {
        const dailyHours = dateRange.map((date) => {
          const dateKey = format(date, "yyyy-MM-dd");
          return employee.dailyHours[dateKey]
            ? convertHoursToHMS(employee.dailyHours[dateKey])
            : "Off";
        });

        // Calculate employee total
        const totalHours = convertHoursToHMS(
          sumHM(dailyHours.map(hours => hours === "Off" ? "0:00:00" : hours))
        );

        totalHoursData.push([
          `${employee.firstName} ${employee.lastName}`,
          ...dailyHours,
          totalHours
        ]);
      });

      // Calculate group total hours
      const positionTotalHours = group.employees.reduce((total, emp) => {
        const dailyHoursArray = dateRange.map((date) => {
          const dateKey = format(date, "yyyy-MM-dd");
          return emp.dailyHours[dateKey]
            ? convertHoursToHMS(emp.dailyHours[dateKey])
            : "0:00:00";
        });
        return total + sumHM(dailyHoursArray);
      }, 0);

      // Store group totals for summary sheet
      allGroupTotals.push({
        location: group.location,
        position: group.position,
        totalHours: positionTotalHours
      });

      // Add group total row
      totalHoursData.push([
        "Total", 
        ...Array(dateRange.length).fill(""),
        convertHoursToHMS(positionTotalHours)
      ]);

      // Add empty row between groups
      totalHoursData.push([]);
    });

    const wsHours = XLSX.utils.aoa_to_sheet(totalHoursData);
    XLSX.utils.book_append_sheet(wb, wsHours, "Total Hours");

    // Second sheet - Punch Details
    const punchDetailsData = [];
    groupedPositions.forEach((group) => {
      punchDetailsData.push([`${group.location} (${group.position})`]);
      punchDetailsData.push(["Employee", ...dateRangeHeaders]);

      group.employees.forEach((emp) => {
        const punchData = dateRange.map((date) => {
          const dateKey = format(date, "yyyy-MM-dd");
          const shifts = employees.find(e => e.userId === emp.userId)?.shifts?.[dateKey];
          
          if (!shifts) return "Off";

          const formatTime = (timestamp) => {
            if (!timestamp) return "";
            const date = timestamp instanceof Date 
              ? timestamp 
              : timestamp?.toDate?.() 
              ? timestamp.toDate() 
              : new Date(timestamp);
            return format(date, "h:mm a");
          };

          const breakTimes = shifts.breakTimestamps?.length > 0
            ? chunks(shifts.breakTimestamps, 2)
              .map((break_, idx) => 
                `Break ${idx + 1}: ${formatTime(break_[0])} - ${break_[1] ? formatTime(break_[1]) : "ongoing"}`)
              .join("\n")
            : "";

          return [
            `In: ${formatTime(shifts.startTimestamp)}`,
            breakTimes,
            `Out: ${formatTime(shifts.endTimestamp)}`
          ].filter(Boolean).join("\n");
        });

        punchDetailsData.push([
          `${emp.firstName} ${emp.lastName}`,
          ...punchData
        ]);
      });

      punchDetailsData.push([]);
    });

    const wsPunchDetails = XLSX.utils.aoa_to_sheet(punchDetailsData);
    
    // Set column widths for better readability
    const colWidths = [{ wch: 20 }];
    dateRangeHeaders.forEach(() => colWidths.push({ wch: 25 }));
    wsPunchDetails['!cols'] = colWidths;

    // Enable text wrapping for cells
    const range = XLSX.utils.decode_range(wsPunchDetails['!ref']);
    for (let R = range.s.r; R <= range.e.r; ++R) {
      for (let C = range.s.c; C <= range.e.c; ++C) {
        const cell_address = { c: C, r: R };
        const cell_ref = XLSX.utils.encode_cell(cell_address);
        if (!wsPunchDetails[cell_ref]) continue;
        wsPunchDetails[cell_ref].s = { alignment: { wrapText: true } };
      }
    }

    XLSX.utils.book_append_sheet(wb, wsPunchDetails, "Punch Details");

    // Third sheet - Summary of Totals
    const summaryData = [
      ["Location", "Position", "Total Hours"],
      ...allGroupTotals.map(group => [
        group.location,
        group.position,
        convertHoursToHMS(group.totalHours)
      ]),
      ["", "", ""],
      [
        "Grand Total",
        "",
        convertHoursToHMS(allGroupTotals.reduce((sum, group) => sum + group.totalHours, 0))
      ]
    ];

    const wsSummary = XLSX.utils.aoa_to_sheet(summaryData);
    XLSX.utils.book_append_sheet(wb, wsSummary, "Summary");

    // Generate Excel file name based on date range
    const fileName = `Timesheets_${format(parseISO(queryStartDate), "yyyy-MM-dd")}_to_${format(
      parseISO(queryEndDate),
      "yyyy-MM-dd"
    )}.xlsx`;

    XLSX.writeFile(wb, fileName);
  };

  const toggleLocationDropdown = () => {
    setLocationDropdownOpen(!locationDropdownOpen);
    setPositionDropdownOpen(false);
  };

  const filteredEmployees = employees.filter((employee) => {
    const employeeLocations = employee.locations.split(", ");
    const locationMatch = selectedLocation === "All Locations" ||
      selectedLocation === "" ||
      employeeLocations.includes(selectedLocation);
    
    return locationMatch && (!showNegativeOnly || hasNegativeHours(employee));
  });

  const formatPDFFilename = () => {
    const timestamp = format(new Date(), "yyyy-MM-dd_HH-mm");
    const locationStr = selectedLocation || "all-locations";
    return `timesheet_${locationStr}_${queryStartDate}_to_${queryEndDate}_${timestamp}.pdf`;
  };

  const PDFDownloadButton = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [pdfError, setPdfError] = useState(null);
    const [isPdfReady, setIsPdfReady] = useState(false);
    const [pdfBlob, setPdfBlob] = useState(null);

    const generatePDF = async () => {
      try {
        setPdfError(null);
        setIsPdfReady(false);
        
        const groups = groupEmployeesByLocationAndPosition(filteredEmployees);
        if (!groups || groups.length === 0) {
          throw new Error('No data available for PDF generation');
        }

        const blob = await pdf(
          <TimesheetPDF
            groups={groups}
            startDate={queryStartDate}
            endDate={queryEndDate}
            showDetailedView={showDetailedView}
          />
        ).toBlob();

        setPdfBlob(blob);
        setIsPdfReady(true);
      } catch (error) {
        console.error("PDF Generation Error:", error);
        setPdfError(error);
      }
    };

    const handlePDFDownload = () => {
      if (!pdfBlob) return;

      const url = URL.createObjectURL(pdfBlob);
      const link = document.createElement('a');
      link.href = url;
      link.download = formatPDFFilename();
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
      setIsModalOpen(false); // Close modal after download
    };

    const handleModalOpen = () => {
      setIsModalOpen(true);
      generatePDF(); // Start generating PDF when modal opens
    };

    const handleModalClose = () => {
      setIsModalOpen(false);
      setPdfError(null);
      setIsPdfReady(false);
      setPdfBlob(null);
    };

    return (
      <>
        <button
          onClick={handleModalOpen}
          className="flex-1 px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white transition-colors flex items-center justify-center gap-2"
        >
          <FontAwesomeIcon icon={faFilePdf} />
          Generate PDF
        </button>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
            <div className="bg-gray-800 rounded-lg p-6 max-w-sm w-full mx-4 border border-gray-700">
              <h3 className="text-xl font-bold text-white mb-4">
                PDF Generation
              </h3>
              
              {pdfError ? (
                <div className="mb-4">
                  <p className="text-red-400 mb-4">Error generating PDF. Please try again.</p>
                  <button
                    onClick={generatePDF}
                    className="w-full px-4 py-2 rounded-lg bg-red-500 hover:bg-red-600 text-white transition-colors"
                  >
                    Retry
                  </button>
                </div>
              ) : !isPdfReady ? (
                <div className="flex flex-col items-center justify-center mb-4">
                  <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-white mb-4"></div>
                  <p className="text-gray-300">Generating PDF...</p>
                </div>
              ) : (
                <div className="mb-4">
                  <p className="text-green-400 mb-4">PDF is ready!</p>
                  <button
                    onClick={handlePDFDownload}
                    className="w-full px-4 py-2 rounded-lg bg-green-500 hover:bg-green-600 text-white transition-colors mb-2"
                  >
                    Download PDF
                  </button>
                </div>
              )}

              <button
                onClick={handleModalClose}
                className="w-full px-4 py-2 rounded-lg bg-gray-700 hover:bg-gray-600 text-white transition-colors"
              >
                Close
              </button>
            </div>
          </div>
        )}
      </>
    );
  };

  // Handle start date changes with validation
  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    // If end date exists, validate the range
    if (endDateInput) {
      const start = new Date(`${newStartDate}T00:00:00`);
      const end = new Date(`${endDateInput}T23:59:59`);
      if (end < start) {
        // Set error message
        setDateRangeError("End date must be after start date");
        // Still update the date input field
        setStartDateInput(newStartDate);
        return;
      }
    }
    // Valid date, clear error
    setDateRangeError(null);
    setStartDateInput(newStartDate);
  };

  // Handle end date changes with validation
  const handleEndDateChange = (e) => {
    const newEndDate = e.target.value;
    // If start date exists, validate the range
    if (startDateInput) {
      const start = new Date(`${startDateInput}T00:00:00`);
      const end = new Date(`${newEndDate}T23:59:59`);
      if (end < start) {
        // Set error message
        setDateRangeError("End date must be after start date");
        // Still update the date input field
        setEndDateInput(newEndDate);
        return;
      }
    }
    // Valid date, clear error
    setDateRangeError(null);
    setEndDateInput(newEndDate);
  };

  // Function to handle manual fetching with button click
  const handleFetchTimesheets = () => {
    const start = new Date(`${startDateInput}T00:00:00`);
    const end = new Date(`${endDateInput}T23:59:59`);
    
    if (end < start) {
      setDateRangeError("End date must be after start date");
      return;
    }
    
    // Clear any errors
    setDateRangeError(null);
    // Set query dates - this will update the queryKey for React Query
    setQueryStartDate(startDateInput);
    setQueryEndDate(endDateInput);
    // Increment fetch counter to trigger a new query
    setFetchCounter(prev => prev + 1);
  };

  // Function to handle quick selection of common date ranges
  const handleQuickDateSelect = (range) => {
    let newStartDate, newEndDate;
    const today = new Date();
    
    switch(range) {
      case 'thisWeek':
        newStartDate = format(startOfWeek(today, { weekStartsOn: 0 }), "yyyy-MM-dd");
        newEndDate = format(endOfWeek(today, { weekStartsOn: 0 }), "yyyy-MM-dd");
        break;
      case 'lastWeek':
        const lastWeekRange = getLastWeekRange();
        newStartDate = lastWeekRange.lastWeekStart;
        newEndDate = lastWeekRange.lastWeekEnd;
        break;
      case 'twoWeeksAgo':
        // Calculate date range for two weeks ago
        const twoWeeksAgo = subWeeks(today, 2);
        newStartDate = format(startOfWeek(twoWeeksAgo, { weekStartsOn: 0 }), "yyyy-MM-dd");
        newEndDate = format(endOfWeek(twoWeeksAgo, { weekStartsOn: 0 }), "yyyy-MM-dd");
        break;
      case 'thisMonth':
        const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0);
        newStartDate = format(thisMonthStart, "yyyy-MM-dd");
        newEndDate = format(thisMonthEnd, "yyyy-MM-dd");
        break;
      case 'lastMonth':
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        const lastMonthEnd = new Date(today.getFullYear(), today.getMonth(), 0);
        newStartDate = format(lastMonthStart, "yyyy-MM-dd");
        newEndDate = format(lastMonthEnd, "yyyy-MM-dd");
        break;
      default:
        return;
    }
    
    // Update date inputs
    setStartDateInput(newStartDate);
    setEndDateInput(newEndDate);
    
    // Clear any errors
    setDateRangeError(null);
    
    // Update query dates to trigger data loading
    setQueryStartDate(newStartDate);
    setQueryEndDate(newEndDate);
    
    // Increment fetch counter to trigger a new query
    setFetchCounter(prev => prev + 1);
  };

  // Load data when component mounts - consolidated initialization
  useEffect(() => {
    // Initial fetch - uses the default date range
    setQueryStartDate(getLastWeekRange().lastWeekStart);
    setQueryEndDate(getLastWeekRange().lastWeekEnd);
    setFetchCounter(1);
    
    // Only run this once when the component mounts
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="bg-[#1F2937] min-h-screen text-white p-8">
      <div className="flex justify-center mb-6"></div>
      {error ? (
        <div className="p-8 bg-red-500/10 border border-red-500 rounded-lg">
          <h2 className="text-red-500 font-bold mb-2">Error Loading Timesheets</h2>
          <p className="text-red-400">{error.message}</p>
          <button 
            onClick={() => window.location.reload()}
            className="mt-4 px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600"
          >
            Retry
          </button>
        </div>
      ) : isLoading ? (
        <div className="p-8">
          {/* Title and description skeleton */}
          <div className="mb-8">
            <Skeleton height={24} width={120} className="mb-2" />
            <Skeleton height={16} width={240} />
          </div>

          {/* Filter controls skeleton */}
          <div className="bg-white/10 backdrop-blur-sm rounded-lg p-6 mb-8 shadow-lg border border-white/20">
            {/* Date filters and quick select */}
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 mb-6">
              {/* Quick Select */}
              <div>
                <Skeleton height={16} width={100} className="mb-2" />
                <div className="flex flex-wrap gap-2">
                  {[1, 2, 3, 4].map((i) => (
                    <Skeleton key={i} height={32} width={90} />
                  ))}
                </div>
              </div>
              
              {/* Start Date */}
              <div>
                <Skeleton height={16} width={80} className="mb-2" />
                <Skeleton height={38} />
              </div>
              
              {/* End Date */}
              <div>
                <Skeleton height={16} width={80} className="mb-2" />
                <Skeleton height={38} />
              </div>
              
              {/* Load Data Button */}
              <div>
                <Skeleton height={16} width={100} className="mb-2" />
                <Skeleton height={38} />
              </div>
            </div>

            {/* Location, Position, View and Export Options */}
            <div className="flex flex-wrap items-center gap-4">
              {/* Location Dropdown */}
              <div className="flex-1 min-w-[200px]">
                <Skeleton height={16} width={80} className="mb-2" />
                <Skeleton height={38} />
              </div>
              
              {/* Position Dropdown */}
              <div className="flex-1 min-w-[200px]">
                <Skeleton height={16} width={80} className="mb-2" />
                <Skeleton height={38} />
              </div>
              
              {/* View Options */}
              <div className="flex-1 min-w-[200px]">
                <Skeleton height={16} width={80} className="mb-2" />
                <div className="flex gap-2">
                  <Skeleton height={38} />
                  <Skeleton height={38} />
                </div>
              </div>
              
              {/* Export Options */}
              <div className="flex-1 min-w-[200px]">
                <Skeleton height={16} width={80} className="mb-2" />
                <div className="flex gap-2">
                  <Skeleton height={38} />
                  <Skeleton height={38} />
                </div>
              </div>
            </div>
          </div>

          {/* Timesheet tables skeleton */}
          {[1, 2].map((groupIndex) => (
            <div key={groupIndex} className="mb-8">
              {/* Location and position header */}
              <Skeleton height={28} width={250} className="mb-4" />
              
              {/* Table skeleton */}
              <div className="bg-white rounded-lg overflow-hidden mb-6">
                {/* Table header */}
                <div className="bg-gray-200 p-2">
                  <div className="flex">
                    <Skeleton height={24} width={120} className="mr-4" />
                    {/* Date columns */}
                    {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                      <Skeleton key={day} height={24} width={120} className="mr-4" />
                    ))}
                    <Skeleton height={24} width={80} />
                  </div>
                </div>
                
                {/* Table body */}
                <div className="bg-white p-2">
                  {[1, 2, 3].map((empIndex) => (
                    <div key={empIndex} className="flex mb-4">
                      <Skeleton height={24} width={120} className="mr-4" />
                      {/* Daily hours */}
                      {[1, 2, 3, 4, 5, 6, 7].map((day) => (
                        <Skeleton key={day} height={24} width={120} className="mr-4" />
                      ))}
                      <Skeleton height={24} width={80} />
                    </div>
                  ))}
                </div>
                
                {/* Table footer/total */}
                <div className="bg-gray-200 p-2">
                  <div className="flex justify-between">
                    <Skeleton height={24} width={120} />
                    <Skeleton height={24} width={100} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        renderTimesheets()
      )}
    </div>
  );
});

export default Timesheets;
