import React, { useRef, useEffect, useState } from "react";
import { collection, getDocs, query, where } from "firebase/firestore";
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Confetti from 'react-confetti';
import { 
  faCakeCandles, 
  faGift, 
  faCalendarDay,
  faXmark
} from '@fortawesome/free-solid-svg-icons';

const fetchBirthdays = async () => {
  const usersRef = collection(db, "users");
  const q = query(usersRef, where("status", "==", "Active"));
  const snapshot = await getDocs(q);
  const users = snapshot.docs.map((doc) => ({ id: doc.id, ...doc.data() }));

  const today = new Date();
  today.setHours(0, 0, 0, 0);

  const birthdayData = users.map((user) => {
    const birthDate = new Date(user.birthDate);
    const normalizedBirthDate = new Date(
      birthDate.getUTCFullYear(),
      birthDate.getUTCMonth(),
      birthDate.getUTCDate()
    );

    const birthdayThisYear = new Date(
      today.getFullYear(),
      normalizedBirthDate.getMonth(),
      normalizedBirthDate.getDate()
    );

    let diffDays = Math.ceil(
      (birthdayThisYear - today) / (1000 * 60 * 60 * 24)
    );
    if (diffDays < -5) diffDays += 365;

    return {
      ...user,
      diffDays,
    };
  });

  const sortedBirthdays = birthdayData
    .filter((user) => user.diffDays >= -5 && user.diffDays <= 5)
    .sort((a, b) => a.diffDays - b.diffDays);

  return sortedBirthdays;
};

const BirthdayFlyout = ({ closeFlyout }) => {
  const flyoutRef = useRef(null);
  const [windowDimensions, setWindowDimensions] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  });
  const [showConfetti, setShowConfetti] = useState(false);
  const hasRunConfettiRef = useRef(false);

  useEffect(() => {
    const handleResize = () => {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (flyoutRef.current && !flyoutRef.current.contains(event.target)) {
        closeFlyout();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [closeFlyout]);

  const {
    data: birthdays = [],
    isLoading,
    error,
  } = useQuery({
    queryKey: ["birthdays"],
    queryFn: fetchBirthdays,
    staleTime: 24 * 60 * 60 * 1000,
    cacheTime: 7 * 24 * 60 * 60 * 1000,
  });

  const todayBirthdays = birthdays.filter((user) => user.diffDays === 0);
  const upcomingBirthdays = birthdays
    .filter((user) => user.diffDays > 0)
    .reverse();
  const recentBirthdays = birthdays
    .filter((user) => user.diffDays < 0)
    .reverse();

  // Effect that runs once data is available
  useEffect(() => {
    // Check if we have birthdays data and confetti hasn't been shown yet
    if (birthdays.length > 0 && !hasRunConfettiRef.current && todayBirthdays.length > 0) {
      // Mark that we've shown confetti for this session
      hasRunConfettiRef.current = true;
      
      // Show the confetti
      setShowConfetti(true);
      
      // Set timer to hide confetti after 6 seconds
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 6000);
      
      return () => clearTimeout(timer);
    }
  }, [birthdays, todayBirthdays]);

  // Reset the flag when component unmounts
  useEffect(() => {
    return () => {
      hasRunConfettiRef.current = false;
    };
  }, []);

  const formatDayText = (days) => (days === 1 || days === -1 ? "day" : "days");

  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <>
      {showConfetti && (
        <Confetti
          width={windowDimensions.width}
          height={windowDimensions.height}
          recycle={false}
          numberOfPieces={200}
          gravity={0.1}
          colors={['#fd5949', '#ffbe3d', '#4968fd', '#ff49db', '#50e991', '#9b19f5', '#ffa300']}
          style={{ position: 'fixed', top: 0, left: 0, zIndex: 100 }}
        />
      )}
      <div
        ref={flyoutRef}
        className="absolute right-0 mt-2 bg-white rounded-lg shadow-lg overflow-hidden z-50"
        style={{ width: "auto", minWidth: "25rem", maxWidth: "30rem" }}
      >
        <div className="relative p-6" style={{ maxHeight: "80vh", overflowY: "auto" }}>
          <button
            onClick={closeFlyout}
            className="absolute top-2 right-2 text-gray-400 hover:text-gray-600 transition-colors duration-300"
            aria-label="Close"
          >
            <FontAwesomeIcon icon={faXmark} className="h-5 w-5" />
          </button>

          {isLoading ? (
            <div>
              {[...Array(3)].map((_, index) => (
                <div key={index} className="mb-4">
                  <div className="flex items-center mb-2">
                    <Skeleton width={200} height={24} className="mb-2" />
                  </div>
                  {[...Array(2)].map((_, i) => (
                    <div key={i} className="mb-1">
                      <Skeleton width={300} height={20} />
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : error ? (
            <div className="text-red-500 text-center">
              Error loading birthdays
            </div>
          ) : (
            <>
              {upcomingBirthdays.length > 0 && (
                <div className="mb-4">
                  <h2 className="text-xl font-bold mb-2 text-indigo-600 border-b pb-1">
                    <FontAwesomeIcon icon={faCalendarDay} className="mr-2" />
                    Upcoming Birthdays
                  </h2>
                  <ul>
                    {upcomingBirthdays.map((user) => (
                      <li
                        key={user.id}
                        className="text-sm mb-1 flex items-center"
                      >
                        <span className="font-semibold text-gray-700">
                          {`${user.firstName} ${user.lastName}`}
                        </span>{" "}
                        <span className="text-gray-500 ml-2">
                          {`will be `}
                          <span className="font-bold text-indigo-600">
                            {calculateAge(user.birthDate) + 1}
                          </span>
                          {` in ${user.diffDays} ${formatDayText(user.diffDays)}`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {todayBirthdays.length > 0 && (
                <div className="mb-4">
                  <h2 className="text-xl font-bold mb-2 text-green-600 border-b pb-1">
                    <FontAwesomeIcon icon={faCakeCandles} className="mr-2" />
                    Today's Birthday
                  </h2>
                  <ul>
                    {todayBirthdays.map((user) => (
                      <li
                        key={user.id}
                        className="text-sm mb-1 flex items-center"
                      >
                        <span className="font-semibold text-gray-700">
                          {`${user.firstName} ${user.lastName}`}
                        </span>{" "}
                        <span className="text-gray-500 ml-2">
                          {`is `}
                          <span className="font-bold text-green-600">
                            {calculateAge(user.birthDate)}
                          </span>
                          {` today`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
              {recentBirthdays.length > 0 && (
                <div className="mb-4">
                  <h2 className="text-xl font-bold mb-2 text-pink-600 border-b pb-1">
                    <FontAwesomeIcon icon={faGift} className="mr-2" />
                    Recent Birthdays
                  </h2>
                  <ul>
                    {recentBirthdays.map((user) => (
                      <li
                        key={user.id}
                        className="text-sm mb-1 flex items-center"
                      >
                        <span className="font-semibold text-gray-700">
                          {`${user.firstName} ${user.lastName}`}
                        </span>{" "}
                        <span className="text-gray-500 ml-2">
                          {`turned `}
                          <span className="font-bold text-pink-600">
                            {calculateAge(user.birthDate)}
                          </span>
                          {` ${Math.abs(user.diffDays)} ${formatDayText(user.diffDays)} ago`}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default BirthdayFlyout;