import React, { useEffect } from "react";

const UniquePunch = () => {
  useEffect(() => {
    // Redirect to the new website
    window.location.href = "https://uniquepunch.app";
  }, []);

  // Return a minimal loading state while the redirect happens
  return (
    <div className="bg-[#111111] text-white min-h-screen font-['Inter',sans-serif] flex items-center justify-center">
      <div className="text-center">
        <h1 className="text-2xl font-bold mb-4">
          UNIQUE<span className="text-cyan-400">PUNCH</span>
        </h1>
        <p className="text-gray-400">Redirecting to uniquepunch.app...</p>
      </div>
    </div>
  );
};

export default UniquePunch;

