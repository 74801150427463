import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import {
  useReactTable,
  getCoreRowModel,
  getSortedRowModel,
  flexRender,
  getExpandedRowModel,
} from "@tanstack/react-table";
import { db } from "./firebase";
import {
  collection,
  doc,
  getDoc,
  query,
  where,
  onSnapshot,
} from "firebase/firestore";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronDown,
  faChevronUp,
} from "@fortawesome/free-solid-svg-icons";
import { PDFDownloadLink } from "@react-pdf/renderer";
import VinCollectPDF from "./components/VinCollectPDF";
import ReactDOM from "react-dom";
import { format } from "date-fns";

const VALID_COLLECT_ROLES = ["Vin Collect", "Tech Collect - SO"];

const createTaskWithUser = (
  docSnapshot,
  taskData,
  userData,
  shifts,
  tasksInShift
) => {
  const taskTime = taskData.startTimestamp.toDate();
  const matchingShift = shifts.find((shift) => {
    const shiftStart = shift.startTimestamp.toDate();
    const shiftEnd = shift.endTimestamp
      ? shift.endTimestamp.toDate()
      : new Date();

    if (taskTime >= shiftStart && taskTime <= shiftEnd) {
      const roleChanges = [...shift.roleChanges].sort(
        (a, b) => a.timestamp.seconds - b.timestamp.seconds
      );

      const roleAtTaskTime = roleChanges.reduce((currentRole, change) => {
        const changeTime = change.timestamp.toDate();
        return changeTime <= taskTime ? change.role : currentRole;
      }, roleChanges[0].role);

      return VALID_COLLECT_ROLES.includes(roleAtTaskTime);
    }
    return false;
  });

  const task = {
    id: docSnapshot.id,
    ...taskData,
    user: userData
      ? {
          id: taskData.userId,
          firstName: userData.firstName,
          lastName: userData.lastName,
          rate: userData.rate,
          salary: userData.salary,
        }
      : null,
    shift: matchingShift,
  };

  return {
    ...task,
    ...calculateMetrics(task, matchingShift, tasksInShift),
  };
};

const calculateMetrics = (task, shift, tasksInShift) => {
  if (!shift?.startTimestamp) {
    return {
      workedHours: 0,
      revenue: task.price || 0,
      payroll: 0,
      grossProfit: 0,
      grossProfitPercentage: 0,
      tasksPerHour: 0,
      revenuePerHour: 0,
      profitPerHour: 0,
    };
  }

  const shiftStart = shift.startTimestamp.toDate();
  const shiftEnd = shift.endTimestamp
    ? shift.endTimestamp.toDate()
    : new Date();

  // Calculate total milliseconds only for "Vin Collect" periods
  let totalMilliseconds = 0;

  if (shift.roleChanges && shift.roleChanges.length > 0) {
    const roleChanges = [...shift.roleChanges].sort(
      (a, b) => a.timestamp.seconds - b.timestamp.seconds
    );

    let lastVinCollectStart = null;
    let currentRole = roleChanges[0].role;

    // Add shift start if first role is a valid collect role
    if (VALID_COLLECT_ROLES.includes(currentRole)) {
      lastVinCollectStart = shiftStart;
    }

    // Process role changes
    for (let i = 1; i < roleChanges.length; i++) {
      const changeTime = roleChanges[i].timestamp.toDate();

      if (VALID_COLLECT_ROLES.includes(currentRole) && lastVinCollectStart) {
        totalMilliseconds += changeTime - lastVinCollectStart;
        lastVinCollectStart = null;
      } else if (VALID_COLLECT_ROLES.includes(roleChanges[i].role)) {
        lastVinCollectStart = changeTime;
      }

      currentRole = roleChanges[i].role;
    }

    // Handle final period if ended in a valid collect role
    if (VALID_COLLECT_ROLES.includes(currentRole) && lastVinCollectStart) {
      totalMilliseconds += shiftEnd - lastVinCollectStart;
    }
  } else {
    // If no role changes, assume entire shift was a valid collect role
    // This might need adjustment if a shift *could* exist without roleChanges but not be a collect role.
    // Assuming shifts always have roleChanges or are implicitly collect roles if not.
    totalMilliseconds = shiftEnd - shiftStart;
  }

  // Subtract breaks if any
  if (shift.breakTimestamps && shift.breakTimestamps.length > 1) {
    for (let i = 0; i < shift.breakTimestamps.length; i += 2) {
      const breakStart = new Date(shift.breakTimestamps[i]);
      const breakEnd = shift.breakTimestamps[i + 1]
        ? new Date(shift.breakTimestamps[i + 1])
        : new Date();

      if (breakStart >= shiftStart && breakEnd <= shiftEnd) {
        totalMilliseconds -= breakEnd - breakStart;
      }
    }
  }

  // Convert to precise hours (e.g., 9.0333... for 9 hours and 2 minutes)
  const totalHours = totalMilliseconds / (1000 * 60 * 60);
  const workedHours = totalHours / tasksInShift;
  const revenue = task.price || 0;

  // Calculate payroll using precise hours
  let payroll = 0;
  const hourlyRate = parseFloat(shift.rate) || parseFloat(task.user?.rate) || 0;

  if (hourlyRate > 0) {
    payroll = workedHours * hourlyRate;
  } else if (task.user?.salary && !isNaN(parseFloat(task.user.salary))) {
    const hourlyWage = parseFloat(task.user.salary) / (260 * 8);
    payroll = workedHours * hourlyWage;
  }

  // Calculate metrics using precise hours
  const grossProfit = revenue - payroll;
  const grossProfitPercentage = revenue ? (grossProfit / revenue) * 100 : 0;
  const tasksPerHour = totalHours > 0 ? tasksInShift / totalHours : 0;
  const revenuePerHour = workedHours > 0 ? revenue / workedHours : 0;
  const profitPerHour = workedHours > 0 ? grossProfit / workedHours : 0;

  // Return raw values without formatting
  return {
    workedHours,
    revenue,
    payroll,
    grossProfit,
    grossProfitPercentage,
    tasksPerHour,
    revenuePerHour,
    profitPerHour,
  };
};

const fetchTasks = async (startDate, endDate, selectedLocation) => {
  const tasksCollection = collection(db, "tasks");
  const shiftsCollection = collection(db, "shifts");

  const startTimestamp = new Date(startDate);
  startTimestamp.setHours(0, 0, 0, 0);

  const endTimestamp = new Date(endDate);
  endTimestamp.setHours(23, 59, 59, 999);

  // Define tasks query
  const tasksQuery = query(
    tasksCollection,
    where("startTimestamp", ">=", startTimestamp),
    where("startTimestamp", "<=", endTimestamp)
  );

  // Modify shifts query to not filter by location initially
  const shiftsQuery = query(
    shiftsCollection,
    where("startTimestamp", ">=", startTimestamp),
    where("startTimestamp", "<=", endTimestamp)
  );

  return new Promise((resolve, reject) => {
    let tasks = [];
    const shiftsMap = {};
    const vinCollectUsers = {};
    let unsubscribeTasks, unsubscribeShifts;

    // Subscribe to shifts changes first
    unsubscribeShifts = onSnapshot(
      shiftsQuery,
      (shiftsSnapshot) => {
        shiftsSnapshot.docs.forEach((doc) => {
          const shiftData = doc.data();
          const userId = shiftData.userId;
          if (!shiftsMap[userId]) {
            shiftsMap[userId] = [];
          }
          shiftsMap[userId].push({ id: doc.id, ...shiftData });

          // Check for Vin Collect role
          if (shiftData.roleChanges) {
            const isValidCollectRoleShift = shiftData.roleChanges.some(
              (change) => VALID_COLLECT_ROLES.includes(change.role)
            );
            if (isValidCollectRoleShift) {
              const userId = shiftData.userId;
              vinCollectUsers[userId] = vinCollectUsers[userId] || [];
              // Only add shifts for selected location or all locations
              if (
                selectedLocation === "all" ||
                shiftData.locationName === selectedLocation
              ) {
                vinCollectUsers[userId].push(shiftData);
              }
            }
          }
        });

        if (tasks.length > 0) {
          updateTasksWithShifts();
        }
      },
      reject
    );

    unsubscribeTasks = onSnapshot(
      tasksQuery,
      async (tasksSnapshot) => {
        try {
          const shiftTaskCounts = {};

          tasksSnapshot.docs.forEach((doc) => {
            const taskData = doc.data();
            const taskDate = taskData.startTimestamp.toDate().toDateString();
            const shiftKey = `${taskData.userId}_${taskDate}_${taskData.locationName}`;
            shiftTaskCounts[shiftKey] = (shiftTaskCounts[shiftKey] || 0) + 1;
          });

          tasks = await Promise.all(
            tasksSnapshot.docs.map(async (docSnapshot) => {
              const taskData = docSnapshot.data();
              const taskDate = taskData.startTimestamp.toDate().toDateString();
              const shiftKey = `${taskData.userId}_${taskDate}_${taskData.locationName}`;
              const userShifts = shiftsMap[taskData.userId] || [];
              const tasksInShift = shiftTaskCounts[shiftKey] || 1;

              try {
                const userDoc = await getDoc(doc(db, "users", taskData.userId));
                const userData = userDoc.data();

                if (!userData) {
                  console.warn(
                    `No user data found for userId: ${taskData.userId}`
                  );
                  return createTaskWithUser(
                    docSnapshot,
                    taskData,
                    null,
                    userShifts,
                    tasksInShift
                  );
                }

                return createTaskWithUser(
                  docSnapshot,
                  taskData,
                  userData,
                  userShifts,
                  tasksInShift
                );
              } catch (error) {
                console.error(
                  `Error fetching user data for task ${docSnapshot.id}:`,
                  error
                );
                return createTaskWithUser(
                  docSnapshot,
                  taskData,
                  { firstName: "Error", lastName: "Loading" },
                  userShifts,
                  tasksInShift
                );
              }
            })
          );

          const usersWithoutTasksData = await Promise.all(
            Object.keys(vinCollectUsers).flatMap((userId) => {
              return vinCollectUsers[userId].map(async (shift) => {
                const shiftKey = `${userId}_${shift.startTimestamp
                  .toDate()
                  .toDateString()}_${shift.locationName}`;
                if (shiftTaskCounts[shiftKey]) return null; // Skip if tasks exist

                const userDoc = await getDoc(doc(db, "users", userId));
                const userData = userDoc.data();

                if (
                  selectedLocation !== "all" &&
                  shift.locationName !== selectedLocation
                ) {
                  return null;
                }

                const workedHours = calculateWorkedHours(shift);
                const payroll = calculatePayroll(userData, workedHours);

                return {
                  id: `no-task-${userId}-${shiftKey}`,
                  userId,
                  user: userData,
                  shift,
                  workedHours,
                  payroll,
                  revenue: 0,
                  grossProfit: -payroll,
                  grossProfitPercentage: 0,
                  tasksPerHour: 0,
                  revenuePerHour: 0,
                  profitPerHour: 0,
                };
              });
            })
          );

          const filteredUsersWithoutTasksData =
            usersWithoutTasksData.filter(Boolean);

          resolve({
            tasks: [...tasks, ...filteredUsersWithoutTasksData],
            vinCollectUsers,
          });
        } catch (error) {
          reject(error);
        }
      },
      reject
    );

    const updateTasksWithShifts = () => {
      const updatedTasks = tasks.map((task) => {
        const shift = shiftsMap[task.userId];
        return {
          ...task,
          shift,
        };
      });
      resolve({ tasks: updatedTasks, vinCollectUsers });
    };

    return () => {
      unsubscribeTasks && unsubscribeTasks();
      unsubscribeShifts && unsubscribeShifts();
    };
  });
};

// Helper function to calculate worked hours for users without tasks
const calculateWorkedHours = (shift) => {
  const shiftStart = shift.startTimestamp.toDate();
  const shiftEnd = shift.endTimestamp
    ? shift.endTimestamp.toDate()
    : new Date();
  let totalMilliseconds = shiftEnd - shiftStart;

  // Subtract breaks if any
  if (shift.breakTimestamps && shift.breakTimestamps.length > 1) {
    for (let i = 0; i < shift.breakTimestamps.length; i += 2) {
      const breakStart = new Date(shift.breakTimestamps[i]);
      const breakEnd = shift.breakTimestamps[i + 1]
        ? new Date(shift.breakTimestamps[i + 1])
        : new Date();

      if (breakStart >= shiftStart && breakEnd <= shiftEnd) {
        totalMilliseconds -= breakEnd - breakStart;
      }
    }
  }

  return totalMilliseconds / (1000 * 60 * 60); // Convert to hours
};

// Helper function to calculate payroll for users without tasks
const calculatePayroll = (userData, workedHours) => {
  const hourlyRate = parseFloat(userData?.rate) || 0;
  if (hourlyRate > 0) {
    return workedHours * hourlyRate;
  } else if (userData?.salary && !isNaN(parseFloat(userData.salary))) {
    const hourlyWage = parseFloat(userData.salary) / (260 * 8);
    return workedHours * hourlyWage;
  }
  return 0;
};

const getCurrentWeekRange = () => {
  const now = new Date();
  const sunday = new Date(now);
  sunday.setDate(now.getDate() - now.getDay());
  sunday.setHours(0, 0, 0, 0);

  const saturday = new Date(now);
  saturday.setDate(now.getDate() + (6 - now.getDay()));
  saturday.setHours(23, 59, 59, 999);

  return [sunday, saturday];
};

function VinCollect({ userRole }) {
  // Move noTasksColumns definition inside the component
  const noTasksColumns = [
    {
      header: "Employee",
      accessorFn: (row) =>
        row.user
          ? `${row.user.firstName} ${row.user.lastName}`
          : "Unknown User",
    },
    {
      header: "Worked Hours",
      accessorFn: (row) => Number(row.workedHours.toFixed(2)),
      cell: (info) => `${info.getValue()} hours`,
    },
    // Conditionally include Payroll column for owner and admin
    ...(["owner", "admin"].includes(userRole)
      ? [
          {
            header: "Payroll",
            accessorFn: (row) => Number(row.payroll.toFixed(2)),
            cell: (info) => `$${info.getValue()}`,
          },
        ]
      : []),
  ];

  const [dateRange, setDateRange] = useState(getCurrentWeekRange());
  const [startDate, endDate] = dateRange;
  const [selectedLocation, setSelectedLocation] = useState("all");
  const [expanded, setExpanded] = useState({});
  const [sortDirection, setSortDirection] = useState("desc");
  const [clientVehicleIds, setClientVehicleIds] = useState("");
  const [showComparison, setShowComparison] = useState(false);
  const [isPDFModalOpen, setIsPDFModalOpen] = useState(false);
  const [vinCollectUsers, setVinCollectUsers] = useState({});
  const [searchTerm, setSearchTerm] = useState("");
  const [showLocationDetails, setShowLocationDetails] = useState(false);
  const [showUsersWithoutTasks, setShowUsersWithoutTasks] = useState(false);
  const [selectedLocationsForComparison, setSelectedLocationsForComparison] =
    useState([]);
  const [hideFinancialData, setHideFinancialData] = useState(false);

  const {
    data, // This will now contain { tasks, vinCollectUsers }
    isLoading,
    error,
  } = useQuery({
    queryKey: ["tasks", startDate, endDate, selectedLocation],
    queryFn: () => fetchTasks(startDate, endDate, selectedLocation),
    enabled: !!startDate && !!endDate,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
  });

  // Wrap tasks initialization in useMemo
  const tasks = useMemo(() => data?.tasks || [], [data?.tasks]);

  // Separate users without tasks
  const usersWithoutTasks = useMemo(() => {
    return tasks.filter((task) => task.id.startsWith("no-task-"));
  }, [tasks]);

  // Update the existing useEffect
  useEffect(() => {
    if (data?.vinCollectUsers) {
      setVinCollectUsers(data.vinCollectUsers);
    }
  }, [data?.vinCollectUsers]);

  // Update the locations useMemo to use memoized tasks
  const locations = useMemo(() => {
    if (!tasks) return [];
    const uniqueLocations = [
      ...new Set(tasks.map((task) => task.locationName)),
    ];
    return uniqueLocations.sort();
  }, [tasks]);

  // Update the filteredTasks useMemo to include search functionality
  const filteredTasks = useMemo(() => {
    if (!tasks) return [];

    return tasks.filter((task) => {
      // First filter by location if needed
      if (
        selectedLocation !== "all" &&
        task.locationName !== selectedLocation
      ) {
        return false;
      }

      // Then check if the task was created during a "Vin Collect" period
      const shift = task.shift;
      if (!shift?.roleChanges?.length) {
        return false;
      }

      const taskTime = task.startTimestamp?.toDate();
      if (!taskTime) {
        return false;
      }

      // Role changes check (existing code)
      const roleChanges = [...shift.roleChanges].sort(
        (a, b) => a.timestamp.seconds - b.timestamp.seconds
      );

      const roleAtTaskTime = roleChanges.reduce((currentRole, change) => {
        const changeTime = change.timestamp.toDate();
        return changeTime <= taskTime ? change.role : currentRole;
      }, roleChanges[0].role);

      if (!VALID_COLLECT_ROLES.includes(roleAtTaskTime)) {
        return false;
      }

      // Add search functionality
      if (searchTerm) {
        const searchLower = searchTerm.toLowerCase();
        return (
          // Search in employee name
          (task.user?.firstName + " " + task.user?.lastName)
            .toLowerCase()
            .includes(searchLower) ||
          // Search in task name
          task.taskName?.toLowerCase().includes(searchLower) ||
          // Search in vehicle ID
          task.vehicleId?.toLowerCase().includes(searchLower)
        );
      }

      return true;
    });
  }, [tasks, selectedLocation, searchTerm]);

  const groupedTasks = useMemo(() => {
    if (!filteredTasks) return [];

    // Create a Set of all valid user IDs (from both tasks and vinCollectUsers)
    const allVinCollectUsers = new Set([
      ...filteredTasks.map((task) => task.userId),
      ...(vinCollectUsers ? Object.keys(vinCollectUsers) : []),
    ]);

    const groupedByUser = Array.from(allVinCollectUsers).reduce(
      (acc, userId) => {
        const userTasks = filteredTasks.filter(
          (task) => task.userId === userId
        );
        const userShift = vinCollectUsers?.[userId];

        // Only add the user if they have tasks OR they are a valid Vin Collect user
        if (userShift?.user || userTasks[0]?.user) {
          acc[userId] = {
            userId,
            user: userShift?.user || userTasks[0]?.user,
            workedHours: userTasks.reduce(
              (sum, task) => sum + (task.workedHours || 0),
              0
            ),
            taskCount: userTasks.length,
            revenue: userTasks.reduce(
              (sum, task) => sum + (task.revenue || 0),
              0
            ),
            payroll: userTasks.reduce(
              (sum, task) => sum + (task.payroll || 0),
              0
            ),
            grossProfit: userTasks.reduce(
              (sum, task) => sum + (task.grossProfit || 0),
              0
            ),
          };
        }

        return acc;
      },
      {}
    );

    return Object.values(groupedByUser).map((group) => ({
      ...group,
      tasksPerHour:
        group.workedHours > 0 ? group.taskCount / group.workedHours : 0,
      grossProfitPercentage:
        group.revenue > 0 ? (group.grossProfit / group.revenue) * 100 : 0,
    }));
  }, [filteredTasks, vinCollectUsers]);

  const normalizeVehicleId = (id) => {
    // Remove all spaces, hyphens and convert to uppercase
    const cleaned = id.replace(/[\s-]/g, "").toUpperCase();

    // If the first two characters are letters (potential state code)
    if (/^[A-Z]{2}/.test(cleaned)) {
      // Insert hyphen after state code if not present
      return `${cleaned.slice(0, 2)}-${cleaned.slice(2)}`;
    }

    return cleaned;
  };

  const vehicleComparison = useMemo(() => {
    if (!clientVehicleIds || !filteredTasks) return null;

    // Convert client vehicle IDs into a Set of unique, normalized, non-empty IDs
    const clientIds = new Set(
      clientVehicleIds
        .split(/[\n,]/)
        .map((id) => id.trim())
        .filter((id) => id.length > 0)
        .map(normalizeVehicleId)
    );

    // Get vehicle IDs and their task counts from our tasks
    const vehicleTaskCounts = filteredTasks.reduce((acc, task) => {
      if (task.vehicleId && task.vehicleId.trim()) {
        const normalizedId = normalizeVehicleId(task.vehicleId);
        if (!acc[normalizedId]) {
          acc[normalizedId] = {
            count: 0,
            tasks: [],
            originalId: task.vehicleId, // Store original format
          };
        }
        acc[normalizedId].count++;
        acc[normalizedId].tasks.push({
          taskName: task.taskName,
          timestamp: task.startTimestamp?.toDate(),
          originalId: task.vehicleId, // Store original format
        });
      }
      return acc;
    }, {});

    const ourIds = new Set(Object.keys(vehicleTaskCounts));

    // Find vehicles with multiple tasks
    const duplicateVehicles = Object.entries(vehicleTaskCounts)
      .filter(([_, data]) => data.count > 1)
      .map(([id, data]) => ({
        id,
        originalId: data.originalId,
        count: data.count,
        tasks: data.tasks.sort((a, b) => b.timestamp - a.timestamp),
      }));

    // Calculate the differences
    const missingFromUs = [...clientIds].filter((id) => !ourIds.has(id));
    const missingFromClient = [...ourIds].filter((id) => !clientIds.has(id));
    const matchingIds = [...clientIds].filter((id) => ourIds.has(id));

    return {
      missingFromUs,
      missingFromClient,
      matchingIds,
      duplicateVehicles,
      totalClientIds: clientIds.size,
      totalOurIds: ourIds.size,
      totalTasks: filteredTasks.length,
    };
  }, [clientVehicleIds, filteredTasks]);

  const columns = useMemo(
    () => [
      {
        id: "expander",
        header: () => null,
        cell: ({ row }) => (
          <button
            {...{
              onClick: row.getToggleExpandedHandler(),
              className: "px-2",
            }}
          >
            <FontAwesomeIcon
              icon={row.getIsExpanded() ? faChevronDown : faChevronRight}
              className="text-gray-500"
            />
          </button>
        ),
      },
      {
        header: "Employee",
        accessorFn: (row) => {
          if (!row.user) return "Unknown User";
          return `${row.user.firstName} ${row.user.lastName}`;
        },
      },
      {
        header: "Tasks",
        accessorKey: "taskCount",
      },
      {
        header: "Worked Hours",
        accessorFn: (row) => {
          const hours = row.workedHours || 0;
          return Number(hours.toFixed(2));
        },
      },
      {
        header: "Tasks/Hour",
        accessorKey: "tasksPerHour",
        cell: (info) => `${Number(info.getValue()).toFixed(2)} tasks/hr`,
      },
      ...(!hideFinancialData && ["owner", "admin"].includes(userRole)
        ? [
            {
              header: "Revenue/Hour",
              accessorFn: (row) => row.revenue / row.workedHours || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}/hr`,
            },
            {
              header: "Profit/Hour",
              accessorFn: (row) => row.grossProfit / row.workedHours || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}/hr`,
            },
            {
              header: "Revenue",
              accessorFn: (row) => row.revenue || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
            },
            {
              header: "Payroll",
              accessorFn: (row) => row.payroll || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
            },
            {
              header: "Gross Profit",
              accessorFn: (row) => row.grossProfit || 0,
              cell: (info) => `$${Number(info.getValue()).toFixed(2)}`,
            },
          ]
        : []),
      ...(!hideFinancialData
        ? [
            {
              header: "Gross Profit %",
              accessorFn: (row) => row.grossProfitPercentage || 0,
              cell: (info) => `${Number(info.getValue()).toFixed(1)}%`,
            },
          ]
        : []),
    ],
    [userRole, hideFinancialData]
  );

  const renderSubRow = useCallback(
    (row) => {
      const userTasks = filteredTasks
        .filter((task) => task.userId === row.original.userId)
        .sort((a, b) => {
          const aTime = a.startTimestamp?.seconds || 0;
          const bTime = b.startTimestamp?.seconds || 0;
          return sortDirection === "desc" ? bTime - aTime : aTime - bTime;
        });

      // Modified helper function to calculate duration between consecutive tasks
      const getTaskDuration = (task, index, tasks) => {
        const startTime = task.startTimestamp?.toDate();
        if (!startTime) return { hours: 0, minutes: 0 };

        // Get the next task's start time (if it exists)
        const nextTask = tasks[index + 1];
        const endTime = nextTask?.startTimestamp?.toDate() || startTime;

        // For the last task in the sequence, use the difference to the next task's start time
        // or a reasonable default duration (e.g., 15 minutes) if it's the last task
        if (!nextTask) {
          return { hours: 0, minutes: 15 }; // Default duration for the last task
        }

        const durationMs = startTime - endTime; // Note: reversed because we're sorting desc
        const hours = Math.floor(Math.abs(durationMs) / (1000 * 60 * 60));
        const minutes = Math.floor(
          (Math.abs(durationMs) % (1000 * 60 * 60)) / (1000 * 60)
        );
        return { hours, minutes };
      };

      return (
        <tr>
          <td colSpan={columns.length} className="bg-gray-50 p-4">
            <div className="flex justify-end mb-4">
              <button
                onClick={() =>
                  setSortDirection((current) =>
                    current === "desc" ? "asc" : "desc"
                  )
                }
                className="flex items-center space-x-2 px-3 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
              >
                <span className="text-sm text-gray-700">
                  Sort{" "}
                  {sortDirection === "desc" ? "Oldest First" : "Newest First"}
                </span>
                <FontAwesomeIcon
                  icon={sortDirection === "desc" ? faChevronUp : faChevronDown}
                  className="text-gray-500"
                />
              </button>
            </div>
            <div className="grid grid-cols-1 gap-4">
              {userTasks.map((task, index) => {
                const duration = getTaskDuration(task, index, userTasks);
                return (
                  <div key={task.id} className="bg-white p-4 rounded-lg shadow">
                    <div className="grid grid-cols-4 gap-4">
                      {/* Time Information */}
                      <div>
                        <p className="text-sm font-semibold text-gray-600">
                          Start Time
                        </p>
                        <p>
                          {task.startTimestamp?.seconds
                            ? new Date(
                                task.startTimestamp.seconds * 1000
                              ).toLocaleString()
                            : "Not started"}
                        </p>
                      </div>
                      {/* Task Details */}
                      <div>
                        <p className="text-sm font-semibold text-gray-600">
                          Task Name
                        </p>
                        <p>{task.taskName || "N/A"}</p>
                        <p className="text-sm font-semibold text-gray-600 mt-2">
                          Vehicle ID
                        </p>
                        <p>{task.vehicleId || "N/A"}</p>
                      </div>
                      {/* Location and Price */}
                      <div>
                        <p className="text-sm font-semibold text-gray-600">
                          Location
                        </p>
                        <p>{task.locationName || "N/A"}</p>
                        {userRole === "owner" && !hideFinancialData && (
                          <>
                            <p className="text-sm font-semibold text-gray-600 mt-2">
                              Price
                            </p>
                            <p>${task.price || "0.00"}</p>
                          </>
                        )}
                      </div>
                      {/* Metrics */}
                      <div>
                        <p className="text-sm font-semibold text-gray-600">
                          Duration
                        </p>
                        <p>{`${duration.hours}h ${duration.minutes}m`}</p>
                        {userRole === "owner" && !hideFinancialData && (
                          <>
                            <p className="text-sm font-semibold text-gray-600 mt-2">
                              Gross Profit
                            </p>
                            <p>
                              ${(task.grossProfit || 0).toFixed(2)} (
                              {(task.grossProfitPercentage || 0).toFixed(1)}%)
                            </p>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </td>
        </tr>
      );
    },
    [filteredTasks, columns, sortDirection, userRole, hideFinancialData]
  );

  const table = useReactTable({
    data: groupedTasks,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getExpandedRowModel: getExpandedRowModel(),
    state: {
      expanded,
    },
    onExpandedChange: setExpanded,
  });

  const summaryMetrics = useMemo(() => {
    if (!groupedTasks?.length && !usersWithoutTasks?.length) return null;

    // Calculate metrics for users with tasks
    const taskMetrics = groupedTasks.reduce(
      (sum, group) => ({
        taskCount: sum.taskCount + group.taskCount,
        workedHours: sum.workedHours + (group.workedHours || 0),
        revenue: sum.revenue + (group.revenue || 0),
        payroll: sum.payroll + (group.payroll || 0),
        grossProfit: sum.grossProfit + (group.grossProfit || 0),
      }),
      {
        taskCount: 0,
        workedHours: 0,
        revenue: 0,
        payroll: 0,
        grossProfit: 0,
      }
    );

    // Calculate metrics for users without tasks
    const noTaskMetrics = usersWithoutTasks.reduce(
      (sum, user) => ({
        taskCount: sum.taskCount,
        workedHours: sum.workedHours + (user.workedHours || 0),
        revenue: sum.revenue,
        payroll: sum.payroll + (user.payroll || 0),
        grossProfit: sum.grossProfit - (user.payroll || 0),
      }),
      {
        taskCount: 0,
        workedHours: 0,
        revenue: 0,
        payroll: 0,
        grossProfit: 0,
      }
    );

    // Combine both metrics
    const totalMetrics = {
      taskCount: taskMetrics.taskCount + noTaskMetrics.taskCount,
      workedHours: Number(
        (taskMetrics.workedHours + noTaskMetrics.workedHours).toFixed(2)
      ),
      revenue: Number((taskMetrics.revenue + noTaskMetrics.revenue).toFixed(2)),
      payroll: taskMetrics.payroll + noTaskMetrics.payroll,
      grossProfit: taskMetrics.grossProfit + noTaskMetrics.grossProfit,
    };

    return totalMetrics;
  }, [groupedTasks, usersWithoutTasks]);

  const locationMetrics = useMemo(() => {
    // Initialize metrics for all locations
    const metrics = locations.reduce((acc, location) => {
      acc[location] = {
        taskCount: 0,
        workedHours: 0,
        revenue: 0,
        payroll: 0,
        grossProfit: 0,
      };
      return acc;
    }, {});

    // Add "Unknown" location
    metrics["Unknown"] = {
      taskCount: 0,
      workedHours: 0,
      revenue: 0,
      payroll: 0,
      grossProfit: 0,
    };

    // Use filteredTasks and their pre-calculated metrics
    filteredTasks.forEach((task) => {
      const location = task.locationName || "Unknown";
      if (!metrics[location]) {
        metrics[location] = {
          taskCount: 0,
          workedHours: 0,
          revenue: 0,
          payroll: 0,
          grossProfit: 0,
        };
      }

      metrics[location].taskCount += 1;
      metrics[location].workedHours += task.workedHours || 0;
      metrics[location].revenue += task.revenue || 0;
      metrics[location].payroll += task.payroll || 0;
      metrics[location].grossProfit += task.grossProfit || 0;
    });

    // Add users without tasks to their respective locations
    usersWithoutTasks.forEach((user) => {
      const location = user.shift?.locationName || "Unknown";
      if (metrics[location]) {
        metrics[location].workedHours += user.workedHours || 0;
        metrics[location].payroll += user.payroll || 0;
        metrics[location].grossProfit += user.grossProfit || 0;
      }
    });

    // Remove locations with no data and return
    return Object.fromEntries(
      Object.entries(metrics).filter(([location, data]) =>
        location === "Unknown" ? data.taskCount > 0 : true
      )
    );
  }, [locations, filteredTasks, usersWithoutTasks]);

  const formatMetric = (value, suffix = "") => {
    const num = Number(value.toFixed(2));
    return `${num.toLocaleString("en-US", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })}${suffix}`;
  };

  const renderSummarySection = () => {
    if (!summaryMetrics) return null;

    const tasksPerHour = summaryMetrics.workedHours
      ? summaryMetrics.taskCount / summaryMetrics.workedHours
      : 0;
    const grossProfitPercentage = summaryMetrics.revenue
      ? (summaryMetrics.grossProfit / summaryMetrics.revenue) * 100
      : 0;

    return (
      <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-white">
            {selectedLocation === "all"
              ? "Overall Summary"
              : `Summary for ${selectedLocation}`}
          </h2>
          <div className="flex space-x-3">
            {selectedLocation === "all" && (
              <button
                onClick={() => setShowLocationDetails(!showLocationDetails)}
                className="flex items-center space-x-2 px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
              >
                <FontAwesomeIcon
                  icon={showLocationDetails ? faChevronDown : faChevronRight}
                  className="text-gray-400"
                />
                <span className="text-white">Location Details</span>
              </button>
            )}
          </div>
        </div>

        {/* Overall Summary Grid */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-4 mb-4">
          <div className="bg-gray-700 p-4 rounded-lg">
            <div className="text-gray-400 text-sm">Tasks</div>
            <div className="text-white text-2xl font-bold">
              {summaryMetrics.taskCount.toLocaleString()}
            </div>
            <div className="text-blue-400 text-sm mt-1">
              {tasksPerHour.toFixed(2)} tasks/hr
            </div>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <div className="text-gray-400 text-sm">Hours Worked</div>
            <div className="text-white text-2xl font-bold">
              {formatMetric(summaryMetrics.workedHours)}
            </div>
          </div>
          <div className="bg-gray-700 p-4 rounded-lg">
            <div className="text-gray-400 text-sm">Gross Profit %</div>
            <div className="text-white text-2xl font-bold">
              {!hideFinancialData
                ? `${grossProfitPercentage.toFixed(1)}% margin`
                : "***"}
            </div>
          </div>
          {userRole === "owner" && !hideFinancialData && (
            <>
              <div className="bg-gray-700 p-4 rounded-lg">
                <div className="text-gray-400 text-sm">Revenue</div>
                <div className="text-white text-2xl font-bold">
                  ${formatMetric(summaryMetrics.revenue)}
                </div>
              </div>
              <div className="bg-gray-700 p-4 rounded-lg">
                <div className="text-gray-400 text-sm">Gross Profit</div>
                <div className="text-white text-2xl font-bold">
                  ${formatMetric(summaryMetrics.grossProfit)}
                </div>
              </div>
            </>
          )}
        </div>

        {/* Location Details Expansion - only show when "all" is selected */}
        {selectedLocation === "all" && showLocationDetails && (
          <div className="mt-6 border-t border-gray-700 pt-6">
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-300 mb-2">
                Compare Locations
              </label>
              <div className="relative">
                <select
                  multiple
                  value={selectedLocationsForComparison}
                  onChange={(e) => {
                    const values = Array.from(
                      e.target.selectedOptions,
                      (option) => option.value
                    );
                    setSelectedLocationsForComparison(values);
                  }}
                  className="w-full p-3 bg-gray-700 border border-gray-600 rounded-lg text-white 
                     focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent
                     font-medium text-sm
                     [&>option]:py-2 [&>option]:px-3 [&>option]:cursor-pointer
                     [&>option:checked]:bg-blue-600 [&>option:checked]:text-white
                     [&>option:hover]:bg-gray-600
                     [&>optgroup]:font-semibold [&>optgroup]:text-blue-400 [&>optgroup]:bg-gray-800
                     [&>optgroup]:sticky [&>optgroup]:top-0"
                  size={6}
                >
                  <optgroup label="Quick Actions">
                    <option value="all">All Locations</option>
                  </optgroup>
                  <optgroup label="Locations">
                    {Object.keys(locationMetrics)
                      .sort()
                      .map((location) => (
                        <option key={location} value={location}>
                          {location}
                        </option>
                      ))}
                  </optgroup>
                </select>
                <div className="absolute right-3 top-3 pointer-events-none text-gray-400">
                  <svg
                    className="w-5 h-5"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M8 7h12m-12 5h12m-12 5h12"
                    />
                  </svg>
                </div>
              </div>
              <p className="mt-2 text-sm text-gray-400">
                {selectedLocationsForComparison.length === 0
                  ? "Showing all locations. Select specific locations to compare."
                  : `Comparing ${
                      selectedLocationsForComparison.length
                    } location${
                      selectedLocationsForComparison.length === 1 ? "" : "s"
                    }`}
              </p>
            </div>

            <div className="grid gap-4">
              {(selectedLocationsForComparison.length === 0
                ? Object.entries(locationMetrics)
                : Object.entries(locationMetrics).filter(
                    ([location]) =>
                      selectedLocationsForComparison.includes(location) ||
                      selectedLocationsForComparison.includes("all")
                  )
              ).map(([location, metrics]) => {
                const locationTasksPerHour = metrics.workedHours
                  ? metrics.taskCount / metrics.workedHours
                  : 0;
                const locationProfitPercentage = metrics.revenue
                  ? (metrics.grossProfit / metrics.revenue) * 100
                  : 0;

                return (
                  <div key={location} className="bg-gray-700 p-4 rounded-lg">
                    <h3 className="text-white font-semibold mb-3">
                      {location}
                    </h3>
                    <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                      <div>
                        <div className="text-gray-400 text-sm">Tasks</div>
                        <div className="text-white font-bold">
                          {metrics.taskCount.toLocaleString()}
                        </div>
                        <div className="text-blue-400 text-sm">
                          {locationTasksPerHour.toFixed(2)} tasks/hr
                        </div>
                      </div>
                      <div>
                        <div className="text-gray-400 text-sm">Hours</div>
                        <div className="text-white font-bold">
                          {formatMetric(metrics.workedHours)}
                        </div>
                        <div className="text-blue-400 text-sm">
                          {locationProfitPercentage.toFixed(1)}% margin
                        </div>
                      </div>
                      {userRole === "owner" && !hideFinancialData && (
                        <>
                          <div>
                            <div className="text-gray-400 text-sm">Revenue</div>
                            <div className="text-white font-bold">
                              ${formatMetric(metrics.revenue)}
                            </div>
                          </div>
                          <div>
                            <div className="text-gray-400 text-sm">Profit</div>
                            <div className="text-white font-bold">
                              ${formatMetric(metrics.grossProfit)}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderVehicleComparison = () => (
    <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-semibold text-white">
          Vehicle ID Comparison
        </h2>
        <button
          onClick={() => setShowComparison(!showComparison)}
          className="px-4 py-2 bg-blue-500 text-white rounded-lg hover:bg-blue-600 transition-colors"
        >
          {showComparison ? "Hide Comparison" : "Show Comparison"}
        </button>
      </div>

      {showComparison && (
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-300 mb-2">
              Paste Client Vehicle IDs (one per line or comma-separated)
            </label>
            <textarea
              value={clientVehicleIds}
              onChange={(e) => setClientVehicleIds(e.target.value)}
              className="w-full h-32 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500"
              placeholder="Enter vehicle IDs..."
            />
          </div>

          {vehicleComparison && clientVehicleIds && (
            <div className="grid grid-cols-1 md:grid-cols-4 gap-4 w-full">
              <div className="bg-gray-700 p-4 rounded-lg w-full">
                <h3 className="text-white font-semibold mb-2">
                  Client IDs Not Found in Our System
                </h3>
                <p className="text-gray-400 text-sm mb-2">
                  {vehicleComparison.missingFromUs.length} unique vehicle
                  {vehicleComparison.missingFromUs.length !== 1 ? "s" : ""}
                </p>
                <div className="max-h-40 overflow-y-auto">
                  {vehicleComparison.missingFromUs.map((id) => (
                    <div key={id} className="text-red-400 text-sm py-1">
                      {id}
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg w-full">
                <h3 className="text-white font-semibold mb-2">
                  Our System Activity
                </h3>
                <div className="text-gray-400 text-sm mb-4">
                  <div className="grid grid-cols-2 gap-2 mb-2">
                    <div>Total Tasks:</div>
                    <div className="text-white">
                      {vehicleComparison.totalTasks}
                    </div>

                    <div>Unique Vehicles:</div>
                    <div className="text-white">
                      {vehicleComparison.totalOurIds}
                    </div>

                    <div>Tasks per Vehicle:</div>
                    <div className="text-white">
                      {(
                        vehicleComparison.totalTasks /
                        vehicleComparison.totalOurIds
                      ).toFixed(2)}
                    </div>
                  </div>
                  <div className="mt-4 text-sm font-medium">
                    {vehicleComparison.missingFromClient.length} vehicle
                    {vehicleComparison.missingFromClient.length !== 1
                      ? "s"
                      : ""}{" "}
                    not in client's list
                  </div>
                </div>
                <div className="max-h-40 overflow-y-auto">
                  {vehicleComparison.missingFromClient.map((id) => (
                    <div key={id} className="text-yellow-400 text-sm py-1">
                      {id}
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg w-full">
                <h3 className="text-white font-semibold mb-2">
                  Matching Vehicle IDs
                </h3>
                <p className="text-gray-400 text-sm mb-2">
                  {vehicleComparison.matchingIds.length} vehicle
                  {vehicleComparison.matchingIds.length !== 1 ? "s" : ""} found
                  in both systems
                </p>
                <div className="max-h-40 overflow-y-auto">
                  {vehicleComparison.matchingIds.map((id) => (
                    <div key={id} className="text-green-400 text-sm py-1">
                      {id}
                    </div>
                  ))}
                </div>
              </div>

              <div className="bg-gray-700 p-4 rounded-lg w-full">
                <h3 className="text-white font-semibold mb-2">
                  Vehicles with Multiple Tasks
                </h3>
                <p className="text-gray-400 text-sm mb-2">
                  {vehicleComparison.duplicateVehicles.length} vehicle
                  {vehicleComparison.duplicateVehicles.length !== 1
                    ? "s"
                    : ""}{" "}
                  with repeated tasks
                </p>
                <div className="max-h-40 overflow-y-auto w-full">
                  {vehicleComparison.duplicateVehicles.map((vehicle) => (
                    <div
                      key={vehicle.id}
                      className="mb-3 border-b border-gray-600 pb-2 w-full"
                    >
                      <div className="text-blue-400 font-medium w-full break-all">
                        {vehicle.originalId}
                        {vehicle.originalId !== vehicle.id && (
                          <span className="text-gray-400 text-xs ml-2">
                            (Normalized: {vehicle.id})
                          </span>
                        )}
                      </div>
                      <div className="text-gray-400 text-xs mb-1">
                        {vehicle.count} tasks
                      </div>
                      <div>
                        {vehicle.tasks.map((task, index) => (
                          <div
                            key={index}
                            className="text-gray-300 text-xs pl-2 py-1 flex items-start"
                          >
                            <span className="mr-2">•</span>
                            <span className="break-all flex-1">
                              {task.taskName || "Unnamed Task"}
                              {task.originalId !== vehicle.id && (
                                <span className="text-gray-400 block">
                                  Original ID: {task.originalId}
                                </span>
                              )}
                              <span className="text-gray-400 block">
                                {task.timestamp?.toLocaleString() || "No date"}
                              </span>
                            </span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );

  // Add this with other table configurations
  const noTasksTable = useReactTable({
    data: usersWithoutTasks,
    columns: noTasksColumns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Update the render function to use the table instance
  const renderUsersWithoutTasks = () => {
    if (!usersWithoutTasks.length) return null;

    return (
      <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-lg font-semibold text-white">
            Users Without Tasks ({usersWithoutTasks.length})
          </h2>
          <button
            onClick={() => setShowUsersWithoutTasks(!showUsersWithoutTasks)}
            className="flex items-center space-x-2 px-4 py-2 bg-gray-700 rounded-lg hover:bg-gray-600 transition-colors"
          >
            <FontAwesomeIcon
              icon={showUsersWithoutTasks ? faChevronDown : faChevronRight}
              className="text-gray-400"
            />
            <span className="text-white">
              {showUsersWithoutTasks ? "Hide Details" : "Show Details"}
            </span>
          </button>
        </div>

        {showUsersWithoutTasks && (
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {noTasksTable.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {noTasksTable.getRowModel().rows.map((row) => (
                  <tr key={row.id} className="hover:bg-gray-50">
                    {row.getVisibleCells().map((cell) => (
                      <td
                        key={cell.id}
                        className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                      >
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    );
  };

  const getFormattedFileName = () => {
    const location =
      selectedLocation === "all"
        ? "all-locations"
        : selectedLocation.replace(/\s+/g, "-").toLowerCase();
    const startDateFormatted = format(startDate, "yyyy-MM-dd");
    const endDateFormatted = format(endDate, "yyyy-MM-dd");
    const exportDateTime = format(new Date(), "yyyy-MM-dd_HH-mm-ss");

    return `tasks_${location}_${startDateFormatted}_${endDateFormatted}_${exportDateTime}.pdf`;
  };

  const taskSummary = useMemo(() => {
    if (!filteredTasks) return { totalTasks: 0, tasks: [] };

    const taskMap = filteredTasks.reduce((acc, task) => {
      if (!acc[task.taskName]) {
        acc[task.taskName] = {
          name: task.taskName,
          quantity: 0,
          price: task.price || 0,
        };
      }
      acc[task.taskName].quantity += 1;
      return acc;
    }, {});

    return {
      totalTasks: filteredTasks.length,
      tasks: Object.values(taskMap),
    };
  }, [filteredTasks]);

  const handleSummaryDownload = () => {
    setIsPDFModalOpen(false);

    // Create a temporary container div
    const container = document.createElement("div");
    document.body.appendChild(container);

    // Render the PDFDownloadLink into the container
    ReactDOM.render(
      <PDFDownloadLink
        document={
          <VinCollectPDF
            taskSummary={taskSummary}
            filteredTasks={filteredTasks}
            selectedLocation={selectedLocation}
            dateRange={[startDate, endDate]}
            summaryOnly={true}
            userRole={userRole}
          />
        }
        fileName={`summary_${getFormattedFileName()}`}
      >
        {({ blob, url, loading, error }) => {
          // Automatically click the download link once it's ready
          if (!loading && !error) {
            setTimeout(() => {
              const link = container.querySelector("a");
              if (link) {
                link.click();
                // Clean up after download starts
                if (document.body.contains(container)) {
                  document.body.removeChild(container);
                }
                // Unmount the React component
                ReactDOM.unmountComponentAtNode(container);
              }
            }, 100);
          }
          return null;
        }}
      </PDFDownloadLink>,
      container
    );
  };

  if (isLoading)
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  if (error)
    return (
      <div className="text-red-500 text-center">Error: {error.message}</div>
    );

  return (
    <div className="container mx-auto px-4 sm:px-8">
      <div className="py-8">
        <div className="flex justify-between items-center">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider">
            Vin Collect Report
          </h1>
        </div>

        {/* Filters Section with Export Button */}
        <div className="my-6 p-6 bg-gray-800 rounded-lg shadow-lg">
          <div className="flex flex-col space-y-4">
            {/* Add search and toggle row */}
            <div className="flex items-center justify-between">
              <div className="relative flex-grow mr-4">
                <input
                  type="text"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder="Search by name, task, or vehicle ID..."
                  className="w-full p-3 pl-10 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                />
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    className="h-5 w-5 text-gray-400"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              </div>

              {/* Financial Data Toggle */}
              {["owner", "admin"].includes(userRole) && (
                <div className="flex items-center">
                  <span className="text-gray-300 text-sm mr-2">
                    {hideFinancialData
                      ? "Financial Data Hidden"
                      : "Financial Data Visible"}
                  </span>
                  <button
                    onClick={() => setHideFinancialData(!hideFinancialData)}
                    className="relative inline-flex h-6 w-11 items-center rounded-full transition-colors focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2"
                    style={{
                      backgroundColor: hideFinancialData
                        ? "#ef4444"
                        : "#10b981",
                    }}
                    aria-checked={!hideFinancialData}
                    role="switch"
                  >
                    <span
                      className={`${
                        hideFinancialData ? "translate-x-6" : "translate-x-1"
                      } inline-block h-4 w-4 transform rounded-full bg-white transition-transform`}
                    />
                    <span className="sr-only">
                      {hideFinancialData
                        ? "Show financial data"
                        : "Hide financial data"}
                    </span>
                  </button>
                </div>
              )}
            </div>

            {/* Existing filters and Export button */}
            <div className="flex items-end justify-between">
              {/* Date Range Picker */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-gray-300">
                  Date Range
                </label>
                <DatePicker
                  selectsRange={true}
                  startDate={startDate}
                  endDate={endDate}
                  onChange={(update) => setDateRange(update)}
                  className="w-72 p-3 bg-gray-700 border border-gray-600 rounded-lg text-white placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200"
                  placeholderText="Select date range"
                  dateFormat="MMM dd, yyyy"
                />
              </div>

              {/* Location Dropdown */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-gray-300">
                  Location
                </label>
                <div className="relative">
                  <select
                    value={selectedLocation}
                    onChange={(e) => setSelectedLocation(e.target.value)}
                    className="w-48 appearance-none p-3 bg-gray-700 border border-gray-600 rounded-lg text-white focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 pr-10"
                  >
                    <option value="all">All Locations</option>
                    {locations.map((location) => (
                      <option key={location} value={location}>
                        {location}
                      </option>
                    ))}
                  </select>
                  <div className="absolute inset-y-0 right-0 flex items-center px-3 pointer-events-none">
                    <svg
                      className="w-4 h-4 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
              </div>

              {/* Add Export Button */}
              <div className="flex flex-col gap-2">
                <label className="text-sm font-semibold text-gray-300">
                  Export
                </label>
                {selectedLocation === "all" ? (
                  <button
                    disabled
                    className="px-6 py-3 bg-gray-600 
                               text-gray-400 rounded-lg font-medium
                               cursor-not-allowed
                               flex items-center space-x-2"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                      />
                    </svg>
                    <span>Select a Location to Export</span>
                  </button>
                ) : (
                  <button
                    onClick={() => setIsPDFModalOpen(true)}
                    className="px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 
                               text-white rounded-lg font-medium
                               hover:from-blue-600 hover:to-blue-700 
                               transform transition-all duration-200 
                               hover:shadow-lg hover:-translate-y-0.5
                               focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                               flex items-center space-x-2"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    <span>Export PDF</span>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Summary Section */}
        {renderSummarySection()}

        {/* Vehicle Comparison Section */}
        {renderVehicleComparison()}

        {/* Users Without Tasks Section */}
        {renderUsersWithoutTasks()}

        {/* Table section */}
        <div className="-mx-4 sm:-mx-8 px-4 sm:px-8 py-4 overflow-x-auto">
          <div className="inline-block min-w-full shadow rounded-lg overflow-hidden">
            <table className="min-w-full leading-normal">
              <thead>
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                      <th
                        key={header.id}
                        onClick={header.column.getToggleSortingHandler()}
                        className="px-5 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider cursor-pointer"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row) => (
                  <React.Fragment key={row.id}>
                    <tr
                      key={row.id}
                      className="hover:bg-gray-50 cursor-pointer"
                      onClick={() => row.toggleExpanded()}
                    >
                      {row.getVisibleCells().map((cell) => (
                        <td
                          key={cell.id}
                          className="px-5 py-5 border-b border-gray-200 bg-white text-sm"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      ))}
                    </tr>
                    {row.getIsExpanded() && renderSubRow(row)}
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </div>

        {/* PDF Export Modal */}
        {isPDFModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 z-50 flex items-center justify-center">
            <div className="bg-gray-800 rounded-lg p-6 max-w-lg w-full mx-4">
              <div className="flex justify-between items-center mb-4">
                <h3 className="text-xl font-semibold text-white">
                  Export to PDF
                </h3>
                <button
                  onClick={() => setIsPDFModalOpen(false)}
                  className="text-gray-400 hover:text-white"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>

              <div className="mb-4">
                <p className="text-gray-300 mb-3 font-medium">
                  Export Options:
                </p>
                <div className="space-y-3">
                  <button
                    onClick={handleSummaryDownload}
                    className="w-full px-6 py-3 bg-gradient-to-r from-blue-500 to-blue-600 
                               text-white rounded-lg font-medium
                               hover:from-blue-600 hover:to-blue-700 
                               transform transition-all duration-200 
                               hover:shadow-lg hover:-translate-y-0.5
                               focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                               flex items-center justify-center space-x-2"
                  >
                    <svg
                      className="w-5 h-5"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M4 16v1a3 3 0 003 3h10a3 3 0 003-3v-1m-4-4l-4 4m0 0l-4-4m4 4V4"
                      />
                    </svg>
                    <span>Download Summary (Recommended)</span>
                  </button>

                  <PDFDownloadLink
                    document={
                      <VinCollectPDF
                        taskSummary={taskSummary}
                        filteredTasks={filteredTasks}
                        selectedLocation={selectedLocation}
                        dateRange={[startDate, endDate]}
                        summaryOnly={false}
                        userRole={userRole}
                      />
                    }
                    fileName={getFormattedFileName()}
                    className="w-full px-6 py-3 bg-gradient-to-r from-gray-600 to-gray-700 
                               text-white rounded-lg font-medium
                               hover:from-gray-700 hover:to-gray-800 
                               transform transition-all duration-200 
                               hover:shadow-lg hover:-translate-y-0.5
                               focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50
                               flex items-center justify-center space-x-2"
                  >
                    {({ blob, url, loading, error }) => (
                      <>
                        <svg
                          className="w-5 h-5"
                          fill="none"
                          stroke="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                          />
                        </svg>
                        <span>
                          {loading
                            ? "Preparing Full Report..."
                            : "Download Full Report"}
                        </span>
                      </>
                    )}
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default VinCollect;
