import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
  useRef,
} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileExport, faCheck } from "@fortawesome/free-solid-svg-icons";
import { db } from "./firebase";
import { useQuery } from "@tanstack/react-query";
import * as XLSX from "xlsx";
import { collection, query, where, getDocs } from "firebase/firestore";
import { calculateRoleHours, convertHoursToHM } from "./timeCalculations";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { useRanger } from "@tanstack/react-ranger";

const Reports = () => {
  const [locations, setLocations] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState("All Locations");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [locationDropdownOpen, setLocationDropdownOpen] = useState(false);
  const [minHours, setMinHours] = useState(0);
  const [maxHours, setMaxHours] = useState(0);
  const [sliderValue, setSliderValue] = useState(0);
  const [showApprovedOnly, setShowApprovedOnly] = useState(true);
  const [showExportOptions, setShowExportOptions] = useState(false);
  const [positions, setPositions] = useState([]);
  const [selectedPosition, setSelectedPosition] = useState("All Positions");
  const [positionDropdownOpen, setPositionDropdownOpen] = useState(false);
  const [selectedDivision, setSelectedDivision] = useState("All Divisions");
  const [divisionDropdownOpen, setDivisionDropdownOpen] = useState(false);

  const rangerRef = React.useRef(null);
  const rangerInstance = useRanger({
    getRangerElement: () => rangerRef.current,
    values: [sliderValue],
    min: 0,
    max: maxHours,
    stepSize: 0.5,
    onDrag: (instance) => setSliderValue(instance.sortedValues[0]),
  });

  const locationDropdownRef = useRef(null);
  const positionDropdownRef = useRef(null);
  const exportDropdownRef = useRef(null);
  const divisionDropdownRef = useRef(null);

  const divisionMapping = useMemo(() => ({
    "Unique Solutions": "- SO",
    "Unique Supreme": "- SU",
    "Prime National Labor": "- P",
    "Unique Facility": "- F",
    "Unique Auto Detail": "- SH",
    "Unique Corp": null  // null indicates it should catch all other positions
  }), []);

  const fetchShiftsData = async ({ queryKey }) => {
    const {
      startDate,
      endDate,
      selectedLocation,
      selectedPosition,
      showApprovedOnly,
    } = queryKey[1];
    
    // First, fetch only Active salaried users
    const salariedUsersQuery = query(
      collection(db, "users"),
      where("status", "==", "Active"),
      where("salary", "!=", "")
    );
    const salariedUsersSnapshot = await getDocs(salariedUsersQuery);
    let shiftsData = {};

    // Add salaried users to shiftsData first
    salariedUsersSnapshot.docs.forEach(doc => {
      const userData = doc.data();
      const salaryNumber = parseFloat(userData.salary) || 0;
      if (salaryNumber > 0) {
        shiftsData[userData.uid] = {
          userId: userData.uid,
          firstName: userData.firstName || "Unknown",
          lastName: userData.lastName || "Unknown",
          companyName: userData.companyName || "",
          positions: {},
          locations: {},
          method: new Set([userData.payMethod || ""]),
          hours: 0,
          rate: 0,
          total: 0,
          weeklySalary: salaryNumber / 52,
          payMethod: userData.payMethod || "",
          role: userData.role || "",
          status: "Active",
          salary: userData.salary,
          division: userData.division || "Unique Corp",
          regularHours: 0,
          overtimeHours: 0,
          services: [],
          servicesTotal: 0,
        };
      }
    });

    // Continue with existing shifts query logic
    const start = startDate ? new Date(startDate + "T00:00:00") : null;
    const end = endDate ? new Date(endDate + "T23:59:59") : null;

    let queryConditions = [collection(db, "shifts")];

    if (selectedLocation && selectedLocation !== "All Locations") {
      queryConditions.push(where("locationName", "==", selectedLocation));
    }

    if (selectedPosition && selectedPosition !== "All Positions") {
      queryConditions.push(
        where("roleChanges", "array-contains", { role: selectedPosition })
      );
    }

    if (showApprovedOnly) {
      queryConditions.push(where("approvedBy", "!=", null));
    }

    if (start) {
      queryConditions.push(where("startTimestamp", ">=", start));
    }

    if (end) {
      queryConditions.push(where("startTimestamp", "<=", end));
    }

    let shiftsQuery = query(...queryConditions);
    const querySnapshot = await getDocs(shiftsQuery);

    const userDataPromises = querySnapshot.docs.map(async (doc) => {
      const data = doc.data();

      try {
        const userDoc = await getDocs(
          query(
            collection(db, "users"),
            where("uid", "==", data.userId)
          )
        );
        const userData = userDoc.empty ? null : userDoc.docs[0].data();
        
        // Check if user has a valid salary value
        const hasSalary = userData?.salary && parseFloat(userData.salary) > 0;
        
        // If they have a salary but are not Active, skip them
        if (hasSalary && userData?.status !== "Active") {
          return null;
        }

        const userRate = !hasSalary ? (parseFloat(userData?.rate) || 0) : 0;
        const weeklySalary = hasSalary ? parseFloat(userData.salary) / 52 : 0;

        const roleHours = calculateRoleHours(
          data.startTimestamp,
          data.endTimestamp,
          data.roleChanges,
          data.breakTimestamps
        );

        return {
          ...data,
          userRate,
          roleHours,
          companyName: userData?.companyName || "",
          weeklySalary,
          payMethod: userData?.payMethod || "",
          role: userData?.role || "",
          status: userData?.status || "Active",
        };
      } catch (error) {
        console.error("Error fetching user data:", error);
        return null;
      }
    });

    const results = await Promise.all(userDataPromises);

    results.forEach((result) => {
      if (!result) return;

      const employeeKey = result.userId || "missing-userId";
      if (!shiftsData[employeeKey]) {
        shiftsData[employeeKey] = {
          userId: employeeKey,
          firstName: result.firstName || "Unknown",
          lastName: result.lastName || "Unknown",
          companyName: result.companyName || "",
          positions: {},
          locations: {},
          method: new Set(),
          hours: 0,
          rate: result.userRate,
          total: 0,
          weeklySalary: result.weeklySalary,
          payMethod: result.payMethod,
          role: result.role,
          status: result.status,
          regularHours: 0,
          overtimeHours: 0,
          services: [],
          servicesTotal: 0,
        };
      }

      Object.entries(result.roleHours).forEach(([role, hours]) => {
        if (!shiftsData[employeeKey].positions[role]) {
          shiftsData[employeeKey].positions[role] = 0;
        }
        shiftsData[employeeKey].positions[role] += hours;

        if (!shiftsData[employeeKey].locations[result.locationName]) {
          shiftsData[employeeKey].locations[result.locationName] = {
            total: 0,
            roles: {},
          };
        }
        shiftsData[employeeKey].locations[result.locationName].total += hours;
        shiftsData[employeeKey].locations[result.locationName].roles[role] =
          (shiftsData[employeeKey].locations[result.locationName].roles[role] ||
            0) + hours;

        shiftsData[employeeKey].hours += hours;

        // Check both payMethod and role for overtime eligibility
        const isEligibleForOvertime = 
          result.payMethod === '1099' && 
          result.role?.toLowerCase() === 'employee';

        if (isEligibleForOvertime) {
          const regularHours = Math.min(40, shiftsData[employeeKey].hours);
          const overtimeHours = Math.max(0, shiftsData[employeeKey].hours - 40);
          shiftsData[employeeKey].regularHours = regularHours;
          shiftsData[employeeKey].overtimeHours = overtimeHours;
          shiftsData[employeeKey].total = 
            (regularHours * result.userRate) + 
            (overtimeHours * result.userRate * 1.5);
        } else {
          // All other cases (non-1099 or non-employee roles) get regular pay
          shiftsData[employeeKey].regularHours = shiftsData[employeeKey].hours;
          shiftsData[employeeKey].overtimeHours = 0;
          shiftsData[employeeKey].total = shiftsData[employeeKey].hours * result.userRate;
        }
      });

      shiftsData[employeeKey].method.add(result.payMethod);
    });

    // Fetch services data for the date range
    let servicesQueryConditions = [collection(db, "services")];
    
    if (showApprovedOnly) {
      servicesQueryConditions.push(where("status", "==", "approved"));
    }
    
    if (start) {
      servicesQueryConditions.push(where("date", ">=", start));
    }
    
    if (end) {
      servicesQueryConditions.push(where("date", "<=", end));
    }
    
    if (selectedLocation && selectedLocation !== "All Locations") {
      servicesQueryConditions.push(where("location", "==", selectedLocation));
    }
    
    try {
      const servicesQuery = query(...servicesQueryConditions);
      const servicesSnapshot = await getDocs(servicesQuery);
      console.log(`Found ${servicesSnapshot.size} services in date range`);
      
      // Process services data - need to use for...of for await support
      for (const doc of servicesSnapshot.docs) {
        const serviceData = doc.data();
        const employeeId = serviceData.employee;
        
        if (!employeeId) {
          console.log('Service without employee ID:', serviceData);
          continue; // Skip services without employee ID
        }
        
        if (!shiftsData[employeeId]) {
          // Try to get user data first to improve display
          try {
            // Fetch user data for this service employee
            const userQuery = query(
              collection(db, "users"), 
              where("uid", "==", employeeId)
            );
            const userSnapshot = await getDocs(userQuery);
            const userData = userSnapshot.empty ? null : userSnapshot.docs[0].data();
            
            // Create a new employee entry with user data if available
            shiftsData[employeeId] = {
              userId: employeeId,
              firstName: userData?.firstName || "Unknown",
              lastName: userData?.lastName || "Unknown",
              companyName: userData?.companyName || "",
              positions: {},
              locations: {},
              method: new Set([userData?.payMethod || ""]),
              hours: 0,
              rate: userData?.rate ? parseFloat(userData.rate) : 0,
              total: 0,
              weeklySalary: userData?.salary ? parseFloat(userData.salary) / 52 : 0,
              payMethod: userData?.payMethod || "",
              role: userData?.role || "",
              status: userData?.status || "Active",
              division: userData?.division || "Unique Corp",
              regularHours: 0,
              overtimeHours: 0,
              services: [],
              servicesTotal: 0,
            };
          } catch (error) {
            console.log('Error fetching user data for service employee:', error);
            // Fallback to basic employee entry
            shiftsData[employeeId] = {
              userId: employeeId,
              firstName: "Unknown",
              lastName: "Unknown",
              companyName: "",
              positions: {},
              locations: {},
              method: new Set(),
              hours: 0,
              rate: 0,
              total: 0,
              weeklySalary: 0,
              payMethod: "",
              role: "",
              status: "Active",
              regularHours: 0,
              overtimeHours: 0,
              services: [],
              servicesTotal: 0,
            };
          }
        }
        
        // Add service data
        const serviceAmount = parseFloat(serviceData.providerRate) || 0;
        
        // Verify the service has a valid amount
        if (serviceAmount <= 0) {
          console.log('Service with zero or invalid amount:', serviceData);
        }
        
        // Add the service to the employee's list of services
        shiftsData[employeeId].services.push({
          id: doc.id,
          name: serviceData.serviceName || "Unnamed Service",
          amount: serviceAmount,
          date: serviceData.date?.toDate ? serviceData.date.toDate() : new Date(),
          location: serviceData.location || "",
          division: serviceData.division || "Unique Corp"
        });
        
        // Update their total services amount
        shiftsData[employeeId].servicesTotal += serviceAmount;
      }
    } catch (error) {
      console.error('Error fetching services:', error);
    }

    let filteredShiftsData = Object.values(shiftsData);
    console.log(`Total employees with shifts or services: ${filteredShiftsData.length}`);
    
    // Ensure we don't crash if there are no employees
    if (filteredShiftsData.length === 0) {
      setMaxHours(0);
      setMinHours(0);
      setSliderValue(0);
      return [];
    }

    const maxHours = Math.max(...filteredShiftsData.map((emp) => emp.hours), 0);
    const minHours = Math.min(...filteredShiftsData.map((emp) => emp.hours), 0);
    setMaxHours(maxHours);
    setMinHours(minHours);
    setSliderValue(minHours);

    return filteredShiftsData.map((employee) => ({
      ...employee,
      positions: employee.hours === 0 && employee.status === "Active" ? 
                (employee.servicesTotal > 0 ? "Services Only" : "Salary Only") : 
                Object.entries(employee.positions)
                  .map(([role, hours]) => `${role}: ${convertHoursToHM(hours)}`)
                  .join(", "),
      locations: employee.hours === 0 && employee.status === "Active" ? 
                (employee.servicesTotal > 0 && employee.services[0]?.location ? 
                 employee.services[0].location : "-") : 
                Object.entries(employee.locations)
                  .map(([location]) => location)
                  .join(", "),
      hours: Number(employee.hours),
      serviceAmount: employee.servicesTotal || 0,
      // Update total to include services
      total: (employee.hours === 0 && employee.status === "Active" ? 
              employee.weeklySalary : 
              Number(employee.total)) + 
             (employee.servicesTotal || 0),
      positionsDetail: employee.positions,
      locationsDetail: employee.locations,
      servicesDetail: employee.services || [],
    }));
  };

  const getDefaultDateRange = useCallback(() => {
    const today = new Date();
    const dayOfWeek = today.getDay();
    const lastSunday = new Date(today);
    lastSunday.setDate(today.getDate() - dayOfWeek - 7);
    const lastSaturday = new Date(today);
    lastSaturday.setDate(today.getDate() - dayOfWeek - 1);

    const formatDate = (date) => {
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${year}-${month}-${day}`;
    };

    return {
      startDate: formatDate(lastSunday),
      endDate: formatDate(lastSaturday),
    };
  }, []);

  useEffect(() => {
    const { startDate, endDate } = getDefaultDateRange();
    setStartDate(startDate);
    setEndDate(endDate);
  }, [getDefaultDateRange]);

  const { data: allEmployees = [], isLoading } = useQuery({
    queryKey: [
      "shifts",
      {
        startDate,
        endDate,
        showApprovedOnly,
      },
    ],
    queryFn: fetchShiftsData,
    enabled: !!startDate && !!endDate,
  });

  const filteredEmployees = useMemo(() => {
    return allEmployees
      .map((employee) => {
        // First apply location filtering
        let filteredEmployee = employee;
        
        if (selectedLocation !== "All Locations") {
          const locationData = employee.locationsDetail[selectedLocation];
          
          // Check if employee has services at this location even without shifts
          const hasServicesAtLocation = employee.servicesDetail && 
            employee.servicesDetail.some(service => service.location === selectedLocation);
          
          // If no shifts at this location and no services, skip this employee
          if (!locationData && !hasServicesAtLocation) {
            return null;
          }
          
          // Calculate service amount for this location only
          const servicesForLocation = employee.servicesDetail
            ? employee.servicesDetail
                .filter(service => service.location === selectedLocation)
                .reduce((sum, service) => sum + service.amount, 0)
            : 0;
          
          // If they have shifts at this location, create location-filtered employee data
          if (locationData) {
            filteredEmployee = {
              ...employee,
              positions: Object.entries(locationData.roles)
                .map(([role, hours]) => `${role}: ${convertHoursToHM(hours)}`)
                .join(", "),
              locations: selectedLocation,
              hours: locationData.total,
              serviceAmount: servicesForLocation,
              positionsDetail: locationData.roles,
              // Simplify locationsDetail to just include the selected location
              locationsDetail: { 
                [selectedLocation]: locationData 
              },
              // Update servicesDetail to only include services at this location
              servicesDetail: employee.servicesDetail ? 
                employee.servicesDetail.filter(service => service.location === selectedLocation) : 
                [],
              // Update total
              total: (locationData.total * employee.rate) + servicesForLocation
            };
          } else if (hasServicesAtLocation) {
            // If they only have services at this location
            filteredEmployee = {
              ...employee,
              positions: "Services Only",
              locations: selectedLocation,
              hours: 0,
              serviceAmount: servicesForLocation,
              positionsDetail: {},
              locationsDetail: {},
              // Update servicesDetail to only include services at this location
              servicesDetail: employee.servicesDetail ? 
                employee.servicesDetail.filter(service => service.location === selectedLocation) : 
                [],
              total: servicesForLocation
            };
          }
        }
        
        // Then apply division filtering
        if (selectedDivision !== "All Divisions") {
          // For salaried employees (those with salary > 0), check their division property
          const isSalariedEmployee = parseFloat(filteredEmployee.salary) > 0;
          
          if (isSalariedEmployee) {
            return filteredEmployee.division === selectedDivision ? filteredEmployee : null;
          }

          const suffix = divisionMapping[selectedDivision];
          
          // First filter positions by division
          const divisionPositions = Object.entries(filteredEmployee.positionsDetail)
            .filter(([role]) => {
              if (selectedDivision === "Unique Corp") {
                return !Object.values(divisionMapping)
                  .filter(s => s !== null)
                  .some(s => role.endsWith(s));
              }
              return role.endsWith(suffix);
            })
            .reduce((acc, [role, hours]) => {
              acc[role] = hours;
              return acc;
            }, {});

          // Also filter services by division
          const divisionServices = filteredEmployee.servicesDetail
            .filter(service => {
              if (selectedDivision === "Unique Corp") {
                return service.division === "Unique Corp" || !service.division;
              }
              return service.division === selectedDivision;
            });
          
          const divisionServiceAmount = divisionServices.reduce(
            (sum, service) => sum + service.amount, 
            0
          );

          // If no positions for this division and no services for this division, skip this employee
          if (Object.keys(divisionPositions).length === 0 && divisionServices.length === 0) {
            return null;
          }

          const divisionHours = Object.values(divisionPositions).reduce((a, b) => a + b, 0);

          return {
            ...filteredEmployee,
            positions: divisionHours === 0 && divisionServices.length > 0 ? 
                      "Services Only" :
                      Object.entries(divisionPositions)
                        .map(([role, hours]) => `${role}: ${convertHoursToHM(hours)}`)
                        .join(", "),
            hours: divisionHours,
            serviceAmount: divisionServiceAmount,
            services: divisionServices,
            // Update total to include both filtered hours and filtered services
            total: (divisionHours * filteredEmployee.rate) + divisionServiceAmount,
          };
        }

        // Handle position filtering only if no division filtering
        if (selectedDivision === "All Divisions" && selectedPosition !== "All Positions") {
          // Get the data based on current location filter state
          const positionsData = filteredEmployee.positionsDetail;
          const positionHours = positionsData[selectedPosition] || 0;
          
          if (positionHours === 0) {
            return null;
          }

          return {
            ...filteredEmployee,
            positions: `${selectedPosition}: ${convertHoursToHM(positionHours)}`,
            hours: positionHours,
            // Keep service amount and update total
            total: (positionHours * filteredEmployee.rate) + filteredEmployee.serviceAmount,
          };
        }
        
        // If we got here, we're returning the employee data with the currently applied filters
        return filteredEmployee;
      })
      .filter(Boolean);
  }, [allEmployees, selectedLocation, selectedPosition, selectedDivision, divisionMapping]);

  useEffect(() => {
    const fetchLocationsAndPositions = async () => {
      if (!startDate || !endDate) {
        console.error("Invalid startDate or endDate");
        return;
      }

      const start = new Date(`${startDate}T00:00:00`);
      const end = new Date(`${endDate}T23:59:59`);

      if (isNaN(start) || isNaN(end)) {
        console.error("Invalid start or end date");
        return;
      }

      const shiftsRef = collection(db, "shifts");
      const queryConstraints = [
        where("startTimestamp", ">=", start),
        where("startTimestamp", "<=", end),
      ];

      const shiftsQuery = query(shiftsRef, ...queryConstraints);
      const querySnapshot = await getDocs(shiftsQuery);

      const newLocations = new Set();
      const newPositions = new Set();

      querySnapshot.forEach((doc) => {
        const data = doc.data();
        const { locationName, roleChanges } = data;

        if (locationName) {
          newLocations.add(locationName);
        }

        if (roleChanges) {
          roleChanges.forEach(change => {
            if (change.role) {
              newPositions.add(change.role);
            }
          });
        }
      });

      setLocations(Array.from(newLocations).sort());
      setPositions(Array.from(newPositions).sort());
    };

    if (startDate && endDate) {
      fetchLocationsAndPositions().catch(console.error);
    }
  }, [startDate, endDate]);

  const toggleLocationDropdown = useCallback(() => {
    setLocationDropdownOpen((prev) => !prev);
  }, []);

  const togglePositionDropdown = useCallback(() => {
    setPositionDropdownOpen((prev) => !prev);
  }, []);

  const toggleDivisionDropdown = useCallback(() => {
    setDivisionDropdownOpen((prev) => !prev);
  }, []);

  const exportToExcel = useCallback(
    (exportType = 'grouped') => {
      const wb = XLSX.utils.book_new();
      
      if (exportType === 'grouped') {
        const dataToExport = filteredEmployees.filter(
          (emp) => emp.hours >= sliderValue // Remove the service exception
        );
        
        if (dataToExport.length > 0) {
          const ws = XLSX.utils.aoa_to_sheet([
            [
              "Name",
              "Status",
              "UID",
              "Position",
              "Location",
              "Hours",
              "Method",
              "Rate",
              "Regular Pay",
              "OT Pay (1.5x)",
              "Services",
              "Total ($)",
              "Weekly Salary",
            ],
            ...dataToExport.map((emp) => [
              emp.companyName || `${emp.lastName}, ${emp.firstName}`,
              emp.status || "Active",
              emp.userId,
              emp.positions,
              emp.locations,
              convertHoursToHM(emp.hours),
              emp.payMethod,
              `$${emp.rate.toFixed(2)}`,
              emp.payMethod === '1099' ? `$${(emp.rate * emp.regularHours).toFixed(2)}` : '-',
              emp.payMethod === '1099' ? `$${(emp.rate * 1.5 * emp.overtimeHours).toFixed(2)}` : '-',
              `$${emp.serviceAmount.toFixed(2)}`,
              `$${emp.total.toFixed(2)}`,
              emp.weeklySalary ? `$${emp.weeklySalary.toFixed(2)}` : '-',
            ]),
          ]);
          XLSX.utils.book_append_sheet(wb, ws, "Report");
        } else {
          // If no data, add an empty sheet with a message
          const ws = XLSX.utils.aoa_to_sheet([
            ["No matching data found for the selected filters"],
            ["Try adjusting your location, division, or other filters"]
          ]);
          XLSX.utils.book_append_sheet(wb, ws, "No Data");
        }
      } else if (exportType === 'stacked') {
        let allData = [
          [
            "Name",
            "Status",
            "UID",
            "Position",
            "Location",
            "Hours",
            "Method",
            "Rate",
            "Regular Pay",
            "OT Pay (1.5x)",
            "Services",
            "Total ($)",
            "Weekly Salary",
          ]
        ];

        // If a specific location is selected, only process that location
        const locationsToProcess = selectedLocation !== "All Locations" 
          ? [selectedLocation] 
          : locations;
        
        let hasAnyData = false;

        for (const location of locationsToProcess) {
          const locationData = allEmployees
            .map((employee) => {
              // First check if this employee has services at this location
              const hasServicesAtLocation = employee.servicesDetail && 
                employee.servicesDetail.some(service => {
                  // For location filter always check location
                  if (service.location !== location) return false;
                  
                  // When division filter is applied, also check division
                  if (selectedDivision !== "All Divisions") {
                    return selectedDivision === "Unique Corp" ? 
                      (service.division === "Unique Corp" || !service.division) : 
                      service.division === selectedDivision;
                  }
                  
                  return true;
                });
              
              // If they only have services at this location
              if (hasServicesAtLocation && !employee.locationsDetail[location]) {
                // Filter services by both location and division
                const filteredServices = employee.servicesDetail.filter(service => {
                  // Location filter
                  if (service.location !== location) return false;
                  
                  // Division filter if applied
                  if (selectedDivision !== "All Divisions") {
                    return selectedDivision === "Unique Corp" ? 
                      (service.division === "Unique Corp" || !service.division) : 
                      service.division === selectedDivision;
                  }
                  
                  return true;
                });
                
                const filteredServiceAmount = filteredServices.reduce((sum, service) => sum + service.amount, 0);
                
                return {
                  ...employee,
                  positions: "Services Only",
                  locations: location,
                  hours: 0,
                  serviceAmount: filteredServiceAmount,
                  total: filteredServiceAmount,
                  weeklySalary: 0
                };
              }
              
              const locationData = employee.locationsDetail[location];
              if (!locationData && !hasServicesAtLocation) return null;

              // Apply division filter
              let filteredRoles = {};
              let totalHours = 0;
              
              if (selectedDivision !== "All Divisions") {
                const suffix = divisionMapping[selectedDivision];
                if (locationData && locationData.roles) {
                  Object.entries(locationData.roles).forEach(([role, hours]) => {
                    if (selectedDivision === "Unique Corp") {
                      // For Unique Corp, include positions that don't end with any of the other suffixes
                      if (!Object.values(divisionMapping)
                          .filter(s => s !== null)
                          .some(s => role.endsWith(s))) {
                        filteredRoles[role] = hours;
                        totalHours += hours;
                      }
                    } else if (role.endsWith(suffix)) {
                      filteredRoles[role] = hours;
                      totalHours += hours;
                    }
                  });
                }
                
                // Skip if no matching roles found for this division and no services
                if (Object.keys(filteredRoles).length === 0 && !hasServicesAtLocation) return null;
              } else if (locationData) {
                filteredRoles = locationData.roles;
                totalHours = locationData.total;
              }

              const positionsForLocation = Object.entries(filteredRoles)
                .map(([role, roleHours]) => `${role}: ${convertHoursToHM(roleHours)}`)
                .join(", ");

              // Calculate proportional salary for this location
              let weeklySalary = 0;
              if (employee.weeklySalary) {
                if (employee.hours > 0) {
                  // If they worked hours, distribute salary proportionally
                  weeklySalary = (totalHours / employee.hours) * employee.weeklySalary;
                } else {
                  // If they're salary-only, only show in first location they're associated with
                  const firstLocation = Object.keys(employee.locationsDetail)[0];
                  weeklySalary = location === firstLocation ? employee.weeklySalary : 0;
                }
              }

              // Calculate service amount for this location
              const servicesForLocation = employee.servicesDetail
                ? employee.servicesDetail
                    .filter(service => {
                      // Location filter
                      if (service.location !== location) return false;
                      
                      // Division filter if applied
                      if (selectedDivision !== "All Divisions") {
                        return selectedDivision === "Unique Corp" ? 
                          (service.division === "Unique Corp" || !service.division) : 
                          service.division === selectedDivision;
                      }
                      
                      return true;
                    })
                    .reduce((sum, service) => sum + service.amount, 0)
                : 0;

              return {
                ...employee,
                positions: totalHours === 0 && servicesForLocation > 0 
                  ? "Services Only" 
                  : (totalHours === 0 ? "Salary Only" : positionsForLocation),
                locations: location,
                hours: totalHours,
                serviceAmount: servicesForLocation,
                // Include services in the total
                total: (employee.weeklySalary ? weeklySalary : totalHours * employee.rate) + servicesForLocation,
                weeklySalary: weeklySalary || 0
              };
            })
            .filter(Boolean)
            .filter((emp) => emp.hours >= sliderValue); // Remove the service exception here too

          if (locationData.length > 0) {
            hasAnyData = true;
            allData.push(
              ...locationData.map((emp) => [
                emp.companyName || `${emp.lastName}, ${emp.firstName}`,
                emp.status || "Active",
                emp.userId,
                emp.positions,
                emp.locations,
                convertHoursToHM(emp.hours),
                emp.payMethod,
                `$${emp.rate.toFixed(2)}`,
                emp.payMethod === '1099' ? `$${(emp.rate * emp.regularHours).toFixed(2)}` : '-',
                emp.payMethod === '1099' ? `$${(emp.rate * 1.5 * emp.overtimeHours).toFixed(2)}` : '-',
                `$${emp.serviceAmount.toFixed(2)}`,
                `$${emp.total.toFixed(2)}`,
                emp.weeklySalary > 0 ? `$${emp.weeklySalary.toFixed(2)}` : '-',
              ])
            );
          }
        }
        
        // Only create sheet if we have data
        if (hasAnyData) {
          const ws = XLSX.utils.aoa_to_sheet(allData);
          XLSX.utils.book_append_sheet(wb, ws, "Report");
        } else {
          // If no data, add an empty sheet with a message
          const ws = XLSX.utils.aoa_to_sheet([
            ["No matching data found for the selected filters"],
            ["Try adjusting your location, division, or other filters"]
          ]);
          XLSX.utils.book_append_sheet(wb, ws, "No Data");
        }
      }

      // Generate filename with date range and current time
      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
      const currentTime = new Date()
        .toLocaleTimeString("en-US", {
          hour12: false,
          hour: "2-digit",
          minute: "2-digit",
        })
        .replace(":", "");

      // Use selected location name in the filename if a specific location is selected
      const locationPrefix = selectedLocation !== "All Locations" ? 
        selectedLocation.replace(/[^a-zA-Z0-9]/g, "_") : // Replace special chars with underscores
        "All_Locations";

      const filename = `${locationPrefix}_${
        startDateObj.getMonth() + 1
      }-${startDateObj.getDate()}_${
        endDateObj.getMonth() + 1
      }-${endDateObj.getDate()}_${currentTime}.xlsx`;

      XLSX.writeFile(wb, filename);
    },
    [filteredEmployees, allEmployees, locations, sliderValue, startDate, endDate, 
     selectedDivision, divisionMapping, selectedLocation]
  );

  // Add this new useEffect to update min and max hours when filteredEmployees changes
  useEffect(() => {
    if (filteredEmployees.length > 0) {
      const hours = filteredEmployees.map((emp) => emp.hours);
      setMaxHours(Math.max(...hours));
      setMinHours(0);
      setSliderValue(0);
    } else {
      setMaxHours(0);
      setMinHours(0);
      setSliderValue(0);
    }
  }, [filteredEmployees]);

  // Update the renderReports useMemo to use filteredEmployees for displaying data
  const renderReports = useMemo(() => {
    const dataToDisplay = filteredEmployees.filter(
      (emp) => emp.hours >= sliderValue // Remove the exception for services
    );

    console.log('Filtered employees for display:', dataToDisplay.length);

    return (
      <div className="bg-[#1F2937] min-h-screen text-white">
        <div className="mb-6">
          <h1 className="text-lg font-bold text-white uppercase tracking-wider mb-2">
            Reports
          </h1>
          <p className="text-gray-400">Generate and export employee reports</p>
        </div>

        <div className="bg-white/10 backdrop-blur-sm rounded-lg p-4 mb-6 shadow-lg border border-white/20">
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-3">
            {/* Date inputs */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={startDate}
                onChange={(e) => setStartDate(e.target.value)}
                className="w-full px-3 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={endDate}
                onChange={(e) => setEndDate(e.target.value)}
                className="w-full px-3 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all"
              />
            </div>

            {/* Location dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Location
              </label>
              <div className="relative" ref={locationDropdownRef}>
                <button
                  className="w-full px-3 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={toggleLocationDropdown}
                >
                  <span className="truncate">{selectedLocation || "Select Location"}</span>
                  <svg className="w-4 h-4 flex-shrink-0 ml-1" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {locationDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-3 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedLocation("All Locations");
                        setLocationDropdownOpen(false);
                      }}
                    >
                      All Locations
                    </button>
                    {locations.map((location) => (
                      <button
                        key={location}
                        className="w-full px-3 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                        onClick={() => {
                          setSelectedLocation(location);
                          setLocationDropdownOpen(false);
                        }}
                      >
                        {location}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Position dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Position
              </label>
              <div className="relative" ref={positionDropdownRef}>
                <button
                  className="w-full px-3 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={togglePositionDropdown}
                >
                  <span className="truncate">{selectedPosition || "Select Position"}</span>
                  <svg className="w-4 h-4 flex-shrink-0 ml-1" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {positionDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-3 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedPosition("All Positions");
                        setPositionDropdownOpen(false);
                      }}
                    >
                      All Positions
                    </button>
                    {positions.map((position) => (
                      <button
                        key={position}
                        className="w-full px-3 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                        onClick={() => {
                          setSelectedPosition(position);
                          setPositionDropdownOpen(false);
                        }}
                      >
                        {position}
                      </button>
                    ))}
                  </div>
                )}
              </div>
            </div>

            {/* Division dropdown */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Division
              </label>
              <div className="relative" ref={divisionDropdownRef}>
                <button
                  className="w-full px-3 py-2 rounded-lg bg-white/5 border border-white/20 text-white focus:ring-2 focus:ring-blue-500 focus:border-blue-500 hover:border-blue-400 transition-all flex items-center justify-between"
                  onClick={toggleDivisionDropdown}
                >
                  <span className="truncate">{selectedDivision}</span>
                  <svg className="w-4 h-4 flex-shrink-0 ml-1" fill="none" viewBox="0 0 20 20">
                    <path
                      d="M5.5 7L10 11.5L14.5 7"
                      stroke="currentColor"
                      strokeWidth="1.5"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </button>
                {divisionDropdownOpen && (
                  <div className="absolute z-10 w-full mt-1 bg-gray-800 border border-white/20 rounded-lg shadow-lg max-h-60 overflow-auto">
                    <button
                      className="w-full px-3 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                      onClick={() => {
                        setSelectedDivision("All Divisions");
                        setDivisionDropdownOpen(false);
                      }}
                    >
                      All Divisions
                    </button>
                    {Object.keys(divisionMapping)
                      .sort((a, b) => a.localeCompare(b))
                      .map((division) => (
                        <button
                          key={division}
                          className="w-full px-3 py-2 text-left text-white hover:bg-gray-700 transition-colors"
                          onClick={() => {
                            setSelectedDivision(division);
                            setDivisionDropdownOpen(false);
                          }}
                        >
                          {division}
                        </button>
                      ))}
                  </div>
                )}
              </div>
            </div>

            {/* Hours slider */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Minimum Hours
              </label>
              <div className="px-2">
                <div
                  ref={rangerRef}
                  className="relative h-2 bg-gray-700 rounded-lg"
                  style={{ userSelect: "none" }}
                >
                  <div
                    className="absolute h-full bg-blue-500 rounded-lg"
                    style={{
                      width: `${
                        ((sliderValue - minHours) / (maxHours - minHours)) * 100
                      }%`,
                    }}
                  />
                  {rangerInstance
                    .handles()
                    .map(
                      (
                        {
                          value,
                          onKeyDownHandler,
                          onMouseDownHandler,
                          onTouchStart,
                          isActive,
                        },
                        i
                      ) => (
                        <button
                          key={i}
                          onKeyDown={onKeyDownHandler}
                          onMouseDown={onMouseDownHandler}
                          onTouchStart={onTouchStart}
                          role="slider"
                          aria-valuemin={rangerInstance.options.min}
                          aria-valuemax={rangerInstance.options.max}
                          aria-valuenow={value}
                          className={`absolute top-1/2 -translate-y-1/2 w-4 h-4 bg-white rounded-full shadow focus:outline-none focus:ring-2 focus:ring-blue-500 cursor-grab active:cursor-grabbing ${
                            isActive ? "z-10" : "z-0"
                          }`}
                          style={{
                            left: `${rangerInstance.getPercentageForValue(
                              value
                            )}%`,
                            transform: "translate(-50%, -50%)",
                          }}
                        />
                      )
                    )}
                </div>
                <div className="mt-2 text-right">
                  <span className="text-white text-sm bg-gray-700 px-2 py-1 rounded">
                    {convertHoursToHM(sliderValue)}
                  </span>
                </div>
              </div>
            </div>

            {/* Approved only toggle */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Filter
              </label>
              <button
                onClick={() => setShowApprovedOnly(!showApprovedOnly)}
                className={`w-full px-3 py-2 rounded-lg flex items-center justify-center gap-2 transition-all ${
                  showApprovedOnly
                    ? "bg-green-500/20 text-green-300 border border-green-500"
                    : "bg-white/5 text-gray-300 border border-white/20"
                }`}
              >
                <FontAwesomeIcon
                  icon={faCheck}
                  className={showApprovedOnly ? "opacity-100" : "opacity-50"}
                />
                Approved Only
              </button>
            </div>

            {/* Export button */}
            <div>
              <label className="block text-sm font-medium text-gray-200 mb-1">
                Export
              </label>
              <div className="relative" ref={exportDropdownRef}>
                <button
                  onClick={() => {
                    if (selectedLocation !== "All Locations") {
                      exportToExcel('stacked');
                    } else {
                      setShowExportOptions((prev) => !prev);
                    }
                  }}
                  className="w-full px-3 py-2 rounded-lg bg-blue-500 hover:bg-blue-600 text-white transition-colors flex items-center justify-center gap-2"
                >
                  <FontAwesomeIcon icon={faFileExport} />
                  Export Report
                  {selectedLocation === "All Locations" && (
                    <svg className="w-4 h-4" fill="none" viewBox="0 0 20 20">
                      <path
                        d="M5.5 7L10 11.5L14.5 7"
                        stroke="currentColor"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  )}
                </button>
                {showExportOptions && selectedLocation === "All Locations" && (
                  <div className="absolute right-0 mt-1 w-56 rounded-lg shadow-lg bg-gray-800 border border-white/20 z-20">
                    <div className="py-1">
                      <button
                        onClick={() => {
                          exportToExcel('grouped');
                          setShowExportOptions(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700 transition-colors"
                      >
                        Export Combined
                      </button>
                      <button
                        onClick={() => {
                          exportToExcel('stacked');
                          setShowExportOptions(false);
                        }}
                        className="block w-full text-left px-4 py-2 text-sm text-white hover:bg-gray-700 transition-colors"
                      >
                        Export Stacked
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {isLoading ? (
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full table-auto">
                <thead className="bg-gray-200 text-gray-700">
                  <tr>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Name</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Status</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">UID</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Position</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Location</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Hours</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Method</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Rate</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Regular</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">OT</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Services</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Total</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Salary</th>
                  </tr>
                </thead>
                <tbody className="text-gray-700">
                  {[...Array(5)].map((_, index) => (
                    <tr key={index}>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                      <td className="border-t px-3 py-2">
                        <Skeleton />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="bg-white rounded-lg shadow-md overflow-hidden">
            <div className="overflow-x-auto">
              <table className="w-full table-auto border-collapse">
                <thead className="bg-gray-200 text-gray-700">
                  <tr>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Name</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Status</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold max-w-[80px]">UID</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Position</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Location</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Hours</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Method</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Rate</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Regular</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">OT</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Services</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Total</th>
                    <th className="px-3 py-2 text-left text-sm font-semibold">Salary</th>
                  </tr>
                </thead>

                <tbody className="text-gray-700 text-sm">
                  {dataToDisplay.map((employee) => (
                    <tr 
                      key={employee.userId}
                      className={employee.status === "terminated" ? "bg-red-50" : ""}
                    >
                      <td className="border-t px-3 py-2 max-w-[150px] truncate" title={employee.companyName || `${employee.lastName}, ${employee.firstName}`}>
                        {employee.companyName ||
                          `${employee.lastName}, ${employee.firstName}`}
                      </td>
                      <td className="border-t px-3 py-2">
                        <span className={employee.status === "terminated" ? "text-red-600 font-medium" : ""}>
                          {employee.status || "Active"}
                        </span>
                      </td>
                      <td className="border-t px-3 py-2 max-w-[80px] truncate" title={employee.userId}>{employee.userId}</td>
                      <td className="border-t px-3 py-2 max-w-[150px] truncate" title={employee.positions}>{employee.positions}</td>
                      <td className="border-t px-3 py-2 max-w-[120px] truncate" title={employee.locations}>{employee.locations}</td>
                      <td className="border-t px-3 py-2">
                        {convertHoursToHM(employee.hours)}
                      </td>
                      <td className="border-t px-3 py-2">
                        {employee.payMethod}
                      </td>
                      <td className="border-t px-3 py-2">${employee.rate}</td>
                      <td className="border-t px-3 py-2">
                        {employee.payMethod === '1099' 
                          ? `$${(employee.rate * employee.regularHours).toFixed(2)}`
                          : '-'}
                      </td>
                      <td className="border-t px-3 py-2">
                        {employee.payMethod === '1099' 
                          ? `$${(employee.rate * 1.5 * employee.overtimeHours).toFixed(2)}`
                          : '-'}
                      </td>
                      <td className="border-t px-3 py-2">
                        ${employee.serviceAmount.toFixed(2)}
                      </td>
                      <td className="border-t px-3 py-2">
                        ${employee.total.toFixed(2)}
                      </td>
                      <td className="border-t px-3 py-2">
                        {employee.weeklySalary ? `$${employee.weeklySalary.toFixed(2)}` : '-'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        )}
      </div>
    );
  }, [
    filteredEmployees,
    startDate,
    endDate,
    toggleLocationDropdown,
    selectedLocation,
    locationDropdownOpen,
    locations,
    minHours,
    maxHours,
    sliderValue,
    showApprovedOnly,
    exportToExcel,
    isLoading,
    showExportOptions,
    rangerInstance,
    togglePositionDropdown,
    selectedPosition,
    positions,
    positionDropdownOpen,
    selectedDivision,
    divisionDropdownOpen,
    toggleDivisionDropdown,
    divisionMapping
  ]);

  return (
    <div className="bg-[#1F2937] min-h-screen text-white">
      {renderReports}
    </div>
  );
};

export default Reports;
