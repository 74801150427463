/**
 * Utility function to provide user-friendly Firebase auth error messages
 * @param {string} errorCode - The Firebase auth error code
 * @returns {string} A user-friendly error message
 */
export const getFriendlyAuthErrorMessage = (errorCode) => {
  const errorMessages = {
    // Login errors
    "auth/wrong-password":
      "The password you entered is incorrect. Please try again.",
    "auth/user-not-found":
      "No account found with this email. Please check your email and try again.",
    "auth/invalid-login-credentials":
      "Invalid login credentials. Please check your email and password then try again.",
    "auth/invalid-credential":
      "Invalid login credentials. Please check your email and password then try again.",
    "auth/too-many-requests":
      "Too many failed attempts. Please wait a few minutes before trying again.",
    "auth/account-disabled":
      "Your account has been disabled. Please contact your supervisor for assistance.",
    
    // Password reset errors
    "auth/invalid-action-code":
      "The password reset link has expired or already been used. Please request a new password reset email and use it within 1 hour.",
    "auth/expired-action-code":
      "The password reset link has expired. Please request a new password reset email.",
    "auth/weak-password":
      "Password should be at least 6 characters long.",
    "auth/user-disabled":
      "This account has been disabled. Please contact support for assistance.",
    
    // Email action errors
    "auth/invalid-email":
      "The email address is not valid. Please enter a valid email address.",
    "auth/email-already-in-use":
      "This email is already in use. Please use a different email or try to log in instead.",
  };
  
  return (
    errorMessages[errorCode] ||
    `An unexpected error occurred (${errorCode}). Please try again later.`
  );
}; 