import React from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import { format, parseISO, eachDayOfInterval } from "date-fns";
import logo from "./assets/images/unique_onthego_logo.jpg";

const styles = StyleSheet.create({
  page: {
    padding: 30,
    backgroundColor: "#ffffff",
    width: "100%",
  },
  header: {
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 30,
    borderBottom: 2,
    borderBottomColor: "#2563eb",
    paddingBottom: 15,
  },
  logo: {
    width: 150,
    height: 43,
  },
  title: {
    fontSize: 28,
    color: "#1e3a8a",
    fontWeight: "bold",
    flex: 1,
    marginRight: 20,
  },
  section: {
    marginBottom: 25,
  },
  sectionTitle: {
    fontSize: 16,
    color: "#1e3a8a",
    fontWeight: "bold",
    marginBottom: 10,
    borderBottom: 1,
    borderBottomColor: "#e5e7eb",
    paddingBottom: 5,
  },
  table: {
    display: "flex",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#e5e7eb",
    marginBottom: 20,
  },
  tableRow: {
    flexDirection: "row",
    borderBottomWidth: 1,
    borderBottomColor: "#e5e7eb",
  },
  tableHeader: {
    backgroundColor: "#f3f4f6",
  },
  tableCell: {
    padding: 5,
    fontSize: 8,
    textAlign: "center",
    flex: 1,
    borderRightWidth: 1,
    borderRightColor: "#e5e7eb",
  },
  employeeCell: {
    width: "15%",
    borderRightWidth: 1,
    borderRightColor: "#e5e7eb",
    padding: 5,
    fontSize: 8,
  },
  totalCell: {
    backgroundColor: "#f3f4f6",
    fontWeight: "bold",
  },
});

const TimesheetPDF = ({ groups, startDate, endDate, showDetailedView }) => {
  // Filter out empty groups
  const nonEmptyGroups = (groups || []).filter(group => 
    group.employees && 
    group.employees.length > 0 && 
    group.employees.some(employee => 
      Object.keys(employee.dailyHours || {}).length > 0
    )
  );

  // Only render if there are non-empty groups
  if (nonEmptyGroups.length === 0) {
    return (
      <Document>
        <Page orientation="landscape" size={[842, 595]} style={styles.page}>
          <View style={styles.header}>
            <Text style={styles.title}>Timesheet Report</Text>
            <Image style={styles.logo} src={logo} />
          </View>
          <Text>No timesheet data available for the selected period.</Text>
        </Page>
      </Document>
    );
  }

  const dateRange = eachDayOfInterval({
    start: parseISO(startDate),
    end: parseISO(endDate),
  });

  const formatShiftTime = (timestamp) => {
    if (!timestamp) return "";
    try {
      // Handle both Firestore Timestamp and regular Date objects
      const date = timestamp instanceof Date 
        ? timestamp 
        : timestamp?.toDate?.()
        ? timestamp.toDate()
        : new Date(timestamp);
      return format(date, "h:mm a");
    } catch (error) {
      console.error("Error formatting time:", error);
      return "Invalid";
    }
  };

  const calculateTotal = (dailyHours) => {
    try {
      return Object.values(dailyHours)
        .reduce((sum, hours) => {
          const hourValue = typeof hours === 'object' 
            ? Object.values(hours)[0] 
            : hours;
          return sum + (parseFloat(hourValue) || 0);
        }, 0)
        .toFixed(2);
    } catch (error) {
      console.error("Error calculating total:", error);
      return "0.00";
    }
  };

  return (
    <Document>
      <Page orientation="landscape" size={[842, 595]} style={styles.page}>
        <View style={styles.header}>
          <Text style={styles.title}>Timesheet Report</Text>
          <Image style={styles.logo} src={logo} />
        </View>

        {nonEmptyGroups.map((group, index) => (
          <View key={index} style={styles.section}>
            <Text style={styles.sectionTitle}>
              {group.location || 'Unknown'} ({group.position || 'Unknown'})
            </Text>
            <View style={styles.table}>
              <View style={[styles.tableRow, styles.tableHeader]}>
                <Text style={styles.employeeCell}>Employee</Text>
                {dateRange.map((date) => (
                  <Text key={date.toString()} style={styles.tableCell}>
                    {format(date, "MMM d")}
                  </Text>
                ))}
                <Text style={styles.tableCell}>Total</Text>
              </View>

              {(group.employees || []).map((employee) => (
                <View key={employee.userId || index} style={styles.tableRow}>
                  <Text style={styles.employeeCell}>
                    {`${employee.firstName || ''} ${employee.lastName || ''}`}
                  </Text>
                  {dateRange.map((date) => {
                    const dateKey = format(date, "yyyy-MM-dd");
                    let hours = "Off";
                    
                    try {
                      if (employee.dailyHours?.[dateKey]) {
                        if (showDetailedView && employee.shifts?.[dateKey]) {
                          const shift = employee.shifts[dateKey];
                          hours = `${formatShiftTime(shift.startTimestamp)} - ${formatShiftTime(shift.endTimestamp)}`;
                        } else {
                          const hourValue = typeof employee.dailyHours[dateKey] === 'object'
                            ? Object.values(employee.dailyHours[dateKey])[0]
                            : employee.dailyHours[dateKey];
                          hours = parseFloat(hourValue).toFixed(2);
                        }
                      }
                    } catch (error) {
                      console.error("Error processing hours:", error);
                      hours = "Error";
                    }

                    return (
                      <Text key={dateKey} style={styles.tableCell}>
                        {hours}
                      </Text>
                    );
                  })}
                  <Text style={[styles.tableCell, styles.totalCell]}>
                    {calculateTotal(employee.dailyHours || {})}
                  </Text>
                </View>
              ))}
              
              {/* Group Total Row */}
              <View style={[styles.tableRow, { backgroundColor: "#f3f4f6" }]}>
                <Text style={[styles.employeeCell, { fontWeight: "bold" }]}>
                  Total
                </Text>
                {dateRange.map((date) => (
                  <Text key={date.toString()} style={styles.tableCell}>
                    {/* Leave date columns empty for the total row */}
                  </Text>
                ))}
                <Text style={[styles.tableCell, { fontWeight: "bold" }]}>
                  {(group.employees || []).reduce((sum, employee) => {
                    return sum + parseFloat(calculateTotal(employee.dailyHours || {}));
                  }, 0).toFixed(2)}
                </Text>
              </View>
            </View>
          </View>
        ))}

        {/* Grand Total Summary Section */}
        <View style={[styles.section, { marginTop: 30, borderTop: 1, borderTopColor: '#2563eb', paddingTop: 10 }]}>
          <Text style={[styles.sectionTitle, { fontSize: 18 }]}>Summary</Text>
          <View style={styles.table}>
            <View style={[styles.tableRow, styles.tableHeader]}>
              <Text style={{ ...styles.tableCell, flex: 2 }}>Location</Text>
              <Text style={{ ...styles.tableCell, flex: 2 }}>Position</Text>
              <Text style={{ ...styles.tableCell, flex: 1 }}>Total Hours</Text>
            </View>

            {nonEmptyGroups.map((group, index) => {
              const groupTotal = (group.employees || []).reduce((sum, employee) => {
                return sum + parseFloat(calculateTotal(employee.dailyHours || {}));
              }, 0);
              
              return (
                <View key={index} style={styles.tableRow}>
                  <Text style={{ ...styles.tableCell, flex: 2 }}>
                    {group.location || 'Unknown'}
                  </Text>
                  <Text style={{ ...styles.tableCell, flex: 2 }}>
                    {group.position || 'Unknown'}
                  </Text>
                  <Text style={{ ...styles.tableCell, flex: 1 }}>
                    {groupTotal.toFixed(2)}
                  </Text>
                </View>
              );
            })}

            {/* Grand Total Row */}
            <View style={[styles.tableRow, { backgroundColor: "#f3f4f6" }]}>
              <Text style={{ ...styles.tableCell, flex: 2, fontWeight: "bold" }}>
                Grand Total
              </Text>
              <Text style={{ ...styles.tableCell, flex: 2 }}>
                {/* Empty cell */}
              </Text>
              <Text style={{ ...styles.tableCell, flex: 1, fontWeight: "bold" }}>
                {nonEmptyGroups.reduce((sum, group) => {
                  return sum + (group.employees || []).reduce((groupSum, employee) => {
                    return groupSum + parseFloat(calculateTotal(employee.dailyHours || {}));
                  }, 0);
                }, 0).toFixed(2)}
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default TimesheetPDF; 